import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { nanoid } from '@reduxjs/toolkit'
import { getPromiseStatus } from 'api/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import { AppDialog, AppSpinner, AppTypography, SharedIcon } from 'shared'
import * as parkCar from 'store/slices/parkCarSlice'
import { Lot } from 'typedef/lot'
import { Translations } from 'utils'
import { makeStyles } from '@mui/styles'

export interface SelectLotDialogProps {
  lots: Lot[]
  open: boolean
  closeDialog: () => void
}

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 36,
  },
}))

export const SelectLotDialog: React.FC<SelectLotDialogProps> = props => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const promiseStatus = useSelector(parkCar.selectPromiseStatus)
  const { pending } = getPromiseStatus(promiseStatus.getLotDetailsPark.status)

  const onClickItem = async (lotId: string) =>
    history.push(`${paths.parking.parkCar}/${lotId}`)

  return (
    <AppDialog
      fullWidth={false}
      open={props.open}
      onBackdropClick={() => (!pending ? props.closeDialog() : undefined)}
    >
      <AppSpinner show={pending} />
      <AppTypography weight="bold" align="center" color="textPrimary">
        {t(Translations.PARK_ANOTHER_CAR)}
      </AppTypography>
      <List dense={true}>
        {props.lots.map(el => (
          <ListItem button onClick={() => onClickItem(el.lotId)} key={nanoid()}>
            <ListItemIcon className={classes.root}>
              <SharedIcon.Outlined.Pin />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <AppTypography color="textPrimary">{el.lotName}</AppTypography>
              }
              secondary={
                <AppTypography weight={700} color="textPrimary">
                  {el.locationName}
                </AppTypography>
              }
            />
          </ListItem>
        ))}
      </List>
    </AppDialog>
  )
}

export default SelectLotDialog
