import { ErrorCode, ErrorResponse } from 'typedef'

import T from 'utils/translations'

// TODO:
export const getTerms: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
}

export const TermsApiError = { getTerms }
export default TermsApiError
