import { Divider, List } from '@mui/material'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { T2Fields } from 'pages/park_car/ParkCarT2'
import SelectPriceItem from 'pages/park_car/ParkCarComponents/SelectPriceItem'
import React from 'react'
import { parkCarSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { ParkCarState } from 'store/slices/parkCarSlice types'

export interface PricingListProps {
  onSelect?: () => void
  limit?: number
}

export const PricingList: React.FC<PricingListProps> = props => {
  const { values, setFieldValue } = useFormikContext<T2Fields>()
  const { selectedPricing, pricings } = values
  const dispatch = useAppDispatch()

  const onClick = (id: string) => {
    let arg = id
    if (id === selectedPricing) {
      arg = ''
    }
    setFieldValue('selectedPricing', arg)
    dispatch(
      parkCarSlice.actions.updateParkingDetails<
        Partial<ParkCarState['parkingDetails']>
      >({
        externalPricingId: arg,
      })
    )

    arg && props.onSelect()
  }

  React.useEffect(() => {
    if (selectedPricing) {
      const obj = pricings
        .slice(0, props.limit)
        .find(el => el.externalPricingId === selectedPricing)

      if (!obj) {
        setFieldValue('selectedPricing', '')
        dispatch(
          parkCarSlice.actions.updateParkingDetails<
            Partial<ParkCarState['parkingDetails']>
          >({
            fees: [],
            parkingExpireDateTime: '',
            totalAmount: 0,
            externalPricingId: '',
          })
        )
      } else {
        dispatch(
          parkCarSlice.actions.updateParkingDetails<
            Partial<ParkCarState['parkingDetails']>
          >({
            fees: obj.fees,
            parkingExpireDateTime: obj.parkingExpireDateTime,
            totalAmount: obj.amount,
            externalPricingId: obj.externalPricingId,
          })
        )
      }
    }
  }, [dispatch, pricings, props.limit, selectedPricing, setFieldValue])

  return (
    <>
      <List>
        <Divider />
        {pricings.slice(0, props.limit).map(el => (
          <SelectPriceItem
            amount={el.amount}
            label={moment(el.parkingExpireDateTime)
              .format('LT, MMM DD')
              .toUpperCase()}
            onClick={() => onClick(el.externalPricingId)}
            key={el.externalPricingId}
            selected={el.externalPricingId === selectedPricing}
            divider
          />
        ))}
      </List>
      {props.children}
    </>
  )
}
PricingList.defaultProps = {
  onSelect: () => undefined,
}
export default PricingList
