import { useTheme } from '@mui/material/styles'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { SharedIcon } from 'shared'
import { RootState } from 'store/rootReducer'
import { mapSlice, simulatePriceSlice } from 'store/slices'
import styled, { css } from 'styled-components'
interface LotMarkerProps {
  lotId?: string
  lat: number
  lng: number
  $hover?: boolean
  // findCarMode: boolean
  isSelected: boolean
  isCluster: boolean
  pointCount: number
  manyLots?: boolean
  lotsIds?: string[]
  pricingMode?: boolean
  // onChildClick: () => void
}

const PinContainer = styled.div<{
  hovered: boolean
  isSelected: boolean
  isCluster: boolean
  pointCount: number
  hoverColor?: string
  manyLots: boolean
  lotsCount: number
  color: string
  pricingMode: boolean
  simulatedPrice?: string
  mounted?: boolean
}>`
  position: absolute;
  transform-origin: left center;
  transform: translate(-50%, -50%);
  transition: all 0.2s;
  opacity: ${_ => (_.mounted ? 1 : 0)};
  path {
    fill: ${_ => _.color};
  }
  &:after {
    border-radius: 100%;
    position: absolute;
    top: 45%;
    left: 50%;
    font-weight: bold;
    transform: translate(-50%, -60%);
  }
  ${_ => {
    let content
    if (_.manyLots) {
      content = _.lotsCount
    }
    if (_.isCluster) {
      content = _.pointCount
    }
    if (_.manyLots || _.isCluster) {
      return css`
        &:after {
          color: ${_.color};
          content: '${content}';
          font-size: 1.6em;
        }
      `
    }

    return null
  }}
  ${_ => {
    if (_.pricingMode && !(_.manyLots || _.isCluster)) {
      return css`
        &:after {
          color: ${_.color};
          content: '${_.simulatedPrice || '?'}';
          font-size: 1.4em;
          background: white;
          border: 2px solid white;
        }
      `
    }

    return null
  }}

  ${_ =>
    _.isSelected &&
    css`
      z-index: 2;
      transform:  scale(${_.pricingMode ? 1.2 : 1.5}) translate(-50%, -50%);
      path {
        fill: ${_.hoverColor};
      }
            &:after {
        color:${_.hoverColor};
    `}

    ${_ =>
    _.hovered &&
    _.hoverColor &&
    css`
      path {
        fill: ${_.hoverColor};
      }
      &:after {
        color:${_.hoverColor};
    `}
`

const LotMarker: React.FC<LotMarkerProps> = props => {
  const mountedRef = React.useRef(false)
  const [mounted, setMounted] = React.useState(false)
  const { hasParkedCar } = useSelector((state: RootState) =>
    mapSlice.selectHasParkedCar(state, props.lotId)
  )
  const { findCarMode } = useSelector(mapSlice.stateSelectors)

  const simulatedPrice = useSelector((state: RootState) =>
    simulatePriceSlice.selectPriceString(state, props.lotId)
  )
  const { pin } = useTheme().palette
  React.useEffect(() => {
    setTimeout(() => mountedRef.current && setMounted(true), 50)
    return () => {
      mountedRef.current = false
    }
  }, [])
  mountedRef.current = true

  if (findCarMode && !props.isCluster && hasParkedCar) return null

  return (
    <PinContainer
      hovered={props.$hover}
      isSelected={props.isSelected}
      color={pin.main}
      hoverColor={pin.light}
      isCluster={props.isCluster}
      pointCount={props.pointCount}
      manyLots={props.manyLots}
      lotsCount={props.lotsIds?.length}
      pricingMode={props.pricingMode}
      simulatedPrice={simulatedPrice}
      mounted={mounted}
    >
      {props.manyLots || props.isCluster ? (
        <SharedIcon.MapPin.ClusterPin />
      ) : props.pricingMode ? (
        <SharedIcon.MapPin.PricePin />
      ) : (
        <SharedIcon.MapPin.StandardPin />
      )}
    </PinContainer>
  )
}

export default LotMarker
