import { useChangeLayout } from 'hooks'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import { PaddingContainer, PageDialog } from 'shared'
import SendMailComplete from './SendMailComplete'
import SendMeMail from './SendMeMail'
import { useSendEmail } from './useSendEmail'

interface ForgotPasswordProps {}

export interface PersonalData {
  email: string
}

const ForgotPassword = (props: ForgotPasswordProps) => {
  const [success, setSuccess] = React.useState(false)
  const history = useHistory()
  const sendEmail = useSendEmail(setSuccess)
  useChangeLayout()
  const goBack = () => {
    history.push(paths.auth.login)
  }

  return (
    <>
      <PaddingContainer>
        <SendMeMail handleSubmit={sendEmail} goBack={goBack} />
      </PaddingContainer>
      <PageDialog open={success}>
        <SendMailComplete goBack={goBack} />
      </PageDialog>
    </>
  )
}

export default ForgotPassword
