import { TextField, TextFieldProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import styled from 'styled-components'

const StyledTextField = styled(TextField)`
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  .MuiSelect-select {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .MuiOutlinedInput-inputMarginDense {
    padding-bottom: 8px;
    padding-top: 8px;
  }
  .MuiInputBase-root.Mui-error {
    border: none;
  }
`
export interface AppTextFieldProps {}

const useStyles = makeStyles(_ => ({
  outlinedRoot: {
    borderRadius: 18,
    border: 0,
    // height: 34,
    '&:hover $notchedOutline': {
      borderColor: _.palette.primary.main,
    },
    '&$focused $notchedOutline': {
      borderColor: _.palette.primary.main,
      borderWidth: 1,
    },
    '&$disabled': {
      background: '#f5F5F5',
    },
    '&$disabled $notchedOutline': {
      borderColor: _.palette.divider,
    },
  },
  notchedOutline: {},
  focused: {},
  disabled: {},
  input: {},
}))

export const AppTextField: React.FC<AppTextFieldProps & TextFieldProps> = ({
  InputProps,
  ...props
}) => {
  const classes = useStyles(props)
  const CustomInputProps = {
    classes: {
      root: classes.outlinedRoot,
      notchedOutline: classes.notchedOutline,
      focused: classes.focused,
      input: classes.input,
      disabled: classes.disabled,
    },
  }
  return (
    <StyledTextField
      {...props}
      InputProps={{ ...CustomInputProps, ...InputProps }}
    />
  )
}
AppTextField.defaultProps = {
  variant: 'outlined',
  size: 'small',
}
export default AppTextField
