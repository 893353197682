import { useGetTicket } from 'pages/park_car/ParkCarHooks'
import usePaymentCard from 'pages/park_car_credit_card/CreditCardComponents/creditCardHooks/usePaymentCard'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import paths from 'routing/paths'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'
import { customerSlice, parkCarSlice, ticketsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { PayForCarPayload } from 'typedef'
import Translations from 'utils/translations'
import useFailedPay from './useFailedPay'

export const usePay = () => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const { t } = useTranslation()
  const { ticketId, carId } = useParams<{ carId?: string; ticketId?: string; }>()
  const [loading, setLoading] = React.useState(false)
  const { customerSignedIn, customerSignedInAsGuest } = useSelector(
    customerSlice.selectCustomerStatus
  )
  const { paymentCard, hasCardBeenUsed, zipCode, recaptchaToken } = usePaymentCard()
  const { setPopup, showPopup } = usePopup()
  const { handleRejection: onFailedPay } = useFailedPay()
  const [, getTicket] = useGetTicket()

  const onSuccessPayment = async () => {
    setPopup({
      title: t(Translations.PAYMENT_SUCCESSFUL),
      variant: 'success',
      description: ' ',
    })
    showPopup(true)
    await getTicket()
    setLoading(false)

    dispatch(parkCarSlice.actions.resetParkingDetails())
    dispatch(
      ticketsSlice.actions.setSelectedTicket({
        ticketId: carId || ticketId,
      })
    )
    return push(paths.parking.tickets)
  }

  const pay = React.useCallback(async () => {
    let args: PayForCarPayload = {
      carId: ticketId,
      zipCode,
      recaptchaToken
    }

    if (customerSignedIn) {
      args = {
        ...args,
        creditCardId: paymentCard.creditCardId,
      }
    }

    if (customerSignedInAsGuest && !hasCardBeenUsed) {
      args = {
        ...args,
        creditCardInfo: {
          ccNumber: paymentCard?.ccNumber,
          expirationMonth: paymentCard?.expirationMonth,
          expirationYear: paymentCard?.expirationYear,
        },
      }
    }
    setLoading(true)
    const promise = await dispatch(ticketsSlice.payForCar(args))
    if (ticketsSlice.payForCar.rejected.match(promise)) {
      setLoading(false)
      onFailedPay(promise.payload)
    } else {
      onSuccessPayment()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerSignedIn,
    customerSignedInAsGuest,
    dispatch,
    hasCardBeenUsed,
    paymentCard,
    push,
    setPopup,
    showPopup,
    t,
    ticketId,
    zipCode,
    recaptchaToken
  ])

  return { pay, loading, onSuccessPayment }
}

export default usePay
