/* eslint-disable no-console */
import withClearCache from 'ClearCache'
import React from 'react'
import ReactDOM from 'react-dom'
import 'api/axios'
import './i18n'
import { Provider } from 'react-redux'
import { Route, Router } from 'react-router-dom'
import { store } from 'store'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { isLocalhost } from './utils/env'
import history from './historyListener'
import '@mui/material'
// import { worker } from 'mocks/browser'

const ClearCacheComponent = withClearCache(App)

// MSV - to enable intercept requests start msw worker below
// Execute the init command of the Mock Service Worker CLI:
// $ npx msw init public/ --save
// to create mockCerviceWorker.js file

// if (process.env.NODE_ENV === 'development') {
//   worker.start()
// }

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Route component={ClearCacheComponent} />
    </Router>
  </Provider>,
  document.getElementById('root')
)
// })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: (registration: ServiceWorkerRegistration) => {
    if (isLocalhost) {
      console.log('Service worker registered.')
      console.log(registration)
    }
  },
  onUpdate: (registration: ServiceWorkerRegistration) => {
    if (isLocalhost) {
      console.log('Service worker updated.')
      console.log(registration)
    }
  },
})
