import { useChangeLayout, useUpdateTicket } from 'hooks'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AppSnackBarProvider } from 'shared'
import { customerSlice } from 'store/slices'
import { Translations } from 'utils'
import ParkerContainer from './ParkerComponents/ParkerContainer'
import { ParkerContainerGuest } from './ParkerComponents/ParkerContainerGuest'

interface ParkerPageProps {}

const ParkerPage = (props: ParkerPageProps) => {
  const { t } = useTranslation()
  const { customerSignedIn, customerSignedInAsGuest } = useSelector(
    customerSlice.selectCustomerStatus
  )
  useUpdateTicket()
  const title = t(Translations.MY_PROFILE)
  useChangeLayout(
    {
      topbar: {
        title,
        hasGoBack: false,
      },
    },
    'app'
  )

  return (
    <AppSnackBarProvider hasBottomGutter>
      {customerSignedIn && <ParkerContainer />}
      {customerSignedInAsGuest && <ParkerContainerGuest />}
    </AppSnackBarProvider>
  )
}

export default ParkerPage
