import { Divider } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Translations } from 'utils'
import AppTypography from './AppTypography'

const Wrapper = styled.div`
  position: relative;
  margin: 6px;
  p {
    position: absolute;
    background: white;
    top: -12px;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    padding: 0 10px;
    top: 0;
    color: ${_ => _.theme.palette.divider};
    text-transform: uppercase;
  }
`

export interface TextDividerProps {
  translation?: keyof typeof Translations
}

export const TextDivider: React.FC<TextDividerProps> = props => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Divider />
      {props.translation && (
        <AppTypography weight="bold">
          {t(Translations[props.translation])}
        </AppTypography>
      )}
    </Wrapper>
  )
}

export default TextDivider
