import { IconButton } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import React, { HTMLProps } from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as ErrorIconSvg } from '../assets/icons/input_error_icon.svg'

const InputContainer = styled.div<InputProps>`
  border: 1.25px solid;
  border-radius: 32.25px;
  display: flex;
  width: 100%;
  flex-direction: row;
  box-sizing: border-box;
  background: ${props => (props.disabled ? '#F5F5F5' : '#FFFFFF')};
  border-color: ${_ => (_.error ? '#FF3333' : 'rgba(0, 0, 0, 0.23)')};
  overflow: hidden;
  height: 36px;
  &:hover,
  &:focus-within {
    border-color: ${props => props.theme.palette.primary.main};
  }
  ${_ =>
    !_.noMargin &&
    css`
      margin-bottom: 15px;
    `}
`

const BaseInput = styled.input<InputProps>`
  flex: 1;
  border: none;
  padding: ${props => (props.LeftIcon ? '5px 0px' : '10px 10px')};

  font-size: 16px;
  line-height: 20px;

  min-width: 0px;
  padding-right: 0px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.23);
  }

  &:disabled {
    background: #f5f5f5;
    color: ${_ => _.theme.palette.grey['500']};
  }
`

const ErrorIconStyled = styled(ErrorIconSvg)`
  align-self: center;
  margin-right: 10px;
`

export interface InputProps extends HTMLProps<HTMLInputElement> {
  error?: boolean
  fullWidth?: boolean
  LeftIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  leftIconClick?: () => void
  RightIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  rightIconClick?: () => void
  noMargin?: boolean
}

const theme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '0px 18.75px',
        },
      },
    },
  },
})

export const Input: React.FC<InputProps & { refProp?: any }> = props => {
  const {
    as: _as,
    refProp,
    LeftIcon,
    RightIcon,
    leftIconClick,
    rightIconClick,
    style,
    noMargin,
    className,
    ...other
  } = props
  return (
    <InputContainer
      disabled={props.disabled}
      error={props.error}
      style={style}
      className={className}
      noMargin={noMargin}
    >
      <ThemeProvider theme={theme}>
        {LeftIcon && (
          <IconButton onClick={leftIconClick}>
            <LeftIcon />
          </IconButton>
        )}
        <BaseInput LeftIcon={LeftIcon} ref={refProp} {...other}></BaseInput>
        {RightIcon && (
          <IconButton onClick={rightIconClick}>
            <RightIcon />
          </IconButton>
        )}
        {props.error && <ErrorIconStyled width={30} height={30} />}
      </ThemeProvider>
    </InputContainer>
  )
}

export default Input
