import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { paths } from 'routing'
import { layoutSlice, mapSlice, ticketsSlice } from 'store/slices'
import { AppState, useAppDispatch } from 'store/store'
import { parseCustomStyles } from 'utils'

export interface useCustomColorsProps {}

export const useCustomLotColors = () => {
  const dispatch = useAppDispatch()
  const { customStyles } = useSelector(layoutSlice.stateSelectors)
  const { lotId, carId, ticketId } = useParams<{
    lotId?: string
    carId?: string
    ticketId?: string
  }>()
  const lotByLotId = useSelector((state: AppState) =>
    mapSlice.selectById(state, lotId)
  )

  const lotByCarId = useSelector((state: AppState) =>
    mapSlice.selectLotByTicketId(state, carId || ticketId)
  )

  const tickets = useSelector(ticketsSlice.selectSortedParkedTickets)

  const { pathname } = useLocation()

  React.useEffect(() => {
    if (lotByLotId) {
      dispatch(
        layoutSlice.actions.setCustomStyles(
          parseCustomStyles(lotByLotId.customStyles)
        )
      )
      return
    }
    if (lotByCarId) {
      dispatch(
        layoutSlice.actions.setCustomStyles(
          parseCustomStyles(lotByCarId.customStyles)
        )
      )
      return
    }
    if (tickets[0]?.customStyles.length && pathname === paths.parking.tickets) {
      dispatch(
        layoutSlice.actions.setCustomStyles(
          parseCustomStyles(tickets[0].customStyles)
        )
      )
      return
    }
    dispatch(layoutSlice.actions.setCustomStyles({}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotId, carId, ticketId, tickets[0]])

  return customStyles
}

export default useCustomLotColors
