import { nanoid } from '@reduxjs/toolkit'
import { Receipt } from 'typedef/receipt'

export const receipts: Receipt[] = [
  {
    amount: {
      currency: {
        cultureName: 'en_US',
        currencyFormat: null,
        currencySymbol: null,
      },
      totalAmount: 1,
      convenienceFee: 50,
    },
    areaAddress: 'area address',
    cardUsed: false,
    locationName: 'Area st 11',
    carId: nanoid(),
    dateInUTC: new Date().toUTCString(),
    externalServiceDeliversReceipts: false,
  },
  {
    amount: {
      currency: {
        cultureName: 'en_US',
        currencyFormat: null,
        currencySymbol: null,
      },
      totalAmount: 100,
      convenienceFee: 50,
    },
    areaAddress: 'area address',
    cardUsed: false,
    locationName: 'Area st 11',
    carId: nanoid(),
    dateInUTC: new Date().toUTCString(),
    externalServiceDeliversReceipts: false,
  },
  {
    amount: {
      currency: {
        cultureName: 'en_US',
        currencyFormat: null,
        currencySymbol: null,
      },
      totalAmount: 102,
      convenienceFee: 50,
    },
    areaAddress: 'area address',
    cardUsed: false,
    locationName: 'Area st 11',
    carId: nanoid(),
    dateInUTC: new Date().toUTCString(),
    externalServiceDeliversReceipts: false,
  },
  {
    amount: {
      currency: {
        cultureName: 'en_US',
        currencyFormat: null,
        currencySymbol: null,
      },
      totalAmount: 120,
      convenienceFee: 50,
    },
    areaAddress: 'area address',
    cardUsed: false,
    locationName: 'Area st 11',
    carId: nanoid(),
    dateInUTC: new Date().toUTCString(),
    externalServiceDeliversReceipts: false,
  },

  {
    amount: {
      currency: {
        cultureName: 'en_US',
        currencyFormat: null,
        currencySymbol: null,
      },
      totalAmount: 1000,
      convenienceFee: 50,
    },
    areaAddress: 'area address',
    cardUsed: false,
    locationName: 'Area st 11',
    carId: nanoid(),
    dateInUTC: new Date().toUTCString(),
    externalServiceDeliversReceipts: false,
  },

  {
    amount: {
      currency: {
        cultureName: 'en_US',
        currencyFormat: null,
        currencySymbol: null,
      },
      totalAmount: 1003,
      convenienceFee: 50,
    },
    areaAddress: 'area address',
    cardUsed: false,
    locationName: 'Area st 11',
    carId: nanoid(),
    dateInUTC: new Date().toUTCString(),
    externalServiceDeliversReceipts: false,
  },
  {
    amount: {
      currency: {
        cultureName: 'en_US',
        currencyFormat: null,
        currencySymbol: null,
      },
      totalAmount: 1030,
      convenienceFee: 50,
    },
    areaAddress: 'area address',
    cardUsed: false,
    locationName: 'Area st 11',
    carId: nanoid(),
    dateInUTC: new Date().toUTCString(),
    externalServiceDeliversReceipts: false,
  },
  {
    amount: {
      currency: {
        cultureName: 'en_US',
        currencyFormat: null,
        currencySymbol: null,
      },
      totalAmount: 1033,
      convenienceFee: 50,
    },
    areaAddress: 'area address',
    cardUsed: false,
    locationName: 'Area st 11',
    carId: nanoid(),
    dateInUTC: new Date().toUTCString(),
    externalServiceDeliversReceipts: false,
  },
  {
    amount: {
      currency: {
        cultureName: 'en_US',
        currencyFormat: null,
        currencySymbol: null,
      },
      totalAmount: 1300,
      convenienceFee: 50,
    },
    areaAddress: 'area address',
    cardUsed: false,
    locationName: 'Area st 11',
    carId: nanoid(),
    dateInUTC: new Date().toUTCString(),
    externalServiceDeliversReceipts: false,
  },
  {
    amount: {
      currency: {
        cultureName: 'en_US',
        currencyFormat: null,
        currencySymbol: null,
      },
      totalAmount: 132103,
      convenienceFee: 50,
    },
    areaAddress: 'area address',
    cardUsed: false,
    locationName: 'Area st 11',
    carId: nanoid(),
    dateInUTC: new Date().toUTCString(),
    externalServiceDeliversReceipts: false,
  },
  {
    amount: {
      currency: {
        cultureName: 'en_US',
        currencyFormat: null,
        currencySymbol: null,
      },
      totalAmount: 1330,
      convenienceFee: 50,
    },
    areaAddress: 'area address',
    cardUsed: false,
    locationName: 'Area st 11',
    carId: nanoid(),
    dateInUTC: new Date().toUTCString(),
    externalServiceDeliversReceipts: false,
  },
  {
    amount: {
      currency: {
        cultureName: 'en_US',
        currencyFormat: null,
        currencySymbol: null,
      },
      totalAmount: 2000,
      convenienceFee: 50,
    },
    areaAddress: 'area address',
    locationName: 'Area st 12',
    cardUsed: false,
    carId: 'carId2',
    dateInUTC: new Date().toUTCString(),
    externalServiceDeliversReceipts: false,
  },
]
