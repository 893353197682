import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  AppButton,
  AppSpinner,
  ErrorPlaceholder,
  WhiteBoxContainer,
} from 'shared'
import { receiptsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import Translations from 'utils/translations'
import MyReceipts from './MyReceipts'
import MyReceiptsPlaceholder from './MyReceiptsPlaceholder'

export interface MyReceiptsContainerProps {}

export const MyReceiptsContainer: React.FC<MyReceiptsContainerProps> = props => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const snackbarKey = React.useRef(null)
  const receipts = useSelector(receiptsSlice.selectAllReceipts)

  const { getReceiptsStatus: status } = useSelector(
    receiptsSlice.promiseStatusSelectors
  )

  const reFetchData = (close?: boolean) => {
    if (status.rejected) {
      dispatch(receiptsSlice.getReceipts())
    }
    if (close) {
      closeSnackbar(snackbarKey.current)
    }
  }
  const action = (key: any) => (
    <AppButton
      color="primary"
      minWidth="auto"
      size="small"
      onClick={() => {
        closeSnackbar(key)
        reFetchData()
      }}
    >
      {t(Translations.REFRESH)}
    </AppButton>
  )
  React.useEffect(() => {
    if (status.idle) {
      dispatch(receiptsSlice.getReceipts())
    }
  }, [dispatch, status.idle])

  React.useEffect(() => {
    if (status.rejected) {
      snackbarKey.current = enqueueSnackbar(
        t(Translations.SOME_DATA_HAS_NOT_BEEN_UPDATED),
        {
          persist: true,
          key: 'fetchError',
          action,
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, dispatch, enqueueSnackbar, status.rejected])

  return (
    <WhiteBoxContainer>
      {status.pendingIdle && <AppSpinner show relative height="100px" />}
      {!receipts.length && (status.rejected || status.pendingRejected) && (
        <ErrorPlaceholder showSpinner={status.pending} onClick={reFetchData} />
      )}
      {!!receipts.length && <MyReceipts receipts={receipts} />}
      {!receipts.length && (status.fulfilled || status.pendingFulfilled) && (
        <MyReceiptsPlaceholder>
          {status.pending && <AppSpinner show />}
        </MyReceiptsPlaceholder>
      )}
    </WhiteBoxContainer>
  )
}

export default MyReceiptsContainer
