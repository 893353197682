import { useTerms } from 'pages/park_car/ParkCarHooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton } from 'shared'
import Translations from 'utils/translations'

export interface TermsButtonProps {
  onClick?: () => void
}

export const TermsButton: React.FC<TermsButtonProps> = props => {
  const { t } = useTranslation()
  const onClickTerms = useTerms()
  return (
    <AppButton
      type="button"
      letterCase="none"
      hasHoverBg={false}
      color="primary"
      onClick={() => (props.onClick ? props.onClick() : onClickTerms())}
      data-testid="termsButton"
    >
      {t(Translations.TERMS_AND_CONDITIONS)}
    </AppButton>
  )
}

export default TermsButton
