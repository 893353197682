import { Box, Divider } from '@mui/material'
import NeedHelpPopup from 'pages/park_car/ParkCarComponents/NeedHelpPopup'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import paths from 'routing/paths'
import AppButton from 'shared/AppButton'
import { customerSlice, parkCarSlice } from 'store/slices'
import { TicketState } from 'store/slices/ticketsSlice.types'
import { useAppDispatch } from 'store/store'
import { CarStatus, ParkerType } from 'typedef'
import Translations from 'utils/translations'
import { tickets } from '__fixtures__'
import useTicketActions from '../TicketHooks/useTicketActions'
import LotName from './LotName'
import {
  TicketExpiredButtons,
  TicketUnpaidButtons,
  TicketValidButtons,
} from './TicketButtons'
import TicketDetails from './TicketDetails'
import TicketDetailsDialog from './TicketDetailsDialog'

export interface TicketStandardProps {
  ticket: TicketState
}

export const TicketStandard: React.FC<TicketStandardProps> = ({ ticket }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const { customerSignedInAsGuest } = useSelector(
    customerSlice.selectCustomerStatus
  )
  const [showDialog, setShowDialog] = React.useState(false)
  const [showNeedHelpPopup, setShowNeedHelpPopup] = React.useState(false)

  const onClickDetails = () => setShowDialog(!showDialog)

  const {
    onClickFindCar,
    hasLotGeolocation,
    onClickValidate,
    onClickReceipt,
    isExpired: isTicketExpired,
  } = useTicketActions(ticket.carId)

  const onClickParkAgain = () => {
    dispatch(parkCarSlice.actions.setTicket({ carId: ticket.carId }))
    push(`${paths.parking.parkCar}/${ticket.lot.lotId}`)
  }
  const onClickPay = React.useCallback(() => {
    push(`${paths.parking.selectCard}/${ticket.carId}`)
  }, [push, ticket.carId])

  const onClickAddTime = React.useCallback(() => {
    push(`${paths.parking.addTime}/${ticket.carId}`)
  }, [push, ticket.carId])

  const carStatus = {
    expired: ticket.carStatus === CarStatus.ParkingHasExpired,
    unpaid: ticket.carStatus === CarStatus.RequiresPayment,
    parked: ticket.carStatus === CarStatus.Parked,
  }

  const displayReceipt =
    ticket.parkerType === ParkerType.Normal &&
    ticket.settlement.finalAmount !== 0 &&
    !isTicketExpired

  return (
    <>
      <LotName
        lot={ticket.lot}
        hasRightElement={hasLotGeolocation && !isTicketExpired}
        onClickFindCar={onClickFindCar}
      />
      <TicketDetails ticket={ticket} onClickDetails={onClickDetails} />
      {ticket.parkerType === ParkerType.Normal && (
        <>
          {carStatus.parked && (
            <TicketValidButtons
              validateEnable={ticket.validateEnable}
              addTimeEnable={ticket.addTimeEnable}
              onClickAddTime={onClickAddTime}
              onClickValidate={onClickValidate}
            />
          )}
          {isTicketExpired && (
            <TicketExpiredButtons
              onClickReceipt={onClickReceipt}
              isExternalService={ticket.externalServiceDeliversReceipts}
              onClickParkAgain={onClickParkAgain}
              parkAgainEnable={
                customerSignedInAsGuest && tickets.length ? false : true
              }
            />
          )}
          {carStatus.unpaid && <TicketUnpaidButtons onClickPay={onClickPay} />}
        </>
      )}
      {ticket.settlement && (
        <TicketDetailsDialog
          show={showDialog}
          onClick={onClickDetails}
          settlement={ticket.settlement}
        />
      )}
      <Box display="flex" justifyContent="center" marginTop={2}>
        {displayReceipt && customerSignedInAsGuest && (
          <>
            <AppButton
              minWidth={
                ticket.externalServiceDeliversReceipts ? '100px' : '195px'
              }
              type="button"
              letterCase="none"
              hasHoverBg={false}
              color="primary"
              onClick={() =>
                // push(`${paths.parker.receiptsAndNotifications}/${ticket.carId}`)
                push({
                  pathname: `${paths.parker.receiptsAndNotifications}/${ticket.carId}`,
                  state: {
                    externalService: ticket.externalServiceDeliversReceipts,
                  },
                })
              }
              data-testid="helpButton"
            >
              {ticket.externalServiceDeliversReceipts
                ? t(Translations.NOTIFICATIONS)
                : t(Translations.RECEIPTS_AND_NOTIFICATIONS)}
            </AppButton>
            <Divider flexItem orientation="vertical" variant="middle" />
          </>
        )}
        <AppButton
          minWidth="100px"
          type="button"
          letterCase="none"
          hasHoverBg={false}
          color="primary"
          onClick={() => setShowNeedHelpPopup(true)}
          data-testid="helpButton"
        >
          {t(Translations.NEED_HELP)}
        </AppButton>
      </Box>
      <NeedHelpPopup
        closePopup={() => setShowNeedHelpPopup(false)}
        showNeedHelpPopup={showNeedHelpPopup}
        lotId={ticket.lot.lotId}
      />
    </>
  )
}

export default TicketStandard
