import { Formik } from 'formik'
import React from 'react'
import { useSelector } from 'react-redux'
import { parkCarSlice } from 'store/slices'
import { useGetTicket, useParkDetails, useParkMode } from '../ParkCarHooks'
import { parkCarT2Schema, T2Fields } from './fields'
import { useTranslation } from 'react-i18next'
export interface ParkCarT2FormProps {}

export const initialValues: T2Fields = {
  spotNumber: '',
  licencePlate: '',
  periodMode: 'parkFor',
  parkUntilDate: null,
  startDate: '',
  parkForCustomTimeDate: null,
  parkForDefaultTimeDate: null,
  defaultMinutes: null,
  endDate: null,
  day: 0,
  hour: 0,
  minute: 0,
  pricings: [],
  selectedPricing: '',
  lastListView: 'excerpt',
  pricingExpirationDateUtc: null,
  isSpotEnabled: false,
}

export const ParkCarT2Form: React.FC<ParkCarT2FormProps> = props => {
  const { t } = useTranslation()
  const [init, setInit] = React.useState(initialValues)
  const parkMode = useParkMode()
  const { lot } = useSelector(parkCarSlice.stateSelectors)
  const [, , ticket] = useGetTicket()
  const { parkingStartDateTime, plate, spot } = useParkDetails()

  React.useEffect(() => {
    let init = initialValues
    if (lot) {
      init = {
        ...init,
        startDate: lot.currentDateTimeLocal,
        currentTime: lot.currentDateTimeLocal,
        isSpotEnabled: lot.spot?.isEnabled,
      }
    }

    if (parkMode === 'parkCar') {
      if (plate) {
        init = { ...init, licencePlate: plate }
      }
      if (spot) {
        init = { ...init, spotNumber: spot }
      }
    }

    if (parkMode === 'addMoreTime' && ticket) {
      init = {
        ...init,
        startDate: parkingStartDateTime,
        currentTime: ticket.currentDateTime,
        licencePlate: ticket.plate,
        spotNumber: ticket.spot,
        isSpotEnabled: Boolean(ticket.spot),
      }
    }
    setInit(init)
  }, [lot, parkMode, parkingStartDateTime, plate, spot, ticket])

  return (
    <Formik
      initialValues={init}
      validationSchema={parkCarT2Schema(
        t,
        lot,
        parkMode === 'addMoreTime' ? true : false
      )}
      onSubmit={undefined}
      validateOnChange={false}
      enableReinitialize
    >
      {utils => {
        return <form>{props.children}</form>
      }}
    </Formik>
  )
}

export default ParkCarT2Form
