import { Dialog, DialogProps } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const StyledDialog = styled(Dialog)<PageDialogProps>`
  .MuiDialog-paper {
    overflow: hidden;
  }
`
interface PageDialogProps {
  layout?: 'full'
  paddingBottom?: string
  center?: boolean
  overflowScroll?: boolean
}
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />
// })
export const PageDialog: React.FC<PageDialogProps & DialogProps> = ({
  paddingBottom,
  layout,
  center,
  overflowScroll,
  ...props
}) => {
  return (
    <StyledDialog
      fullScreen
      // TransitionComponent={Transition}
      {...props}
    >
      {props.children}
    </StyledDialog>
  )
}
PageDialog.defaultProps = {}
export default PageDialog
