import React from 'react'
import { AppButton } from 'shared'
import { useWantMonthly } from '../ParkCarHooks'

export interface WantMonthlyProps {}

export const WantMonthly: React.FC<WantMonthlyProps> = props => {
  const { isWantMonthly, wantMonthlyHandler } = useWantMonthly()

  if (!isWantMonthly) return null
  return (
    <AppButton
      textAlign="center"
      onClick={wantMonthlyHandler}
      translation="I_WANT_MONTHLY_PARKING"
    />
  )
}

export default WantMonthly
