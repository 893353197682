import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { mapSlice, parkCarSlice } from 'store/slices'
import { AppState } from 'store/store'
import { LotNameSection } from '.'

export interface SelectedTitleProps {}

export const SelectedLotTitle = (props: SelectedTitleProps) => {
  const { lotId } = useParams<{ lotId: string }>()
  const selectedLotOnMap = useSelector((state: AppState) =>
    mapSlice.selectById(state, lotId)
  )
  const { lot: selectedLotOnPark } = useSelector(parkCarSlice.stateSelectors)

  return Boolean(selectedLotOnMap || selectedLotOnPark) ? (
    <LotNameSection
      lot={selectedLotOnMap ? selectedLotOnMap : selectedLotOnPark}
    />
  ) : null
}

export default SelectedLotTitle
