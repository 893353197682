import { TotalText } from 'pages/park_car/ParkCarComponents'
import { useParkDetails } from 'pages/park_car/ParkCarHooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppTypography, DetailsBox, SharedIcon } from 'shared'
import styled, { css } from 'styled-components'
import { Ticket } from 'typedef/ticket'
import { countParkTime, Translations } from 'utils'

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  div:not(:last-of-type) {
    border-right: 1px solid ${_ => _.theme.palette.divider};
  }
`
const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${_ => css`
    padding: ${_.theme.spacing(1.5)} ${_.theme.spacing(3)};
  `}
`

export type PaymentSummaryDetailsProps = Pick<Ticket, 'spot' | 'plate'>

export const PaymentSummaryDetails: React.FC<PaymentSummaryDetailsProps> = ({
  plate,
  spot,
}) => {
  const {
    totalAmount,
    currency,
    parkingExpireDateTime,
    fees,
    parkingFee,
    parkingStartDateTime,
    upfrontCoupons,
  } = useParkDetails()

  const { t } = useTranslation()
  const time = countParkTime(parkingStartDateTime, parkingExpireDateTime)
  return (
    <>
      <DetailsBox.Wrapper>
        <DetailsBox.InnerRow noTopPadding>
          <IconsWrapper>
            {spot && (
              <IconWrapper>
                <SharedIcon.Parking />
                <AppTypography textCase="uppercase">{spot}</AppTypography>
              </IconWrapper>
            )}
            <IconWrapper>
              <SharedIcon.Car />
              <AppTypography textCase="uppercase">{plate}</AppTypography>
            </IconWrapper>
            <IconWrapper>
              <SharedIcon.Clock />
              <AppTypography textCase="uppercase" noWrap>
                {time}
              </AppTypography>
            </IconWrapper>
          </IconsWrapper>
        </DetailsBox.InnerRow>
        <DetailsBox.Divider />
        <DetailsBox.TimeRow time={parkingExpireDateTime} />
        <DetailsBox.Divider />
        <DetailsBox.InnerRow noBottomPadding>
          {[parkingFee, ...fees]
            .filter(el => el)
            .map((el, i) => (
              <DetailsBox.PriceRow
                label={el.label}
                amount={el.amount}
                key={i}
                currency={currency}
              />
            ))}
          {!!upfrontCoupons &&
            upfrontCoupons.map((el, i) => (
              <DetailsBox.PriceRow
                label={`${t(Translations.VALIDATED)} (${el.name})`}
                amount={el.amount}
                key={i}
                currency={currency}
                negativeAmount
              />
            ))}
        </DetailsBox.InnerRow>
      </DetailsBox.Wrapper>
      <TotalText amount={totalAmount} currency={currency} />
    </>
  )
}

export default PaymentSummaryDetails
