import { useChangeLayout } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom'
import { paths } from 'routing'
import { AppSnackBarProvider, WhiteBoxContainer } from 'shared'
import PopupProvider from 'shared/popup_provider'
import { Translations } from 'utils'
import ReceiptsAndNotifications from './ReceiptsAndNotifications'
import { useSelector } from 'react-redux'
import { customerSlice } from 'store/slices'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'

interface LocationState {
  externalService: boolean
}

const ReceiptsAndNotifiactionsPage = () => {
  const { push } = useHistory()
  const { t } = useTranslation()
  const { carId } = useParams<{ carId: string }>()
  const { setPopup, showPopup } = usePopup()
  const { customerSignedIn } = useSelector(customerSlice.selectCustomerStatus)
  const location = useLocation<LocationState>()
  const externalService = location.state && location.state.externalService
  const title = externalService
    ? t(Translations.NOTIFICATIONS)
    : t(Translations.RECEIPTS_AND_NOTIFICATIONS)

  useChangeLayout(
    {
      topbar: {
        title,
        onClickBack: () => push(paths.parking.tickets),
      },
    },
    'app'
  )

  React.useEffect(() => {
    if (customerSignedIn) {
      setPopup({
        variant: 'success',
        title: t(Translations.SUCCESS),
        description: ' ',
        buttons: [
          {
            onClick() {
              push(paths.parking.tickets)
            },
          },
        ],
      })
      showPopup(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!carId) {
    return <Redirect to={paths.parking.tickets} />
  }

  return (
    <AppSnackBarProvider hasBottomGutter>
      <PopupProvider>
        <WhiteBoxContainer>
          <ReceiptsAndNotifications carId={carId} />
        </WhiteBoxContainer>
      </PopupProvider>
    </AppSnackBarProvider>
  )
}

export default ReceiptsAndNotifiactionsPage
