import { TicketState } from 'store/slices/ticketsSlice.types'
import { GetContactInformationsData } from 'typedef'

export interface ReceiptsAndNotificationsProps {
  carId: string
  ticket?: TicketState
}

export interface ContactInformationsProps {
  carId: string
}
export interface ReceiptsAndNotificationsFormValues {
  email: string
  phoneNumber: string
  code: string
}

export interface GuestPersonalData extends GetContactInformationsData {}

export enum RedirectType {
  ContactInformations = 1,
  ReceiptsAndNotifications = 2,
}
