import {
  GetCompanyContactInfoParams,
  GetCompanyContactInfoResult,
} from 'typedef/companyDetails'
import axios from '.'
import { Response } from '../typedef/api'
import { LOTS_COMPANY_CONTACT_INFO } from './constants'

export const getCompanyContactInfo = async (
  params: GetCompanyContactInfoParams
) => {
  try {
    const response = await axios.get<Response<GetCompanyContactInfoResult>>(
      LOTS_COMPANY_CONTACT_INFO,
      {
        params,
      }
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}
