import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()
let lastLocation: any = null
history.listen(location => {
  lastLocation = location
})

// monkey patching to prevent pushing same url into history stack
const prevHistoryPush = history.push
history.push = (pathname: string, state = {}) => {
  if (
    lastLocation === null ||
    pathname !==
      lastLocation.pathname + lastLocation.search + lastLocation.hash ||
    JSON.stringify(state) !== JSON.stringify(lastLocation.state)
  ) {
    prevHistoryPush(pathname, state)
  }
}

export default history
