import { Fade, Grid, Modal } from '@mui/material'
import { MobileDatePicker, MobileTimePicker } from '@mui/lab'
import { makeStyles } from '@mui/styles'
import moment, { Moment } from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AppButton,
  AppSpinner,
  AppTextField,
  FlexBox,
  WhiteBoxContainer,
} from 'shared'
import AppTypography from 'shared/AppTypography'
import Translations from 'utils/translations'

export interface ParkingRatesModalProps {
  open: boolean
  loader?: boolean
  handleCalculate?: (entryDate?: string, exitDate?: string) => void
  handleClose?: () => void
  cleanup?: () => void
  title?: string
}

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export const ParkingRatesModal: React.FC<ParkingRatesModalProps> = props => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [initDate, setInitDate] = React.useState(moment())
  const [entryDate, setEntryDate] = React.useState(moment())
  const [exitDate, setExitDate] = React.useState(moment())
  const onChangeEntryDate = (date: Moment | null) => {
    const entry = date.second(0)
    const exit = moment(exitDate)
    if (entry >= exit) {
      setEntryDate(exit.subtract(10, 'minutes'))
    } else {
      setEntryDate(entry)
    }
  }
  const onChangeExitDate = (date: Moment | null) => {
    const exit = date.second(0)
    const entry = moment(entryDate)
    if (exit <= entry) {
      setExitDate(entry.add(10, 'minutes'))
    } else {
      setExitDate(exit)
    }
  }

  const setInitDates = () => {
    const startDate = moment().second(0)
    const roundToFive = 5 - (startDate.minute() % 5)
    startDate.add(roundToFive, 'minute')
    setInitDate(startDate)
    setEntryDate(startDate)
    setExitDate(startDate.add(30, 'minute'))
  }

  const maxEntryTime = () => {
    if (moment(entryDate, true).isSame(exitDate, 'day')) {
      return moment(exitDate).subtract(10, 'minutes')
    } else {
      return moment(entryDate).endOf('day')
    }
  }
  const minExitTime = () => {
    if (moment(entryDate).isSame(exitDate, 'day')) {
      return moment(entryDate).add(10, 'minutes')
    } else {
      return moment(exitDate).startOf('day')
    }
  }
  const setDefaultDuration = (hours: number) => {
    const exit = moment(initDate).add(hours, 'hours')
    setEntryDate(initDate)
    setExitDate(exit)
    props.handleCalculate(initDate.toString(), exit.toString())
  }
  const cleanup = () => {
    setInitDates()
    props.cleanup()
  }
  React.useEffect(() => {
    cleanup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal open={props.open} disableEnforceFocus className={classes.modal}>
      <Fade in={props.open} onExited={cleanup}>
        <WhiteBoxContainer maxWidth={400}>
          <div style={{ position: 'relative' }}>
            <AppSpinner show={props.loader} />
            <Grid spacing={2} container>
              <Grid item xs={12}>
                {props.title && (
                  <AppTypography weight="bold" align="center">
                    {props.title}
                  </AppTypography>
                )}
                <FlexBox horizontal>
                  <AppButton
                    minWidth="auto"
                    letterCase="none"
                    variant="text"
                    onClick={() => setDefaultDuration(1)}
                  >
                    1 {t(Translations.HOUR)}
                  </AppButton>
                  <AppButton
                    minWidth="auto"
                    letterCase="none"
                    variant="text"
                    onClick={() => setDefaultDuration(4)}
                  >
                    4 {t(Translations.HOURS)}
                  </AppButton>
                  <AppButton
                    minWidth="auto"
                    letterCase="none"
                    variant="text"
                    onClick={() => setDefaultDuration(8)}
                  >
                    8 {t(Translations.HOURS)}
                  </AppButton>
                </FlexBox>
              </Grid>
              <Grid item xs={6}>
                <MobileDatePicker
                  allowSameDateSelection
                  onChange={onChangeEntryDate}
                  value={entryDate}
                  minDate={initDate}
                  maxDate={exitDate}
                  label={t(Translations.ENTRY_TIME)}
                  disablePast
                  renderInput={props => <AppTextField {...props} />}
                  okText={t(Translations.OK)}
                  cancelText={t(Translations.CANCEL)}
                />
              </Grid>
              <Grid item xs={6}>
                <MobileTimePicker
                  minutesStep={5}
                  minTime={initDate}
                  maxTime={maxEntryTime()}
                  onChange={onChangeEntryDate}
                  value={entryDate}
                  renderInput={props => <AppTextField {...props} />}
                  okText={t(Translations.OK)}
                  cancelText={t(Translations.CANCEL)}
                  toolbarTitle={t(Translations.SELECT_TIME)}
                />
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={6}>
                <MobileDatePicker
                  allowSameDateSelection
                  onChange={onChangeExitDate}
                  minDate={entryDate}
                  label={t(Translations.EXIT_TIME)}
                  value={exitDate}
                  renderInput={props => <AppTextField {...props} />}
                  okText={t(Translations.OK)}
                  cancelText={t(Translations.CANCEL)}
                />
              </Grid>
              <Grid item xs={6}>
                <MobileTimePicker
                  minutesStep={5}
                  minTime={minExitTime()}
                  onChange={onChangeExitDate}
                  value={exitDate}
                  renderInput={props => <AppTextField {...props} />}
                  okText={t(Translations.OK)}
                  cancelText={t(Translations.CANCEL)}
                  toolbarTitle={t(Translations.SELECT_TIME)}
                />
              </Grid>
              <Grid item xs={12}>
                {props.children}
              </Grid>
              <Grid item xs={12}>
                <FlexBox>
                  <AppButton
                    variant="contained"
                    onClick={() =>
                      props.handleCalculate(
                        entryDate.toString(),
                        exitDate.toString()
                      )
                    }
                  >
                    {t(Translations.CALCULATE)}
                  </AppButton>
                </FlexBox>
              </Grid>
            </Grid>
          </div>{' '}
          <FlexBox>
            <AppButton variant="outlined" onClick={props.handleClose}>
              {t(Translations.CANCEL)}
            </AppButton>
          </FlexBox>
        </WhiteBoxContainer>
      </Fade>
    </Modal>
  )
}
ParkingRatesModal.defaultProps = {
  handleClose: () => undefined,
  handleCalculate: () => undefined,
  cleanup: () => undefined,
}
export default ParkingRatesModal
