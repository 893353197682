import { TFunction } from 'i18next'
import { GetLotDetailsParkData } from 'typedef'
import { Translations } from 'utils'
import { licencePlateRegex } from 'utils/regex'
import * as Yup from 'yup'

export const spotNumberSchema = (t: TFunction, lot?: GetLotDetailsParkData) => {
  const yupSchema = Yup.number()
    .typeError(
      t(Translations.SPOT_NUMBER_MUST_BE_BETWEEN, {
        min: lot.spot?.minSpotNumber || 1,
        max: lot.spot?.maxSpotNumber || 9999,
      })
    )
    .min(
      lot.spot?.minSpotNumber || 1,
      t(Translations.SPOT_NUMBER_MUST_BE_BETWEEN, {
        min: lot.spot?.minSpotNumber || 1,
        max: lot.spot?.maxSpotNumber || 9999,
      })
    )
    .max(
      lot.spot?.maxSpotNumber || 9999,
      t(Translations.SPOT_NUMBER_MUST_BE_BETWEEN, {
        min: lot.spot?.minSpotNumber || 1,
        max: lot.spot?.maxSpotNumber || 9999,
      })
    )

  return lot?.spot?.isEnabled
    ? yupSchema.required(t(Translations.SPOT_NUMBER_IS_REQUIRED))
    : yupSchema
}

export const parkCarSchema = (t: TFunction, lot?: GetLotDetailsParkData) =>
  Yup.object().shape({
    spotNumber: spotNumberSchema(t, lot),
    licencePlate: Yup.string()
      .required(t(Translations.PLATE_CANNOT_BE_EMPTY))
      .matches(
        licencePlateRegex,
        t(Translations.ONLY_ALPHANUMERIC_CHARACTERS_ARE_ALLOWED)
      ),
  })
