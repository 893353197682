import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { carsApiError } from 'api/apiErrors'
import * as carsApi from 'api/CarsApi'
import { getErrorCode, getErrorMessage } from 'api/utils'
import { RootState } from 'store/rootReducer'
import {
  AddCarPayload,
  Car,
  DeleteCarPayload,
  GetCarsData,
  ErrorData,
  FetchStatus,
} from 'typedef'
import { CarPromiseStatuses, CarsState, CarStateType } from './carsSlice.types'
import { FETCH, initialPromiseStatus, CARS } from './constants'
import * as sliceUtil from './slice.utils'

const carsAdapter = createEntityAdapter<CarStateType>({
  selectId: car => car.carId,
})
/**
 * state
 */
export const initialPromiseStatuses: CarPromiseStatuses = {
  getCars: initialPromiseStatus,
  addCar: initialPromiseStatus,
  removeCar: {},
}
const initialState = carsAdapter.getInitialState<CarsState>({
  promisesStatus: initialPromiseStatuses,
})

export const getCars = createAsyncThunk<
  GetCarsData,
  null,
  { rejectValue: ErrorData }
>(
  `${CARS}/${FETCH}/getCars`,
  async (_, thunkApi) => {
    try {
      const response = await carsApi.getCars()
      return response
    } catch (error) {
      const errorMessage = getErrorMessage(
        getErrorCode(error),
        carsApiError.getCars
      )
      return thunkApi.rejectWithValue(errorMessage)
    }
  },
  {
    condition: (arg, api) => {
      const { cars } = api.getState() as RootState
      return !cars.promisesStatus.getCars.status.includes(FetchStatus.Pending)
    },
  }
)

export const addCar = createAsyncThunk<
  Car,
  AddCarPayload,
  { rejectValue: ErrorData }
>(`${CARS}/${FETCH}/addCar`, async (car, thunkApi) => {
  try {
    const response = await carsApi.addCar(car)
    return { ...response, ...car }
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      carsApiError.AddCar
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const removeCar = createAsyncThunk<
  { carId: string },
  DeleteCarPayload,
  { rejectValue: ErrorData }
>(`${CARS}/${FETCH}/removeCar`, async (arg, thunkApi) => {
  try {
    await carsApi.deleteCar({ carId: arg.carId })
    return arg
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      carsApiError.AddCar
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

const carsSlice = createSlice({
  name: CARS,
  initialState,
  reducers: {
    addCars(state, action: PayloadAction<{ cars: CarStateType[] }>) {
      carsAdapter.upsertMany(state, action.payload.cars)
    },
    setCars(state, action: PayloadAction<{ cars: Car[] }>) {
      carsAdapter.setAll(state, action.payload.cars)
    },
  },
  extraReducers: builder => {
    // Fetch cars
    builder.addCase(getCars.pending, ({ promisesStatus }) => {
      promisesStatus.getCars = sliceUtil.pendingPromise(promisesStatus.getCars)
    })
    builder.addCase(getCars.fulfilled, (state, action) => {
      state.promisesStatus.getCars = sliceUtil.fulfilledPromise()
      carsAdapter.setAll(state, action.payload.cars)
    })
    builder.addCase(getCars.rejected, (state, action) => {
      state.promisesStatus.getCars = sliceUtil.rejectedPromise(action.payload)
    })

    // Add car
    builder.addCase(addCar.fulfilled, (state, action) => {
      state.promisesStatus.addCar = sliceUtil.fulfilledPromise()
      carsAdapter.addOne(state, action.payload)
    })
    builder.addCase(addCar.pending, ({ promisesStatus }) => {
      promisesStatus.addCar = sliceUtil.pendingPromise(promisesStatus.addCar)
    })
    builder.addCase(addCar.rejected, (state, action) => {
      state.promisesStatus.addCar = sliceUtil.rejectedPromise(action.payload)
    })

    // Remove car
    builder.addCase(removeCar.fulfilled, (state, action) => {
      const { carId } = action.meta.arg
      state.promisesStatus.removeCar[carId] = {
        ...initialPromiseStatus,
        status: [FetchStatus.Fulfilled],
      }
      carsAdapter.removeOne(state, carId)
    })
    builder.addCase(removeCar.pending, (state, action) => {
      const { carId } = action.meta.arg
      state.promisesStatus.removeCar[carId] = {
        ...initialPromiseStatus,
        status: [FetchStatus.Pending],
      }
    })
    builder.addCase(removeCar.rejected, (state, action) => {
      const { carId } = action.meta.arg
      state.promisesStatus.removeCar[carId] = {
        ...initialPromiseStatus,
        status: [FetchStatus.Rejected],
      }
    })
  },
})

export default carsSlice.reducer
export const { actions } = carsSlice

const carsSelectors = carsAdapter.getSelectors<RootState>(state => state.cars)
export const {
  selectAll: selectAllCars,
  selectById: selectCarById,
  selectIds: selectCarIds,
} = carsSelectors

export const stateSelectors = (state: RootState) => state.cars

export const promiseStatusSelectors = (state: RootState, carId?: string) => {
  const promises = stateSelectors(state).promisesStatus
  return {
    getCarsStatus: sliceUtil.fetchStatus(promises.getCars),
    addCarStatus: sliceUtil.fetchStatus(promises.addCar),
    removeCarStatus: carId
      ? sliceUtil.fetchStatus(promises.removeCar[carId])
      : null,
  }
}
