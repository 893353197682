import { getPromiseStatus } from 'api/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  AppButton,
  AppSpinner,
  ErrorPlaceholder,
  FlexBox,
  WhiteBoxContainer,
} from 'shared'
import * as customerSlice from 'store/slices/customerSlice'
import { actions } from 'store/slices/customerSlice'
import { useAppDispatch } from 'store/store'
import Translations from 'utils/translations'
import { MyProfileDetails } from './MyProfileDetails'

export interface MyProfileProps {
  onClickRefresh: () => void
}

export const MyProfile: React.FC<MyProfileProps> = props => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { details, promisesStatus } = useSelector(customerSlice.stateSelectors)
  const fetchStatus = getPromiseStatus(promisesStatus.getCustomerDetails.status)
  return (
    <WhiteBoxContainer>
      {fetchStatus.pendingIdle && <AppSpinner show relative height="100px" />}
      {!details && !fetchStatus.pendingIdle && (
        <ErrorPlaceholder
          showSpinner={fetchStatus.pending}
          onClick={props.onClickRefresh}
        />
      )}
      {details && <MyProfileDetails details={details} />}
      <FlexBox>
        <AppButton
          color="primary"
          onClick={() => dispatch(actions.destroySession())}
        >
          {t(Translations.SIGN_OUT)}
        </AppButton>
      </FlexBox>
    </WhiteBoxContainer>
  )
}

export default MyProfile
