import moment from 'moment'
import React from 'react'

export const useCounter = (endDate: string, onEnd?: () => void) => {
  const [isRunning, setIsRunning] = React.useState(true)
  const getElapsedSeconds = React.useCallback(() => {
    let seconds = moment
      .duration(moment(endDate).diff(moment(), 'millisecond'))
      .asSeconds()
    if (seconds < 0) {
      seconds = 0
    }
    return seconds
  }, [endDate])

  const [seconds, setSeconds] = React.useState(getElapsedSeconds())
  const getElapsedTimeString = React.useCallback(
    (seconds: number) =>
      moment.duration(seconds, 'seconds').format('mm:ss', {
        trim: false,
      }),
    []
  )
  const [timeString, setTimeString] = React.useState(
    getElapsedTimeString(getElapsedSeconds())
  )

  React.useEffect(() => {
    if (isRunning) {
      const timer = setInterval(() => {
        setSeconds(getElapsedSeconds())
      }, 1000)
      return () => {
        clearInterval(timer)
      }
    }
  }, [getElapsedSeconds, isRunning])

  React.useEffect(() => {
    setTimeString(getElapsedTimeString(seconds))
  }, [getElapsedTimeString, seconds])

  React.useEffect(() => {
    if (seconds > 0) {
      setIsRunning(true)
    } else {
      setIsRunning(false)
    }
  }, [getElapsedSeconds, getElapsedTimeString, seconds])

  React.useEffect(() => {
    if (!isRunning) {
      onEnd()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning])

  return timeString
}

export default useCounter
