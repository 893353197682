import { fetchStatusIs } from 'api/utils'
import { useChangeLayout } from 'hooks'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import paths from 'routing/paths'
import { AppSpinner, WhiteBoxContainer } from 'shared'
import { showPopup } from 'store/globalPopup/actions'
import { RootState } from 'store/rootReducer'
import { parkCarSlice } from 'store/slices'
import {
  selectSendValidateCodeUpfrontStatus,
  selectValidatorById,
  validateCodeUpfront,
} from 'store/slices/validatorsSlice'
import { useAppDispatch } from 'store/store'
import Translations from 'utils/translations'
import ValidateTicket from './ValidateTicket'

export const UpfrontValidate = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { parkerId, couponId } = useParams<{
    parkerId?: string
    couponId?: string
  }>()

  const { lot, pricingPayload, providedPlate, providedSpotNumber } =
    useSelector(parkCarSlice.stateSelectors)
  const partner = useSelector((state: RootState) =>
    selectValidatorById(state, couponId)
  )

  const { pending } = fetchStatusIs(
    useSelector((state: RootState) =>
      selectSendValidateCodeUpfrontStatus(state)
    )
  )
  const title = t(Translations.VALIDATE)
  useChangeLayout(
    {
      layout: {
        hasBottomBar: false,
      },
      topbar: {
        title,
        onClickBack: () => {
          history.push(`${paths.parking.upfrontValidate}/${parkerId}`)
        },
      },
      bottomMenu: false,
    },
    'app'
  )
  if (!partner) {
    history.push(paths.home.map)
    return null
  }
  const handleSubmit = async (validationCode: string) => {
    const actionResult = await dispatch(
      validateCodeUpfront({
        parkerId,
        couponId: partner.couponId,
        partnerId: partner.partnerId,
        validationCode,
      })
    )

    if (validateCodeUpfront.rejected.match(actionResult)) {
      dispatch(
        showPopup({
          ...actionResult.payload,
          handleAdditionalButtonClick: () => handleSubmit(validationCode),
        })
      )
    } else {
      dispatch(
        showPopup({
          variant: 'success',
          title: `${t(Translations.SUCCESS)}!`,
          description: t(Translations.TICKET_HAS_BEEN_VALIDATED),
          okButtonClick: () => {
            history.push({
              pathname: `${paths.parking.parkCar}/${lot.lotId}`,
              state: {
                preValidationSettings: pricingPayload,
                providedPlate: providedPlate,
                providedSpotNumber: providedSpotNumber,
              },
            })
          },
        })
      )
    }
  }

  return (
    <WhiteBoxContainer>
      <AppSpinner show={pending} />
      <ValidateTicket partner={partner} handleSubmit={handleSubmit} />
    </WhiteBoxContainer>
  )
}
