import { SnackbarProvider, SnackbarProviderProps } from 'notistack'
import React from 'react'
import { useSelector } from 'react-redux'
import { SharedIcon } from 'shared'
import { layoutSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import styled, { css } from 'styled-components'
import { BOTTOM_BAR_HEIGHT } from './layout/constants'

const StyledSnackbarProvider = styled(SnackbarProvider)<{
  $hasBottomGutter?: boolean
  $gutterSize?: number
}>`
  svg {
    min-width: 1em;
    min-height: 1em;
    font-size: 2em;
    padding-right: 5px;
  }
  ${_ =>
    _.$hasBottomGutter &&
    css`
      margin-bottom: ${_.$gutterSize}px;
    `}
`
StyledSnackbarProvider.defaultProps = {
  $gutterSize: BOTTOM_BAR_HEIGHT,
}
export interface AppSnackBarProviderProps extends SnackbarProviderProps {
  hasBottomGutter?: boolean
  gutterSize?: number
}

export const AppSnackBarProvider: React.FC<AppSnackBarProviderProps> = ({
  hasBottomGutter,
  gutterSize,
  ...props
}) => {
  const notistackRef = React.createRef<any>()
  const dispatch = useAppDispatch()
  const { autoToogleSnackGap, bottomMenu } = useSelector(
    layoutSlice.stateSelectors
  )
  return (
    <StyledSnackbarProvider
      classes={{
        anchorOriginBottomCenter: 'snackbarProvider-anchorOriginBottomCenter',
      }}
      $hasBottomGutter={hasBottomGutter || bottomMenu}
      $gutterSize={gutterSize}
      iconVariant={{
        default: <SharedIcon.Info />,
      }}
      maxSnack={4}
      dense
      preventDuplicate
      ref={notistackRef}
      autoHideDuration={2500}
      TransitionProps={{
        onEntered: () => {
          autoToogleSnackGap &&
            dispatch(layoutSlice.actions.setSnackbarGutter(true))
        },
        onExiting: () => {
          autoToogleSnackGap &&
            dispatch(layoutSlice.actions.setSnackbarGutter(false))
        },
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      {...props}
    >
      {props.children}
    </StyledSnackbarProvider>
  )
}
AppSnackBarProvider.defaultProps = {
  hasBottomGutter: false,
}
export default AppSnackBarProvider
