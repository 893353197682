import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { creditCardsApiError } from 'api/apiErrors'
import * as CreditCardApi from 'api/CreditCardApi'
import { getErrorCode, getErrorMessage } from 'api/utils'
import { RootState } from 'store/rootReducer'
import {
  ErrorData,
  FetchStatus,
  AddCreditCardPayload,
  CreditCard,
  GetCreditCardsPayload,
  GetCreditCardsData,
  RemoveCreditCardData,
  RemoveCreditCardPayload,
} from 'typedef'

import { getCreditCardType } from 'utils/creditCardTypeMapper'
import { FETCH, initialPromiseStatus, CREDIT_CARDS } from './constants'
import { CreditCardsState } from './creditCardsSlice.types'
import * as sliceUtil from './slice.utils'

/**
 * state
 */
const adapter = createEntityAdapter<CreditCard>({
  selectId: card => card.creditCardId,
})
export const initialPromiseStatuses: CreditCardsState['promisesStatus'] = {
  getCreditCards: initialPromiseStatus,
  addCreditCard: initialPromiseStatus,
  removeCreditCard: {},
}
const initialState = adapter.getInitialState<CreditCardsState>({
  promisesStatus: initialPromiseStatuses,
})

/**
 * thunks
 */
export const getCreditCards = createAsyncThunk<
  GetCreditCardsData,
  GetCreditCardsPayload,
  { rejectValue: ErrorData }
>(
  `${CREDIT_CARDS}${FETCH}/getCreditCards`,
  async (arg, thunkApi) => {
    try {
      const response = await CreditCardApi.getCreditCards(arg)
      return response
    } catch (error) {
      const errorMessage = getErrorMessage(
        getErrorCode(error),
        creditCardsApiError.getCreditCards
      )
      return thunkApi.rejectWithValue(errorMessage)
    }
  },
  {
    condition: (arg, api) => {
      const { creditCards } = api.getState() as RootState
      return !creditCards.promisesStatus.getCreditCards.status.includes(
        FetchStatus.Pending
      )
    },
  }
)
export const addCreditCard = createAsyncThunk<
  CreditCard,
  AddCreditCardPayload,
  { rejectValue: ErrorData }
>(`${CREDIT_CARDS}${FETCH}/addCreditCard`, async (arg, thunkApi) => {
  try {
    const response = await CreditCardApi.addCreditCard(arg)
    const cardType = getCreditCardType(arg.ccNumber)
    return {
      cardType,
      creditCardId: response.creditCardId,
      last4Digits: arg.ccNumber.slice(-4),
      zipCode: arg.zipCode,
    }
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      creditCardsApiError.addCreditCard
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})
export const removeCreditCard = createAsyncThunk<
  RemoveCreditCardData,
  RemoveCreditCardPayload,
  { rejectValue: ErrorData }
>(
  `${CREDIT_CARDS}${FETCH}/removeCreditCard`,
  async (arg, thunkApi) => {
    try {
      const response = await CreditCardApi.removeCreditCard(arg)
      return response
    } catch (error) {
      const errorMessage = getErrorMessage(
        getErrorCode(error),
        creditCardsApiError.addCreditCard
      )
      return thunkApi.rejectWithValue(errorMessage)
    }
  },
  {
    condition: (arg, api) => {
      const { creditCards } = api.getState() as RootState
      if (!creditCards.promisesStatus.removeCreditCard[arg.creditCardId])
        return true
      return !creditCards.promisesStatus.removeCreditCard[
        arg.creditCardId
      ].status.includes(FetchStatus.Pending)
    },
  }
)

/**
 * reducers
 */
const creditCardsSlice = createSlice({
  name: CREDIT_CARDS,
  initialState,
  reducers: {
    resetPromiseStatus(
      state,
      { payload }: PayloadAction<keyof CreditCardsState['promisesStatus']>
    ) {
      if (payload === 'removeCreditCard') {
        state.promisesStatus[payload] = {}
      } else {
        state.promisesStatus[payload] = initialPromiseStatus
      }
    },
    addCard(state, { payload }: PayloadAction<CreditCard>) {
      adapter.upsertOne(state, payload)
    },
    removeCard(state, { payload }: PayloadAction<string>) {
      adapter.removeOne(state, payload)
    },
    removeCardZip(state, { payload }: PayloadAction<string>) {
      adapter.updateOne(state, { id: payload, changes: { zipCode: '' } })
    },
  },
  extraReducers: builder => {
    /**
     * getCreditCards
     */
    builder.addCase(getCreditCards.pending, ({ promisesStatus }, action) => {
      promisesStatus.getCreditCards = sliceUtil.pendingPromise(
        promisesStatus.getCreditCards,
        action
      )
    })
    builder.addCase(getCreditCards.fulfilled, (state, action) => {
      if (
        // eslint-disable-next-line
        state.promisesStatus.getCreditCards.requestId != action.meta.requestId
      )
        return
      state.promisesStatus.getCreditCards = sliceUtil.fulfilledPromise()
      adapter.setAll(state, action.payload.creditCards)
    })
    builder.addCase(getCreditCards.rejected, (state, action) => {
      if (
        // eslint-disable-next-line
        state.promisesStatus.getCreditCards.requestId != action.meta.requestId
      )
        return
      state.promisesStatus.getCreditCards = sliceUtil.rejectedPromise(
        action.payload
      )
    })

    /**
     * addCreditCard
     */
    builder.addCase(addCreditCard.pending, ({ promisesStatus }) => {
      promisesStatus.addCreditCard = sliceUtil.pendingPromise(
        promisesStatus.addCreditCard
      )
    })
    builder.addCase(addCreditCard.fulfilled, (state, { payload }) => {
      state.promisesStatus.addCreditCard = sliceUtil.fulfilledPromise()
      adapter.addOne(state, payload)
    })
    builder.addCase(addCreditCard.rejected, (state, action) => {
      state.promisesStatus.addCreditCard = sliceUtil.rejectedPromise(
        action.payload
      )
    })

    /**
     * removeCreditCard
     */
    builder.addCase(removeCreditCard.fulfilled, (state, action) => {
      const { creditCardId } = action.meta.arg
      state.promisesStatus.removeCreditCard[creditCardId] = {
        ...initialPromiseStatus,
        status: [FetchStatus.Fulfilled],
      }
      adapter.removeOne(state, creditCardId)
    })
    builder.addCase(removeCreditCard.pending, (state, action) => {
      const { creditCardId } = action.meta.arg
      state.promisesStatus.removeCreditCard[creditCardId] = {
        ...initialPromiseStatus,
        status: [FetchStatus.Pending],
      }
    })
    builder.addCase(removeCreditCard.rejected, (state, action) => {
      const { creditCardId } = action.meta.arg
      state.promisesStatus.removeCreditCard[creditCardId] = {
        ...initialPromiseStatus,
        status: [FetchStatus.Rejected],
      }
    })
  },
})

export default creditCardsSlice.reducer
export const { actions } = creditCardsSlice

/**
 * selectors
 */
export const stateSelectors = (state: RootState) => state.creditCards
export const {
  selectAll,
  selectById,
  selectEntities,
  selectIds,
  selectTotal,
} = adapter.getSelectors<RootState>(stateSelectors)

export const promiseStatusSelectors = (
  state: RootState,
  creditCardId?: string
) => {
  const promises = stateSelectors(state).promisesStatus
  return {
    getCreditCardsStatus: sliceUtil.fetchStatus(promises.getCreditCards),
    addCreditCardStatus: sliceUtil.fetchStatus(promises.addCreditCard),
    removeCreditCardStatus: creditCardId
      ? sliceUtil.fetchStatus(promises.removeCreditCard[creditCardId])
      : null,
  }
}
