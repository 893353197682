import { useChangeLayout, useUpdateTicket } from 'hooks'
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { paths } from 'routing'
import { AppSnackBarProvider } from 'shared'
import { BOTTOM_BAR_HEIGHT, TRANSITION_TIME } from 'shared/layout/constants'
import PopupProvider from 'shared/popup_provider'
import { customerSlice, mapSlice, ticketsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import styled from 'styled-components'
import MapContainer from './MapContainer'

const Wrapper = styled.div`
  position: relative;
  .search-view-enter {
    opacity: 0;
  }
  .search-view-enter-active {
    opacity: 1;
    transition: opacity ${TRANSITION_TIME}ms;
  }
  .search-view-exit {
    opacity: 1;
  }
  .search-view-exit-active {
    opacity: 0;
    transition: opacity ${TRANSITION_TIME}ms;
  }
`

export interface MapViewProps {}

export const MapView: React.FC<MapViewProps> = props => {
  const dispatch = useAppDispatch()
  const { selectedLot } = useSelector(mapSlice.stateSelectors)
  const { token } = useParams<{ token: string }>()
  useUpdateTicket()
  const { idle, rejected } = useSelector(
    ticketsSlice.promiseStatusSelectors
  ).getTicketsStatus
  const { customerSignedOut } = useSelector(customerSlice.selectCustomerStatus)
  useChangeLayout(
    {
      layout: {
        hasTopBar: customerSignedOut ? true : false,
        hasBottomBar: true,
        hasFlatBackground: customerSignedOut ? false : true,
      },
      autoToogleSnackGap: false,
      bottomMenu: customerSignedOut ? false : true,
      topbar: null,
    },
    'app',
    [customerSignedOut]
  )

  React.useEffect(() => {
    if (!customerSignedOut && (idle || rejected)) {
      dispatch(ticketsSlice.getTickets())
    }
    return () => {
      dispatch(mapSlice.actions.unsetFindCarMode())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSignedOut, dispatch, idle])

  if (customerSignedOut && !token) {
    return <Redirect to={paths.auth.login} />
  }
  return (
    <AppSnackBarProvider
      hasBottomGutter
      autoHideDuration={2000}
      gutterSize={selectedLot ? 170 + BOTTOM_BAR_HEIGHT : undefined}
    >
      <PopupProvider>
        <Wrapper>
          <MapContainer />
        </Wrapper>
      </PopupProvider>
    </AppSnackBarProvider>
  )
}

export default MapView
