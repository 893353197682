import { useChangeLayout } from 'hooks'
import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { paths } from 'routing'
import { PaddingContainer, PageDialog } from 'shared'
import SendMailComplete from './SendMailComplete'
import SendMeMail from './SendMeMail'
import { ThemeProvider } from '@mui/material/styles'
import { whiteTheme } from 'themes'
import { useSendEmail } from './useSendEmail'

export interface PersonalData {
  email: string
}

export const ForgotPasswordRedirect = () => {
  const [success, setSuccess] = React.useState(false)
  const history = useHistory()
  const { carId } = useParams<{ carId: string }>()
  const sendEmail = useSendEmail(setSuccess)
  useChangeLayout()
  const goBack = () => {
    history.push(`${paths.parking.login}/${carId}`)
  }

  return (
    <ThemeProvider theme={whiteTheme}>
      <PaddingContainer>
        <SendMeMail handleSubmit={sendEmail} goBack={goBack} />
      </PaddingContainer>
      <PageDialog open={success}>
        <SendMailComplete goBack={goBack} />
      </PageDialog>
    </ThemeProvider>
  )
}
