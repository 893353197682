import { unwrapResult } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { batch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import { customerSlice, mapSlice, ticketsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { UserRole } from 'typedef/customer'
import { FindCarPayload } from 'typedef/customer'
import Translations from '../../utils/translations'
import { FindCarsForm } from './FindCarsForm'
interface FindCarsContainerProps {}

export const FindCarsContainer: React.FunctionComponent<
  FindCarsContainerProps
> = props => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const { auth } = useSelector(customerSlice.stateSelectors).tokens
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const { pending } = useSelector(
    customerSlice.promiseStatusSelectors
  ).findCarStatus

  const findCar = React.useCallback(
    async (arg: FindCarPayload) => {
      const promise = await dispatch(customerSlice.findCar(arg))
      if (customerSlice.findCar.fulfilled.match(promise)) {
        const { ticket, tokens } = unwrapResult(promise)
        batch(async () => {
          if (tokens.auth !== auth) {
            dispatch(
              customerSlice.actions.setLogin({
                role: UserRole.Guest,
                tokens,
              })
            )
            dispatch(ticketsSlice.getTickets())
          }
          dispatch(
            mapSlice.actions.selectLot({
              lotId: ticket.lot.lotId,
              position: true,
            })
          )
          dispatch(mapSlice.getLotDetailsMap({ lotId: ticket.lot.lotId }))
          dispatch(mapSlice.actions.foundParkedCar({ lotId: ticket.lot.lotId }))
          push(paths.home.map)
        })
      } else {
        enqueueSnackbar(t(Translations.NO_PARKED_CARS))
      }
    },
    [auth, dispatch, enqueueSnackbar, push, t]
  )

  return <FindCarsForm disabled={pending} findCar={findCar} />
}

export default FindCarsContainer
