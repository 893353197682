import { Form, Formik } from 'formik'
import { useChangeLayout } from 'hooks'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import paths from 'routing/paths'
import {
  AppButton,
  AppSnackBarProvider,
  AppSpinner,
  FlexBox,
  Input,
  Placeholder,
  SharedIcon,
  WhiteBoxContainer,
} from 'shared'
import { showPopup } from 'store/globalPopup/actions'
import { carsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { licencePlateRegex } from 'utils/regex'
import { Translations, blurLastInput } from 'utils'
import * as Yup from 'yup'

interface AddCarProps {}

const AddCar: React.FunctionComponent<AddCarProps> = props => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { addCarStatus } = useSelector(carsSlice.promiseStatusSelectors)
  const { enqueueSnackbar } = useSnackbar()
  const handleSubmit = async (values: { plate: string; name: string }) => {
    const resultAction = await dispatch(
      carsSlice.addCar({
        name: values.name,
        plate: values.plate,
      })
    )
    if (carsSlice.addCar.fulfilled.match(resultAction)) {
      dispatch(
        showPopup({
          title: t(Translations.SUCCESS),
          description: t(Translations.THE_CAR_HAS_BEEN_ADDED),
          variant: 'success',
          okButtonClick: () => history.push(paths.parker.myProfile),
        })
      )
      dispatch(carsSlice.getCars())
    }
    if (carsSlice.addCar.rejected.match(resultAction)) {
      enqueueSnackbar(resultAction.payload.description)
    }
  }

  const CarInputSchema = Yup.object().shape({
    plate: Yup.string()
      .required(t(Translations.PLATE_CANNOT_BE_EMPTY))
      .matches(
        licencePlateRegex,
        t(Translations.ONLY_ALPHANUMERIC_CHARACTERS_ARE_ALLOWED)
      ),
    name: Yup.string(),
  })

  return (
    <WhiteBoxContainer>
      <AppSpinner show={addCarStatus.pending} />
      <Placeholder
        title={t(Translations.ADD_NEW_CAR)}
        icon={<SharedIcon.Cars />}
      />

      <Formik
        initialValues={{ plate: '', name: '' }}
        onSubmit={handleSubmit}
        validationSchema={CarInputSchema}
      >
        {({ values, errors, handleBlur, handleChange, validateForm }) => (
          <Form>
            <Input
              placeholder={t(Translations.LICENCE_PLATE)}
              name="plate"
              type="text"
              inputMode="text"
              value={values.plate}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.plate}
              maxLength={12}
              autoFocus
              data-testid="vehicleLicenceInput"
            />
            <Input
              placeholder={t(Translations.CAR_NAME)}
              name="name"
              type="text"
              inputMode="text"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.name}
              onKeyDown={e => {
                blurLastInput(e)
              }}
              data-testid="vehicleNameInput"
            />
            <FlexBox hasTopMargin>
              <AppButton
                variant="contained"
                color="primary"
                type="submit"
                data-testid="addCarSubmit"
                onClick={() => {
                  validateForm().then(errors => {
                    if (errors) {
                      const messages = Object.values(errors) as string[]
                      if (messages.length) {
                        enqueueSnackbar(messages[0])
                      }
                    }
                  })
                }}
              >
                {t('ADD_NEW_CAR')}
              </AppButton>
              <AppButton
                variant="outlined"
                color="primary"
                onClick={() => history.push(paths.parker.myProfile)}
              >
                {t(Translations.CANCEL)}
              </AppButton>
            </FlexBox>
          </Form>
        )}
      </Formik>
    </WhiteBoxContainer>
  )
}

export const AddCarView: React.FC = props => {
  const { t } = useTranslation()
  const title = t(Translations.ADD_NEW_CAR)
  const { push } = useHistory()
  useChangeLayout(
    {
      topbar: {
        onClickBack: () => push(paths.parker.myProfile),
        title,
      },
    },
    'app'
  )

  return (
    <>
      <AppSnackBarProvider hasBottomGutter>
        <AddCar />
      </AppSnackBarProvider>
    </>
  )
}

export default AddCarView
