import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={295} height={168} viewBox="0 0 295 168" fill="none" {...props}>
      <g filter="url(#prefix__filter0_d)">
        <rect x={4} width={287} height={160} rx={15} fill="#EC3B56" />
      </g>
      <path
        d="M171.293 0C171.293 91 87 103 87 160h189c8.284 0 15-6.716 15-15V15c0-8.284-6.7-15-14.984-15H171.293z"
        fill="#DE344E"
      />
      <rect
        x={24.5}
        y={21.5}
        width={42.154}
        height={32}
        rx={3.5}
        fill="url(#prefix__paint1_linear)"
        stroke="#B1831F"
      />
      <path
        d="M38.695 41.307c-.202.801-.31 1.654-.31 2.538 0 4.673 3.03 8.462 6.769 8.462 3.738 0 6.77-3.789 6.77-8.462 0-.884-.11-1.737-.31-2.538-.18-.705-.537-2.454-.537-3.808 0-1.354 1.128-3.102 1.692-3.808h14.385M51.923 42.154h15.23M38.385 33.691H24M38.385 42.154H24M51.71 29.038c-.752-3.65-3.402-6.347-6.556-6.347-3.739 0-6.77 3.789-6.77 8.462 0 .884.11 1.737.31 2.538.18.706.537 2.454.537 3.808 0 1.354-.358 3.103-.536 3.808"
        stroke="#B1831F"
      />
      <rect x={217} y={19} width={54} height={36} rx={2} fill="#fff" />
      <path
        d="M228.533 48.095h5.507c1.936 0 4.175-1.758 4.175-4.043V26h-5.325c-2.48 0-4.357 1.523-4.357 4.16v17.935z"
        fill="#2F93FD"
      />
      <path
        d="M239.426 48.095h5.507c1.937 0 4.176-1.758 4.176-4.043V26h-5.325c-2.482 0-4.358 1.523-4.358 4.16v17.935z"
        fill="#EC3B56"
      />
      <path
        d="M250.471 48.095h5.507c1.936 0 4.176-1.758 4.176-4.043V26h-5.325c-2.481 0-4.357 1.523-4.357 4.16l-.001 17.935z"
        fill="#4DCC71"
      />
      <path
        d="M228.512 37.687H227c.424 1.641 2.42 2.93 4.66 2.93 2.057 0 4.841-1.113 4.841-3.222v-3.81h-3.45v3.81c0 1.114-.907 1.992-2.178 1.992-1.15 0-2.179-.703-2.361-1.7zM258.703 38.45c0 .878-.786 1.582-1.815 1.582h-7.624v-6.446h7.624c.907 0 1.695.762 1.695 1.641s-.787 1.641-1.695 1.641c1.029 0 1.815.703 1.815 1.582zm-6.535-1.934h2.36c.605 0 1.15-.527 1.15-1.114 0-.585-.545-1.113-1.15-1.113h-2.36v2.227zm2.421 2.812c.604 0 1.149-.527 1.149-1.113s-.545-1.113-1.149-1.113h-2.421v2.226h2.421zM247.441 38.918v1.113h-5.81c-1.815 0-3.328-1.465-3.328-3.222 0-1.758 1.513-3.223 3.328-3.223h5.81v1.172c-1.392-.293-2.725-.469-3.39-.469-1.452 0-2.601 1.114-2.601 2.52s1.149 2.52 2.601 2.52c.665-.001 1.998-.118 3.39-.411z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={150.5}
          y1={161}
          x2={288}
          y2={161}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DE344E" />
          <stop offset={1} stopColor="#DE344E" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={24}
          y1={21}
          x2={67.154}
          y2={54}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C966" />
          <stop offset={0.516} stopColor="#FFE7BD" />
          <stop offset={1} stopColor="#E1A448" />
        </linearGradient>
        <filter
          id="prefix__filter0_d"
          x={0}
          y={0}
          width={295}
          height={168}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent
