import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import paths from 'routing/paths'
import {
  AppButton,
  FlexBox,
  FullLayout,
  PaddingContainer,
  PageHeading,
  SharedIcon,
} from 'shared'
import { customerSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { UserRole } from 'typedef/customer'
import Translations from 'utils/translations'

interface SetNewPasswordCompleteProps {}

const SetNewPasswordComplete: React.FunctionComponent<
  SetNewPasswordCompleteProps
> = props => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const dispatch = useAppDispatch()
  return (
    <FullLayout hasTopBar imageBackground>
      <PaddingContainer>
        <PageHeading
          title={t(Translations.THE_PASSWORD_IS_CHANGED)}
          subtitle={t(Translations.YOUR_PASSWORD_HAS_BEEN_RESET)}
        >
          <SharedIcon.ResetPassword />
        </PageHeading>
        <FlexBox hasTopMargin>
          <AppButton
            variant="outlined"
            onClick={() => {
              dispatch(customerSlice.actions.setRole(UserRole.User))
              push(paths.home.map)
            }}
          >
            {t(Translations.OK)}
          </AppButton>
        </FlexBox>
      </PaddingContainer>
    </FullLayout>
  )
}

export default SetNewPasswordComplete
