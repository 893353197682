import { unwrapResult } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { parkCarSlice } from 'store/slices'
import { ParkCarState } from 'store/slices/parkCarSlice types'
import { useAppDispatch } from 'store/store'
import { GetPricePayload } from 'typedef'
import { Translations } from 'utils'
import useParkMode from './useParkMode'

export interface useGetPriceProps {}

export const useGetPrice = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { getPriceStatus } = useSelector(parkCarSlice.promiseStatusSelectors)
  const { carId } = useParams<{ carId?: string }>()
  const parkMode = useParkMode()
  const { parkingDetails } = useSelector(parkCarSlice.stateSelectors)
  const { totalAmount } = parkingDetails

  const fetch = React.useCallback(
    async (args: GetPricePayload) => {
      const promise = await dispatch(parkCarSlice.getPrice({ carId, ...args }))
      if (parkCarSlice.getPrice.fulfilled.match(promise)) {
        const res = unwrapResult(promise)
        // FIXME: fix typing
        dispatch(
          parkCarSlice.actions.updateParkingDetails<
            Partial<ParkCarState['parkingDetails']>
          >({
            totalAmount:
              parkMode === 'addMoreTime'
                ? res.amount + res.convenienceFee
                : totalAmount,
            parkingExpireDateTime: res.parkingExpireDateTime,
            fees: [
              {
                amount: res.convenienceFee,
                label: res.convenienceFeeName,
              },
            ],
            parkingFee: {
              amount: res.amount,
              label: t(Translations.PARKING_FEE),
            },
            upfrontCoupons: res.validations,
          })
        )
      } else {
        enqueueSnackbar(promise.payload.description, {
          onClose: () => {
            dispatch(parkCarSlice.actions.resetParkingDetails())
            dispatch(parkCarSlice.actions.resetPromiseStatus('getPrice'))
          },
        })
      }
    },
    [carId, dispatch, enqueueSnackbar, parkMode, t, totalAmount]
  )
  return [getPriceStatus.pending, fetch] as const
}

export default useGetPrice
