import {
  AddCarData,
  AddCarPayload,
  DeleteCarPayload,
  GetCarsData,
} from 'typedef/car'
import { CUSTOMER_CAR } from './constants'
import axios from '.'
import { Response } from '../typedef/api'
import './mocks/CarsApiMock'

export const getCars = async () => {
  try {
    const result = await axios.get<Response<GetCarsData>>(CUSTOMER_CAR)
    return result.data.data
  } catch (err) {
    throw err
  }
}

export const addCar = async (request: AddCarPayload) => {
  try {
    const { data } = await axios.post<Response<AddCarData>>(
      CUSTOMER_CAR,
      request
    )
    return data.data
  } catch (err) {
    throw err
  }
}

export const deleteCar = async (request: DeleteCarPayload) => {
  try {
    await axios.delete(`${CUSTOMER_CAR}/${request.carId}`)
    return request.carId
  } catch (err) {
    throw err
  }
}
