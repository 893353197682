import { Grow } from '@mui/material'
import { MyCars as MyCarsDetails } from 'pages/my_cars/MyCars'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import {
  AppButton,
  AppSpinner,
  AppTypography,
  ErrorPlaceholder,
  FlexBox,
  Placeholder,
  SharedIcon,
  WhiteBoxContainer,
} from 'shared'
import * as carsSlice from 'store/slices/carsSlice'
import { fetchStatus } from 'store/slices/slice.utils'
import Translations from 'utils/translations'

export interface MyProfileProps {
  onClickRefresh: () => void
}

export const MyProfile: React.FC<MyProfileProps> = props => {
  const { t } = useTranslation()
  const { promisesStatus } = useSelector(carsSlice.stateSelectors)
  const cars = useSelector(carsSlice.selectAllCars)
  const status = fetchStatus(promisesStatus.getCars)
  const carAmount = cars.length ? ` (${cars.length})` : ''
  const carTitle = `${t(Translations.MY_CARS)}${carAmount}`
  const history = useHistory()
  const shouldRenderError = () =>
    !cars.length && (status.rejected || status.pendingRejected)

  return (
    <WhiteBoxContainer>
      <AppTypography variant="h2" weight="bold">
        {carTitle}
      </AppTypography>
      {status.pendingIdle && <AppSpinner show relative height="100px" />}
      {shouldRenderError() && (
        <ErrorPlaceholder
          showSpinner={status.pending}
          onClick={props.onClickRefresh}
        />
      )}
      <Grow timeout="auto" in={!!cars.length} appear={false}>
        <div>
          <MyCarsDetails cars={cars} />
        </div>
      </Grow>
      {!cars.length && (status.fulfilled || status.pendingFulfilled) && (
        <Placeholder
          title={t(Translations.NO_CARS_FOUND)}
          icon={<SharedIcon.Cars />}
        >
          {status.pending && <AppSpinner show />}
        </Placeholder>
      )}
      <FlexBox>
        <AppButton
          variant="contained"
          color="primary"
          onClick={() => history.push(paths.parker.addCar)}
          topGutter={!shouldRenderError() ? 15 : 0}
          data-testid="addNewCar"
        >
          {t(Translations.ADD_NEW_CAR)}
        </AppButton>
      </FlexBox>
    </WhiteBoxContainer>
  )
}

export default MyProfile
