import { ThemeProvider } from '@mui/material/styles'
import { useChangeLayout } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import { AppPopupProvider, AppSnackBarProvider } from 'shared'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'
import { hideLoader, showLoader } from 'store/loader'
import { customerSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { whiteTheme } from 'themes/white-theme'
import { Translations } from 'utils'
import ChangePassword from './ChangePassword'
export interface ChangePassowrdContainerProps {}

export const ChangePassowrdContainer: React.FC<ChangePassowrdContainerProps> =
  props => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const { push } = useHistory()
    const { setPopup, showPopup } = usePopup()
    const onClickCancel = () => {
      push(paths.parker.edit)
    }

    const onSubmit = async (password: string, newPassword: string) => {
      dispatch(showLoader())
      const promise = await dispatch(
        customerSlice.changePassword({ password, newPassword })
      )
      dispatch(hideLoader())
      if (customerSlice.changePassword.fulfilled.match(promise)) {
        setPopup({
          title: t(Translations.SUCCESS),
          description: t(Translations.YOUR_PASSWORD_HAS_BEEN_CHANGED),
          variant: 'success',
          buttons: [{ onClick: () => onClickCancel() }],
        })
      } else {
        setPopup({
          ...promise.payload,
          buttons: [{ onClick: () => onSubmit(password, newPassword) }, {}],
        })
      }
      showPopup(true)
    }

    return <ChangePassword onClickCancel={onClickCancel} onSubmit={onSubmit} />
  }

export const ChangePasswordPage: React.FC = props => {
  useChangeLayout()

  return (
    <AppSnackBarProvider>
      <AppPopupProvider>
        <ThemeProvider theme={whiteTheme}>
          <ChangePassowrdContainer />
        </ThemeProvider>
      </AppPopupProvider>
    </AppSnackBarProvider>
  )
}

export default ChangePasswordPage
