import T from 'utils/translations'
import { ErrorCode, ErrorResponse } from 'typedef'

// TODO:
export const login: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.NotEnoughParameters]: {
    title: T.INCORRECT_PASSWORD,
    description: '4013',
  },
  [ErrorCode.CustomerDoesntExist]: {
    title: T.INCORRECT_PASSWORD,
    description: '4013',
  },
}

export const getCustomerDetails: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.FAILED_PROPERLY_RETRIEVE,
    variant: 'warning',
  },
}
export const register: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
}
export const confirmRegister: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
}
export const remindPassword: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
}
export const resetPassword: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
}
export const changePassword: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description:
      T.YOUR_PASSWORD_CANNOT_BE_CHANGED_AT_THE_MOMENT_PLEASE_TRY_AGAIN_LATER,
    variant: 'warning',
  },
}
export const refreshToken: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
}
export const findCar: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.NO_CARS_FOUND,
    variant: 'warning',
  },
}

export const CustomerApiError = {
  login,
  getCustomerDetails,
  register,
  confirmRegister,
  remindPassword,
  resetPassword,
  changePassword,
  refreshToken,
  findCar,
}
export default CustomerApiError
