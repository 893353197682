import {
  number,
  // expirationDate,
  expirationMonth,
  expirationYear,
} from 'card-validator'
import { expirationDate } from 'card-validator/dist/expiration-date'

export function formatCreditCardNumber(cardNumber: string) {
  // if (cardNumber.endsWith(' ')) return cardNumber.trimLeft()

  const digitGroupRegex = /(\d{4}(?!\s)(?!$))/g
  const formattedcardNumber = cardNumber
    .trimRight()
    .replace(digitGroupRegex, '$1 ')
  return formattedcardNumber
}

export function formatCreditCardExpirationDate(expDate: string) {
  if (!expDate) return ''
  expDate = expDate.trim()
  let digits = expDate.replace(/[^0-9]+/, '')
  if (digits.length > 2) {
    digits = `${digits.slice(0, 2)} / ${digits.slice(2)}`
  }
  return digits
}

export function validateCardNumber(cardNumber: string) {
  if (!cardNumber) return false
  const str = cardNumber.replace(/\s/g, '')
  const { isValid } = number(str, {
    luhnValidateUnionPay: true,
  })
  return isValid
}
export function validateExpirationDate(dateStr: string) {
  const { isValid } = expirationDate(dateStr, 20)
  return isValid
}
export function validateExpirationMonth(month: string) {
  const { isValid } = expirationMonth(month)
  return isValid
}
export function validateExpirationYear(year: string) {
  const { isValid } = expirationYear(year, 20)
  return isValid
}

export const asterisks2Digits: (arg: string) => string = cardNumber => {
  const asteriskString = '**** **** **** ****'
  let str = ''
  cardNumber
    .replace(/\s/g, '')
    .split('')
    .forEach((el, i) => {
      if (i > 3 && i < 16 && i % 4 === 0) {
        str = str.concat(' ', el)
      } else {
        str = str.concat('', el)
      }
    })

  const length = str.length
  return str.concat(asteriskString.slice(length))
}
