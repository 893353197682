import { MenuItem, TextFieldProps } from '@mui/material'
import { SelectListOptionsProps } from 'shared/AppSelectListField/SelectListOptions'
import React from 'react'
import { AppTextField, AppTypography } from 'shared'

export interface AppSelectListFieldProps extends SelectListOptionsProps {
  value: number
  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  disabled?: boolean
  sufix: string
  name?: string
  $innerSufix?: boolean
}

export const AppSelectListField: React.FC<
  AppSelectListFieldProps & TextFieldProps
> = props => {
  const SelectListOptions = React.useMemo(() => {
    const arr = []
    for (let i = 0; i <= props.max; i = i + props.interval) {
      arr.push(i)
    }

    return arr.map(el => (
      <MenuItem value={el} key={el} data-testid={`selectItem-${el}`}>
        {`${el}${props.$innerSufix ? ` ${props.sufix}` : ''}`}
      </MenuItem>
    ))
  }, [props.$innerSufix, props.interval, props.max, props.sufix])

  return (
    <>
      <AppTextField
        defaultValue={0}
        {...props}
        value={props.value}
        select
        onChange={props.onChange}
        disabled={props.disabled}
        name={props.name}
      >
        {SelectListOptions}
      </AppTextField>
      {props.sufix && !props.$innerSufix && (
        <AppTypography paddingLeft={1}>{props.sufix}</AppTypography>
      )}
    </>
  )
}

export default AppSelectListField
