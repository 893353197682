import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import React from 'react'
import { AppTypography, SharedIcon } from 'shared'
import styled from 'styled-components'
import { makeStyles } from '@mui/styles'

export interface AppHeaderProps {
  topText: string
  bottomText: string
  iconSrc: string
  hasRightElement?: boolean
}

const StyledList = styled(List)`
  padding: 0;
  li {
    padding: 0;
  }
`
const useStyles = makeStyles(theme => ({
  root: {
    width: 60,
    overflow: 'initial',
    backgroundColor: 'inherit',
    marginRight: 16,
  },
}))
export const AppHeader: React.FC<AppHeaderProps> = ({
  bottomText,
  iconSrc,
  topText,
}) => {
  const classes = useStyles()
  return (
    <StyledList dense>
      <ListItem data-testid="receiptBar" disableGutters dense>
        <ListItemAvatar>
          <Avatar
            src={iconSrc}
            alt="Parking"
            classes={{ root: classes.root }}
            variant="square"
            imgProps={{
              style: { objectFit: 'contain', background: 'transparent' },
            }}
          >
            <SharedIcon.LotAvatarPlaceholder />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={<AppTypography color="textPrimary">{topText}</AppTypography>}
          secondary={
            <AppTypography weight="bold" color="textPrimary">
              {bottomText}
            </AppTypography>
          }
        />
      </ListItem>
    </StyledList>
  )
}
AppHeader.defaultProps = {}
export default AppHeader
