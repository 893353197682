import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={26} height={26} viewBox="0 0 26 26" fill="none" {...props}>
      <path
        d="M3.933 18.507a10.461 10.461 0 0011.066 5.05m8.405-11.362a10.463 10.463 0 01-.3 3.805"
        stroke="#000"
        strokeLinecap="round"
      />
      <path
        d="M3.343 21.335v-3.22h3.219"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.066 7.78a10.462 10.462 0 00-19.471 6.312"
        stroke="#000"
        strokeLinecap="round"
      />
      <path
        d="M22.657 4.952v3.22h-3.219"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M18 19l6 6M24 19l-6 6" stroke="#F33" strokeLinecap="round" />
    </svg>
  )
}

export default SvgComponent
