import {
  CircularProgress,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
} from '@mui/material'
import { Close, Search } from '@mui/icons-material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { AppTextField } from 'shared'
import styled from 'styled-components'
import Translations from '../../../utils/translations'
import { makeStyles } from '@mui/styles'
import { customerSlice, mapSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'

interface SearchBoxProps {
  onSearchClick: () => void
  showSearchView: (arg: boolean) => void
  setSearchValue: (arg: string) => void
  searchValue: string
  pending: boolean
  isSearchView: boolean
}

type BlurHandle = {
  blur: () => void
}

const SearchBoxContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: ${_ => _.theme.spacing(2)};
  z-index: 4;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  .MuiInputBase-root {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  }
`
const useStyles = makeStyles(_ => ({
  iconRoot: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const SearchBox: React.ForwardRefRenderFunction<BlurHandle, SearchBoxProps> = (
  props,
  ref
) => {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useAppDispatch()
  const inputEl = React.useRef(null)
  React.useImperativeHandle(ref, () => ({
    blur: () => {
      inputEl.current.blur()
    },
  }))
  const onChange = (e: any) => {
    props.setSearchValue(e.target.value?.toUpperCase())
    if (e.target.value) {
      props.showSearchView(true)
    } else {
      dispatch(customerSlice.actions.setCorporationGlobalPcode(''))
      dispatch(customerSlice.actions.setCorporationToken(''))
      dispatch(mapSlice.actions.resetBoundsToOmit())
    }
  }
  const onSubmit = (e: any) => {
    if (e.key === 'Enter' && e.target.value.trim()) {
      props.onSearchClick()
    }
    if (!e.target.value && (e.key === 'Escape' || e.key === 'Backspace')) {
      props.showSearchView(false)
    }
  }

  const onClickClear = () => {
    props.setSearchValue('')
    dispatch(customerSlice.actions.setCorporationGlobalPcode(''))
    dispatch(customerSlice.actions.setCorporationToken(''))
    dispatch(mapSlice.actions.resetBoundsToOmit())
    inputEl.current.focus()
  }

  const onSearchClick = () => {
    if (props.searchValue) {
      props.onSearchClick()
      props.showSearchView(false)
    }
  }

  return (
    <SearchBoxContainer>
      <FormControl>
        <AppTextField
          inputRef={inputEl}
          value={props.searchValue}
          onKeyDown={onSubmit}
          onChange={onChange}
          onFocusCapture={() => props.showSearchView(true)}
          onClick={() => props.showSearchView(true)}
          fullWidth
          placeholder={t(Translations.PCODE_PARKING_NAME)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={onSearchClick}>
                  <Search color="disabled" />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {props.pending && props.isSearchView && (
                  <Icon classes={{ root: classes.iconRoot }}>
                    <CircularProgress size={18} />
                  </Icon>
                )}
                {props.searchValue && (
                  <IconButton onClick={onClickClear}>
                    <Close />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          inputProps={{ 
            maxLength: 10, 
            type: 'search',  
            enterKeyHint: 'go',
            inputMode: 'search' 
          }}
        />
      </FormControl>
    </SearchBoxContainer>
  )
}

export default React.forwardRef(SearchBox)
