import { Typography } from '@mui/material'
import * as React from 'react'
import styled from 'styled-components'
import { Receipt } from 'typedef/receipt'
import { getDate } from 'utils/moment'
import { formatCurrency } from 'utils/random'

const ReceiptInfoWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 10px 0;
`
const ReceiptInfo: React.FC<
  Pick<Receipt, 'amount' | 'dateInUTC' | 'locationName'>
> = props => (
  <ReceiptInfoWrapper>
    <div>
      <Typography align="left">{getDate(props.dateInUTC)}</Typography>
      <Typography align="left"> {props.locationName}</Typography>
    </div>
    <Typography>
      {formatCurrency(props.amount.currency, props.amount.totalAmount)}
    </Typography>
  </ReceiptInfoWrapper>
)

export default ReceiptInfo
