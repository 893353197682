import {
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { SharedIcon } from 'shared'
import * as carSlice from 'store/slices/carsSlice'
import { CarStateType } from 'store/slices/carsSlice.types'
import { fetchStatus } from 'store/slices/slice.utils'
import styled from 'styled-components'
interface CarBarProps {
  carPlate: string
  handleDeleteClick(): void
  car: CarStateType
  divider?: boolean
}

const PlateIcon = styled.div`
  width: 45px;
  margin: 10px 15px 5px 0;
`

const CarBar: React.FunctionComponent<CarBarProps> = props => {
  const { removeCar } = useSelector(carSlice.stateSelectors).promisesStatus
  const status = fetchStatus(removeCar[props.car.carId])

  return (
    <>
      <ListItem data-testid="bar">
        <PlateIcon>
          <SharedIcon.Plate />
        </PlateIcon>
        <ListItemText primary={props.carPlate} />
        <ListItemSecondaryAction>
          {status.pending ? (
            <Icon>
              <CircularProgress size={22} style={{ padding: '4px' }} />
            </Icon>
          ) : (
            <IconButton
              edge="end"
              aria-label="delete"
              data-testid="deleteCarIcon"
              onClick={props.handleDeleteClick}
            >
              {status.rejected && (
                <SharedIcon.RefreshBroken style={{ padding: '3px' }} />
              )}
              {status.idle && <SharedIcon.Delete />}
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      {props.divider && <Divider variant="middle" component="li" />}
    </>
  )
}

export default CarBar
