import { useChangeLayout } from 'hooks'
import * as React from 'react'
import { Redirect, RouteComponentProps, useParams } from 'react-router-dom'
import { paths } from 'routing'
import { PageDialog } from 'shared'
import { showPopup } from 'store/globalPopup/actions'
import { hideLoader, showLoader } from 'store/loader'
import { customerSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import SetNewPassword from './SetNewPassword'
import SetNewPasswordComplete from './SetNewPasswordComplete'
import { ThemeProvider } from '@mui/material/styles'
import { whiteTheme } from 'themes'

interface ResetPasswordProps extends RouteComponentProps {}

const ResetPassword = (props: ResetPasswordProps) => {
  const [success, setSuccess] = React.useState(false)
  const { token } = useParams<{ token: string }>()

  const dispatch = useAppDispatch()
  useChangeLayout()

  const onSubmit = async (password: string) => {
    dispatch(showLoader())
    const promise = await dispatch(
      customerSlice.resetPassword({ password, confirmToken: token })
    )
    dispatch(hideLoader())
    if (customerSlice.resetPassword.fulfilled.match(promise)) {
      setSuccess(true)
    } else {
      // FIXME: update to new popup system
      dispatch(
        showPopup({
          ...promise.payload,
          handleAdditionalButtonClick: () => onSubmit(password),
        })
      )
    }
  }

  if (!token) return <Redirect to={paths.root.root} />
  return (
    <ThemeProvider theme={whiteTheme}>
      <SetNewPassword handleSubmit={onSubmit} />
      <PageDialog open={success}>
        <SetNewPasswordComplete />
      </PageDialog>
    </ThemeProvider>
  )
}

export default ResetPassword
