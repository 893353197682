import T from 'utils/translations'
import { ErrorCode, ErrorResponse } from 'typedef'

export const getLotDetailsPark: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.IncorrectParameterException]: {
    title: T.OOPS,
    description: T.INCORRECT_TOKEN,
  },
  [ErrorCode.TokenExpired]: {
    title: T.OOPS,
    description: T.TOKEN_EXPIRED,
  },
}
export const getLots: ErrorResponse = {
  [ErrorCode.IncorrectParameterException]: {
    title: T.OOPS,
    description: T.INCORRECT_TOKEN,
  },
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.TokenExpired]: {
    title: T.OOPS,
    description: T.TOKEN_EXPIRED,
  },
}
export const getSimulatedPrices: ErrorResponse = {}

export const LotApiError = { getLotDetailsPark, getLots, getSimulatedPrices }
export default LotApiError
