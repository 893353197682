import axios, { AxiosRequestConfig } from 'axios'
import { API_URL } from './config'

const axiosRequestConfig: AxiosRequestConfig = {
  baseURL: API_URL,
  headers: { Version: '1.0', 'Content-Type': 'application/json' },
}

const instance = axios.create(axiosRequestConfig)
const mockedAxios = axios.create(axiosRequestConfig)

instance.interceptors.response.use(
  response => {
    return response
  },
  function (err) {
    if (process.env.NODE_ENV === 'test') {
      const { url, method } = err.response.config
      process.stdout.write(
        `\u001b[1;33m  Unhandled API call - ${method}${url}\n`
      )
    }
    return Promise.reject(err)
  }
)
mockedAxios.interceptors.response.use(
  response => {
    // eslint-disable-next-line no-console
    console.log(
      'API MOCK -',
      response.status,
      response.config.method?.toUpperCase(),
      response.config.url,
      { req: response.config.params, resp: response.data.data }
    )
    return response
  },
  function (err) {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'test'
    )
      // eslint-disable-next-line no-console
      console.log({ err })
    return Promise.reject(err)
  }
)

export { mockedAxios }
export default instance
