import { Grid } from '@mui/material'
import React from 'react'
import { AppHeader } from 'shared'
import { LotState } from 'store/slices/mapSlice.types'
export interface LotNameSectionProps {
  lot: Pick<LotState, 'lotName' | 'locationName' | 'logoLink'>
}

export const LotNameSection: React.FC<LotNameSectionProps> = ({ lot }) => {
  return (
    <Grid item xs={12}>
      <AppHeader
        iconSrc={lot.logoLink}
        topText={lot.locationName}
        bottomText={lot.lotName}
      />
    </Grid>
  )
}
LotNameSection.defaultProps = {}
export default LotNameSection
