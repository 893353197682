import { useParams, useRouteMatch } from 'react-router-dom'
import { paths } from 'routing'

export const useParkMode = () => {
  const { carId, lotId } = useParams<{ carId?: string; lotId?: string }>()
  const parkCarPay = useRouteMatch(paths.parking.pay)
  const parkCarPaid = useRouteMatch(paths.parking.paid)
  const parkCarSelectCard = useRouteMatch(paths.parking.selectCard)
  const parkManageCards = useRouteMatch(paths.parking.manageCards)
  const parkCarAddCard = useRouteMatch(paths.parking.addCard)

  const addTimePay = useRouteMatch(paths.parking.addTimePay)
  const addTimeSelectCard = useRouteMatch(paths.parking.addTimeSelectCard)
  const addTimeManageCards = useRouteMatch(paths.parking.addTimeManageCards)
  const addTimeAddCard = useRouteMatch(paths.parking.addTimeAddCard)

  if (
    parkCarSelectCard ||
    parkCarPay ||
    parkCarPaid ||
    parkManageCards ||
    parkCarAddCard
  )
    return 'parkCar'
  if (addTimePay || addTimeSelectCard || addTimeManageCards || addTimeAddCard)
    return 'addMoreTime'
  if (carId) {
    return 'addMoreTime'
  }
  if (lotId) return 'parkCar'
  throw Error('Unhandled park mode')
}

export default useParkMode
