import {
  SHOW_LOADER,
  HIDE_LOADER,
  SHOW_LOADING_PAGE,
  HIDE_LOADING_PAGE,
} from './constants'

export const showLoader = (spinnerNames?: string[]) => ({
  type: SHOW_LOADER as typeof SHOW_LOADER,
  spinnerNames: spinnerNames || [],
})

export const hideLoader = (spinnerNames?: string[]) => ({
  type: HIDE_LOADER as typeof HIDE_LOADER,
  spinnerNames: spinnerNames || [],
})

export const showLoadingPage = () => ({
  type: SHOW_LOADING_PAGE as typeof SHOW_LOADING_PAGE,
})

export const hideLoadingPage = () => ({
  type: HIDE_LOADING_PAGE as typeof HIDE_LOADING_PAGE,
})

export type Actions =
  | ReturnType<typeof showLoader>
  | ReturnType<typeof hideLoader>
  | ReturnType<typeof showLoadingPage>
  | ReturnType<typeof hideLoadingPage>
