import { Actions } from './actions'
import {
  SHOW_LOADER,
  HIDE_LOADER,
  HIDE_LOADING_PAGE,
  SHOW_LOADING_PAGE,
} from './constants'

export type LoaderState = {
  show: boolean
  showLoadingPage: boolean
  spinners: string[]
}

const initialState: LoaderState = {
  show: false,
  showLoadingPage: false,
  spinners: [],
}

export const loaderReducer = (
  state = initialState,
  action: Actions
): LoaderState => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        ...state,
        spinners: [...state.spinners, ...action.spinnerNames],
        show: action.spinnerNames.length ? state.show : true,
      }

    case HIDE_LOADER:
      return {
        ...state,
        spinners: state.spinners.filter(
          el => !action.spinnerNames.includes(el)
        ),
        show: action.spinnerNames.length ? state.show : false,
      }
    case SHOW_LOADING_PAGE:
      return {
        ...state,
        showLoadingPage: true,
      }
    case HIDE_LOADING_PAGE:
      return {
        ...state,
        showLoadingPage: false,
      }
    default:
      return state
  }
}
