import { useParkCarPath } from 'pages/park_car/ParkCarHooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'
import Translations from 'utils/translations'

export const useExpiredPricingPopup = () => {
  const { setPopup, showPopup } = usePopup()
  const [_, goTo] = useParkCarPath()
  const { t } = useTranslation()

  return React.useCallback(() => {
    setPopup({
      variant: 'error',
      description: t(
        Translations.RATE_HAS_EXPIRED_PLEASE_SELECT_YOUR_TIME_AGAIN
      ),
      title: t(Translations.RATE_HAS_EXPIRED),
      disableBackdropClick: true,
      buttons: [
        {
          onClick() {
            goTo('set', { fullRefresh: true })
          },
        },
      ],
    })
    showPopup(true)
  }, [goTo, setPopup, showPopup, t])
}

export default useExpiredPricingPopup
