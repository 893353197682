import bg from 'assets/images/bg.png'
import { useCustomLotColors } from 'hooks'
import styled, { css } from 'styled-components'
import {
  BOTTOM_BAR_HEIGHT,
  INNER_WIDTH,
  NARROW_INNER_WIDTH,
  SNACKBAR_HEIGHT,
  TOP_BAR_HEIGHT,
} from './constants'
import React, { ComponentProps } from 'react'
export const OuterContainer = styled.div<{
  hasTopBar?: boolean
  hasBottomBar?: boolean
  hasFlatBackground?: boolean
  whiteBackground?: boolean
  imageBackground?: boolean
  hasAd?: boolean
  height: number
}>`
  min-height: ${_ => _.height}px;
  width: 100%;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  transition: background-color 0.2s;
  ${_ =>
    _.imageBackground &&
    css`
      background-image: url(${bg});
    `}
  ${_ =>
    _.hasFlatBackground &&
    css`
      background-color: #e5e5e5;
    `}
`

export const InnerContainer = styled.div<{
  center?: boolean
  narrow?: boolean
  hasFlatBackground?: boolean
  innerScroll?: boolean
}>`
  ::-webkit-scrollbar {
    display: none;
  }
  width: 100%;
  height: 100%;
  max-width: ${_ => (_.narrow ? NARROW_INNER_WIDTH : INNER_WIDTH)}px;
  margin: 0 auto;
  z-index: 1;
  box-sizing: border-box;
  position: relative;
  overflow: ${_ => (_.innerScroll ? 'scroll' : 'visible')};
  scrollbar-width: none;
  ${_ =>
    _.center &&
    css`
      margin: auto;
    `}
`

interface PaddingContainerProps {
  /**
   * theme.spacing(number)
   */
  paddingTop?: number
  padding?: number
  hasTopBar?: boolean
}
export const PaddingContainer = styled.div<PaddingContainerProps>`
  padding: ${_ =>
    _.padding ? _.theme.spacing(_.padding) : _.theme.spacing(1)};
  ${_ =>
    _.paddingTop &&
    css`
      padding-top: ${_.theme.spacing(_.paddingTop)};
    `}
  ${_ =>
    _.hasTopBar &&
    css`
      /* padding-top: ${TOP_BAR_HEIGHT}px; */
    `}
`
export const TopBarSpace = styled.div`
  width: 100%;
  height: ${TOP_BAR_HEIGHT}px;
`
export const BottomBarSpace = styled.div<{
  hasBottomBar?: boolean
  hasAd?: boolean
  hasSnackbar?: boolean
}>`
  width: 100%;
  ${_ => {
    const bar = _.hasBottomBar ? BOTTOM_BAR_HEIGHT : 0
    const snack = _.hasSnackbar ? SNACKBAR_HEIGHT : 0
    return css`
      height: ${bar + snack}px;
    `
  }}
`

export const AppPaper = styled.div<{
  minHeight?: number
  minWidth?: number
  maxWidth?: number
  noShadow?: boolean
  gridPaddingDiff?: number
  backgroundUrl?: string
}>`
  overflow: hidden;
  background-color: #fff;
  ${_ =>
    _.backgroundUrl &&
    css`
      background-image: url(${_.backgroundUrl});
      background-repeat: repeat;
    `};
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: ${_ => (_.noShadow ? 'none' : '0px 0px 5px rgba(0, 0, 0, 0.15)')};
  position: relative;
  margin-top: 0;
  padding: ${_ => _.theme.spacing(2.5 - _.gridPaddingDiff)};
  margin: ${_ => _.theme.spacing(1)};
  min-height: ${_ => _.minHeight}px;
  ${_ =>
    _.minWidth &&
    css`
      min-width: ${_.minWidth}px;
    `}
  ${_ =>
    _.maxWidth &&
    css`
      max-width: ${_.maxWidth}px;
    `}
`
AppPaper.defaultProps = {
  gridPaddingDiff: 0,
  minHeight: 150,
}

export const WhiteBoxContainer = React.forwardRef(function BoxContainer(
  props: { backgroundUrl?: string } & ComponentProps<typeof AppPaper>,
  ref
) {
  const { cardBgUrl } = useCustomLotColors()
  return (
    <AppPaper
      backgroundUrl={props.backgroundUrl || cardBgUrl}
      ref={ref}
      {...props}
    />
  )
})
