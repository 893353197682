import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'
import { AddContactInformationsPayload } from 'typedef'
import { Translations } from 'utils'
import { ReceiptsAndNotificationsFormValues, RedirectType } from './types'
import { contactInformationsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { hideLoader, showLoader } from 'store/loader'
import { useParkCar } from 'pages/park_car/ParkCarHooks'

export const useSubmitForm = (
  redirectType: RedirectType,
  carId: string,
  confirmToken: string,
  pin: string,
  paymentRedirectionAvailable?: boolean,
  recaptchaToken?: string
) => {
  const { t } = useTranslation()
  const { setPopup, showPopup } = usePopup()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [, , paymentRequired] = useParkCar()

  const handleSubmit = async (values: ReceiptsAndNotificationsFormValues) => {
    const params = {
      carId,
      email: values.email,
      countryCode: parseInt(values.code),
      phoneNumber: values.phoneNumber,
      confirmToken: confirmToken,
      pin,
    }
    const existingParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value !== '')
    )

    dispatch(showLoader())
    const promise = await dispatch(
      contactInformationsSlice.addContactInformations(
        existingParams as AddContactInformationsPayload
      )
    )
    dispatch(hideLoader())
    if (
      contactInformationsSlice.addContactInformations.fulfilled.match(promise)
    ) {
      if (redirectType === RedirectType.ContactInformations) {
        paymentRequired({ carId, paymentRedirectionAvailable }, recaptchaToken)
      } else {
        setPopup({
          variant: 'success',
          title: t(Translations.SUCCESS),
          description: ' ',
          buttons: [
            {
              onClick() {
                history.push(paths.parking.tickets)
              },
            },
          ],
        })
        showPopup(true)
      }
    } else {
      setPopup({
        ...promise.payload,
      })
      showPopup(true)
    }
  }

  return handleSubmit
}
