import { Step, StepLabel, Stepper, StepperProps } from '@mui/material'
import * as React from 'react'
import styled from 'styled-components'

export interface AppStepperProps {
  steps: (string | number)[]
  hasLabel?: boolean
  maxWidth?: string
}

const S = {
  Stepper: styled(Stepper)``,
  StepLabel: styled(StepLabel)`
    font-weight: bold;
    [class*='Mui-completed'] {
      fill: #fff;
    }
    [class*='Mui-active'] {
      circle {
        fill: #fff;
      }
      [class*='MuiStepIcon-text'] {
        fill: ${_ => _.theme.palette.secondary.main};
      }
    }
  `,
}

const AppStepperWrapper = styled.span<{ maxWidth?: string }>`
  ${S.Stepper} {
    padding: 0;
    max-width: ${_ => _.maxWidth};
    margin: auto;
  }
`

const AppStepper: React.SFC<AppStepperProps & Partial<StepperProps>> = ({
  maxWidth,
  hasLabel,
  steps,
  ...props
}) => {
  return (
    <AppStepperWrapper maxWidth={maxWidth}>
      <S.Stepper {...props}>
        {steps.map(label => (
          <Step key={label}>
            <S.StepLabel>{hasLabel ? label : ''}</S.StepLabel>
          </Step>
        ))}
      </S.Stepper>
    </AppStepperWrapper>
  )
}

AppStepper.defaultProps = {
  hasLabel: false,
  alternativeLabel: true,
  maxWidth: '300px',
}

export default AppStepper
