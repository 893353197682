import moment from 'moment'
import 'moment/min/locales'
// import momentDurationFormatSetup from 'moment-duration-format'
// var moment = require('moment')
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment as any)

export const timeSpanFormat = 'HH:mm'
export const getDate = (arg: string | Date) => moment(arg).format('L')

export const isValid = (endDate: string | Date) =>
  moment(endDate).startOf('minute') >= moment().startOf('minute')

export interface CountDiffParkingTimeArgs {
  /** Current time on lot at its zone */
  currParkingTime: string
  /** Current time on lot at local zone */
  currLocalTime: number
  /** End parking time at lot zone*/
  endParkingTime: string
}
type CountDiffParkingTime = (
  args: CountDiffParkingTimeArgs
) => /** Time difference */ number
export const countDiffParkingTime: CountDiffParkingTime = ({
  currParkingTime,
  currLocalTime,
  endParkingTime,
}) => {
  return (
    +moment(endParkingTime) -
    +moment(currParkingTime) -
    (Date.now() - currLocalTime) +
    55000
  )
}
export const countParkTime = (parkStateDate: string, parkEndDate: string) => {
  const dateFormat = 'd[D]  h[H] m[M] '
  const time = moment.duration(
    moment(parkEndDate)
      .startOf('minute')
      .diff(moment(parkStateDate).startOf('minute')),
    'milliseconds'
  )
  return time.format(dateFormat, {
    minValue: 1,
    trim: 'all',
    useSignificantDigits: true,
  })
}
export const getMinutesHoursDays = (diff: number) => {
  if (!diff) {
    console.warn('Parking time error')
    return { days: 0, hours: 0, minutes: 0 }
  }
  if (diff < 0) {
    return { days: 0, hours: 0, minutes: 0 }
  }
  const day = 1000 * 3600 * 24
  const hour = 1000 * 3600
  const minute = 1000 * 60
  const days = parseInt((diff / day).toString())
  const hours = parseInt(((diff - days * day) / hour).toString())
  const minutes = parseInt(
    ((diff - days * day - hours * hour) / minute).toString()
  )
  return { days, hours, minutes }
}

export const formatParkingtime = (diff: number) => {
  const { days, hours, minutes } = getMinutesHoursDays(diff)
  let timeString = ''
  if (days) {
    timeString = `${days}D`
  }
  if (hours) {
    timeString = `${timeString} ${hours}H`
  }
  if (minutes) {
    timeString = `${timeString} ${minutes}M`
  }
  return timeString
}

export const getDateWithCustomTime = (
  fullDate: moment.MomentInput,
  time: string /**01:25:20 */
) => {
  if (!fullDate || !time || time.length < 8) return null
  const hour = parseInt(time.slice(0, 2))
  const minute = parseInt(time.slice(3, 5))
  const second = parseInt(time.slice(6, 8))
  const fullMomentDate = moment(fullDate)
    .hour(hour)
    .minute(minute)
    .second(second)
  return { hour, minute, second, fullMomentDate }
}

type GetDurationString = (
  beginTime: string,
  expireTime: string,
  /** in minutes*/ paymentTime: number,
  /** in minutes*/ parkingExitTime: number,
  dateFormat?: string
) => string
export const getDurationString: GetDurationString = (
  beginTime,
  expireTime,
  paymentTime,
  parkingExitTime,
  dateFormat
) => {
  const defaultDateFormat = dateFormat ? dateFormat : 'd __ h __ m __'
  const duration = moment.duration(
    moment(expireTime)
      .startOf('minute')
      .diff(moment(beginTime).startOf('minute')) -
      (paymentTime + parkingExitTime) * 60 * 1000,
    'milliseconds'
  )
  return duration.format(defaultDateFormat, {
    minValue: 1,
    trim: 'all',
    useSignificantDigits: true,
  })
}

export const apiDateFormat = (date: string) =>
  moment(date).format('YYYY-MM-DDTHH:mm:SS')
