import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Translations } from 'utils'
import React, { useEffect, useState } from 'react'
import PhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { FormikHandlers, useFormikContext } from 'formik'
import { countryList } from 'utils/phoneNumberInput'
import i from './NumberError.png'
import { PersonalData } from 'pages/auth/signup/SignUp'

export const PhoneNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const StyledPhoneInput = styled(PhoneInput)`
  width: 100%;
  &.react-tel-input .form-control {
    border-radius: 32.5px;
    width: 100%;
  }
  &.react-tel-input .flag-dropdown {
    border-radius: 32.5px 0px 0px 32.5px;
  }

  &.react-tel-input .flag-dropdown .open {
    border-radius: 32.5px 0px 0px 32.5px;
  }
  &.react-tel-input .selected-flag {
    border-radius: 32.5px 0px 0px 32.5px;
  }
  &.react-tel-input :disabled {
    background-color: #dbdbdb !important;
    color: #646464;
  }
  &.react-tel-input input[disabled] + .flag-dropdown {
    cursor: not-allowed;
    background-color: #dbdbdb !important;
    opacity: 0.7;
  }
  &.react-tel-input .selected-flag {
    &:focus {
      background-color: #dbdbdb !important;
    }
  }
  &.react-tel-input .form-control.invalid-number {
    background-image: url(${i});
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
    background-position-x: 97%;
  }
`

export interface IPhoneInputProps extends PhoneInputProps {
  countryCodeFieldName: string
  phoneNumberFieldName: string
  disabled?: boolean
  errors?: string
  customHandleBlur?: (
    e: React.FocusEvent,
    handleBlur: FormikHandlers['handleBlur'],
    values: PersonalData
  ) => void
}

const NewPhoneNumberInput = (props: IPhoneInputProps) => {
  const { t } = useTranslation()
  const [number, setNumber] = useState('')
  const { setFieldValue, handleBlur, values } = useFormikContext()
  const language = window.navigator.language

  const findMatchingCountry = (lang: string, countriesArray: string[]) => {
    const matchingCountry = countriesArray.find(country =>
      lang.includes(country.toLowerCase())
    )
    return matchingCountry || 'us'
  }

  const selectedCountry = findMatchingCountry(
    language.toLowerCase(),
    countryList
  )

  useEffect(() => {
    if (props.value) {
      setNumber(props.value)
    }
  }, [props.value])

  return (
    <StyledPhoneInput
      inputProps={{
        id: 'phoneNumberInput',
        autocomplete: 'off',
      }}
      country={selectedCountry}
      data-testid="phoneNumberInput"
      disabled={props.disabled}
      onBlur={(e, country: CountryData) => {
        setFieldValue(props.countryCodeFieldName, country.dialCode)
        setFieldValue(
          props.phoneNumberFieldName,
          number.slice(country.dialCode.length)
        )
        if (props.customHandleBlur) {
          props.customHandleBlur(e, handleBlur, {
            ...(values as PersonalData),
            phoneNumber: number.slice(country.dialCode.length),
          })
        }
      }}
      isValid={!props.errors}
      placeholder={t(Translations.COUNTRY_CODE_AND_PHONE_NUMBER)}
      value={props.value}
      onChange={value => {
        setNumber(value)
      }}
    />
  )
}
export default NewPhoneNumberInput
