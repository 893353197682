import { Autocomplete } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AppTextField } from 'shared'
import { selectAllCars } from 'store/slices/carsSlice'
import Translations from 'utils/translations'

export interface LicenseFieldProps {
  disabled?: boolean
  onChange?: (arg: any) => void
  onBlur: (e: any) => void
  error?: boolean
  value?: string
  maxLength?: number
  onKeyDown?: (e: any) => void
}

export const LicenseField: React.FC<LicenseFieldProps> = props => {
  const { t } = useTranslation()
  const cars = useSelector(selectAllCars)
  const options = cars ? cars.map(option => option.plate) : []

  return (
    <Autocomplete
      freeSolo
      options={options}
      disabled={props.disabled}
      onSelect={props.onChange}
      clearIcon={false}
      value={props.value}
      onClick={props.onChange}
      renderInput={params => (
        <AppTextField
          {...params}
          value={props.value}
          onChange={props.onChange}
          name="licencePlate"
          error={props.error}
          inputProps={{
            ...params.inputProps,
            type: 'text',
            inputMode: 'text',
            maxLength: props.maxLength,
            onKeyDown: props.onKeyDown,
            ['data-testid']: 'licenceInput',
          }}
          autoFocus={!props.disabled}
          onBlur={props.onBlur}
          placeholder={t(Translations.LICENCE_PLATE)}
        />
      )}
    />
  )
}
LicenseField.defaultProps = {
  onBlur: () => undefined,
  onChange: () => undefined,
  onKeyDown: () => undefined,
  maxLength: 12,
}
export default LicenseField
