import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { needHelpApi, receiptsApi } from 'api'
import { getErrorCode, getErrorMessage } from 'api/utils'
import { RootState } from 'store/rootReducer'
import {
  AddContactInformationsPayload,
  AddContactInformationsData,
  GetContactInformationsData,
  ErrorData,
  SendVerificationSMSData,
  SendVerificationSMSPayload,
  ParkCarData,
} from 'typedef'
import { CONTACT_INFORMATIONS } from './constants'
import { ContactInformationState } from './contactInformationsSlice.types'
import { customerSlice } from '.'
import {
  GetCompanyContactInfoParams,
  GetCompanyContactInfoResult,
} from 'typedef/companyDetails'

export const initialState: ContactInformationState = {
  contactDataRequired: null,
  customerHasEmail: null,
  paymentRedirectionAvailable: null,
}

export const sendVerificationSMS = createAsyncThunk<
  SendVerificationSMSData,
  SendVerificationSMSPayload,
  { rejectValue: ErrorData }
>(`${CONTACT_INFORMATIONS}/sendVerificationSMS`, async (arg, thunkApi) => {
  try {
    const response = await receiptsApi.sendVerificationSMS(arg)
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(getErrorCode(error))
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const resendVerificationSMS = createAsyncThunk<
  SendVerificationSMSData,
  SendVerificationSMSData,
  { rejectValue: ErrorData }
>(`${CONTACT_INFORMATIONS}/resendVerificationSMS`, async (arg, thunkApi) => {
  try {
    const response = await receiptsApi.resendVerificationSMS(arg)
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(getErrorCode(error))
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const addContactInformations = createAsyncThunk<
  AddContactInformationsData,
  AddContactInformationsPayload,
  { rejectValue: ErrorData }
>(`${CONTACT_INFORMATIONS}/addContactInformations`, async (arg, thunkApi) => {
  try {
    const response = await receiptsApi.addContactInformations(arg)
    if (response.newTokens) {
      thunkApi.dispatch(customerSlice.actions.setLogin(response.newTokens))
    }
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(getErrorCode(error))
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const getContactInformations = createAsyncThunk<
  GetContactInformationsData,
  null,
  { rejectValue: ErrorData }
>(`${CONTACT_INFORMATIONS}/getContactInformations`, async (_, thunkApi) => {
  try {
    const response = await receiptsApi.getContactInformations()
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(getErrorCode(error))
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const getCompanyContactInfo = createAsyncThunk<
  GetCompanyContactInfoResult,
  GetCompanyContactInfoParams,
  { rejectValue: ErrorData }
>(`${CONTACT_INFORMATIONS}/getCompanyContactInfo`, async (arg, thunkApi) => {
  try {
    const response = await needHelpApi.getCompanyContactInfo(arg)
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(getErrorCode(error))
    return thunkApi.rejectWithValue(errorMessage)
  }
})

const contactInformationsSlice = createSlice({
  name: CONTACT_INFORMATIONS,
  initialState,
  reducers: {
    setRequirements(
      state,
      {
        payload,
      }: PayloadAction<
        Pick<
          ParkCarData,
          | 'contactDataRequired'
          | 'customerHasEmail'
          | 'paymentRedirectionAvailable'
        >
      >
    ) {
      state.contactDataRequired = payload.contactDataRequired
      state.customerHasEmail = payload.customerHasEmail
      state.paymentRedirectionAvailable = payload.paymentRedirectionAvailable
    },
    resetState(state) {
      state.contactDataRequired = null
      state.customerHasEmail = null
      state.paymentRedirectionAvailable = null
    },
  },
})

export default contactInformationsSlice.reducer
export const { actions } = contactInformationsSlice
export const stateSelectors = (state: RootState) => state.contactInformations
