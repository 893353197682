import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppTextField } from 'shared'
import Translations from 'utils/translations'

export interface SpotFieldProps {
  disabled?: boolean
  value?: string
  onChange?: (arg: any) => void
  onBlur?: (e: any) => void
  error?: boolean
  maxSpotNumber?: number
  onKeyDown?: (e: any) => void
}

export const SpotField: React.FC<SpotFieldProps> = props => {
  const { t } = useTranslation()

  return (
    <AppTextField
      placeholder={t(Translations.SPOT_NUMBER)}
      name="spotNumber"
      error={props.error}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      disabled={props.disabled}
      inputProps={{
        maxLength: props.maxSpotNumber
          ? props.maxSpotNumber.toString().length
          : 4,
        onKeyDown: props.onKeyDown,
        inputMode: 'numeric',
      }}
    />
  )
}
SpotField.defaultProps = {
  onChange: () => undefined,
  onBlur: () => undefined,
  onKeyDown: () => undefined,
}
export default SpotField
