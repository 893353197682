import { ExternalPricingOption } from 'typedef'
import moment from 'moment'
export const generateExternalPricings = (amount = 4) => {
  const arr: ExternalPricingOption[] = Array.from(Array(amount)).map(
    (el, i) => ({
      amount: 200 + i,
      externalPricingId: `id-${i}`,
      parkingExpireDateTime: moment()
        .add(i, 'hour')
        .add(20, 'minute')
        .toString(),
      fees: [
        { amount: 100 + i, label: 'first fee' },
        {
          amount: 200 + i,
          label: 'Second fee',
        },
      ],
    })
  )
  return arr
}
