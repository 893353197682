import { IconButton } from '@mui/material'
import React from 'react'
import { FlexBox, SharedIcon } from 'shared'
import styled from 'styled-components'

export interface NavButtonsProps {
  onClickGps: () => void
  onClickFindCar: () => void
  onClickPricing: () => void
  disabledFindCar: boolean
  findCarMode: boolean
  pricingMode: boolean
}
const Wrapper = styled.div`
  position: absolute;
  top: 80px;
  right: 0;
  height: auto;
  z-index: 1;
  padding-right: ${_ => _.theme.spacing(2)};
`
const StyledFlexBox = styled(FlexBox)`
  > div {
    background: white;
    border-radius: 50%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  }
`
export const NavButtons: React.FC<NavButtonsProps> = props => {
  return (
    <Wrapper>
      <StyledFlexBox>
        <IconButton
          disabled={props.disabledFindCar}
          onClick={props.onClickFindCar}
          style={props.disabledFindCar ? { filter: 'grayscale(1)' } : {}}
        >
          {props.findCarMode ? (
            <SharedIcon.Button.Close style={{ padding: '3px' }} />
          ) : (
            <SharedIcon.Button.PinDrop />
          )}
        </IconButton>
        <IconButton onClick={props.onClickGps}>
          <SharedIcon.Button.GpsFixed />
        </IconButton>
        <IconButton onClick={props.onClickPricing}>
          {props.pricingMode ? (
            <SharedIcon.Button.MoneyOff />
          ) : (
            <SharedIcon.Button.AttachMoney />
          )}
        </IconButton>
      </StyledFlexBox>
    </Wrapper>
  )
}

export default NavButtons
