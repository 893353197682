import { useChangeLayout } from 'hooks'
import CreditCardsPlaceholder from 'pages/my_credit_cards/CreditCardsComponents/CreditCardPlaceholder'
import CreditCardsList from 'pages/my_credit_cards/CreditCardsComponents/CreditCardsList'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { paths } from 'routing'
import {
  AppButton,
  AppSnackBarProvider,
  AppSpinner,
  ErrorPlaceholder,
  FlexBox,
  WhiteBoxContainer,
} from 'shared'
import { RootState } from 'store/rootReducer'
import { creditCardsSlice, customerSlice, ticketsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import Translations from 'utils/translations'
import { CancelParkCarDialog } from './CreditCardComponents'

export interface ManageCardsProps {
  addCardPath: string
}

export const ManageCards: React.FC<ManageCardsProps> = props => {
  const dispatch = useAppDispatch()
  const { ticketId } = useParams<{ ticketId: string }>()
  const ticket = useSelector((state: RootState) =>
    ticketsSlice.selectById(state, ticketId)
  )
  const { push } = useHistory()
  const { t } = useTranslation()
  const { customerSignedIn } = useSelector(customerSlice.selectCustomerStatus)

  const status = useSelector(
    creditCardsSlice.promiseStatusSelectors
  ).getCreditCardsStatus

  const cards = useSelector(creditCardsSlice.selectAll)

  const addCard = React.useCallback(
    () => push(props.addCardPath),
    [push, props.addCardPath]
  )
  const AddCardButton = () => (
    <FlexBox hasTopMargin>
      <AppButton color="primary" variant="contained" onClick={addCard}>
        {t(Translations.ADD_NEW_CARD)}
      </AppButton>
    </FlexBox>
  )
  const fetchCards = React.useCallback(
    () => {
      if (customerSignedIn) {
        dispatch(creditCardsSlice.getCreditCards({ lotId: ticket?.lot?.lotId }))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )
  const showElement = () => {
    const whiteBox = customerSignedIn && (status.idle || status.pendingIdle)
    const error =
      customerSignedIn && (status.pendingRejected || status.rejected)
    const spinner = customerSignedIn && status.pending
    const placeholder = !cards.length
    const card = customerSignedIn
      ? !!cards.length && (status.fulfilled || status.pendingFulfilled)
      : !!cards.length
    return { error, spinner, placeholder, card, whiteBox }
  }

  if (showElement().card || showElement().error || showElement().whiteBox)
    return (
      <WhiteBoxContainer minHeight={150}>
        <AppSpinner show={showElement().spinner} />
        {showElement().error && <ErrorPlaceholder onClick={fetchCards} />}
        {showElement().card && (
          <>
            <CreditCardsList creditCards={cards} />
            <AddCardButton />
          </>
        )}
      </WhiteBoxContainer>
    )
  if (showElement().placeholder)
    return (
      <>
        <CreditCardsPlaceholder>
          <AppSpinner show={showElement().spinner} transparent />
        </CreditCardsPlaceholder>
        <AddCardButton />
      </>
    )
  return null
}

export const ManageCardsPage: React.FC = props => {
  const { location, goBack } = useHistory()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [showDialog, setShowDialog] = React.useState(false)
  const title = t(Translations.MANAGE_CREDIT_CARDS)
  const { ticketId } = useParams<{ ticketId: string }>()
  const { customerSignedIn } = useSelector(customerSlice.selectCustomerStatus)
  const ticket = useSelector((state: RootState) =>
    ticketsSlice.selectById(state, ticketId)
  )
  const onClickRight = () => {
    setShowDialog(true)
  }

  const onClickBack = () => {
    // if (location.pathname === `${paths.parking.manageCards}/${ticketId}`) {
    //   return push(`${paths.parking.selectCard}/${ticketId}`)
    // }
    // if (
    //   location.pathname === `${paths.parking.addTimeManageCards}/${ticketId}`
    // ) {
    //   return push(`${paths.parking.addTimeSelectCard}/${ticketId}`)
    // }
    return goBack()
  }
  const addCardPath = React.useCallback(() => {
    if (location.pathname === `${paths.parking.manageCards}/${ticketId}`) {
      return `${paths.parking.addCard}/${ticketId}`
    }
    if (
      location.pathname === `${paths.parking.addTimeManageCards}/${ticketId}`
    ) {
      return `${paths.parking.addTimeAddCard}/${ticketId}`
    }
  }, [location.pathname, ticketId])

  useChangeLayout(
    {
      topbar: {
        title,
        onClickBack,
        rightIcon: 'close',
        onClickRight,
      },
      bottomMenu: false,
    },
    'app'
  )

  React.useEffect(() => {
    if (customerSignedIn) {
      dispatch(creditCardsSlice.getCreditCards({ lotId: ticket?.lot?.lotId }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!ticketId) {
    return <Redirect to={paths.home.map} />
  }

  return (
    <AppSnackBarProvider>
      <ManageCards addCardPath={addCardPath()} />
      <CancelParkCarDialog
        open={showDialog}
        closeDialog={() => setShowDialog(false)}
      />
    </AppSnackBarProvider>
  )
}

export default ManageCardsPage
