import { Grid } from '@mui/material'
import { t } from 'i18next'
import React from 'react'
import { AppSelectListField, AppTypography, FlexBox, SharedIcon } from 'shared'
import styled from 'styled-components'
import { IntervalOptions } from 'typedef/lot'
import { Translations } from 'utils'

export const InlineGrid = styled(Grid)`
  display: inline-flex;
  align-items: center;
`
export interface SelectTimeSectionProps {
  options?: IntervalOptions
  disabled?: boolean
  hours: number
  minutes: number
  days: number
  isAddTime: boolean
  onDaysChange: (arg: number) => void
  onHoursChange: (arg: number) => void
  onMinutesChange: (arg: number) => void
}

export const SelectTimeSection: React.FC<SelectTimeSectionProps> = ({
  options,
  disabled,
  days,
  hours,
  minutes,
  isAddTime,
  onDaysChange,
  onHoursChange,
  onMinutesChange,
}) => {
  if (!options) {
    return null
  }

  const areThreeSelectsVisible =
    options.showDaySelect && options.showHourSelect && options.showMinuteSelect

  const hoursAndMinuteSelectsVisible =
    options.showHourSelect && options.showMinuteSelect && !options.showDaySelect

  const dayAndHourSelectsVisible =
    options.showDaySelect && options.showHourSelect && !options.showMinuteSelect

  const onlyHourSelectVisible =
    !options.showDaySelect &&
    options.showHourSelect &&
    !options.showMinuteSelect

  const onlyDaySelectVisible =
    options.showDaySelect &&
    !options.showHourSelect &&
    !options.showMinuteSelect

  const onlyMinuteSelectVisible =
    !options.showDaySelect &&
    !options.showHourSelect &&
    options.showMinuteSelect

  const dayAndMinuteSelectVisible =
    options.showDaySelect && !options.showHourSelect && options.showMinuteSelect

  const maxValueLastLimitationD = Math.floor(
    options.maxTotalMinutes / (24 * 60)
  )
  const maxValueLastLimitationH =
    Math.floor((options.maxTotalMinutes % (24 * 60)) / 60) +
    (options.showDaySelect ? 0 : maxValueLastLimitationD * 24)

  const maxValueLastLimitationM = Math.floor(options.maxTotalMinutes % 60)

  const getTimeString = () => {
    if (
      hoursAndMinuteSelectsVisible ||
      onlyHourSelectVisible ||
      onlyMinuteSelectVisible
    ) {
      if (maxValueLastLimitationM === 0 && maxValueLastLimitationH !== 0) {
        return `${maxValueLastLimitationH} ${
          maxValueLastLimitationH === 1
            ? t(Translations.HOUR).toLocaleLowerCase()
            : t(Translations.HOURS).toLocaleLowerCase()
        }`
      } else if (
        maxValueLastLimitationH === 0 &&
        maxValueLastLimitationM !== 0
      ) {
        return `${maxValueLastLimitationM} ${t(Translations.MINUTES_SHORT)}`
      } else {
        return `${maxValueLastLimitationH}${t(
          Translations.HOURS_SHORT
        )} ${maxValueLastLimitationM}${t(Translations.MINUTES_SHORT)}`
      }
    } else if (dayAndHourSelectsVisible || onlyDaySelectVisible) {
      if (maxValueLastLimitationH === 0 && maxValueLastLimitationD !== 0) {
        return `${maxValueLastLimitationD} ${
          maxValueLastLimitationD === 1
            ? t(Translations.DAY).toLocaleLowerCase()
            : t(Translations.DAYS).toLocaleLowerCase()
        }`
      } else if (
        maxValueLastLimitationD === 0 &&
        maxValueLastLimitationH !== 0
      ) {
        return `${maxValueLastLimitationH} ${
          maxValueLastLimitationH === 1
            ? t(Translations.HOUR).toLocaleLowerCase()
            : t(Translations.HOURS).toLocaleLowerCase()
        }`
      } else {
        return `${maxValueLastLimitationD}${t(
          Translations.DAY_SHORT
        )} ${maxValueLastLimitationH}${t(Translations.HOURS_SHORT)}`
      }
    } else if (dayAndMinuteSelectVisible) {
      if (maxValueLastLimitationM === 0 && maxValueLastLimitationD !== 0) {
        return `${maxValueLastLimitationD} ${
          maxValueLastLimitationD === 1
            ? t(Translations.DAY).toLocaleLowerCase()
            : t(Translations.DAYS).toLocaleLowerCase()
        }`
      } else if (
        maxValueLastLimitationD === 0 &&
        maxValueLastLimitationM !== 0
      ) {
        return `${maxValueLastLimitationM} ${t(
          Translations.MINUTES_SHORT
        ).toLocaleLowerCase()}`
      } else {
        return `${maxValueLastLimitationD}${t(
          Translations.DAY_SHORT
        )} ${maxValueLastLimitationM}${t(Translations.MINUTES_SHORT)}`
      }
    }
  }

  return (
    <>
      <FlexBox
        horizontal
        horizontalGap={0.5}
        horizontalFullWith
        maxChildWidth={100}
      >
        {options.showDaySelect && (
          <AppSelectListField
            value={days}
            onChange={e => {
              const d = e.target.value as unknown as number
              onDaysChange(d)
            }}
            disabled={disabled}
            interval={options.dayInterval || 1}
            max={options.maxDays || 14}
            sufix="D"
            $innerSufix
          />
        )}
        {options.showHourSelect && (
          <AppSelectListField
            value={hours}
            onChange={e => {
              const h = e.target.value as unknown as number
              onHoursChange(h)
              if (
                Math.floor(options.maxTotalMinutes / 60) === h &&
                !areThreeSelectsVisible &&
                maxValueLastLimitationM < minutes
              ) {
                onHoursChange(h)
                const maxValueLastLimitationM = Math.floor(
                  options.maxTotalMinutes % 60
                )
                onMinutesChange(
                  Number(maxValueLastLimitationM) -
                    (Number(maxValueLastLimitationM) % options.minuteInterval)
                )
              } else if (
                maxValueLastLimitationD === days &&
                !areThreeSelectsVisible &&
                maxValueLastLimitationH < h
              ) {
                onDaysChange(days - options.dayInterval)
              }
            }}
            disabled={disabled}
            interval={options.hourInterval || 1}
            max={options.maxHours || 48}
            sufix="H"
            $innerSufix
          />
        )}
        {options.showMinuteSelect && (
          <AppSelectListField
            value={minutes}
            onChange={e => {
              const m = e.target.value as unknown as number
              onMinutesChange(m)
              if (Math.floor((options.maxTotalMinutes - m) / 60) < hours) {
                onHoursChange(hours - options.hourInterval)
              } else if (
                dayAndMinuteSelectVisible &&
                days === maxValueLastLimitationD
              ) {
                onDaysChange(days - options.dayInterval)
              }
            }}
            disabled={disabled}
            interval={options.minuteInterval || 1}
            max={options.maxMinutes || 48}
            sufix="M"
            $innerSufix
          />
        )}
      </FlexBox>
      {!areThreeSelectsVisible && (
        <Grid
          container
          item
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: '6px' }}
        >
          <Grid item>
            <SharedIcon.Information />
          </Grid>
          <Grid item>
            <AppTypography
              style={{
                color: '#9d9d9d',
                fontSize: '14px',
                marginBottom: '2px',
              }}
            >
              {isAddTime
                ? t(Translations.REMAINING_MAXIMUM_PARKING_TIME)
                : t(Translations.MAXIMUM_PARKING_TIME)}
            </AppTypography>
          </Grid>
          <Grid item>
            <AppTypography
              style={{
                fontWeight: 600,
                color: '#9d9d9d',
                fontSize: '14px',
                marginBottom: '2px',
              }}
            >
              &nbsp;
              {getTimeString()}
            </AppTypography>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default SelectTimeSection
