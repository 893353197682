import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={295} height={168} viewBox="0 0 295 168" fill="none" {...props}>
      <g filter="url(#prefix__filter0_d)">
        <rect x={4} width={287} height={160} rx={15} fill="#5E5E5E" />
      </g>
      <path
        d="M171.293 0C171.293 91 87 103 87 160h189c8.284 0 15-6.716 15-15V15c0-8.284-6.7-15-14.984-15H171.293z"
        fill="#555555"
      />
      <rect
        x={24.5}
        y={21.5}
        width={42.154}
        height={32}
        rx={3.5}
        fill="url(#prefix__paint1_linear)"
        stroke="#B1831F"
      />
      <path
        d="M38.695 41.307c-.202.801-.31 1.654-.31 2.538 0 4.673 3.03 8.462 6.769 8.462 3.738 0 6.77-3.789 6.77-8.462 0-.884-.11-1.737-.31-2.538-.18-.705-.537-2.454-.537-3.808 0-1.354 1.128-3.102 1.692-3.808h14.385M51.923 42.154h15.23M38.385 33.691H24M38.385 42.154H24M51.71 29.038c-.752-3.65-3.402-6.347-6.556-6.347-3.739 0-6.77 3.789-6.77 8.462 0 .884.11 1.737.31 2.538.18.706.537 2.454.537 3.808 0 1.354-.358 3.103-.536 3.808"
        stroke="#B1831F"
      />
      <rect x={217} y={19} width={54} height={36} rx={2} fill="#fff" />
      <path
        d="M255.361 35.82c0 4.182.275 7.92-2.749 10.386-2.347 1.916-6.822 2.71-10.1 2.71-7.503 0-12.116-5.667-12.116-13.096 1.836-6.88 5.773-10.09 13.275-10.09 3.279 0 5.063.225 7.41 2.14 3.024 2.467 4.28 3.767 4.28 7.95z"
        fill="#fff"
      />
      <path
        d="M255.361 35.82c0 4.182.275 7.92-2.749 10.386-2.347 1.916-6.822 2.71-10.1 2.71-7.503 0-12.116-5.667-12.116-13.096 1.836-6.88 5.773-10.09 13.275-10.09 3.279 0 5.063.225 7.41 2.14 3.024 2.467 4.28 3.767 4.28 7.95z"
        fill="#fff"
      />
      <path
        d="M246.195 25H242.5c-6.904 0-12.5 5.437-12.5 12.145 0 6.706 5.596 12.144 12.5 12.144h3.695c6.905 0 12.501-5.438 12.501-12.144 0-6.708-5.596-12.145-12.501-12.145zM242.5 48.128c-6.243 0-11.305-4.918-11.305-10.983 0-6.066 5.062-10.984 11.305-10.984 6.244 0 11.306 4.918 11.306 10.984 0 6.065-5.062 10.983-11.306 10.983z"
        fill="#0059C2"
      />
      <path
        d="M239.892 30.725c-2.633 1.01-4.497 3.502-4.497 6.42 0 2.917 1.864 5.41 4.497 6.42v-12.84zM245.107 30.725v12.84c2.633-1.01 4.497-3.503 4.497-6.42 0-2.918-1.864-5.41-4.497-6.42z"
        fill="#0059C2"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={87}
          y1={160}
          x2={291}
          y2={160}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#555" />
          <stop offset={1} stopColor="#555" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={24}
          y1={21}
          x2={67.154}
          y2={54}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C966" />
          <stop offset={0.516} stopColor="#FFE7BD" />
          <stop offset={1} stopColor="#E1A448" />
        </linearGradient>
        <filter
          id="prefix__filter0_d"
          x={0}
          y={0}
          width={295}
          height={168}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent
