import { useSelectedCardIndex } from 'hooks'
import {
  ChooseCard,
  ParkingDetails,
} from 'pages/park_car_credit_card/CreditCardComponents'
import React from 'react'
import { batch, useSelector } from 'react-redux'
import { AppSpinner, ErrorPlaceholder, WhiteBoxContainer } from 'shared'
import { RootState } from 'store/rootReducer'
import { creditCardsSlice, ticketsSlice } from 'store/slices'
import { AppState, useAppDispatch } from 'store/store'

export interface SelectCardWrapperProps {
  paymentPath: string
  manageCardPath: string
  addCardPath: string
  ticketId: string
}

export const SelectCardWrapper: React.FC<SelectCardWrapperProps> = props => {
  const initCardIndex = useSelectedCardIndex()
  const mounted = React.useRef(false)
  const dispatch = useAppDispatch()
  const ticket = useSelector((state: RootState) =>
    ticketsSlice.selectById(state, props.ticketId)
  )
  const { getTicketStatus } = useSelector((state: AppState) =>
    ticketsSlice.promiseStatusSelectors(state, props.ticketId)
  )
  const { getCreditCardsStatus } = useSelector(
    creditCardsSlice.promiseStatusSelectors
  )
  const cards = useSelector(creditCardsSlice.selectAll)

  const fetchCards = () =>
    dispatch(creditCardsSlice.getCreditCards({ lotId: ticket?.lot?.lotId }))
  const fetchTicket = () =>
    dispatch(ticketsSlice.getTicket({ carId: props.ticketId }))

  const fetchData = () =>
    batch(() => {
      if (!getTicketStatus.fulfilled) fetchTicket()
      fetchCards()
    })

  React.useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, ticket])

  const showElement = () => {
    const error =
      getTicketStatus.pendingRejected ||
      getTicketStatus.rejected ||
      getCreditCardsStatus.rejected ||
      getCreditCardsStatus.pendingRejected
    const spinner =
      getTicketStatus.pendingIdle ||
      getTicketStatus.pendingRejected ||
      getCreditCardsStatus.pending
    const chooseCard = getCreditCardsStatus.fulfilled && mounted.current
    return { error, spinner, chooseCard }
  }
  mounted.current = true

  return (
    <>
      <WhiteBoxContainer minHeight={150}>
        <AppSpinner show={showElement().spinner} />
        <ParkingDetails />
        {showElement().error && <ErrorPlaceholder onClick={fetchData} />}
      </WhiteBoxContainer>
      {showElement().chooseCard && (
        <ChooseCard
          paymentPath={props.paymentPath}
          manageCardPath={props.manageCardPath}
          addCardPath={props.addCardPath}
          ticket={ticket}
          cards={cards}
          initSlide={initCardIndex}
        />
      )}
    </>
  )
}

export default SelectCardWrapper
