import React from 'react'
import styled, { css } from 'styled-components'

interface FlexBoxProps {
  hasBottomMargin?: boolean
  hasTopMargin?: boolean
  horizontal?: boolean
  horizontalGap?: number
  horizontalFullWith?: boolean
  marginSpacing?: number
  style?: any
  maxWidth?: number
  maxChildWidth?: number
}
const StyledFlexBox = styled.div<FlexBoxProps>`
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${_ => _.theme.spacing(_.marginSpacing)} auto;
  > * {
    margin-bottom: ${_ => _.theme.spacing(2)};
  }
  ${_ => _.maxWidth && `max-width: ${_.maxWidth}px;`}
  ${_ => !_.hasBottomMargin && 'margin-bottom: 0;'}
  ${_ => !_.hasTopMargin && 'margin-top: 0;'}
  ${({ horizontalGap, ..._ }) =>
    _.horizontal &&
    css`
      flex-direction: row;
      justify-content: center;
      > * {
        margin-bottom: 0;
        width: ${_.horizontalFullWith ? '100%' : 'auto'};
        &:not(:first-child) {
          padding-left: ${_ => _.theme.spacing(horizontalGap)};
        }
        &:not(:last-child) {
          padding-right: ${_ => _.theme.spacing(horizontalGap)};
        }
        ${_ => _.maxChildWidth && `max-width: ${_.maxChildWidth}px;`}
      }
    `}
`
StyledFlexBox.defaultProps = {
  horizontalGap: 1,
}
const FlexBox: React.FC<FlexBoxProps> = props => {
  const renderChildren = () =>
    React.Children.map(props.children, child =>
      React.isValidElement(child) ? <div>{child}</div> : null
    )
  if (!renderChildren().length) return null
  return <StyledFlexBox {...props}>{renderChildren()}</StyledFlexBox>
}
FlexBox.defaultProps = {
  marginSpacing: 4,
}

export default FlexBox
