import React from 'react'
import { useSelector } from 'react-redux'
import * as layoutSlice from 'store/slices/layoutSlice'
import { useAppDispatch } from 'store/store'

export const useUpdateLayout = (
  props: layoutSlice.LayoutStateWithoutCustomStyles = {},
  deps: React.DependencyList = [],
  stopEffect?: boolean
) => {
  const layout = useSelector(layoutSlice.stateSelectors)
  const dispatch = useAppDispatch()

  const combineLayout = React.useCallback(
    (args: layoutSlice.LayoutStateWithoutCustomStyles) => ({
      ...layout,
      ...args,
      layout: {
        ...layout.layout,
        ...args?.layout,
      },
      topbar: {
        ...layout.topbar,
        ...args?.topbar,
      },
    }),
    [layout]
  )

  const updateLayout = React.useCallback(
    (args: layoutSlice.LayoutStateWithoutCustomStyles) => {
      const combinedLayout = combineLayout(args)
      dispatch(layoutSlice.actions.setFullLayout(combinedLayout))
    },
    [combineLayout, dispatch]
  )

  React.useLayoutEffect(() => {
    if (!stopEffect) {
      updateLayout(props)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return updateLayout
}

export default useUpdateLayout
