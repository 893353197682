import { useChangeLayout } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import Translations from 'utils/translations'
import { EditProfile } from './EditProfile'

export interface EditProfileViewProps {}

export const EditProfileView: React.FC<EditProfileViewProps> = props => {
  const history = useHistory()
  const { t } = useTranslation()
  const title = t(Translations.EDIT_MY_INFO)
  const onClickBack = () => history.push(paths.parker.myProfile)

  useChangeLayout(
    {
      topbar: {
        title,
        onClickBack,
      },
    },
    'app'
  )

  const onClickChangePass = () => {
    history.push(paths.parker.changePassword)
  }

  return <EditProfile onClickChangePass={onClickChangePass} />
}

export default EditProfileView
