const ROOT = '/'
const AUTH = '/auth'
const HOME = '/home'
const MAP = '/map'
const PARKER = '/parker'
const PARKING = '/parking'
const MY_PROFILE = '/my-profile'
const SIGN_UP = '/sign-up'
const SMS = '/sms'
const OLD_MOBI = '/old-mobi'
const REDIRECT = '/redirect'

export const paths = {
  root: {
    root: ROOT,
    oldMobi: OLD_MOBI,
  },
  home: {
    map: `${HOME}${MAP}`,
  },
  parker: {
    info: `${PARKER}/info`,
    edit: `${PARKER}${MY_PROFILE}/edit`,
    delete: `${PARKER}${MY_PROFILE}/delete`,
    changePassword: `${PARKER}${MY_PROFILE}/change-password`,
    myProfile: `${PARKER}${MY_PROFILE}`,
    myReceipts: `${PARKER}/my-receipts`,
    sendReceipt: `${PARKER}/send-receipt`,
    addCar: `${PARKER}/add-car`,
    addCard: `${PARKER}/add-card`,
    question: `${PARKER}/question`,
    receiptsAndNotifications: `${PARKER}/receipts-and-notifications`,
    paymentSuccessful: `${PARKER}/payment-successful`,
  },
  auth: {
    login: `${AUTH}/login`,
    forgotPassword: `${AUTH}/forgot-password`,
    resetPassword: `${AUTH}/reset-password`,
    signUp: `${AUTH}${SIGN_UP}`,
    setPassword: `${AUTH}${SIGN_UP}/set-password`,
    code: `${AUTH}${SIGN_UP}/code`,
    addCard: `${AUTH}${SIGN_UP}/add-card`,
  },
  parking: {
    tickets: `${PARKING}/tickets`,
    parkCar: `${PARKING}/park-car`,
    selectCard: `${PARKING}/select-card`,
    pay: `${PARKING}/pay`,
    paid: `${PARKING}/paid`,
    addTime: `${PARKING}/add-time`,
    addTimePay: `${PARKING}/add-time-pay`,
    addTimeManageCards: `${PARKING}/add-time-manage-cards`,
    addTimeSelectCard: `${PARKING}/add-time-select-card`,
    addTimeAddCard: `${PARKING}/add-time-add-card`,
    manageCards: `${PARKING}/manage-cards`,
    addCard: `${PARKING}/add-card`,
    validators: `${PARKING}/validators`,
    validate: `validate`,
    upfrontValidate: `${PARKING}/upfront-validation`,
    findCar: `${PARKING}/find-car`,
    transaction: `${PARKING}/transaction`,
    contactInformations: `${PARKING}/contact-informations`,
    login: `${PARKING}/login`,
    forgotPassword: `${PARKING}/forgot-password`,
    signUp: `${PARKING}${SIGN_UP}`,
  },
  sms: {
    park: `${SMS}/park`,
    map: `${SMS}/map`,
    corp: `${SMS}/corporation`,
    addTime: `${SMS}/add-time`,
  },
  redirect: {
    landingPage: `${REDIRECT}/landing-page`,
  },
}

export default paths
