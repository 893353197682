import { createTheme } from '@mui/material/styles'
import { Theme } from '@mui/material'
declare module '@mui/material/styles' {
  interface Palette {
    pin: Palette['primary']
  }
  interface PaletteOptions {
    pin: PaletteOptions['primary']
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      light: '#DCF3FF',
      main: '#32a3f5',
      dark: '#0485E3',
      contrastText: '#fff',
    },
    secondary: {
      light: '#FFEBEB',
      main: '#FF3333',
    },
    text: {
      primary: '#123123',
      secondary: '#646464',
    },
    divider: '#DBDBDB',
    pin: {
      main: '#3366EC',
      dark: '#1411A1',
      light: '#32A3F5',
    },
  },
  typography: {
    fontFamily: '"SF Pro Display", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '1.15rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.15rem',
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
      fontSize: '1.15rem',
    },
    subtitle1: {
      fontSize: '1.5rem',
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '2rem',
    },
    body2: {
      fontSize: '0.9rem',
    },
  },
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          '.MuiPickersYear-yearButton': {
            fontSize: '1.1rem',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '16px 8px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: '#FFFFFF',
          border: '1.25px #D2D2D2 solid',
          borderRadius: '17px',
          '&.Mui-error': {
            border: '1.25px #FF3333 solid',
          },
        },
        inputMultiline: {
          padding: '10px 20px',
          color: '#222',

          '&::placeholder': {
            color: '#DBDBDB',
            opacity: '1',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '17px',
          background: '#FFFFFF',
          '&.Mui-error': {
            border: '1.25px #FF3333 solid',
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: '1.25px #D2D2D2 solid!important',
            borderRadius: '17px',
          },
          '.Mui-focused': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#32a3f5!important',
            },
          },
        },
      },
    },
  },
})

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

export type CustomTheme = Theme
