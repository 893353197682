import { Fade } from '@mui/material'
import { CircularProgress, CircularProgressProps } from '@mui/material'
import React from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div<{
  relative?: boolean
  height?: number | string
  transparent?: boolean
  darkBackground?: boolean
  fixed?: boolean
}>`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
  background: ${_ =>
    _.transparent
      ? 'transparent'
      : _.darkBackground
      ? 'rgba(0, 0, 0, 0.5)'
      : 'rgba(255, 255, 255, 0.5)'};
  ${_ =>
    _.relative &&
    css`
      position: relative;
      margin: ${_.theme.spacing(1.5)} 0;
      height: ${_.height || '100%'};
      background: transparent;
    `}
  ${_ =>
    _.fixed &&
    css`
      height: 100%;
      position: fixed;
      touch-action: none;
    `}
`
const PositionContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-17px, -17px);
`

export interface AppSpinnerProps {
  show?: boolean
  relative?: boolean
  height?: string
  transparent?: boolean
  darkBackground?: boolean
  positionContainerStyles?: React.CSSProperties
  fixed?: boolean
}

const AppSpinner: React.FC<AppSpinnerProps & CircularProgressProps> = ({
  show,
  relative,
  fixed,
  height,
  positionContainerStyles,
  transparent,
  darkBackground,
  ...props
}) => {
  const [showSpinner, setShowSpinner] = React.useState(false)
  const mountedRef = React.useRef(null)
  React.useEffect(() => {
    if (show) {
      mountedRef.current = true
      setShowSpinner(true)
    }
    if (!show) {
      mountedRef.current = false
    }
    if (!show && !mountedRef.current) {
      setShowSpinner(false)
    }
  }, [show])

  return (
    <Fade unmountOnExit in={showSpinner}>
      <Container
        relative={relative}
        height={height}
        transparent={transparent}
        darkBackground={darkBackground}
        fixed={fixed}
      >
        <PositionContainer style={positionContainerStyles}>
          <CircularProgress {...props} />
        </PositionContainer>
      </Container>
    </Fade>
  )
}

export default AppSpinner
