import { ThemeProvider } from '@mui/material/styles'

import { useChangeLayout } from 'hooks'
import React from 'react'
import { whiteTheme } from 'themes/white-theme'
import DeleteAccount from './DeleteAccount'

export interface DeleteAccountViewProps {}

export const DeleteAccountView: React.FC<DeleteAccountViewProps> = props => {
  useChangeLayout()

  return (
    <ThemeProvider theme={whiteTheme}>
      <DeleteAccount />
    </ThemeProvider>
  )
}

export default DeleteAccountView
