import { unwrapResult } from '@reduxjs/toolkit'
import { useChangeLayout } from 'hooks'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { paths } from 'routing'
import { AppSpinner, WhiteBoxContainer } from 'shared'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'
import { parkCarSlice, ticketsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { CarStatus, TransactionStatus } from 'typedef/ticket'
import { Translations } from 'utils'

export interface AwaitTransactionPageProps {}

const AwaitTransactionPage: React.FC<AwaitTransactionPageProps> = () => {
  const dispatch = useAppDispatch()
  const { carId, transactionId } = useParams<{ carId: string, transactionId: string }>()
  const [loading, setLoading] = React.useState(false)
  const { push } = useHistory()
  const { t } = useTranslation()
  const { setPopup, showPopup } = usePopup()

  const unpaidRedirection = (carStatus: CarStatus, lotId: string) => {
    dispatch(parkCarSlice.actions.setTicket({ carId }))
    if (carStatus === CarStatus.Parked) {
      push(`${paths.parking.addTime}/${carId}`)
    } else if (carStatus === CarStatus.RequiresPayment) {
      push(`${paths.parking.parkCar}/${lotId}`)
    } else {
      push(paths.parking.tickets)
    }
  }

  const paidRedirection = () => {
    setPopup({
      title: t(Translations.PAYMENT_SUCCESSFUL),
      variant: 'success',
      description: ' ',
    })
    showPopup(true)
    dispatch(
      ticketsSlice.actions.setSelectedTicket({
        ticketId: carId,
      })
    )
    return push(paths.parking.tickets)
  }

  const fetchTicket = async (paid: boolean) => {
    setLoading(true)
    const promise = await dispatch(ticketsSlice.getTicket({ carId }))
    if (ticketsSlice.getTicket.fulfilled.match(promise)) {
      const { carStatus, lot } = unwrapResult(promise)
      if (paid) {
        paidRedirection()
      } else {
        unpaidRedirection(carStatus, lot.lotId)
      }
    } else {
      //  transaction status failed
      setPopup({
        ...promise.payload,
        disableBackdropClick: true,
        buttons: [
          { onClick: () => fetchTicket(paid) },
          {
            text: t(Translations.BACK_TO_TICKETS),
            onClick: () => push(paths.parking.tickets),
          },
        ],
      })
      setLoading(false)
      showPopup(true)
    }
  }

  const fetchTransactionStatus = async () => {
    setLoading(true)
    const promise = await dispatch(
      parkCarSlice.parkCarTransactionStatus({ carId, transactionId })
    )

    if (parkCarSlice.parkCarTransactionStatus.fulfilled.match(promise)) {
      const result = unwrapResult(promise)
      if (
        [
          TransactionStatus.SuccessfulCommit,
          TransactionStatus.SuccessfulPreAuth,
        ].includes(result.transactionStatus)
      ) {
        //  paid ticket
        fetchTicket(true)
      } else {
        //  unpaid ticket
        fetchTicket(false)
      }
    } else {
      //  get transaction status failed
      setPopup({
        ...promise.payload,
        disableBackdropClick: true,
        buttons: [
          { onClick: () => fetchTransactionStatus() },
          {
            text: t(Translations.BACK_TO_TICKETS),
            onClick: () => push(paths.parking.tickets),
          },
        ],
      })
      setLoading(false)
      showPopup(true)
    }
  }

  useEffect(() => {
    fetchTransactionStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useChangeLayout(
    {
      layout: {
        hasBottomBar: true,
      },
      topbar: {
        title: t(Translations.PARK_CAR),
        onClickBack: () => push(paths.home.map),
      },
    },
    'app'
  )

  return (
    <WhiteBoxContainer minHeight={150}>
      <AppSpinner show={loading} />
    </WhiteBoxContainer>
  )
}

export default AwaitTransactionPage
