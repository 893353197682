import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M1.002 1L17 17M16.999 1L1 17"
        stroke="#32A3F5"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default SvgComponent
