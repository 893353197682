import { useChangeLayout } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { paths } from 'routing'
import { RootState } from 'store/rootReducer'
import { ticketsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import Translations from 'utils/translations'
import { AddCreditCard } from './CreditCardComponents/AddCreditCard'
import { CancelParkCarDialog } from './CreditCardComponents'
import { useParkMode } from 'pages/park_car/ParkCarHooks'

export interface AddCardPageProps {}

export const AddCardPage: React.FC<AddCardPageProps> = props => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [showDialog, setShowDialog] = React.useState(false)
  const title = t(Translations.ADD_CREDIT_CARD)
  const { ticketId } = useParams<{ ticketId: string }>()
  const parkMode = useParkMode()
  const { getTicketStatus } = useSelector((state: RootState) =>
    ticketsSlice.promiseStatusSelectors(state, ticketId)
  )

  const onClickRight = () => {
    setShowDialog(true)
  }

  useChangeLayout(
    {
      topbar: {
        title,
        hasGoBack: false,
        rightIcon: 'close',
        onClickRight,
      },
      bottomMenu: false,
    },
    'app'
  )

  const fetchTicket = () =>
    dispatch(ticketsSlice.getTicket({ carId: ticketId }))

  const fetchData = () => {
    if (!getTicketStatus.fulfilled) fetchTicket()
  }

  React.useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <>
      <AddCreditCard
        carId={ticketId}
        payPath={
          parkMode === 'parkCar'
            ? `${paths.parking.pay}/${ticketId}`
            : `${paths.parking.addTimePay}/${ticketId}`
        }
      />
      <CancelParkCarDialog
        open={showDialog}
        closeDialog={() => setShowDialog(false)}
      />
    </>
  )
}

export default AddCardPage
