import { getErrorMessage } from 'api/utils'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { paths } from 'routing'
import { useCounterContext } from 'shared/CounterProvider/CounterProvider'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'
import { RootState } from 'store/rootReducer'
import { mapSlice, ticketsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { ErrorCode } from 'typedef'

export const useTimeoutCounter = () => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const { ticketId } = useParams<{ ticketId: string }>()
  const ticket = useSelector((state: RootState) =>
    ticketsSlice.selectById(state, ticketId)
  )
  const { showPopup, setPopup } = usePopup()
  const { clearCounter, setCounter } = useCounterContext()

  const runTimer = () => {
    setCounter({
      isRunning: true,
      endDate: moment().add(5, 'minutes').toISOString(),
      onEnd: () => {
        setPopup({
          ...getErrorMessage(ErrorCode.PaymentTimeExpired),
          buttons: [
            {
              onClick: () => {
                dispatch(
                  mapSlice.actions.selectLot({ lotId: ticket?.lot.lotId })
                )
                push(paths.root.root)
                clearCounter()
              },
            },
          ],
          disableBackdropClick: true,
        })
        showPopup(true)
      },
    })
  }

  return { runTimer, clearCounter }
}

export default useTimeoutCounter
