import React from 'react'
import { useTranslation } from 'react-i18next'
import { Placeholder, SharedIcon } from 'shared'
import Translations from 'utils/translations'

export interface CreditCardsPlaceholderProps {}

export const CreditCardsPlaceholder: React.FC<CreditCardsPlaceholderProps> = props => {
  const { t } = useTranslation()

  return (
    <Placeholder
      title={t(Translations.NO_CREDIT_CARDS_FOUND)}
      icon={<SharedIcon.NoCreditCard />}
    >
      {props.children}
    </Placeholder>
  )
}

export default CreditCardsPlaceholder
