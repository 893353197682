import { Form, Formik } from 'formik'
import { useChangeLayout } from 'hooks'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import {
  AppButton,
  AppSnackBarProvider,
  AppSpinner,
  ErrorPlaceholder,
  FlexBox,
  Placeholder,
  SharedIcon,
  WhiteBoxContainer,
} from 'shared'
import { Input } from 'shared/Input'
import PopupProvider from 'shared/popup_provider'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'
import { RootState } from 'store/rootReducer'
import { customerSlice, receiptsSlice } from 'store/slices'
import { AppState, useAppDispatch } from 'store/store'
import { Receipt } from 'typedef/receipt'
import { Translations, blurLastInput } from 'utils'
import * as Yup from 'yup'
import ReceiptInfo from './ReceiptInfo'
interface SendReceiptProps {
  receipt?: Receipt
  carId: string
}

const SendReceipt: React.FunctionComponent<SendReceiptProps> = ({
  receipt,
  carId,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { setPopup, showPopup } = usePopup()

  const handleSubmit = async (values: { email: string }) => {
    const promise = await dispatch(
      receiptsSlice.sendReceipt({
        email: values.email,
        carId,
      })
    )
    if (receiptsSlice.sendReceipt.rejected.match(promise)) {
      setPopup({
        ...promise.payload,
        buttons: [
          {
            onClick() {
              handleSubmit(values)
              showPopup(false)
            },
          },
          {},
        ],
      })
      showPopup(true)
    } else {
      setPopup({
        variant: 'success',
        title: t(Translations.SUCCESS),
        description: t(Translations.RECEIPT_WILL_BE_SENT_TO_PROVIDED_EMAIL),
        buttons: [
          {
            onClick() {
              history.push(paths.parker.myReceipts)
            },
          },
        ],
      })
      showPopup(true)
    }
  }
  const EmailInputSchema = Yup.object().shape({
    email: Yup.string()
      .email(t(Translations.INVALID_EMAIL))
      .required(t(Translations.FIELD_REQUIRED)),
  })

  return (
    <>
      <Placeholder
        title={t(Translations.SEND_RECEIPT)}
        icon={<SharedIcon.Receipt />}
      ></Placeholder>
      {receipt && (
        <ReceiptInfo
          amount={receipt.amount}
          dateInUTC={receipt.dateInUTC}
          locationName={receipt.locationName}
        />
      )}
      <Formik
        initialValues={{ email: receipt?.clientMail || '' }}
        onSubmit={handleSubmit}
        validationSchema={EmailInputSchema}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          validateForm,
          submitForm,
        }) => (
          <Form>
            <Input
              placeholder={t(Translations.EMAIL).toUpperCase()}
              name="email"
              type="email"
              inputMode="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.email}
              onKeyDown={e => {
                blurLastInput(e)
              }}
              autoFocus
            ></Input>
            <FlexBox hasTopMargin>
              <AppButton
                variant="contained"
                color="primary"
                type="button"
                onClick={() => {
                  validateForm().then(errors => {
                    const entries = Object.entries(errors)
                    if (entries.length === 0) {
                      submitForm()
                    } else {
                      enqueueSnackbar(entries[0][1])
                    }
                  })
                }}
              >
                {t(Translations.SEND)}
              </AppButton>
            </FlexBox>
          </Form>
        )}
      </Formik>
    </>
  )
}

export const SendReceiptPage: React.FC = props => {
  const { push } = useHistory()
  const { t } = useTranslation()
  const title = t(Translations.SEND_RECEIPT)
  const dispatch = useAppDispatch()
  const { customerSignedIn, customerSignedInAsGuest } = useSelector(
    customerSlice.selectCustomerStatus
  )
  const { carId } = useParams<{ carId: string }>()
  const { sendReceiptStatus, getReceiptsStatus } = useSelector(
    (state: AppState) => receiptsSlice.promiseStatusSelectors(state, carId)
  )
  const receipt = useSelector((state: RootState) =>
    receiptsSlice.selectReceiptById(state, carId)
  )

  useChangeLayout(
    {
      topbar: {
        title,
        onClickBack: () => push(paths.parker.myReceipts),
      },
    },
    'app'
  )

  const fetchData = () => dispatch(receiptsSlice.getReceipts())
  React.useEffect(() => {
    if (
      customerSignedIn &&
      !getReceiptsStatus.fulfilled &&
      !getReceiptsStatus.pending
    ) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!carId) {
    return <Redirect to={paths.parker.myReceipts} />
  }
  if (customerSignedIn && getReceiptsStatus.fulfilled && !receipt) {
    return <Redirect to={paths.parker.myReceipts} />
  }

  return (
    <AppSnackBarProvider hasBottomGutter>
      <PopupProvider>
        <WhiteBoxContainer>
          <AppSpinner
            show={sendReceiptStatus.pending || getReceiptsStatus.pending}
          />
          {customerSignedIn && (
            <>
              {getReceiptsStatus.rejected ||
              getReceiptsStatus.pendingRejected ? (
                <ErrorPlaceholder onClick={fetchData} />
              ) : (
                <SendReceipt receipt={receipt} carId={carId} />
              )}
            </>
          )}
          {customerSignedInAsGuest && <SendReceipt carId={carId} />}
        </WhiteBoxContainer>
      </PopupProvider>
    </AppSnackBarProvider>
  )
}

export default SendReceiptPage
