import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import {
  AppButton,
  FlexBox,
  Placeholder,
  SharedIcon,
  WhiteBoxContainer,
} from 'shared'
import { customerSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { Translations } from 'utils'

interface ParkerGuestProps {}
export const ParkerContainerGuest = (props: ParkerGuestProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useAppDispatch()

  return (
    <WhiteBoxContainer>
      <Placeholder
        title={t(
          Translations.PLEASE_LOGIN_TO_DISCOVER_WHOLE_APPP_FUNCTIONALITY
        )}
        icon={<SharedIcon.Unlock />}
      />
      <FlexBox hasTopMargin>
        <AppButton
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(customerSlice.actions.destroySession())
            history.push(paths.auth.login)
          }}
        >
          {t(Translations.LOG_IN)}
        </AppButton>
        <AppButton
          variant="outlined"
          color="primary"
          onClick={() => {
            dispatch(customerSlice.actions.destroySession())

            history.push(paths.auth.signUp)
          }}
        >
          {t(Translations.SIGN_UP)}
        </AppButton>
      </FlexBox>
    </WhiteBoxContainer>
  )
}

export default ParkerContainerGuest
