import { CreditCard } from './creditCards'
import { Lot, LotCustomStyles, OptionType } from './lot'

export enum CarStatus {
  Parked = 1,
  RequiresPayment = 2,
  ParkingHasExpired = 3,
  RequiresAddMoreTime = 4,
}

export enum PaymentOperationType {
  Payment = 1,
  Coupon = 2,
  BuyMoreTime = 3,
}

export enum TransactionStatus {
  NotStarted = 0,
  Started = 1,
  SuccessfulPreAuth = 2,
  SuccessfulCommit = 3,
  Rejected = 4,
  Failed = 5,
  Suspend = 6,
  Cancelled = 7,
}

export enum CurrencyFormat {
  AfterAmount = 1,
  BeforeAmount = 2,
}
export interface Currency {
  cultureName: string
  currencyFormat: CurrencyFormat
  currencySymbol?: string
}

export interface PaymentOperation {
  amount: number
  dateAtUTC: string
  dateAtLocal: string
  name?: string
  validatorName?: string
  type: PaymentOperationType
}

export interface Settlement {
  convenienceFee: number
  convenienceFeeName?: string
  currency: Currency
  finalAmount: number
  parkingAmount: number
  paymentOperationList: PaymentOperation[]
  creditCardInfo?: Pick<CreditCard, 'cardType' | 'last4Digits' | 'creditCardId'>
}

export enum ParkerType {
  Normal = 1,
  Monthly = 2,
}
export interface Ticket {
  addTimeEnable: boolean
  advertisementUrl?: string
  carId: string
  carStatus: CarStatus
  currentDateTime: string
  /** @deprecated */
  isMonthlyParker?: boolean
  lot: Lot & { logoLink?: string }
  parkerType: ParkerType
  parkingBeginDateTime?: string
  parkingExpireDateTime?: string
  plate: string
  settlement?: Settlement
  spot: string
  validateEnable: boolean
  customStyles: LotCustomStyles[]
  externalServiceDeliversReceipts: boolean
}

export interface ParkTime {
  parkingTimeMinutes?: number
  parkingTimeHours?: number
  parkingTimeDays?: number
  optionId?: string
  optionType?: OptionType
  parkingExpireDateTime?: string
}

/**
 * API CALLS
 */
export interface GetTicketPayload {
  carId: string
}

export type GetTicketData = Ticket
export interface GetTicketsData {
  tickets: Ticket[]
}

export type ParkCarPayload = {
  parkerId: string
  parkerMonthlyId?: string
  plate: string
  spot?: string
  parkTime?: ParkTime
  externalPricingId?: string
}
export interface ParkCarData {
  carId: string
  carStatus: CarStatus
  paymentRedirectionAvailable: boolean
  contactDataRequired: boolean
  customerHasEmail: boolean  
}
export interface BuyMoreTimePayload {
  carId: string
  parkTime?: ParkTime
}

export interface BuyMoreTimeExternalPayload
  extends Pick<BuyMoreTimePayload, 'carId'> {
  externalPricingId: string
}
export interface BuyMoreTimeData {
  requiresPayment: boolean
  paymentRedirectionAvailable: boolean
}

export interface PayForCarPayload {
  carId: string
  creditCardId?: string
  zipCode: string
  lastName?: string
  creditCardInfo?: {
    ccNumber: string
    expirationYear: number
    expirationMonth: number
  }, 
  recaptchaToken: string
}
export interface PayForCarData {
  success: boolean
}

export interface ParkCarRedirectPayload {
  carId: string
  recaptchaToken: string
}

export interface ParkCarRedirectData {
  token: string
}

export interface ParkCarTransactionStatusPayload {
  carId: string
  transactionId?: string
}

export interface ParkCarTransactionStatusData {
  transactionStatus: TransactionStatus
}
