export enum CardType {
  Visa = 1,
  MasterCard = 2,
  AmericanExpress = 3,
  DinersClub = 4,
  Discover = 5,
  JCB = 6,
  Invalid = 7,
}

export interface CreditCard {
  lotId?: string
  creditCardId?: string
  last4Digits: string
  cardType: CardType
  ccNumber?: string
  expirationYear?: number
  expirationMonth?: number
  zipCode?: string
}

export interface AddCreditCardPayload {
  lotId?: string
  ccNumber: string
  zipCode?: string
  expirationYear: number
  expirationMonth: number
}
export interface GetCreditCardsPayload {
  lotId?: string
}
export interface RemoveCreditCardPayload {
  lotId?: string
  creditCardId: string
}
export interface AddCreditCardData {
  creditCardId: string
}

export interface GetCreditCardsData {
  creditCards: CreditCard[]
}
export type RemoveCreditCardData = boolean
