import React from 'react'
import styled, { css } from 'styled-components'
import { CardType } from 'typedef/creditCards'
import * as C from './Cards'

export interface CardWrapperProps {
  last4Digits: string
  type: CardType
  selected: boolean
  touch: boolean
  onClick: () => void
}
const StyledCardWrapper = styled.div<{
  last4Digits: string
  unknown: boolean
  selected: boolean
  touch: boolean
}>`
  position: relative;
  transition: all 0.2s;
  padding: 20px 15px;
  overflow: hidden;
  box-sizing: border-box;
  > * {
    transition: all 0.2s;
  }
  ${_ =>
    !_.selected &&
    !_.touch &&
    css`
      svg {
        opacity: 0.7;
      }
    `}
  ${_ =>
    _.selected &&
    !_.touch &&
    css`
      > * {
        z-index: 2;
        transform: scale(1.15);
      }
    `}
  :hover {
    cursor: pointer;
    svg {
      opacity: 1;
    }
  }
  :after {
    content: '**** **** **** ${_ => _.last4Digits}';
    position: absolute;
    bottom: 30%;
    left: 5px;
    color: ${_ => (_.unknown ? '#646464' : 'white')};
    font-weight: bold;
    letter-spacing: 0.38em;
    width: 100%;
    font-family: 'SF Pro Display', 'Helvetica', 'Arial', sans-serif;
    transition: all 0.2s;
    text-align: center;
    ${_ =>
      _.selected &&
      !_.touch &&
      css`
        transform: scale(1.15);
      `}
  }
`

export const CardWrapper: React.FC<CardWrapperProps> = ({
  type,
  last4Digits,
  selected,
  onClick,
  touch,
}) => {
  const {
    AmericanExpress,
    DinersClub,
    Discover,
    Invalid,
    JCB,
    MasterCard,
    Visa,
  } = CardType
  return (
    <StyledCardWrapper
      selected={selected}
      last4Digits={last4Digits}
      unknown={type === CardType.Invalid}
      onClick={onClick}
      touch={touch}
    >
      {type === MasterCard && <C.MasterCard />}
      {type === DinersClub && <C.DinersClub />}
      {type === AmericanExpress && <C.AmericanExpress />}
      {type === Discover && <C.Discover />}
      {type === JCB && <C.Jbc />}
      {type === Visa && <C.Visa />}
      {(type === Invalid || !type) && <C.Unknown />}
    </StyledCardWrapper>
  )
}

export default CardWrapper
