import { AxiosRequestConfig } from 'axios'
import moment from 'moment'
import mock from '.'
import { ErrorCode, Response } from '../../typedef/api'
import { LoginMode, LoginResult, UserRole } from '../../typedef/customer'
import tokensStorageService from 'utils/tokenStorageService'

const loginResult: LoginResult = {
  role: UserRole.User,
  tokens: {
    auth: 'AUTH_TOKEN',
    expires: moment().add(1, 'minutes').unix(),
    refresh: 'REFRESH_TOKEN',
  },
}

const loginAsGuestResult: LoginResult = {
  role: UserRole.Guest,
  tokens: {
    auth: 'GUEST_AUTH_TOKEN',
    expires: moment().add(1, 'minutes').unix(),
    refresh: 'GUEST_REFRESH_TOKEN',
  },
}

const invalidLoginCredentials = {
  login: '22222222',
  password: '22222222',
}

// mock.onPost('/Customer/RefreshToken').networkError()
mock.onPost('/Customer/RefreshToken').reply(config => {
  const userRole = tokensStorageService.getUserRole()
  const tokens = tokensStorageService.get()

  return [
    200,
    {
      data: { role: userRole, tokens } as LoginResult,
    },
  ]
})
mock.onPost('/Customer/Login').reply((config: AxiosRequestConfig) => {
  const data = JSON.parse(config.data)
  if (
    data.login === invalidLoginCredentials.login &&
    data.password === invalidLoginCredentials.password
  ) {
    return [
      400,
      {
        errors: [
          {
            errorCode: ErrorCode.IncorrectUserOrPassword,
          },
        ],
      } as Response,
    ]
  }

  if (data.mode === LoginMode.Guest) {
    return [
      200,
      {
        data: loginAsGuestResult,
      },
    ]
  }

  if (data.mode === LoginMode.User) {
    return [
      200,
      {
        data: loginResult,
      } as Response<LoginResult>,
    ]
  }

  return [500, { error: { errorCode: ErrorCode.OtherError } }]
})

mock.onPut('/Customer/Edit').reply((config: AxiosRequestConfig) => {
  if (config.headers['Authorization']) {
    return [200, {} as Response]
  }

  return [401, {} as Response]
})

mock.onPut('/Customer/ChangePassword').reply((config: AxiosRequestConfig) => {
  if (config.headers['Authorization']) {
    const data = JSON.parse(config.data)
    if (data.password === '111111' && data.newPassword === '222222') {
      return [
        200,
        {
          errors: [
            {
              errorCode: ErrorCode.IncorrectUserOrPassword,
            },
          ],
        } as Response,
      ]
    }

    return [
      200,
      {
        data: {
          tokens: {
            auth: 'AUTH_TOKEN_CHANGED_PASSWORD',
            refresh: 'REFRESH_TOKEN_CHANGED_PASSWORD',
          },
          role: UserRole.User,
        },
      } as Response<LoginResult>,
    ]
  }

  return [401, {} as Response]
})

mock.onDelete('/Customer/Delete').reply((config: AxiosRequestConfig) => {
  if (config.headers['Authorization']) {
    if (config.params.password === 'invalid') {
      return [
        400,
        {
          errors: [
            {
              errorCode: ErrorCode.IncorrectUserOrPassword,
            },
          ],
        } as Response,
      ]
    }

    if (config.params.password === 'failed') {
      return [
        400,
        {
          errors: [
            {
              errorCode: ErrorCode.OtherError,
            },
          ],
        } as Response,
      ]
    }

    return [200, {} as Response]
  }

  return [401, {} as Response]
})

mock.onPost('/Customer/RemindPassword').reply(() => {
  return [200, {} as Response]
})

mock.onPost('/Customer/ResetPassword').reply((config: AxiosRequestConfig) => {
  return [
    200,
    {
      data: {
        tokens: {
          auth: 'AUTH_TOKEN_RESET_PASSWORD',
          refresh: 'REFRESH_TOKEN_RESET_PASSWORD',
          expires: Date.now() + 60000,
        },
        role: UserRole.Guest,
      },
      errors: [],
    } as Response<LoginResult>,
  ]
})
