import { unwrapResult } from '@reduxjs/toolkit'
import { useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import { useParkCarPath, useParkMode } from 'pages/park_car/ParkCarHooks'
import { T2Fields } from 'pages/park_car/ParkCarT2'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { parkCarSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { apiDateFormat, handleFormikErrors, Translations } from 'utils'
import moment from 'moment'

export const useExternalPricing = () => {
  const dispatch = useAppDispatch()
  const parkMode = useParkMode()
  const { carId } = useParams<{ carId?: string }>()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { lot } = useSelector(parkCarSlice.stateSelectors)
  const [_, goTo] = useParkCarPath()
  const { values, validateForm, setFieldValue } = useFormikContext<T2Fields>()
  const { licencePlate, spotNumber, endDate, currentTime } = values
  const { pending } = useSelector(
    parkCarSlice.promiseStatusSelectors
  ).getExternalPricingStatus

  const getPricing = () =>
    validateForm().then(err =>
      handleFormikErrors(
        err,
        async () => {
          const args = {
            plate: licencePlate,
            spot: spotNumber,
            startTime:
              parkMode === 'parkCar' ? apiDateFormat(currentTime) : null,
            endTime: apiDateFormat(endDate),
            valAreaId: lot?.lotId,
            externalPricingType: lot?.externalPricingType,
            carId,
          }
          if (moment(currentTime) >= moment(endDate).startOf('minute')) {
            return enqueueSnackbar(t(Translations.INVALID_END_TIME))
          }
          const promise = await dispatch(parkCarSlice.getExternalPricing(args))
          if (parkCarSlice.getExternalPricing.fulfilled.match(promise)) {
            const res = unwrapResult(promise)
            if (!res.pricings.length) {
              return enqueueSnackbar(
                t(Translations.NO_AVAILABLE_PARKING_AT_CURRENT_TIME)
              )
            }

            setFieldValue(
              'pricings',
              res.pricings.sort((el1, el2) =>
                el1.amount > el2.amount ? 1 : -1
              )
            )
            setFieldValue(
              'pricingExpirationDateUtc',
              res.pricingExpirationDateUtc
            )
            goTo('select')
          } else {
            enqueueSnackbar(
              t(Translations.SERVICE_TEMPORARILY_UNAVAILABLE_TRY_AGAIN_LATER)
            )
          }
        },
        enqueueSnackbar
      )
    )

  return [pending, getPricing] as const
}

export default useExternalPricing
