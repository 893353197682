import { Divider, List } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import ValidatorBar from './ValidatorBar'
import { ValidatorProps } from './types'
import { paths } from 'routing'

const Validators = (props: ValidatorProps) => {
  const history = useHistory()
  const handleRedirection = (couponId: number) => {
    if (props.carId) {
      history.push(
        `${paths.parking.validators}/${props.carId}/${paths.parking.validate}/${couponId}`
      )
    } else if (props.parkerId) {
      history.push(
        `${paths.parking.upfrontValidate}/${props.parkerId}/${paths.parking.validate}/${couponId}`
      )
    }
  }

  if (!props.partners || !props.partners.length) return null

  return (
    <List>
      {props.partners.map((p, i, arr) => (
        <React.Fragment key={p.couponId}>
          <ValidatorBar
            partner={p}
            handleClick={() => handleRedirection(p.couponId)}
          />
          {i < arr.length - 1 && <Divider component="li" />}
        </React.Fragment>
      ))}
    </List>
  )
}

export default Validators
