import { paths } from 'routing/paths'

export const SET = 'set'
export const SELECT = 'select'
export const SUMMARY = 'summary'

export type PathView = typeof SET | typeof SELECT | typeof SUMMARY
export type ParkMode = 'parkCar' | 'addTime'
export const externalParkCarPath = (
  parkMode: ParkMode,
  lotOrCarId?: string,
  view?: PathView
) =>
  `${
    parkMode === 'parkCar'
      ? `${paths.parking.parkCar}/${lotOrCarId || ':lotId'}`
      : `${paths.parking.addTime}/${lotOrCarId || ':carId'}`
  }${view ? `/${view}` : ''}`
