import {
  GetReceiptsData,
  SendQuestionData,
  SendReceiptsData,
} from 'typedef/receipt'
import { receipts } from '__fixtures__/myReceipts'
import mock from '.'
import { Response } from '../../typedef/api'
// Get Cars
// mock.onGet('/Receipt').networkError()

mock.onGet('/Receipt').reply(config => {
  return [
    400,
    {
      data: { receipts } as GetReceiptsData,
    },
  ]
})

mock.onPost('/Receipt').reply(config => {
  return [
    400,
    {
      data: true as SendReceiptsData,
    },
  ]
})

mock.onPost('/Question').reply(config => {
  return [
    400,
    {
      data: true,
    } as Response<SendQuestionData>,
  ]
})
