import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppTypography } from 'shared'
import { Currency } from 'typedef'
import { formatCurrency } from 'utils'
import Translations from 'utils/translations'

const defaultCurrency: Currency = {
  cultureName: 'en_US',
  currencyFormat: null,
  currencySymbol: null,
}
export interface TotalTextProps {
  currency?: Currency
  disabled?: boolean
  amount?: number
}

export const TotalText: React.FC<TotalTextProps> = props => {
  const { t } = useTranslation()
  const [currency, setCurrency] = React.useState(defaultCurrency)

  React.useEffect(() => {
    if (props.currency) setCurrency(props.currency)
  }, [props.currency])

  return (
    <AppTypography
      align="center"
      weight="bold"
      variant="subtitle2"
      disabled={props.disabled}
    >
      {currency && t(Translations.TOTAL)}:{' '}
      {formatCurrency(currency, props.amount)}
    </AppTypography>
  )
}
TotalText.defaultProps = {
  amount: 0,
  currency: defaultCurrency,
}
export default TotalText
