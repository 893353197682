enum Translations {
    PARKED_CARS = "PARKED_CARS",
    MY_RECEIPTS = "MY_RECEIPTS",
    CREDIT_CARDS = "CREDIT_CARDS",
    MY_CARS = "MY_CARS",
    FIND_CAR = "FIND_CAR",
    SIGN_OUT = "SIGN_OUT",
    PCODE_PARKING_NAME = "PCODE_PARKING_NAME",
    LOG_IN = "LOG_IN",
    SIGN_UP = "SIGN_UP",
    LOGIN = "LOGIN",
    PASSWORD = "PASSWORD",
    FORGOT_PASSWORD = "FORGOT_PASSWORD",
    TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS",
    PARKING_MADE_EASY = "PARKING_MADE_EASY",
    CREATING_ACCOUNT = "CREATING_ACCOUNT",
    NEXT = "NEXT",
    FIRST_NAME = "FIRST_NAME",
    LAST_NAME = "LAST_NAME",
    PHONE_NUMBER = "PHONE_NUMBER",
    E_MAIL = "E_MAIL",
    FIELD_REQUIRED = "FIELD_REQUIRED",
    PASSWORD_MIN_LENGTH = "PASSWORD_MIN_LENGTH",
    PASSWORD_MAX_LENGTH = "PASSWORD_MAX_LENGTH",
    PASSWORD_NOT_MATCH = "PASSWORD_NOT_MATCH",
    INVALID_EMAIL = "INVALID_EMAIL",
    INVALID_PHONE_NUMBER = "INVALID_PHONE_NUMBER",
    SET_PASSWORD = "SET_PASSWORD",
    BOTH_PASSWORD_NEED_TO_BE_THE_SAME = "BOTH_PASSWORD_NEED_TO_BE_THE_SAME",
    CONFIRM_PASSWORD = "CONFIRM_PASSWORD",
    CODE_MESSAGE = "CODE_MESSAGE",
    RESEND_CODE = "RESEND_CODE",
    CREATE = "CREATE",
    WELCOME = "WELCOME",
    YOUR_ACCOUNT_HAS_BEEN_SUCCESFULLY_CREATED = "YOUR_ACCOUNT_HAS_BEEN_SUCCESFULLY_CREATED",
    ADD_NEW_CAR = "ADD_NEW_CAR",
    LICENCE_PLATE = "LICENCE_PLATE",
    INVALID_PLATE_NUMBER = "INVALID_PLATE_NUMBER",
    ONLY_ALPHANUMERIC_CHARACTERS_ARE_ALLOWED = "ONLY_ALPHANUMERIC_CHARACTERS_ARE_ALLOWED",
    DELETE_CAR = "DELETE_CAR",
    ARE_YOU_SURE_YOU_WANT_TO_REMOVE_YOUR_CAR = "ARE_YOU_SURE_YOU_WANT_TO_REMOVE_YOUR_CAR",
    TRY_AGAIN = "TRY_AGAIN",
    MY_PROFILE = "MY_PROFILE",
    CHANGE_PASSWORD = "CHANGE_PASSWORD",
    SAVE = "SAVE",
    DELETE_MY_ACCOUNT = "DELETE_MY_ACCOUNT",
    PLEASE_PROVIDE_YOUR_CURRENT_PASSWORD_AND_SET_THE_NEW_ONE = "PLEASE_PROVIDE_YOUR_CURRENT_PASSWORD_AND_SET_THE_NEW_ONE",
    CURRENT_PASSWORD = "CURRENT_PASSWORD",
    NEW_PASSWORD = "NEW_PASSWORD",
    CONFIRM_NEW_PASSWORD = "CONFIRM_NEW_PASSWORD",
    CHANGE = "CHANGE",
    INCORRECT_PASSWORD = "INCORRECT_PASSWORD",
    SUCCESS = "SUCCESS",
    YOUR_PASSWORD_HAS_BEEN_CHANGED = "YOUR_PASSWORD_HAS_BEEN_CHANGED",
    PLEASE_PROVIDE_YOUR_PASSWORD_TO_REMOVE_YOUR_ACCOUNT = "PLEASE_PROVIDE_YOUR_PASSWORD_TO_REMOVE_YOUR_ACCOUNT",
    DELETE = "DELETE",
    OOPS = "OOPS",
    YOUR_ACCOUNT_CANNOT_BE_DELETED_AT_THE_MOMENT = "YOUR_ACCOUNT_CANNOT_BE_DELETED_AT_THE_MOMENT",
    YOUR_CAR_CANNOT_BE_DELETED_AT_THE_MOMENT = "YOUR_CAR_CANNOT_BE_DELETED_AT_THE_MOMENT",
    PLEASE_TRY_AGAIN_LATER = "PLEASE_TRY_AGAIN_LATER",
    CLOSE = "CLOSE",
    INVALID_PASSWORD = "INVALID_PASSWORD",
    CANCEL = "CANCEL",
    PLEASE_LOGIN_TO_DISCOVER_WHOLE_APPP_FUNCTIONALITY = "PLEASE_LOGIN_TO_DISCOVER_WHOLE_APPP_FUNCTIONALITY",
    SIGN_IN = "SIGN_IN",
    TERMS_AND_CONDITIONS_CONTENT = "TERMS_AND_CONDITIONS_CONTENT",
    DELETE_CREDIT_CARD = "DELETE_CREDIT_CARD",
    ARE_YOU_SURE_YOU_WANT_TO_REMOVE_YOUR_CREDIT_CARD = "ARE_YOU_SURE_YOU_WANT_TO_REMOVE_YOUR_CREDIT_CARD",
    CARD_ADD_INFO = "CARD_ADD_INFO",
    EMAIL_WAS_SENT = "EMAIL_WAS_SENT",
    PLEASE_CHECK_YOUR_INBOX = "PLEASE_CHECK_YOUR_INBOX",
    SUBMIT = "SUBMIT",
    FORGOT_PASSWORD_SEND_ME_MAIL_DESC = "FORGOT_PASSWORD_SEND_ME_MAIL_DESC",
    RESET_PASSWORD = "RESET_PASSWORD",
    THE_PASSWORD_IS_CHANGED = "THE_PASSWORD_IS_CHANGED",
    YOUR_PASSWORD_HAS_BEEN_RESET = "YOUR_PASSWORD_HAS_BEEN_RESET",
    ADD_CARD_INFO = "ADD_CARD_INFO",
    SEND_RECEIPT = "SEND_RECEIPT",
    RECEIPT_WILL_BE_SENT_TO_PROVIDED_EMAIL = "RECEIPT_WILL_BE_SENT_TO_PROVIDED_EMAIL",
    CREDIT_CARD_NUMBER = "CREDIT_CARD_NUMBER",
    MONTH = "MONTH",
    YEAR = "YEAR",
    BILLING_ZIP_CODE = "BILLING_ZIP_CODE",
    ADD_CARD = "ADD_CARD",
    INVALID_ZIP_CODE = "INVALID_ZIP_CODE",
    ZIP_CODE_SHOULD_NOT_BE_EMPTY = "ZIP_CODE_SHOULD_NOT_BE_EMPTY",
    INVALID_CREDIT_CARD_NUMBER = "INVALID_CREDIT_CARD_NUMBER",
    CREDIT_CARD_HAS_BEEN_ADDED = "CREDIT_CARD_HAS_BEEN_ADDED",
    ADD_CARD_ERROR = "ADD_CARD_ERROR",
    FIND_CARS_INFO = "FIND_CARS_INFO",
    FIND_CARS_RESULT_INFO = "FIND_CARS_RESULT_INFO",
    MY_CREDIT_CARDS = "MY_CREDIT_CARDS",
    PARKING_HISTORY = "PARKING_HISTORY",
    INVALID_LICENCE_PLATE = "INVALID_LICENCE_PLATE",
    WRONG_LOGIN_OR_PASSWORD = "WRONG_LOGIN_OR_PASSWORD",
    INVALID_COUNTRY_CODE = "INVALID_COUNTRY_CODE",
    ACTIVATION_CODE_HAS_BEEN_SENT = "ACTIVATION_CODE_HAS_BEEN_SENT",
    YOUR_ACCOUNT_HAS_BEEN_DELETED = "YOUR_ACCOUNT_HAS_BEEN_DELETED",
    CREATE_ACCOUNT_ERROR = "CREATE_ACCOUNT_ERROR",
    ACCOUNT_ALREADY_EXISTS = "ACCOUNT_ALREADY_EXISTS",
    PHONE_NUMBER_ALREADY_EXISTS = "PHONE_NUMBER_ALREADY_EXISTS",
    INVALID_ACTIVATION_CODE = "INVALID_ACTIVATION_CODE",
    ACTIVATION_CODE_SEND_FAIL = "ACTIVATION_CODE_SEND_FAIL",
    AMBIGUOUS_LOGIN = "AMBIGUOUS_LOGIN",
    UNEXPECTED_ERROR_PLEASE_TRY_AGAIN = "UNEXPECTED_ERROR_PLEASE_TRY_AGAIN",
    OK = "OK",
    NO_RECEIPTS_FOUND = "NO_RECEIPTS_FOUND",
    YOUR_RECEIPT_CANNOT_BE_SENT = "YOUR_RECEIPT_CANNOT_BE_SENT",
    YOUR_CAR_CANNOT_BE_ADDED = "YOUR_CAR_CANNOT_BE_ADDED",
    EMAIL = "EMAIL",
    SEND = "SEND",
    NO_CREDIT_CARDS_FOUND = "NO_CREDIT_CARDS_FOUND",
    AT_LEAST_ONE_LOWERCASE = "AT_LEAST_ONE_LOWERCASE",
    AT_LEAST_ONE_UPPERCASE = "AT_LEAST_ONE_UPPERCASE",
    AT_LEAST_ONE_NUMBER = "AT_LEAST_ONE_NUMBER",
    AT_LEAST_ONE_SPECIAL_CHARACTER = "AT_LEAST_ONE_SPECIAL_CHARACTER",
    NEW_PASSWORD_HAS_TO_BE_DIFFERENT = "NEW_PASSWORD_HAS_TO_BE_DIFFERENT",
    ARE_YOU_SURE_YOU_WANT_TO_REMOVE_YOUR_ACCOUNT = "ARE_YOU_SURE_YOU_WANT_TO_REMOVE_YOUR_ACCOUNT",
    PERSONAL_DATA = "PERSONAL_DATA",
    CREATE_PASSWORD = "CREATE_PASSWORD",
    TYPE_ACTIVATION_CODE = "TYPE_ACTIVATION_CODE",
    BACK_TO_LOGIN = "BACK_TO_LOGIN",
    BACK = "BACK",
    FIRSTNAME_CANNOT_BE_EMPTY = "FIRSTNAME_CANNOT_BE_EMPTY",
    LASTNAME_CANNOT_BE_EMPTY = "LASTNAME_CANNOT_BE_EMPTY",
    NAME_CANNOT_BE_EMPTY = "NAME_CANNOT_BE_EMPTY",
    EMAIL_CANNOT_BE_EMPTY = "EMAIL_CANNOT_BE_EMPTY",
    PHONE_CANNOT_BE_EMPTY = "PHONE_CANNOT_BE_EMPTY",
    PLATE_CANNOT_BE_EMPTY = "PLATE_CANNOT_BE_EMPTY",
    NO_CARS_FOUND = "NO_CARS_FOUND",
    CANNOT_FETCH_CAR_LIST = "CANNOT_FETCH_CAR_LIST",
    CANNOT_FETCH_CREDIT_CARDS_LIST = "CANNOT_FETCH_CREDIT_CARDS_LIST",
    CAR_HAS_BEEN_ADDED = "CAR_HAS_BEEN_ADDED",
    CAR_NAME = "CAR_NAME",
    TICKETS = "TICKETS",
    CAR_PARKED = "CAR_PARKED",
    CARS_PARKED = "CARS_PARKED",
    NO_PARKED_CARS = "NO_PARKED_CARS",
    PARK_CAR = "PARK_CAR",
    PARKING_EXPIRES_AT = "PARKING_EXPIRES_AT",
    PARKING_EXPIRED_AT = "PARKING_EXPIRED_AT",
    SEE_DETAILS = "SEE_DETAILS",
    TOTAL = "TOTAL",
    VALIDATE = "VALIDATE",
    ADD_TIME = "ADD_TIME",
    TICKET_DETAILS = "TICKET_DETAILS",
    RECEIPT = "RECEIPT",
    PARK_AGAIN = "PARK_AGAIN",
    EDIT_MY_INFO = "EDIT_MY_INFO",
    LAST_RECEIPT = "LAST_RECEIPT",
    SEE_MORE = "SEE_MORE",
    RETRY = "RETRY",
    FETCH_RECEIPTS_ERROR = "FETCH_RECEIPTS_ERROR",
    FETCH_CARS_ERROR = "FETCH_CARS_ERROR",
    FETCH_CREDIT_CARDS_ERROR = "FETCH_CREDIT_CARDS_ERROR",
    LOG_IN_AS_GUEST = "LOG_IN_AS_GUEST",
    EDIT = "EDIT",
    FETCH_CUSTOMER_DETAILS_ERROR = "FETCH_CUSTOMER_DETAILS_ERROR",
    PARK_ANOTHER_CAR = "PARK_ANOTHER_CAR",
    CONVENIENCE_FEE = "CONVENIENCE_FEE",
    PARKED = "PARKED",
    VALIDATED = "VALIDATED",
    BUY_MORE_TIME = "BUY_MORE_TIME",
    QUESTION = "QUESTION",
    YOUR_EMAIL_ADDRESS = "YOUR_EMAIL_ADDRESS",
    SEND_QUESTION = "SEND_QUESTION",
    YOUR_QUESTION_CANNOT_BE_SENT = "YOUR_QUESTION_CANNOT_BE_SENT",
    YOUR_QUESTION_HAS_BEEN_SENT = "YOUR_QUESTION_HAS_BEEN_SENT",
    QUESTION_CANNOT_BE_EMPTY = "QUESTION_CANNOT_BE_EMPTY",
    YOUR_ACCOUNT_HAS_BEEN_SUCCESSFULLY_SAVED = "YOUR_ACCOUNT_HAS_BEEN_SUCCESSFULLY_SAVED",
    YOUR_ACCOUNT_CANNOT_BE_SAVE = "YOUR_ACCOUNT_CANNOT_BE_SAVE",
    SPOT_NUMBER = "SPOT_NUMBER",
    PAY = "PAY",
    ALL_DAY_UNTIL = "ALL_DAY_UNTIL",
    PARKING_FEE = "PARKING_FEE",
    CHANGE_PRICING = "CHANGE_PRICING",
    SELECT_PRICING = "SELECT_PRICING",
    PRICING_LIST = "PRICING_LIST",
    PLEASE_CONFIRM_YOU_ARE_IN_SPOT = "PLEASE_CONFIRM_YOU_ARE_IN_SPOT",
    CONFIRM = "CONFIRM",
    FAILED_PARK_CAR = "FAILED_PARK_CAR",
    VALIDATOR_INFO = "VALIDATOR_INFO",
    FETCH_VALIDATORS_ERROR = "FETCH_VALIDATORS_ERROR",
    NO_VALIDATORS_FOUND = "NO_VALIDATORS_FOUND",
    ACCEPT = "ACCEPT",
    TICKET_CANNOT_BE_VALIDATED = "TICKET_CANNOT_BE_VALIDATED",
    TICKET_HAS_BEEN_VALIDATED = "TICKET_HAS_BEEN_VALIDATED",
    CODE_IS_INVALID = "CODE_IS_INVALID",
    INVALID_CODE = "INVALID_CODE",
    CURRENT_PASSWORD_CANNOT_BE_EMPTY = "CURRENT_PASSWORD_CANNOT_BE_EMPTY",
    NEW_PASSWORD_CANNOT_BE_EMPTY = "NEW_PASSWORD_CANNOT_BE_EMPTY",
    CONFIRM_NEW_PASSWORD_CANNOT_BE_EMPTY = "CONFIRM_NEW_PASSWORD_CANNOT_BE_EMPTY",
    NO_PARKING_FOUND = "NO_PARKING_FOUND",
    TODAY_OPEN = "TODAY_OPEN",
    LOCATION_IS_NOT_AVAILABLE = "LOCATION_IS_NOT_AVAILABLE",
    NAVIGATE = "NAVIGATE",
    NO_SEARCH_HISTORY = "NO_SEARCH_HISTORY",
    FAILED_PROPERLY_RETRIEVE = "FAILED_PROPERLY_RETRIEVE",
    REFRESH = "REFRESH",
    SOME_DATA_HAS_NOT_BEEN_UPDATED = "SOME_DATA_HAS_NOT_BEEN_UPDATED",
    TICKET = "TICKET",
    CHOOSE_CARD = "CHOOSE_CARD",
    ADD_NEW_CARD = "ADD_NEW_CARD",
    MANAGE_CARDS = "MANAGE_CARDS",
    SELECT_CARD = "SELECT_CARD",
    MANAGE_CREDIT_CARDS = "MANAGE_CREDIT_CARDS",
    ZIP_CODE = "ZIP_CODE",
    CREDIT_CARD_SHOULD_NOT_BE_EMPTY = "CREDIT_CARD_SHOULD_NOT_BE_EMPTY",
    INVALID_CARD_NUMBER = "INVALID_CARD_NUMBER",
    INVALID_EXPIRATION_DATE = "INVALID_EXPIRATION_DATE",
    ADD_CREDIT_CARD = "ADD_CREDIT_CARD",
    CANNOT_ADD_CREDIT_CARD = "CANNOT_ADD_CREDIT_CARD",
    YOUR_CARD_HAS_BEEN_REJECTED = "YOUR_CARD_HAS_BEEN_REJECTED",
    SUCCESSFUL = "SUCCESSFUL",
    REFRESHING_DATA = "REFRESHING_DATA",
    INCORRECT_CONFIRM_TOKEN = "INCORRECT_CONFIRM_TOKEN",
    INCORRECT_TOKEN = "INCORRECT_TOKEN",
    YOUR_PASSWORD_CANNOT_BE_CHANGED_AT_THE_MOMENT_PLEASE_TRY_AGAIN_LATER = "YOUR_PASSWORD_CANNOT_BE_CHANGED_AT_THE_MOMENT_PLEASE_TRY_AGAIN_LATER",
    LAST_4_CREDIT_CARD_DIGITS = "LAST_4_CREDIT_CARD_DIGITS",
    INCORRECT_SPOT_NUMBER = "INCORRECT_SPOT_NUMBER",
    CANCEL_PARKING_PROCESS = "CANCEL_PARKING_PROCESS",
    ARE_YOU_SURE_YOU_WANT_TO_CANCEL_PARKING_PROCESS = "ARE_YOU_SURE_YOU_WANT_TO_CANCEL_PARKING_PROCESS",
    YES = "YES",
    NO = "NO",
    PARK = "PARK",
    SKIP = "SKIP",
    PROVIDED_CODE_IS_INVALID = "PROVIDED_CODE_IS_INVALID",
    PLEASE_SCAN_THE_POSTED_QR_CODE = "PLEASE_SCAN_THE_POSTED_QR_CODE",
    THE_CAR_HAS_BEEN_ADDED = "THE_CAR_HAS_BEEN_ADDED",
    CONFIRM_PASSWORD_CANNOT_BE_EMPTY = "CONFIRM_PASSWORD_CANNOT_BE_EMPTY",
    PASSWORD_CANNOT_BE_EMPTY = "PASSWORD_CANNOT_BE_EMPTY",
    MINIMUM_8_CHARACTERS_AT_LEAST_ONE_CAPITAL = "MINIMUM_8_CHARACTERS_AT_LEAST_ONE_CAPITAL",
    THE_LICENCE_PLATE_IS_ALREADY_IN_USE = "THE_LICENCE_PLATE_IS_ALREADY_IN_USE",
    PARKING_UNAVAILABLE = "PARKING_UNAVAILABLE",
    WE_ARE_SORRY_BUT_THE_CURRENT_PARKING_IS_NOT_AVAILABLE = "WE_ARE_SORRY_BUT_THE_CURRENT_PARKING_IS_NOT_AVAILABLE",
    BUY_MORE_TIME_OPTION_IS_UNVAILABLE_BECAUSE_MAXIMUM_PARKING_TIME_HAS_BEEN_REACHED = "BUY_MORE_TIME_OPTION_IS_UNVAILABLE_BECAUSE_MAXIMUM_PARKING_TIME_HAS_BEEN_REACHED",
    DATE_IS_INVALID = "DATE_IS_INVALID",
    DATE_SHOULD_NOT_BE_EMPTY = "DATE_SHOULD_NOT_BE_EMPTY",
    PAYMENT_SUCCESSFUL = "PAYMENT_SUCCESSFUL",
    SPOT_NUMBER_MUST_BE_BETWEEN = "SPOT_NUMBER_MUST_BE_BETWEEN",
    CLOSED = "CLOSED",
    OLD_PASSWORD_MUST_BE_DIFFERENT_THEN_THE_NEW_PASSWORD = "OLD_PASSWORD_MUST_BE_DIFFERENT_THEN_THE_NEW_PASSWORD",
    NO_E_MAIL_ASSIGNED_TO_GIVEN_PHONE_NUMBER = "NO_E_MAIL_ASSIGNED_TO_GIVEN_PHONE_NUMBER",
    TRANSACTION_DECLINED = "TRANSACTION_DECLINED",
    YOUR_TRANSACTION_HAS_BEEN_REJECTED = "YOUR_TRANSACTION_HAS_BEEN_REJECTED",
    OPEN_GATE = "OPEN_GATE",
    GATE_IS_OPENING_PLEASE_WAIT = "GATE_IS_OPENING_PLEASE_WAIT",
    YOU_SHOULD_RECEIVE_A_TEXT_MESSAGE_WITH_YOUR_DIGITAL_TICKET_HERE_SHORTLY = "YOU_SHOULD_RECEIVE_A_TEXT_MESSAGE_WITH_YOUR_DIGITAL_TICKET_HERE_SHORTLY",
    SEND_DIGITAL_TICKET_AGAIN = "SEND_DIGITAL_TICKET_AGAIN",
    BACK_TO_TICKETS = "BACK_TO_TICKETS",
    APPLICATION_CRASHED = "APPLICATION_CRASHED",
    RESTART_APPLICATION = "RESTART_APPLICATION",
    ERROR = "ERROR",
    PARKING_TIMER = "PARKING_TIMER",
    PAY_AND_EXIT = "PAY_AND_EXIT",
    THANK_YOU_FOR_PARKING = "THANK_YOU_FOR_PARKING",
    TO_OPEN_GATE_AND_LEAVE_THE_PARKING = "TO_OPEN_GATE_AND_LEAVE_THE_PARKING",
    THANK_YOU_FOR_YOUR_PAYMENT = "THANK_YOU_FOR_YOUR_PAYMENT",
    PLEASE_BE_AWARE_THAT_IF_YOU_DO_NOT_EXIT_THE_PARKING_FACILITY = "PLEASE_BE_AWARE_THAT_IF_YOU_DO_NOT_EXIT_THE_PARKING_FACILITY",
    SCAN_QR_CODE_TO_EXIT_PARKING = "SCAN_QR_CODE_TO_EXIT_PARKING",
    EXIT_PARKING = "EXIT_PARKING",
    TOTAL_PARKING_TIME = "TOTAL_PARKING_TIME",
    PARKING_AS_MONTHLY_PARKER = "PARKING_AS_MONTHLY_PARKER",
    YOU_ARE_ON_PARKING = "YOU_ARE_ON_PARKING",
    IT_SEEMS_THAT_YOUR_VEHICLE_IS_CURRENTLY_ALREADY_PARKED = "IT_SEEMS_THAT_YOUR_VEHICLE_IS_CURRENTLY_ALREADY_PARKED",
    UNFORTUNATELY_WE_CANNOT_FIND_YOUR_TICKET = "UNFORTUNATELY_WE_CANNOT_FIND_YOUR_TICKET",
    IT_SEEMS_THAT_YOUR_TICKET_IS_UNPAID = "IT_SEEMS_THAT_YOUR_TICKET_IS_UNPAID",
    PARKING_RATES = "PARKING_RATES",
    CALCULATE = "CALCULATE",
    EXIT_TIME = "EXIT_TIME",
    ENTRY_TIME = "ENTRY_TIME",
    UNHANDLED_GATE = "UNHANDLED_GATE",
    PARKING_IS_CLOSED = "PARKING_IS_CLOSED",
    GATED_PARKING_FACILITY = "GATED_PARKING_FACILITY",
    ACCESS_MAY_BE_GAINED_BY_SCANNING_THE_POSTED_QR = "ACCESS_MAY_BE_GAINED_BY_SCANNING_THE_POSTED_QR",
    TICKET_NOT_FOUND = "TICKET_NOT_FOUND",
    HOUR = "HOUR",
    HOURS = "HOURS",
    SELECT_LOT = "SELECT_LOT",
    SEARCH_BEST_PARKING_PRICING = "SEARCH_BEST_PARKING_PRICING",
    LICENCE_PLATE_IS_REQUIRED = "LICENCE_PLATE_IS_REQUIRED",
    SPOT_NUMBER_IS_REQUIRED = "SPOT_NUMBER_IS_REQUIRED",
    PARK_FOR = "PARK_FOR",
    PARK_UNTIL = "PARK_UNTIL",
    ALL_DAY = "ALL_DAY",
    SERVICE_TEMPORARILY_UNAVAILABLE_TRY_AGAIN_LATER = "SERVICE_TEMPORARILY_UNAVAILABLE_TRY_AGAIN_LATER",
    RATE_HAS_EXPIRED = "RATE_HAS_EXPIRED",
    RATE_HAS_EXPIRED_PLEASE_SELECT_YOUR_TIME_AGAIN = "RATE_HAS_EXPIRED_PLEASE_SELECT_YOUR_TIME_AGAIN",
    OR = "OR",
    RATE_EXPIRES_IN = "RATE_EXPIRES_IN",
    NO_AVAILABLE_PARKING_AT_CURRENT_TIME = "NO_AVAILABLE_PARKING_AT_CURRENT_TIME",
    INVALID_END_TIME = "INVALID_END_TIME",
    TIMEOUT = "TIMEOUT",
    YOUR_SESSION_HAS_EXPIRED_PLEASE_START_OVER = "YOUR_SESSION_HAS_EXPIRED_PLEASE_START_OVER",
    I_WANT_MONTHLY_PARKING = "I_WANT_MONTHLY_PARKING",
    SELECT_TIME = "SELECT_TIME",
    HAS_PARKED_CARS = "HAS_PARKED_CARS",
    TODAY_CLOSED = "TODAY_CLOSED",
    YOUR_CARD_CANNOT_BE_ADDED_AT_THE_MOMENT_PLEASE_TRY_AGAIN_LATER = "YOUR_CARD_CANNOT_BE_ADDED_AT_THE_MOMENT_PLEASE_TRY_AGAIN_LATER",
    USE_SMS_LINK_TO_LEAVE_PARKING = "USE_SMS_LINK_TO_LEAVE_PARKING",
    PLEASE_USE_SMS_LINK_AND_SCAN_QR_CODE = "PLEASE_USE_SMS_LINK_AND_SCAN_QR_CODE",
    TEXT2PARK_NOT_AVAILABLE = "TEXT2PARK_NOT_AVAILABLE",
    TEXT2PARK_IS_CURRENTLY_NOT_AVAILABLE = "TEXT2PARK_IS_CURRENTLY_NOT_AVAILABLE",
    USER_DENIED_GEOLOCALIZATION = "USER_DENIED_GEOLOCALIZATION",
    POSITION_UNAVAILABLE = "POSITION_UNAVAILABLE",
    POSITION_GEOLOCALIZATION_TIMEOUT = "POSITION_GEOLOCALIZATION_TIMEOUT",
    THE_TICKET_CANNOT_BE_VALIDATED_AT_THE_MOMENT = "THE_TICKET_CANNOT_BE_VALIDATED_AT_THE_MOMENT",
    NO_SPACES_AVAILABLE = "NO_SPACES_AVAILABLE",
    PARKING_IS_UNAVAILABLE = "PARKING_IS_UNAVAILABLE",
    NEED_HELP = "NEED_HELP",
    CONTACT_INFORMATION = "CONTACT_INFORMATION",
    COMPANY_NAME = "COMPANY_NAME",
    PHONE = "PHONE",
    WEBSITE = "WEBSITE",
    RECEIPTS_AND_NOTIFICATIONS = "RECEIPTS_AND_NOTIFICATIONS",
    ALMOST_FINISHED = "ALMOST_FINISHED",
    TAP_ADD_NEW_CARD = "TAP_ADD_NEW_CARD",
    TAP_STORED_CARD = "TAP_STORED_CARD",
    TO_ADD_NEW_TAP_MANAGE_CARDS = "TO_ADD_NEW_TAP_MANAGE_CARDS",
    ENTER_ZIP_CODE = "ENTER_ZIP_CODE",
    TYPE_CONFIRMATION_CODE = "TYPE_CONFIRMATION_CODE",
    MESSAGE_WITH_CONFIRMATION_CODE_HAS_BEEN_SENT = "MESSAGE_WITH_CONFIRMATION_CODE_HAS_BEEN_SENT",
    YOU_DIDNT_RECEIVE_THE_CODE = "YOU_DIDNT_RECEIVE_THE_CODE",
    TO_RECEIVE_PARKING_EXPIRATION_TEXT_MESSAGES = "TO_RECEIVE_PARKING_EXPIRATION_TEXT_MESSAGES",
    TO_RECEIVE_YOUR_RECEIPT_VIA_EMAIL = "TO_RECEIVE_YOUR_RECEIPT_VIA_EMAIL",
    TOKEN_EXPIRED = "TOKEN_EXPIRED",
    MONTH_SHORT = "MONTH_SHORT",
    YEAR_SHORT = "YEAR_SHORT",
    ALREADY_HAVE_AN_ACCOUNT = "ALREADY_HAVE_AN_ACCOUNT",
    PLEASE_ENTER_YOUR_MOBILE_PHONE = "PLEASE_ENTER_YOUR_MOBILE_PHONE",
    PLEASE_ENTER_YOUR_EMAIL = "PLEASE_ENTER_YOUR_EMAIL",
    TERMS_OF_SERVICE = "TERMS_OF_SERVICE",
    READ_AND_AGREE = "READ_AND_AGREE",
    NOTIFICATIONS = "NOTIFICATIONS",
    NOTIFICATIONS_SUBTITLE = "NOTIFICATIONS_SUBTITLE",
    PARKING_NOTIFICATIONS = "PARKING_NOTIFICATIONS",
    MAXIMUM_PARKING_TIME = "MAXIMUM_PARKING_TIME",
    REMAINING_MAXIMUM_PARKING_TIME = "REMAINING_MAXIMUM_PARKING_TIME",
    HOURS_SHORT = "HOURS_SHORT",
    MINUTES_SHORT = "MINUTES_SHORT",
    DAYS = "DAYS",
    DAY = "DAY",
    DAY_SHORT = "DAY_SHORT",
    SELECT_LANGUAGE = "SELECT_LANGUAGE",
    AMERICAN_ENGLISH = "AMERICAN_ENGLISH",
    BRITISH_ENGLISH = "BRITISH_ENGLISH",
    ITALIAN = "ITALIAN",
    GERMAN = "GERMAN",
    COUNTRY_CODE_AND_PHONE_NUMBER = "COUNTRY_CODE_AND_PHONE_NUMBER",
    PLEASE_COMPLETE_THE_CAPTCHA = "PLEASE_COMPLETE_THE_CAPTCHA",
    USER_IS_NOT_HUMAN = "USER_IS_NOT_HUMAN",
    RECAPTCHA_VERIFICATION_FAILED = "RECAPTCHA_VERIFICATION_FAILED",
    PLEASE_COMPLETE_RECAPTCHA_VERIFICATION = "PLEASE_COMPLETE_RECAPTCHA_VERIFICATION",
    
}

export default Translations;