export enum LogSource {
  WebApp = 1,
  iOS = 2,
  Android = 3,
}
export enum LogType {
  Information = 1,
  Warning = 2,
  Error = 3,
}

export interface LogActionRequest {
  logMessage: string
  logSource: LogSource
  deviceInfo: string
  logType?: LogType /**default Information */
}
