import { Tokens, UserRole } from 'typedef/customer'

export interface ITokensStorageService {
  set: (tokens: Tokens) => void
  get: () => Tokens | null
  clear: () => void
  setUserRole: (tokens: UserRole) => void
  getUserRole: () => UserRole | null
  clearUserRole: () => void
}

const set = (tokens: Tokens) => {
  localStorage.setItem('tokens', JSON.stringify(tokens))
}
const setUserRole = (role: UserRole) => {
  localStorage.setItem('role', JSON.stringify(role))
}

const get = () => {
  const tokensStorage = localStorage.getItem('tokens')
  if (!tokensStorage) return null

  const tokens: Tokens = JSON.parse(tokensStorage)
  return tokens
}
const getUserRole = () => {
  const tokensStorage = localStorage.getItem('role')
  if (!tokensStorage) return null

  const role: UserRole = JSON.parse(tokensStorage)
  return role
}

const clear = () => {
  localStorage.removeItem('tokens')
}

const clearUserRole = () => {
  localStorage.removeItem('role')
}

const tokensStorageService: ITokensStorageService = {
  set,
  get,
  clear,
  // Temporary
  setUserRole,
  getUserRole,
  clearUserRole,
}

export default tokensStorageService
