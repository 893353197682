import T from 'utils/translations'
import { ErrorCode, ErrorResponse } from 'typedef'

export const getTicket: ErrorResponse = {
  [ErrorCode.IncorrectParameterException]: {
    title: T.OOPS,
    description: T.TICKET_NOT_FOUND,
    variant: 'error',
  },
}

export const getTickets: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
}

export const parkCar: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
  [ErrorCode.IncorrectParameterException]: {
    title: T.OOPS,
    description: T.INCORRECT_SPOT_NUMBER,
  },
  [ErrorCode.AutoClearTimeExeeded]: {
    title: T.OOPS,
    description: T.RATE_HAS_EXPIRED_PLEASE_SELECT_YOUR_TIME_AGAIN,
    variant: 'error',
  },
}

export const buyMoreTime: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
}

export const payForCar: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
}

export const parkCarRedirect: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
  },
}

export const parkCarTransactionStatus: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
}
export default {
  getTickets,
  parkCar,
  payForCar,
  getTicket,
  buyMoreTime,

  parkCarRedirect,
  parkCarTransactionStatus,
}
