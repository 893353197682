import { useFormikContext } from 'formik'
import moment from 'moment'
import React from 'react'
import { T2Fields } from '../fields'

export const useCurrentTime = () => {
  const { values, setFieldValue } = useFormikContext<T2Fields>()
  const { startDate, currentTime } = values
  const currentDateRef = React.useRef(null)
  const timer = React.useRef(null)

  React.useEffect(() => {
    if (currentTime && !timer.current) {
      currentDateRef.current = currentTime
      timer.current = setInterval(() => {
        currentDateRef.current = moment(currentDateRef.current)
          .add(5, 'seconds')
          .toString()
        setFieldValue('currentTime', currentDateRef.current)
      }, 5000)
    }
  }, [currentTime, setFieldValue, startDate])

  React.useEffect(() => {
    return () => clearInterval(timer.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return currentTime
}

export default useCurrentTime
