import { MobileDatePicker, MobileTimePicker } from '@mui/lab'
import { useFormikContext } from 'formik'
import moment, { Moment } from 'moment'
import { T2Fields } from 'pages/park_car/ParkCarT2'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppTextField, FlexBox } from 'shared'
import { Translations } from 'utils'
import { useMinDate } from '../T2Hooks'

export interface ParkUntilSelectProps {}

export const SelectParkUntil: React.FC<ParkUntilSelectProps> = props => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<T2Fields>()
  const { parkUntilDate, currentTime } = values
  const minDate = useMinDate()
  const onChange = (date: Moment | null | string) => {
    let convertedDate = date
    if (date instanceof moment) {
      convertedDate = moment(date).startOf('minute').toString()
    }
    setFieldValue('parkUntilDate', convertedDate)
  }

  React.useEffect(() => {
    if (!parkUntilDate && minDate) {
      setFieldValue('parkUntilDate', minDate)
    }
  }, [setFieldValue, currentTime, parkUntilDate, minDate])

  React.useEffect(() => {
    return () => setFieldValue('parkUntilDate', null) as any
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <FlexBox horizontal horizontalFullWith>
      <MobileDatePicker
        allowSameDateSelection
        onChange={() => undefined}
        onAccept={onChange}
        value={parkUntilDate}
        minDate={moment(minDate)}
        disablePast
        renderInput={props => <AppTextField {...props} />}
        okText={t(Translations.OK)}
        cancelText={t(Translations.CANCEL)}
      />
      <MobileTimePicker
        minutesStep={1}
        minTime={
          moment(minDate).isSame(parkUntilDate, 'day')
            ? moment(minDate)
            : undefined
        }
        onChange={() => undefined}
        onAccept={onChange}
        value={parkUntilDate}
        renderInput={props => <AppTextField {...props} />}
        okText={t(Translations.OK)}
        cancelText={t(Translations.CANCEL)}
        toolbarTitle={t(Translations.SELECT_TIME)}
      />
    </FlexBox>
  )
}

export default SelectParkUntil
