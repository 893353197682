import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import * as validatorsApi from 'api/ValidatorsApi'
import { getErrorCode, getErrorMessage } from 'api/utils'
import { RootState } from 'store/rootReducer'
import {
  Partner,
  FetchStatus,
  GetValidatorsParams,
  ValidateCodeParams,
  GetValidatorsData,
  ErrorData,
  ValidateCodeUpfrontParams,
} from 'typedef'
import { PromiseStatus } from 'store/slices/slices.types'
import { validatorsApiError } from 'api/apiErrors'
import { FETCH, initialPromiseStatus, COUPON } from 'store/slices/constants'
import { sliceUtil } from 'store/slices'

const validateAdapter = createEntityAdapter<Partner>({
  selectId: partner => partner.couponId,
})

interface ValidateState {
  error: number | null
  carId?: string
  lotId?: string
  promisesStatus: {
    getValidators: PromiseStatus
  }
  sendValidateCodeStatus: FetchStatus
  sendValidateCodeUpfrontStatus: FetchStatus
}

const initialState = validateAdapter.getInitialState<ValidateState>({
  sendValidateCodeStatus: FetchStatus.Idle,
  sendValidateCodeUpfrontStatus: FetchStatus.Idle,
  carId: null,
  lotId: null,
  promisesStatus: {
    getValidators: initialPromiseStatus,
  },
  error: null,
})

export const getValidators = createAsyncThunk<
  GetValidatorsData,
  GetValidatorsParams,
  { rejectValue: ErrorData }
>(
  `${COUPON}${FETCH}/getValidators`,
  async (params, thunkApi) => {
    try {
      const response = await validatorsApi.getValidators(params)
      return response
    } catch (error) {
      const errorMessage = getErrorMessage(
        getErrorCode(error),
        validatorsApiError.getValidators
      )
      return thunkApi.rejectWithValue(errorMessage)
    }
  },
  {
    condition: (arg, api) => {
      const { validators } = api.getState() as RootState
      if (arg.carId === validators.carId && arg.lotId === validators.lotId) {
        if (
          validators.promisesStatus.getValidators.status.includes(
            FetchStatus.Pending
          )
        )
          return false
      }
    },
  }
)

export const validateCode = createAsyncThunk<
  boolean,
  ValidateCodeParams,
  { rejectValue: ErrorData }
>(`${COUPON}${FETCH}/validateCode`, async (payload, thunkApi) => {
  try {
    const response = await validatorsApi.validateCode(payload)
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      validatorsApiError.validateCode
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const validateCodeUpfront = createAsyncThunk<
  boolean,
  ValidateCodeUpfrontParams,
  { rejectValue: ErrorData }
>(`${COUPON}${FETCH}/validateCodeUpfront`, async (payload, thunkApi) => {
  try {
    const response = await validatorsApi.validateCodeUpfront(payload)
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      validatorsApiError.validateCode
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

const validatorsSlice = createSlice({
  name: COUPON,
  initialState,
  reducers: {},
  extraReducers: builder => {
    /**
     * getValidators
     */
    builder.addCase(getValidators.pending, ({ promisesStatus }, action) => {
      const init =
        promisesStatus.getValidators.requestId !== action.meta.requestId
          ? initialPromiseStatus
          : promisesStatus.getValidators
      promisesStatus.getValidators = sliceUtil.pendingPromise(init, action)
    })
    builder.addCase(getValidators.fulfilled, (state, action) => {
      if (
        state.promisesStatus.getValidators.requestId !== action.meta.requestId
      )
        return
      state.promisesStatus.getValidators = sliceUtil.fulfilledPromise()
      state.carId = action.meta.arg.carId
      state.lotId = action.meta.arg.lotId
      validateAdapter.setAll(state, action.payload.partners)
    })
    builder
      .addCase(getValidators.rejected, (state, action) => {
        if (
          state.promisesStatus.getValidators.requestId !== action.meta.requestId
        )
          return
        state.promisesStatus.getValidators = sliceUtil.rejectedPromise(
          action.payload
        )
      })

      //Validate code
      .addCase(validateCode.fulfilled, (state, action) => {
        state.sendValidateCodeStatus = FetchStatus.Fulfilled
      })
      .addCase(validateCode.pending, (state, action) => {
        state.sendValidateCodeStatus = FetchStatus.Pending
      })
      .addCase(validateCode.rejected, (state, action) => {
        state.sendValidateCodeStatus = FetchStatus.Rejected
      })

      //Validate code upfront
      .addCase(validateCodeUpfront.fulfilled, (state, action) => {
        state.sendValidateCodeUpfrontStatus = FetchStatus.Fulfilled
      })
      .addCase(validateCodeUpfront.pending, (state, action) => {
        state.sendValidateCodeUpfrontStatus = FetchStatus.Pending
      })
      .addCase(validateCodeUpfront.rejected, (state, action) => {
        state.sendValidateCodeUpfrontStatus = FetchStatus.Rejected
      })
  },
})

export default validatorsSlice.reducer

export const stateSelectors = (state: RootState) => state.validators
export const {
  selectAll: selectAllValidators,
  selectById: selectValidatorById,
  selectIds: selectValidatorIds,
} = validateAdapter.getSelectors<RootState>(state => state.validators)

export const selectSendValidateCodeStatus = (state: RootState) =>
  state.validators.sendValidateCodeStatus
export const selectSendValidateCodeUpfrontStatus = (state: RootState) =>
  state.validators.sendValidateCodeUpfrontStatus
export const promiseStatusSelectors = (state: RootState) => {
  const promises = stateSelectors(state).promisesStatus
  return {
    getValidatorsStatus: sliceUtil.fetchStatus(promises.getValidators),
  }
}
