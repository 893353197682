import {
  Avatar,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import InformationText from 'shared/styled/InformationText'
import { Partner } from 'typedef/validator'
import { Translations } from 'utils'
import { FlexBox, AppButton } from 'shared'
import CodeInput from 'shared/CodeInput'
import styled from 'styled-components'

const StyledCodeInput = styled(CodeInput)`
  input {
    border: 1px solid ${_ => _.theme.palette.divider};
  }
`
export interface ValidateTicketProps {
  partner: Partner
  handleSubmit: (validationCode: string) => void
}

export const ValidateTicket: React.FC<ValidateTicketProps> = props => {
  const { t } = useTranslation()
  const { name, logoDir } = props.partner
  const [pin, setPin] = React.useState('')
  const [codeValid, setCodeValid] = React.useState(false)

  const renderInfoText = () => {
    if (props.partner.qrCodeEnable) {
      if (props.partner.qrValidationMessage) {
        return props.partner.qrValidationMessage
      }
      return t(Translations.PLEASE_SCAN_THE_POSTED_QR_CODE)
    }
    return t(Translations.VALIDATOR_INFO)
  }

  return (
    <>
      <ListItem data-testid="validatorBar">
        <ListItemAvatar>
          <Avatar
            src={logoDir}
            alt="Partner logo"
            variant="square"
            imgProps={{
              style: { objectFit: 'contain', background: 'transparent' },
            }}
          />
        </ListItemAvatar>
        <ListItemText primary={name} />
      </ListItem>
      <Divider />
      <InformationText text={renderInfoText()} />
      {!props.partner.qrCodeEnable && (
        <FlexBox hasTopMargin>
          <StyledCodeInput
            codeLength={4}
            handleValidation={setCodeValid}
            setPin={setPin}
          />
          <AppButton
            color="primary"
            data-testid="acceptButton"
            variant="contained"
            disabled={!codeValid}
            onClick={() => props.handleSubmit(pin)}
          >
            {t(Translations.ACCEPT)}
          </AppButton>
        </FlexBox>
      )}
    </>
  )
}

export default ValidateTicket
