import { Dispatch } from 'react'
import { Action, MiddlewareAPI } from 'redux'
import { customerSlice, layoutSlice } from 'store/slices'

export const destroySession =
  (store: MiddlewareAPI) => (next: Dispatch<Action>) => (action: Action) => {
    if (action.type === customerSlice.actions.destroySession.type) {
      store.dispatch(customerSlice.actions.setLogout())
      store.dispatch(
        layoutSlice.actions.setLayout({
          layout: {
            center: true,
            hasTopBar: true,
          },
          bottomMenu: false,
          topbar: null,
        })
      )
    }
    next(action)
  }

export default destroySession
