import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import paths from 'routing/paths'
import { AppBottomNavigation, FullLayout, Topbar, Ad } from 'shared'
import { selectCustomerStatus } from 'store/slices/customerSlice'
import * as layoutSlice from 'store/slices/layoutSlice'
import SignedInAsGuestRoutes from './SignedInAsGuestRoutes'
import SignedInRoutes from './SignedInRoutes'
import SignedOutRoutes from './SignedOutRoutes'
import ParkCarSms from 'pages/park_car/ParkCarSmsPage'
import MapSmsPage from 'pages/map/MapSmsPage'
import PcodeSignIn from 'pages/pcode_redirect/PcodeSingIn'
import { LandingPageRedirect } from 'pages/landing_page_redirect/LandingPageRedirect'
const Root: React.FC = props => <div>ROOT VIEW</div>

const AppRoutes: React.FC = (props: any) => {
  const { customerSignedIn, customerSignedInAsGuest, customerSignedOut } =
    useSelector(selectCustomerStatus)
  const { bottomMenu, layout, topbar } = useSelector(layoutSlice.stateSelectors)
  return (
    <>
      <FullLayout {...layout}>
        {topbar && <Topbar {...topbar} />}
        <Switch>
          <Route
            path={`${paths.redirect.landingPage}/:lotId`}
            component={LandingPageRedirect}
          />
          <Route path={`${paths.sms.park}/:token`} component={ParkCarSms} />
          <Route path={`${paths.sms.addTime}/:token`} component={ParkCarSms} />
          <Route path={`${paths.root.oldMobi}/:token`} component={ParkCarSms} />
          <Route path={`${paths.sms.map}/:token`} component={MapSmsPage} />
          <Route path={`${paths.sms.corp}/:token`} component={MapSmsPage} />
          <Route exact strict path={`/:pcode`} component={PcodeSignIn} />
          {customerSignedOut && (
            <Route path={paths.root.root} component={SignedOutRoutes} />
          )}
          {customerSignedIn && (
            <Route path={paths.root.root} component={SignedInRoutes} />
          )}
          {customerSignedInAsGuest && (
            <Route path={paths.root.root} component={SignedInAsGuestRoutes} />
          )}

          <Route path={paths.root.root} component={Root} />

          <Redirect to={paths.root.root} />
        </Switch>
        {layout.ad && <Ad />}
      </FullLayout>
      {bottomMenu && <AppBottomNavigation />}
    </>
  )
}

export default AppRoutes
