import { Form, Formik } from 'formik'
import { useChangeLayout } from 'hooks'
import { useSnackbar } from 'notistack'
import { passwordValidationShape } from 'pages/auth/validations'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AppButton,
  FlexBox,
  Input,
  PaddingContainer,
  PageHeading,
} from 'shared'
import { Translations, blurLastInput } from 'utils'

interface SetNewPasswordProps {
  handleSubmit(data: string): void
}

const SetNewPassword: React.FunctionComponent<SetNewPasswordProps> = props => {
  const { t } = useTranslation()
  useChangeLayout()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <PaddingContainer>
      <PageHeading title={t(Translations.RESET_PASSWORD)} />
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={passwordValidationShape(t)}
        onSubmit={data => {
          props.handleSubmit(data.password)
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          validateForm,
          handleSubmit,
        }) => (
          <Form>
            <Input
              name="password"
              type="password"
              inputMode="text"
              autoFocus
              placeholder={t(Translations.NEW_PASSWORD)}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              minLength={8}
              maxLength={32}
              autoComplete="new-password"
              error={!!errors.password}
            />
            <Input
              name="confirmPassword"
              type="password"
              inputMode="text"
              placeholder={t(Translations.CONFIRM_NEW_PASSWORD)}
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.confirmPassword}
              minLength={8}
              maxLength={32}
              autoComplete="new-password"
              onKeyDown={e => {
                blurLastInput(e)
              }}
            />
            <FlexBox hasTopMargin>
              <AppButton
                variant="outlined"
                type="button"
                onClick={() => {
                  validateForm().then(errors => {
                    const entries = Object.entries(errors)
                    if (entries.length === 0) {
                      handleSubmit()
                    } else {
                      entries.forEach(el => {
                        enqueueSnackbar(el[1])
                      })
                    }
                  })
                }}
              >
                {t(Translations.SUBMIT)}
              </AppButton>
            </FlexBox>
          </Form>
        )}
      </Formik>
    </PaddingContainer>
  )
}

export default SetNewPassword
