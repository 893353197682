import { useTouchScreen } from 'hooks'
import CardWrapper from 'pages/my_credit_cards/CreditCardsComponents/CardWrapper'
import CreditCardsPlaceholder from 'pages/my_credit_cards/CreditCardsComponents/CreditCardPlaceholder'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  AppButton,
  AppCarousel,
  AppTypography,
  FlexBox,
  PaddingContainer,
} from 'shared'
import { useAppDispatch } from 'store/store'
import { CreditCard } from 'typedef/creditCards'
import { Ticket } from 'typedef/ticket'
import { Translations } from 'utils'
import usePaymentCard from './creditCardHooks/usePaymentCard'

export interface ChooseCardProps {
  ticket: Ticket
  cards: CreditCard[]
  initSlide?: number
  paymentPath: string
  manageCardPath: string
  addCardPath: string
}

export const ChooseCard: React.FC<ChooseCardProps> = props => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const { t } = useTranslation()
  const { paymentCard, setPaymentCard } = usePaymentCard()
  const touch = useTouchScreen()
  const goToPayment = React.useCallback(
    () => push(props.paymentPath),
    [props.paymentPath, push]
  )

  const selectCard = React.useCallback(
    (card: CreditCard) => {
      setPaymentCard(card)
      if (touch) {
        goToPayment()
      }
    },
    [goToPayment, setPaymentCard, touch]
  )

  const onManageCards = React.useCallback(() => {
    push(props.manageCardPath)
  }, [props.manageCardPath, push])

  const onAddCard = React.useCallback(
    () => push(props.addCardPath),
    [props.addCardPath, push]
  )

  React.useEffect(() => {
    if (!paymentCard && props.cards.length) {
      setPaymentCard(props.cards[0])
    }
  }, [dispatch, props.cards, paymentCard, setPaymentCard])

  return (
    <PaddingContainer>
      <AppTypography weight="700" color="textSecondary" size={1.25}>
        {t(Translations.ALMOST_FINISHED)}
      </AppTypography>

      {!props.cards.length && (
        <>
          <AppTypography gutterBottom color="textSecondary">
            {t(Translations.TAP_ADD_NEW_CARD)}
          </AppTypography>
          <CreditCardsPlaceholder />
          <FlexBox hasTopMargin>
            <AppButton
              color="primary"
              variant="contained"
              data-testid="addCardButton"
              onClick={onAddCard}
            >
              {t(Translations.ADD_NEW_CARD)}
            </AppButton>
          </FlexBox>
        </>
      )}

      {!!props.cards.length && (
        <>
          <AppTypography gutterBottom color="textSecondary">
            {t(Translations.TAP_STORED_CARD)}
            <br />
            {t(Translations.TO_ADD_NEW_TAP_MANAGE_CARDS)}
          </AppTypography>
          {props.cards.length > 1 && (
            <AppCarousel focusOnSelect initialSlide={props.initSlide || 0}>
              {props.cards.map(el => (
                <CardWrapper
                  key={el.creditCardId}
                  last4Digits={el.last4Digits}
                  type={el.cardType}
                  selected={paymentCard?.creditCardId === el.creditCardId}
                  onClick={() => selectCard(el)}
                  touch={touch}
                />
              ))}
            </AppCarousel>
          )}

          <FlexBox>
            {props.cards.length === 1 && (
              <CardWrapper
                key={props.cards[0].creditCardId}
                last4Digits={props.cards[0].last4Digits}
                type={props.cards[0].cardType}
                selected={
                  paymentCard?.creditCardId === props.cards[0].creditCardId
                }
                onClick={() => selectCard(props.cards[0])}
                touch={touch}
              />
            )}
            <AppButton
              color="primary"
              variant="outlined"
              data-testid="manageCardsButton"
              onClick={onManageCards}
            >
              {t(Translations.MANAGE_CARDS)}
            </AppButton>
            {!touch && (
              <AppButton
                color="primary"
                variant="contained"
                onClick={goToPayment}
                disabled={!Boolean(paymentCard)}
                data-testid="selectCardButton"
              >
                {t(Translations.SELECT_CARD)}
              </AppButton>
            )}
          </FlexBox>
        </>
      )}
    </PaddingContainer>
  )
}

export default ChooseCard
