import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { LotApiError } from 'api/apiErrors/LotApiError'
import { LOTS_SIMULATED_PRICES } from 'api/constants'
import * as lotApi from 'api/LotApi'
import { getErrorCode, getErrorMessage } from 'api/utils'
import { RootState } from 'store/rootReducer'
import {
  ErrorData,
  GetSimulatedPricesRequest,
  GetSimulatedPricesResponse,
  SimulatedPrice,
} from 'typedef'
import { formatCurrency } from 'utils'
import { SIMULATE_PRICE } from './constants'
import * as mapSlice from './mapSlice'

/**
 * state
 */
export interface SimulatePriceState {
  pricingMode: boolean
  idsToOmit: string[]
  /** 2020-09-28T10:30:00 */
  simulateStartTime?: string
  /** 2020-09-28T11:30:00 */
  simulateEndTime?: string
  pendingCallsNumber: number
}
const simulatePriceAdapter = createEntityAdapter<SimulatedPrice>({
  selectId: en => en.parkingLotId,
})

const initialState = simulatePriceAdapter.getInitialState<SimulatePriceState>({
  idsToOmit: [],
  pricingMode: false,
  simulateStartTime: null,
  simulateEndTime: null,
  pendingCallsNumber: 0,
})
/**
 * thunks
 */
export const getSimulatedPrices = createAsyncThunk<
  GetSimulatedPricesResponse,
  GetSimulatedPricesRequest,
  { rejectValue: ErrorData & { skip?: boolean } }
>(LOTS_SIMULATED_PRICES, async (params, thunkApi) => {
  try {
    const res = await lotApi.getSimulatedPrices(params)
    return res
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      LotApiError.getLots
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

/**
 * reducers
 */
const simulatePriceSlice = createSlice({
  name: SIMULATE_PRICE,
  initialState,
  reducers: {
    addPrices(state, { payload }: PayloadAction<SimulatedPrice[]>) {
      simulatePriceAdapter.upsertMany(state, payload)
    },
    addIdsToOmit(state, { payload }: PayloadAction<string[]>) {
      state.idsToOmit = [...state.idsToOmit, ...payload]
    },
    removeIdsFromOmit(state, { payload }: PayloadAction<string[]>) {
      state.idsToOmit = state.idsToOmit.filter(el => payload.includes(el))
    },
    setDates(
      state,
      {
        payload,
      }: PayloadAction<{
        /** 2020-09-28T10:30:00 */
        simulateStartTime: string
        /** 2020-09-28T11:30:00 */
        simulateEndTime: string
      }>
    ) {
      state.simulateStartTime = payload.simulateStartTime
      state.simulateEndTime = payload.simulateEndTime
    },
    setPricingMode(state, { payload }: PayloadAction<boolean>) {
      state.pricingMode = payload
    },
    resetState(state) {
      Object.assign(state, initialState)
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSimulatedPrices.fulfilled, (state, action) => {
        simulatePriceSlice.caseReducers.addPrices(state, action)
        state.pendingCallsNumber = state.pendingCallsNumber - 1
      })
      .addCase(getSimulatedPrices.pending, (state, action) => {
        const fwdAction = simulatePriceSlice.actions.addIdsToOmit(
          action.meta.arg.parkings
        )
        simulatePriceSlice.caseReducers.addIdsToOmit(state, fwdAction)
        state.pendingCallsNumber = state.pendingCallsNumber + 1
      })
      .addCase(getSimulatedPrices.rejected, (state, action) => {
        const fwdAction = simulatePriceSlice.actions.removeIdsFromOmit(
          action.meta.arg.parkings
        )
        simulatePriceSlice.caseReducers.removeIdsFromOmit(state, fwdAction)
        state.pendingCallsNumber = state.pendingCallsNumber - 1
      })
  },
})

export const { actions } = simulatePriceSlice

/**
 * selectors
 */
export const stateSelectors = (state: RootState) => state.simulatedPrices
export const { selectAll, selectById, selectEntities, selectIds, selectTotal } =
  simulatePriceAdapter.getSelectors((state: RootState) => state.simulatedPrices)

export const selectByIds = (state: RootState, ids: string[] = []) => {
  const entities = selectEntities(state)
  return ids.map(id => entities[id]).filter(el => el)
}

export const selectMissingPricesLots = (state: RootState) => {
  const mapLots = mapSlice.selectLotsIdsWithinMap(state)
  const { noClusteredLots } = mapSlice.stateSelectors(state)
  const { idsToOmit } = stateSelectors(state)
  const missing = mapLots.filter(el => !idsToOmit.includes(el))
  if (missing.length < 24) {
    return missing
  } else {
    return noClusteredLots.filter(el => !idsToOmit.includes(el))
  }
}

export const selectPriceString = (state: RootState, id: string) => {
  if (!id) return ''
  const simulatedPrice = selectById(state, id)
  return formatCurrency(
    simulatedPrice?.currency,
    simulatedPrice?.simulatedPrice
  )
}

export const selectAvailableCallsNumber = (state: RootState) =>
  5 - stateSelectors(state).pendingCallsNumber

export default simulatePriceSlice.reducer
