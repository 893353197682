import * as React from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { SharedIcon } from 'shared'
import { layoutSlice } from 'store/slices'
import styled, { keyframes } from 'styled-components'
import { AppState } from '../../store/store'

const jump = keyframes`
  from { transform: translateY( -25%) ; }
  50% { transform: translateY( 50%) ; }
  to { transform: translateY( -25%) ; }
}`

const LoaderContainer = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`
const SvgContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center center;
  transform: translate(-15px, -50%);
  svg {
    animation: ${jump} 1.2s infinite;
  }
`

const Loader = () => {
  const { show } = useSelector((state: AppState) => state.loader)
  const { loader } = useSelector(layoutSlice.stateSelectors)

  return (
    <CSSTransition
      unmountOnExit
      in={show || loader}
      timeout={250}
      classNames="item"
    >
      <LoaderContainer>
        <SvgContainer>
          <SharedIcon.ParkerlinkIcon style={{ fontSize: '3em' }} />
        </SvgContainer>
      </LoaderContainer>
    </CSSTransition>
  )
}

export default Loader
