import React, { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { AppButton, AppStepper, FlexBox, Input, PageHeading } from 'shared';
import { hideLoader, showLoader } from 'store/loader';
import { customerSlice } from 'store/slices';
import { useAppDispatch } from 'store/store';
import { Translations, blurLastInput } from 'utils';
import { passwordValidationShape } from '../validations';
import { PersonalData } from './SignUp';
import HybridRecaptcha from '../../../shared/ReCaptcha/HybridRecaptcha';
import {validateFeature } from '../../../shared/ReCaptcha/HybridRecaptcha';


interface SetPasswordStepProps {
  handleSubmit: (token: string, password: string) => void;
  personalData: PersonalData;
  goBack: () => void;
}

const SetPasswordStep: React.FunctionComponent<SetPasswordStepProps> = props => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const handleTokenReceived = (token: string) => {
    setRecaptchaToken(token);
  };

  const handleRecaptchaSuccess = () => {
    setRecaptchaVerified(true);
  };

  const handleRecaptchaFailure = () => {
    enqueueSnackbar(t(Translations.RECAPTCHA_VERIFICATION_FAILED), { variant: 'error' });
    setRecaptchaVerified(false);
  };

  const handleSubmit = async ({ password }: { password: string }) => {
    if (!recaptchaVerified) {
      enqueueSnackbar(t(Translations.PLEASE_COMPLETE_RECAPTCHA_VERIFICATION), { variant: 'warning' });
      return;
    }

    const { firstName, lastName, phoneNumber, code, email } = props.personalData;
    dispatch(showLoader());
    const promise = await dispatch(
      customerSlice.register({
        firstName,
        lastName,
        phoneCountryCode: parseInt(code),
        phoneNumber,
        email,
        password,
        recaptchaToken
      })
    );
    dispatch(hideLoader());
    if (customerSlice.register.fulfilled.match(promise)) {
      const res = unwrapResult(promise);
      props.handleSubmit(res.confirmToken, password);
    } else {
      enqueueSnackbar(promise.payload.description, { variant: 'error' });
    }
  };

  return (
    <> 
      <PageHeading title={t(Translations.CREATE_PASSWORD)} normalizeIcon={false}>
        <AppStepper steps={[1, 2, 3]} activeStep={1} />
      </PageHeading>

      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        onSubmit={handleSubmit}
        validationSchema={passwordValidationShape(t)}
        validateOnBlur
      >
        {({ values, errors, handleChange, handleBlur, validateForm, submitForm, isSubmitting }) => (
          <Form>
            <Input
              name="password"
              type="password"
              inputMode="text"
              placeholder={t(Translations.PASSWORD)}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.password}
              minLength={8}
              maxLength={32}
              autoComplete="new-password"
              autoFocus
            />
            <Input
              name="confirmPassword"
              type="password"
              inputMode="text"
              placeholder={t(Translations.CONFIRM_PASSWORD)}
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.confirmPassword}
              maxLength={32}
              autoComplete="new-password"
              minLength={8}
              onKeyDown={e => {
                blurLastInput(e);
              }}
            />
            <FlexBox hasTopMargin>
              <HybridRecaptcha
                onValidationSuccess={handleRecaptchaSuccess}
                onValidationFailure={handleRecaptchaFailure}
                onTokenReceived={handleTokenReceived}
                feature={validateFeature.Registration}
              />
              <AppButton
                variant="contained"
                type="button"
                onClick={() => {
                  validateForm().then(errors => {
                    const entries = Object.entries(errors);
                    if (entries.length === 0) {
                      submitForm();
                    } else {
                      enqueueSnackbar(entries[0][1]);
                    }
                  });
                }}
                disabled={isSubmitting}
              >
                {t(Translations.SUBMIT)}
              </AppButton>
              <AppButton variant="outlined" type="button" onClick={props.goBack}>
                {t(Translations.BACK)}
              </AppButton>
            </FlexBox>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SetPasswordStep;