import React from 'react'
import { TimeSelectOption } from 'typedef/lot'

export interface Props {
  selectedTimeOption?: TimeSelectOption
  licencePlate?: string
  spotNumber?: string
  minutes?: number
  hours?: number
  days?: number
  isAllDay?: boolean
  hasSpot: boolean
  pricePromiseFullfilled?: boolean
  isTos?: boolean
}

export const usePayButton = ({
  days,
  hours,
  isAllDay,
  licencePlate,
  minutes,
  selectedTimeOption,
  spotNumber,
  hasSpot,
  pricePromiseFullfilled,
  isTos,
}: Props) => {
  const [isPayButtonDisabled, setIsPayButtonDisabled] = React.useState(true)

  const setButton = React.useCallback(() => {
    if (!selectedTimeOption || !pricePromiseFullfilled) {
      setIsPayButtonDisabled(true)
      return
    }
    if (hasSpot && !spotNumber) {
      setIsPayButtonDisabled(true)
      return
    }
    if (!licencePlate) {
      setIsPayButtonDisabled(true)
      return
    }
    // if (!isTos) {
    //   setIsPayButtonDisabled(true)
    //   return
    // }
    if (selectedTimeOption.intervalOptions) {
      if (minutes || hours || days || isAllDay) {
        setIsPayButtonDisabled(false)
      } else {
        setIsPayButtonDisabled(true)
      }
      return
    }
    setIsPayButtonDisabled(false)
  }, [
    licencePlate,
    spotNumber,
    minutes,
    hours,
    days,
    isAllDay,
    selectedTimeOption,
    pricePromiseFullfilled,
    hasSpot,
  ])
  React.useEffect(() => {
    setButton()
  }, [setButton])

  return { isPayButtonDisabled }
}

export default usePayButton
