import React from 'react'
import { SharedIcon } from 'shared'
import styled from 'styled-components'

const GpsIndicator = styled.div`
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  border: 3px solid ${_ => _.theme.palette.primary.main};
`
const GpsMarkerHeading = styled(SharedIcon.HeadingGpsIndicator)<{
  heading: number
}>`
  transition: all 0.5s;
  // svg icon is rotated
  transform: rotate(calc(-71.45deg + ${_ => _.heading}deg));
  transform-origin: 16.6px 29.16px;
`
export interface GpsMarkerProps {
  lat: number
  lng: number
  heading: number | null
}

export const GpsMarker: React.FC<GpsMarkerProps> = props => {
  return (
    <>
      {props.heading ? (
        <GpsMarkerHeading heading={props.heading || 0} />
      ) : (
        <GpsIndicator />
      )}
    </>
  )
}

export default GpsMarker
