import { Dispatch } from 'react'
import { Action, MiddlewareAPI } from 'redux'
import { customerSlice, sliceUtil } from 'store/slices'
import { UserRole } from 'typedef/customer'

export const refreshToken =
  (store: MiddlewareAPI) => (next: Dispatch<Action>) => (action: Action) => {
    const { customer } = store.getState()
    next(action)
    if (customer.role !== UserRole.Guest && customer.role !== UserRole.User)
      return
    if (sliceUtil.fetchStatus(customer.promisesStatus.refreshToken).pending)
      return
    if (!customer?.tokens) return
    const { expires } = customer.tokens
    const refreshThreshold = 60000

    if (expires < Date.now()) {
      store.dispatch(customerSlice.actions.destroySession())
      return
    }

    if (expires > Date.now() && expires - Date.now() < refreshThreshold) {
      store.dispatch<any>(
        customerSlice.refreshToken({
          token: customer.tokens.refresh,
        })
      )
    }
  }

export default refreshToken
