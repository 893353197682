import { useChangeLayout } from 'hooks'
import { useGetTicket, useParkDetails } from 'pages/park_car/ParkCarHooks'
import { CancelParkCarDialog } from 'pages/park_car_credit_card/CreditCardComponents'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { paths } from 'routing'
import { AppSnackBarProvider } from 'shared'
import { customerSlice, ticketsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { CarStatus } from 'typedef/ticket'
import Translations from 'utils/translations'
import SelectCardGuestWrapper from './SelectCardGuestWrapper'
import SelectCardWrapper from './SelectCardWrapper'

export const AddTimeSelectCardPage: React.FC = props => {
  const dispatch = useAppDispatch()
  const { ticketId } = useParams<{ ticketId: string }>()
  const [, , ticket] = useGetTicket()
  const [showDialog, setShowDialog] = React.useState(false)
  const { goBack } = useHistory()
  const { t } = useTranslation()
  const title = t(Translations.PARK_CAR)
  const { customerSignedIn, customerSignedInAsGuest } = useSelector(
    customerSlice.selectCustomerStatus
  )
  const { externalPricingId, pricingPayload } = useParkDetails()
  const onClickBack = React.useCallback(() => {
    dispatch(
      ticketsSlice.actions.setSelectedTicket({
        ticketId,
      })
    )
    // push(`${paths.parking.addTime}/${ticketId}`)
    goBack()
  }, [dispatch, goBack, ticketId])

  const onClickRight = () => {
    setShowDialog(true)
  }

  useChangeLayout(
    {
      topbar: {
        title,
        onClickBack,
        rightIcon: 'close',
        onClickRight,
      },
      bottomMenu: false,
    },
    'app'
  )

  if (!ticketId) {
    return <Redirect to={paths.parking.tickets} />
  }

  if (ticket?.carStatus === CarStatus.ParkingHasExpired) {
    return <Redirect to={paths.parking.tickets} />
  }
  if (!externalPricingId && !pricingPayload && customerSignedIn) {
    return <Redirect to={paths.parking.tickets} />
  }

  return (
    <AppSnackBarProvider>
      {customerSignedIn && (
        <SelectCardWrapper
          ticketId={ticketId}
          paymentPath={`${paths.parking.addTimePay}/${ticketId}`}
          manageCardPath={`${paths.parking.addTimeManageCards}/${ticketId}`}
          addCardPath={`${paths.parking.addTimeAddCard}/${ticketId}`}
        />
      )}
      {customerSignedInAsGuest && (
        <SelectCardGuestWrapper
          paymentPath={`${paths.parking.addTimePay}/${ticketId}`}
          manageCardPath={`${paths.parking.addTimeManageCards}/${ticketId}`}
          addCardPath={`${paths.parking.addTimeAddCard}/${ticketId}`}
          ticketId={ticketId}
        />
      )}
      <CancelParkCarDialog
        open={showDialog}
        closeDialog={() => setShowDialog(false)}
      />
    </AppSnackBarProvider>
  )
}

export default AddTimeSelectCardPage
