import { logAction } from 'api/LogApi'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import { ErrorPlaceholder, FullLayout, Topbar, WhiteBoxContainer } from 'shared'
import { LogSource, LogType } from 'typedef/log'
import { Translations } from 'utils'

const getDeviceInfo = () => {
  const navUserAgent = navigator.userAgent
  let browserName = navigator.appName
  let browserVersion = '' + parseFloat(navigator.appVersion)
  let tempNameOffset, tempVersionOffset, tempVersion

  if ((tempVersionOffset = navUserAgent.indexOf('Opera')) !== -1) {
    browserName = 'Opera'
    browserVersion = navUserAgent.substring(tempVersionOffset + 6)
    if ((tempVersionOffset = navUserAgent.indexOf('Version')) !== -1)
      browserVersion = navUserAgent.substring(tempVersionOffset + 8)
  } else if ((tempVersionOffset = navUserAgent.indexOf('MSIE')) !== -1) {
    browserName = 'Microsoft Internet Explorer'
    browserVersion = navUserAgent.substring(tempVersionOffset + 5)
  } else if ((tempVersionOffset = navUserAgent.indexOf('Chrome')) !== -1) {
    browserName = 'Chrome'
    browserVersion = navUserAgent.substring(tempVersionOffset + 7)
  } else if ((tempVersionOffset = navUserAgent.indexOf('Safari')) !== -1) {
    browserName = 'Safari'
    browserVersion = navUserAgent.substring(tempVersionOffset + 7)
    if ((tempVersionOffset = navUserAgent.indexOf('Version')) !== -1)
      browserVersion = navUserAgent.substring(tempVersionOffset + 8)
  } else if ((tempVersionOffset = navUserAgent.indexOf('Firefox')) !== -1) {
    browserName = 'Firefox'
    browserVersion = navUserAgent.substring(tempVersionOffset + 8)
  } else if (
    (tempNameOffset = navUserAgent.lastIndexOf(' ') + 1) <
    (tempVersionOffset = navUserAgent.lastIndexOf('/'))
  ) {
    browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset)
    browserVersion = navUserAgent.substring(tempVersionOffset + 1)
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName
    }
  }

  // trim version
  if ((tempVersion = browserVersion.indexOf(';')) !== -1)
    browserVersion = browserVersion.substring(0, tempVersion)
  if ((tempVersion = browserVersion.indexOf(' ')) !== -1)
    browserVersion = browserVersion.substring(0, tempVersion)
  return { browserName, browserVersion, appVersion: navigator.appVersion }
}

export interface FallbackComponentProps {
  resetErrorBoundary: () => void
}

export const FallbackComponent: React.FC<FallbackComponentProps> = props => {
  const { push } = useHistory()
  const { t } = useTranslation()

  return (
    <>
      <Topbar
        onClickBack={() => undefined}
        title={t(Translations.APPLICATION_CRASHED)}
        hasGoBack={false}
      />

      <FullLayout hasTopBar hasFlatBackground>
        <WhiteBoxContainer minHeight={150}>
          <ErrorPlaceholder
            title={t(Translations.APPLICATION_CRASHED)}
            description={t(Translations.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN)}
            buttonTitle={t(Translations.RESTART_APPLICATION)}
            onClick={() => {
              props.resetErrorBoundary()
              push(paths.root.root)
            }}
          />
        </WhiteBoxContainer>
      </FullLayout>
    </>
  )
}

export const ErrorsWrapper: React.FC = props => {
  const { location } = useHistory()
  // const { t } = useTranslation()
  const onError = (err: Error) => {
    const { appVersion, browserName, browserVersion } = getDeviceInfo()
    const deviceInfo = `${browserName} ${browserVersion}`
    const { message, name, stack } = err
    const logMessage = JSON.stringify({
      appVersion,
      path: location.pathname,
      name,
      message,
      stack,
    })

    if (process.env.NODE_ENV === 'development') return
    logAction({
      deviceInfo,
      logMessage,
      logSource: LogSource.WebApp,
      logType: LogType.Warning,
    })
  }

  return (
    <ErrorBoundary
      onError={onError}
      FallbackComponent={({ resetErrorBoundary }) => {
        return <FallbackComponent resetErrorBoundary={resetErrorBoundary} />
      }}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {props.children}
    </ErrorBoundary>
  )
}

export default ErrorsWrapper
