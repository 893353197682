import { Typography } from '@mui/material'
import * as React from 'react'
import styled from 'styled-components'

const PlaceholderWrapper = styled.div<{ minHeight?: number }>`
  display: flex;
  flex-flow: column;
  align-items: center;
  min-height: ${_ => _.minHeight}px;
  position: relative;
  max-width: 350px;
  margin: auto;
  text-align: center;
`

const PlaceholderTitle = styled(Typography)`
  color: ${_ => _.theme.palette.grey['500']};
  line-height: 1.5;
  text-transform: uppercase;
  padding: ${_ => _.theme.spacing(1.5)} ${_ => _.theme.spacing(2)};
  text-align: center;
`
const PlaceholderDescription = styled(Typography)`
  color: ${_ => _.theme.palette.grey['500']};
  padding: ${_ => _.theme.spacing(1.5)} ${_ => _.theme.spacing(2)};
  text-align: center;
`
const PlaceholderIcon = styled.div`
  max-width: 180px;
  svg {
    max-width: 100%;
    height: auto;
    text-align: center;
  }
`

export interface PlaceholderProps {
  title?: string
  description?: string
  icon?: JSX.Element
  minHeight?: number
  colorBlack?: boolean
}
export const Placeholder: React.FC<PlaceholderProps> = ({
  icon: Icon,
  title,
  minHeight,
  description,
  ...props
}) => {
  return (
    <PlaceholderWrapper
      data-testid="placeholder"
      minHeight={minHeight}
      {...props}
    >
      {!!Icon && <PlaceholderIcon>{Icon}</PlaceholderIcon>}
      {title && <PlaceholderTitle variant="h3">{title}</PlaceholderTitle>}
      {description && (
        <PlaceholderDescription variant="body1">
          {description}
        </PlaceholderDescription>
      )}
      {props.children}
    </PlaceholderWrapper>
  )
}

Placeholder.defaultProps = {
  minHeight: 40,
}

export default Placeholder
