import React from 'react'
import { AppSpinner, FlexBox, GridWrapper, WhiteBoxContainer } from 'shared'
import { GetLotDetailsParkData } from 'typedef/lot'
import * as C from '.'
import { useParkCar } from '../ParkCarHooks'
import ConfirmPopup from './ConfirmPopup'
import LicenseField from './LicenseField'
import NeedHelpPopup from './NeedHelpPopup'
import SelectedLotTitle from './SelectedLotTitle'
import SpotField from './SpotField'

export interface MonthlyParkCarCardProps {
  lot: GetLotDetailsParkData
}

export const MonthlyParkCarCard: React.FC<MonthlyParkCarCardProps> = ({
  lot,
  ...props
}) => {
  const [licencePlate, setLicencePlate] = React.useState('')
  const [spotNumber, setSpotNumber] = React.useState('')
  const [showConfirmPopup, setShowConfirmPopup] = React.useState(false)
  const [showNeedHelpPopup, setShowNeedHelpPopup] = React.useState(false)
  const [isLoading, parkCar] = useParkCar()
  const [isTosChecked, setIsTosChecked] = React.useState(false)

  const isPayButtonDisabled = () => {
    if (isLoading || !licencePlate) return true
    if (lot.spot.isEnabled && !spotNumber) return true
    return false
  }

  const onPayConfirm = () => {
    setShowConfirmPopup(false)
    parkCar({
      plate: licencePlate,
      spot: spotNumber,
    })
  }

  const onPayClick = () => {
    if (lot.spot?.isEnabled) {
      setShowConfirmPopup(true)
    } else {
      onPayConfirm()
    }
  }
  return (
    <WhiteBoxContainer minHeight={150}>
      <AppSpinner show={isLoading} />
      <GridWrapper>
        <SelectedLotTitle />
        <FlexBox horizontal horizontalFullWith>
          <LicenseField
            onBlur={e => setLicencePlate(e.target.value)}
            onChange={e => setLicencePlate(e.target.value)}
            value={licencePlate}
          />
          {lot?.spot?.isEnabled && (
            <SpotField
              onBlur={e => setSpotNumber(e.target.value)}
              onChange={e => setSpotNumber(e.target.value)}
              maxSpotNumber={lot?.spot?.maxSpotNumber}
            />
          )}
        </FlexBox>
        <C.MonthlyParkingExpiresSection
          expiresAt={lot?.monthlyParkerInfo?.expireMonthlyParkingDateTime}
        />
        <C.PaySection
          isTosChecked={isTosChecked}
          setIsTosChecked={setIsTosChecked}
          disabled={isPayButtonDisabled()}
          onNextClick={onPayClick}
          showNeedHelp={setShowNeedHelpPopup}
          onlyButton
        />
      </GridWrapper>

      <ConfirmPopup
        closePopup={() => setShowConfirmPopup(false)}
        onConfirmClick={onPayConfirm}
        showConfirmPopup={showConfirmPopup}
        spotNumber={spotNumber}
      />
      <NeedHelpPopup
        closePopup={() => setShowNeedHelpPopup(false)}
        showNeedHelpPopup={showNeedHelpPopup}
        lotId={lot.lotId}
      />
    </WhiteBoxContainer>
  )
}

MonthlyParkCarCard.defaultProps = {}
export default MonthlyParkCarCard
