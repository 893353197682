export const LOG = '/Log'
export const CUSTOMER_CAR = '/CustomerCar'
export const TICKETS = '/Tickets'
export const CREDIT_CARD = '/CreditCard'
export const PARK_CAR = '/ParkCar'
export const SMS = '/SMS'
export const PARK_CAR_REDIRECT = `${PARK_CAR}/PayRedirect`
export const PARK_CAR_TRANSACTION_STATUS = `${PARK_CAR}/TransactionStatus`
export const PARK_CAR_PAY = `${PARK_CAR}/Pay`
export const LOTS = '/Lots'
export const LOTS_DETAILS_PARK = `${LOTS}/Details/Park`
export const LOTS_DETAILS_MAP = `${LOTS}/Details/Map`
export const LOTS_SIMULATED_PRICES = `${LOTS}/SimulatedPrices`
export const LOTS_COMPANY_CONTACT_INFO = `${LOTS}/CompanyContactInfo`
export const PRICE = '/Price'
export const PRICE_EXTERNAL_PRICING = `${PRICE}/ExternalPricing`
export const COUPON = '/Coupon'
export const UPFRONT_COUPON = `${COUPON}/Upfront`
export const CUSTOMER = '/Customer'
export const CUSTOMER_LOGIN = `${CUSTOMER}/Login`
export const CUSTOMER_GET_DETAILS = `${CUSTOMER}/GetDetails`
export const CUSTOMER_REFRESH_TOKEN = `${CUSTOMER}/RefreshToken`
export const CUSTOMER_REGISTER = `${CUSTOMER}/Register`
export const CUSTOMER_CONFIRM_REGISTER = `${CUSTOMER}/ConfirmRegister`
export const CUSTOMER_REMIND_PASSWORD = `${CUSTOMER}/RemindPassword`
export const CUSTOMER_RESET_PASSWORD = `${CUSTOMER}/ResetPassword`
export const CUSTOMER_CHANGE_PASSWORD = `${CUSTOMER}/ChangePassword`
export const CUSTOMER_EDIT = `${CUSTOMER}/Edit`
export const CUSTOMER_DELETE = `${CUSTOMER}/Delete`
export const CUSTOMER_PHONE_NO_IS_AVAILABLE = `${CUSTOMER}/PhoneNoIsAvailable`
export const CUSTOMER_RESEND_REGISTRATION_PIN = `${CUSTOMER}/ResendRegistrationPin`
export const CUSTOMER_FIND_MY_CAR = `${CUSTOMER}/FindCar`
export const CUSTOMER_ADD_GUEST_NOTIFICATION = `${CUSTOMER}/AddGuestNotification`
export const CUSTOMER_GET_GUEST_NOTIFICATION = `${CUSTOMER}/GuestNotification`
export const SMS_SEND_VERIFICATION_SMS = `${SMS}/SendVerificationSMS`
export const SMS_RESEND_VERIFICATION_SMS = `${SMS}/ResendVerificationSMS`
export const TERMS_AND_CONDITIONS = `/TermsAndConditions`
export const RECEIPT = `/Receipt`
export const RECEIPT_QUESTION = `${RECEIPT}/SendQuestion`
export const SHOW_FOR_CUSTOMER = `${TERMS_AND_CONDITIONS}/ShowForCustomer`
export const ACCEPT_TOS = `${TERMS_AND_CONDITIONS}/Accept`
export const VERIFY_HUMAN = `/ReCaptcha/VerifyHuman`
