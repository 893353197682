import { SelectedLotTitle, TotalText } from 'pages/park_car/ParkCarComponents'
import { useParkDetails } from 'pages/park_car/ParkCarHooks'
import React from 'react'
import { DetailsBox, GridWrapper } from 'shared'

export interface ParkingDetailsProps {}

export const ParkingDetails: React.FC<ParkingDetailsProps> = props => {
  const { totalAmount, currency, parkingExpireDateTime } = useParkDetails()
  return (
    <GridWrapper>
      <SelectedLotTitle />
      <DetailsBox.Wrapper>
        <DetailsBox.TimeRow time={parkingExpireDateTime} />
      </DetailsBox.Wrapper>
      <TotalText amount={totalAmount} currency={currency} />
    </GridWrapper>
  )
}

export default ParkingDetails
