import { Grid, ButtonProps } from '@mui/material'
import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, FlexBox, Input } from 'shared'
import { AppButtonProps } from 'shared/AppButton'
import { AddCreditCardPayload } from 'typedef/creditCards'
import {
  validateCardNumber,
  validateExpirationDate,
  validateExpirationMonth,
  validateExpirationYear,
} from 'utils/creditCard'
import * as Yup from 'yup'
import styled from 'styled-components'
import { zipCodeRegex, Translations, blurLastInput } from 'utils'
export interface AddCreditCardFormProps {
  addCreditCard: (arg: AddCreditCardPayload) => void
  setCardType?: (arg: string) => void
  setMonth?: (arg: string) => void
  setYear?: (arg: string) => void
  disabled?: boolean
  buttonProps?: ButtonProps & AppButtonProps
}
const InputWithSlash = styled(Input)`
  &::after {
    content: '/';
    position: absolute;
    right: -12px;
    top: 50%;
    transform: translate(0%, -20%);
    font-size: 22px;
  }
`
export const AddCreditCardForm: React.FC<AddCreditCardFormProps> = props => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [currentInput, setCurrentInput] = React.useState(0)
  const inputRefs = React.useRef<React.RefObject<HTMLInputElement>[]>(
    [...Array(4)].map(() => React.createRef())
  )
  const focusNextInput = (
    e: React.FormEvent<HTMLInputElement>,
    creditCard?: boolean
  ) => {
    if (currentInput >= inputRefs.current.length) return false
    const valueLength = creditCard
      ? e.currentTarget.value?.replace(/\s/g, '').length
      : e.currentTarget.value.length
    const maxLength = creditCard
      ? 16
      : parseInt(e.currentTarget.attributes.getNamedItem('maxLength').value)
    if (valueLength < maxLength) return false

    inputRefs.current[currentInput + 1].current?.focus()
    return true
  }

  const creditCardSchema = Yup.object().shape({
    cardnumber: Yup.string()
      .required(t(Translations.CREDIT_CARD_SHOULD_NOT_BE_EMPTY))
      .test(
        'credit-card-validation',
        t(Translations.INVALID_CARD_NUMBER),
        validateCardNumber
      ),
    month: Yup.string()
      .required(t(Translations.DATE_SHOULD_NOT_BE_EMPTY))
      .min(2, t(Translations.DATE_IS_INVALID))
      .test(
        'expiration-month-validation',
        t(Translations.DATE_IS_INVALID),
        function (value) {
          if (!this.parent.year || this.parent.year?.length < 4) {
            return validateExpirationMonth(value)
          } else {
            return validateExpirationDate(`${value}/${this?.parent?.year}`)
          }
        }
      ),
    year: Yup.string()
      .required(t(Translations.DATE_SHOULD_NOT_BE_EMPTY))
      .min(4, t(Translations.DATE_IS_INVALID))
      .test(
        'expiration-year-validation',
        t(Translations.DATE_IS_INVALID),
        function (value) {
          if (!this.parent.month || this.parent.month?.length < 2) {
            return validateExpirationYear(value)
          } else {
            return validateExpirationDate(`${this?.parent?.month}/${value}`)
          }
        }
      ),
    billing: Yup.string()
      .required(t(Translations.ZIP_CODE_SHOULD_NOT_BE_EMPTY))
      .matches(zipCodeRegex, t(Translations.INVALID_ZIP_CODE)),
  })

  return (
    <Formik
      validateOnBlur
      initialValues={{
        cardnumber: '',
        billing: '',
        month: '',
        year: '',
      }}
      onSubmit={data => {
        props.addCreditCard({
          lotId: '',
          ccNumber: data.cardnumber.replace(/\s/g, ''),
          zipCode: data.billing,
          expirationMonth: parseInt(data.month),
          expirationYear: parseInt(data.year),
        })
      }}
      validationSchema={creditCardSchema}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        validateForm,
        submitForm,
        touched,
        setTouched,
      }) => {
        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  noMargin
                  name="cardnumber"
                  data-testid="cardnumber"
                  inputMode="numeric"
                  autoComplete="cc-number"
                  autoFocus
                  onFocus={() => setCurrentInput(0)}
                  refProp={inputRefs.current[0]}
                  placeholder={t(Translations.CREDIT_CARD_NUMBER)}
                  maxLength={19}
                  value={values.cardnumber}
                  onChange={e => {
                    props.setCardType(e.currentTarget.value)
                    handleChange(e)
                    focusNextInput(e, true)
                  }}
                  onBlur={handleBlur}
                  error={
                    touched['cardnumber'] &&
                    (!!errors.cardnumber || !values.cardnumber)
                  }
                />
              </Grid>
              <Grid item xs={3} style={{ position: 'relative' }}>
                <InputWithSlash
                  noMargin
                  autoComplete="cc-exp-month"
                  name="month"
                  data-testid="month"
                  inputMode="numeric"
                  placeholder={t(Translations.MONTH_SHORT)}
                  maxLength={2}
                  onFocus={() => setCurrentInput(1)}
                  refProp={inputRefs.current[1]}
                  value={values.month}
                  onChange={e => {
                    handleChange(e)
                    props.setMonth(e.currentTarget.value)
                    focusNextInput(e)
                  }}
                  onBlur={handleBlur}
                  error={touched['month'] && (!!errors.month || !values.month)}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  noMargin
                  autoComplete="cc-exp-year"
                  name="year"
                  data-testid="year"
                  inputMode="numeric"
                  onFocus={() => setCurrentInput(2)}
                  refProp={inputRefs.current[2]}
                  placeholder={t(Translations.YEAR_SHORT)}
                  maxLength={4}
                  value={values.year}
                  onChange={e => {
                    handleChange(e)
                    props.setYear(e.currentTarget.value)
                    focusNextInput(e)
                  }}
                  onBlur={handleBlur}
                  error={touched['year'] && (!!errors.year || !values.year)}
                />
              </Grid>
              <Grid item xs={5}>
                <Input
                  noMargin
                  name="billing"
                  data-testid="billing"
                  placeholder={t(Translations.ZIP_CODE)}
                  autoComplete="shipping postal-code"
                  onFocus={() => setCurrentInput(3)}
                  refProp={inputRefs.current[3]}
                  maxLength={15}
                  value={values.billing}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputMode="text"
                  onKeyDown={e => {
                    blurLastInput(e)
                  }}
                  error={
                    touched['billing'] && (!!errors.billing || !values.billing)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FlexBox hasTopMargin>
                  <AppButton
                    variant="contained"
                    data-testid="addCardButton"
                    type="button"
                    color="primary"
                    onClick={() =>
                      validateForm().then(errors => {
                        setTouched({
                          billing: true,
                          cardnumber: true,
                          month: true,
                          year: true,
                        })
                        const entries = Object.entries(errors)
                        if (entries.length === 0) {
                          submitForm()
                        } else {
                          entries.forEach(el => {
                            enqueueSnackbar(el[1])
                          })
                        }
                      })
                    }
                    disabled={props.disabled}
                    {...props.buttonProps}
                  >
                    {t(Translations.ADD_CARD)}
                  </AppButton>
                </FlexBox>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
AddCreditCardForm.defaultProps = {
  buttonProps: {},
  setCardType: () => undefined,
  setMonth: () => undefined,
  setYear: () => undefined,
}
export default AddCreditCardForm
