import * as Yup from 'yup'
import Translations from 'utils/translations'
import { TFunction } from 'i18next'

export const passwordValidationShape = (t: TFunction) =>
  Yup.object().shape({
    password: Yup.string()
      .required(t(Translations.PASSWORD_CANNOT_BE_EMPTY))
      .matches(
        /(?=.*[A-Z]{1,})(?=.*[0-9]{1,})(?=.*[^A-Za-z0-9])(?=.*.{8,})/,
        t(Translations.MINIMUM_8_CHARACTERS_AT_LEAST_ONE_CAPITAL)
      ),
    confirmPassword: Yup.string()
      .required(t(Translations.CONFIRM_PASSWORD_CANNOT_BE_EMPTY))
      .oneOf([Yup.ref('password'), null], t(Translations.PASSWORD_NOT_MATCH)),
  })
