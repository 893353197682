import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import enTranslation from './resources/translation-en.json'
import itTranslation from './resources/translation-it.json'
import deTranslation from './resources/translation-de.json'
import enGbTranslation from './resources/translation-en-gb.json'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      'en-US': {
        translation: enTranslation,
      },
      'en-GB': {
        translation: enGbTranslation,
      },
      'it-IT': {
        translation: itTranslation,
      },
      it: {
        translation: itTranslation,
      },
      de: {
        translation: deTranslation,
      },
      'de-DE': {
        translation: deTranslation,
      },
    },
    detection: {
      order: ['localStorage', 'navigator'],
    },
    // whitelist: ['en', 'es'],
    load: 'currentOnly',
    fallbackLng: 'en-US',
    nonExplicitSupportedLngs: true,
    // debug: isLocalhost,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
