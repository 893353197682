import { useInitParkCar } from 'pages/park_car/ParkCarHooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'
import Translations from 'utils/translations'
import { SelectedLotTitle } from '../ParkCarComponents'
import { AppSpinner, ErrorPlaceholder, WhiteBoxContainer } from 'shared'

export const useAutoClearTimeExceedingPopup = () => {
  const { setPopup, showPopup } = usePopup()
  const { t } = useTranslation()
  const { isError, isLoading, fetchData } = useInitParkCar(false)

  return React.useCallback(() => {
    setPopup({
      variant: 'error',
      description: t(
        Translations.RATE_HAS_EXPIRED_PLEASE_SELECT_YOUR_TIME_AGAIN
      ),
      title: t(Translations.RATE_HAS_EXPIRED),
      disableBackdropClick: true,
      buttons: [
        {
          onClick: () => {
            fetchData()
            if (isLoading || isError)
              return (
                <WhiteBoxContainer>
                  <SelectedLotTitle />
                  <AppSpinner show={isLoading} />
                  {isError && <ErrorPlaceholder onClick={fetchData} />}
                </WhiteBoxContainer>
              )
          },
        },
      ],
    })
    showPopup(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPopup, showPopup])
}

export default useAutoClearTimeExceedingPopup
