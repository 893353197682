import ResetPassword from 'pages/auth/forgot_password/resetPassword/ResetPassword'
import ForgotPassword from 'pages/auth/forgot_password/sendMail/ForgotPassword'
import { ForgotPasswordRedirect } from 'pages/auth/forgot_password/sendMail/ForgotPasswordRedirect'
import Login from 'pages/auth/login/Login'
import { LoginRedirect } from 'pages/auth/login/LoginRedirect'
import SignUp from 'pages/auth/signup/SignUp'
import { SignUpRedirect } from 'pages/auth/signup/SignUpRedirect'
import { FindCars } from 'pages/find_cars'
import Map from 'pages/map/MapView'
import { SendReceipt } from 'pages/my_receipts'
import { ContactInformationsPage } from 'pages/my_receipts/receiptsAndNotifications/ContactInformationsPage'
import ReceiptsAndNotificationsPage from 'pages/my_receipts/receiptsAndNotifications/ReceiptsAndNotificationsPage'
import { Parker } from 'pages/parker'
import { ParkCar } from 'pages/park_car'
import AwaitTransactionPage from 'pages/park_car/AwaitTransation'
import {
  AddCreditCard as ParkCarAddCreditCard,
  ManageCards as ParkCarManageCards,
  SelectCreditCard as ParkCarSelectCreditCard,
} from 'pages/park_car_credit_card/'
import AddTimeSelectCardPage from 'pages/park_car_credit_card/AddTimeSelectCardPage'
import { AddTimeSummaryPage, ParkCarSummaryPage } from 'pages/park_car_summary'
import ParkCarSummarySuccessPage from 'pages/park_car_summary/ParkCarSummarySuccessPage'
import PcodeSignIn from 'pages/pcode_redirect/PcodeSingIn'
import { TicketsGuest } from 'pages/tickets'
import { UpfrontValidate } from 'pages/validators/UpfrontValidate'
import { UpfrontValidationView } from 'pages/validators/UpfrontValidationView'
import Validate from 'pages/validators/Validate'
import ValidatorsView from 'pages/validators/ValidatorsView'
import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import paths from 'routing/paths'

const SignedInAsGuestRoutes: React.FC = props => {
  return (
    <Switch>
      <Route path={paths.parker.myProfile} exact component={Parker} />
      <Route path={paths.home.map} component={Map} />
      <Route path={paths.auth.login} component={Login} />
      <Route path={paths.auth.signUp} component={SignUp} />
      <Route path={paths.auth.forgotPassword} component={ForgotPassword} />
      <Route
        path={`${paths.auth.resetPassword}/:token`}
        component={ResetPassword}
      />

      {/* PARKER */}
      <Route
        path={`${paths.parker.sendReceipt}/:carId`}
        component={SendReceipt}
      />

      {/**
       * SMS
       **/}
      <Route path={`${paths.sms.park}/:token`} component={ParkCar} />
      <Route path={`${paths.sms.map}/:token`} component={Map} />
      <Route path={`${paths.sms.corp}/:token`} component={Map} />

      {/**
       * ADD TIME
       */}
      {/* <Route path={`${paths.parking.addTime}/:carId`} component={ParkCar} /> */}
      <Route
        path={`${paths.parking.addTimePay}/:ticketId`}
        component={AddTimeSummaryPage}
      />
      <Route
        path={`${paths.parking.addTimeAddCard}/:ticketId`}
        component={ParkCarAddCreditCard}
      />
      <Route
        path={`${paths.parking.addTimeSelectCard}/:ticketId`}
        component={AddTimeSelectCardPage}
      />
      <Route
        path={`${paths.parking.addTimeManageCards}/:ticketId`}
        component={ParkCarManageCards}
      />

      {/**
       * PARKING
       **/}
      <Route
        exact
        path={`${paths.parking.upfrontValidate}/:parkerId`}
        component={UpfrontValidationView}
      />
      <Route
        exact
        path={`${paths.parking.upfrontValidate}/:parkerId/${paths.parking.validate}/:couponId`}
        component={UpfrontValidate}
      />
      <Route
        exact
        path={`${paths.parking.validators}/:carId`}
        component={ValidatorsView}
      />
      <Route
        exact
        path={`${paths.parking.validators}/:carId/${paths.parking.validate}/:couponId`}
        component={Validate}
      />
      <Route path={`${paths.parking.addTime}/:carId`} component={ParkCar} />
      <Route path={`${paths.parking.parkCar}/:lotId`} component={ParkCar} />
      <Route path={paths.parking.findCar} component={FindCars} />
      <Route
        path={`${paths.parking.selectCard}/:ticketId`}
        component={ParkCarSelectCreditCard}
      />
      <Route
        path={`${paths.parking.transaction}/:carId/transactionId/:transactionId`}
        component={AwaitTransactionPage}
      />
      <Route
        path={`${paths.parking.transaction}/:carId`}
        component={AwaitTransactionPage}
      />
      <Route
        path={`${paths.parking.manageCards}/:ticketId`}
        component={ParkCarManageCards}
      />
      <Route
        path={`${paths.parking.addCard}/:ticketId`}
        component={ParkCarAddCreditCard}
      />
      <Route
        path={`${paths.parking.pay}/:ticketId`}
        component={ParkCarSummaryPage}
      />
      <Route
        path={`${paths.parking.paid}/:ticketId`}
        component={ParkCarSummarySuccessPage}
      />
      <Route
        path={`${paths.parking.contactInformations}/:carId`}
        component={ContactInformationsPage}
      />
      <Route path={`${paths.parking.login}/:carId`} component={LoginRedirect} />
      <Route
        path={`${paths.parking.forgotPassword}/:carId`}
        component={ForgotPasswordRedirect}
      />
      <Route
        path={`${paths.parking.signUp}/:carId`}
        component={SignUpRedirect}
      />
      <Route
        path={`${paths.parker.receiptsAndNotifications}/:carId`}
        component={ReceiptsAndNotificationsPage}
      />
      <Route path={paths.parking.tickets} component={TicketsGuest} />
      <Route exact strict path={`/:pcode`} component={PcodeSignIn} />
      <Redirect to={paths.home.map} />
    </Switch>
  )
}

export default SignedInAsGuestRoutes
