import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { ticketsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'

export const useUpdateTicket = () => {
  const dispatch = useAppDispatch()
  const ticketsRef = React.useRef([])
  const tickets = useSelector(ticketsSlice.selectSortedParkedTickets)

  React.useEffect(() => {
    ticketsRef.current = tickets
  }, [tickets])
  React.useEffect(() => {
    const timer = setInterval(() => {
      ticketsRef.current.forEach(el => {
        const isExpired =
          Date.now() - el.refCurrentTime >
          +moment(el.parkingExpireDateTime) - +moment(el.currentDateTime)
        if (isExpired) {
          dispatch(
            ticketsSlice.getTicket({
              carId: el.carId,
            })
          )
        }
      })
    }, 10000)

    return () => {
      clearInterval(timer)
    }
  }, [dispatch])
}

export default useUpdateTicket
