import * as React from 'react'
import AppTypography from 'shared/AppTypography'
import styled from 'styled-components'
import infoIcon from '../../assets/icons/info_gray.svg'

interface InformationTextProps {
  text: string
}

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0px;
`

const InfoIcon = styled.img`
  width: 30px;
  padding: 0px 10px;
`

const InformationText: React.FunctionComponent<InformationTextProps> = props => {
  return (
    <InfoContainer>
      <InfoIcon src={infoIcon} />
      <AppTypography disabled>{props.text}</AppTypography>
    </InfoContainer>
  )
}

export default InformationText
