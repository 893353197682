import { ThemeProvider } from '@mui/material/styles'

import Interweave, { Node } from 'interweave'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  AppSpinner,
  AppTypography,
  FlexBox,
  FullLayout,
  PaddingContainer,
  PageDialog,
  PageHeading,
  SharedIcon,
  Topbar,
} from 'shared'
import { closeTerms } from 'store/globalPopup/actions'
import { termsSlice } from 'store/slices'
import { AppState, useAppDispatch } from 'store/store'
import styled from 'styled-components'
import { whiteTheme } from 'themes'
import Translations from '../../../utils/translations'

const StyledFlexBox = styled(FlexBox)`
  text-align: left;
  .headerLocal {
    font-weight: bold;
  }
`
const LogoContainer = styled.div`
  background: #fff;
  border-radius: 14px;
  margin: auto;
  padding: 20px 10px;
  box-sizing: border-box;
  max-height: 150px;
  width: max-content;
  min-width: 220px;
  img {
    height: 100%;
    width: auto;
    box-sizing: border-box;
    max-width: 100%;
    max-height: 110px;
  }
`

interface TermsAndConditionsProps {}

function transform(node: HTMLElement, children: Node[]): React.ReactNode {
  if (node.tagName === 'B' || node.tagName === 'STRONG') {
    return (
      <AppTypography weight="bold" component="span">
        {children}
      </AppTypography>
    )
  }
  if (node.tagName === 'P') {
    return <AppTypography component="p">{children}</AppTypography>
  }
}

const TermsAndConditions = (props: TermsAndConditionsProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { terms, logoLink } = useSelector(termsSlice.stateSelectors)
  const { pending } = useSelector(
    termsSlice.promiseStatusSelectors
  ).getTermstatus
  const [imageError, setImageError] = React.useState(false)

  const content = () => {
    if (!terms?.text) return ''
    return terms?.text
  }
  const renderStandardHeading = () => (
    <PageHeading title={t(Translations.TERMS_AND_CONDITIONS)}>
      <div>
        <SharedIcon.Terms />
      </div>
    </PageHeading>
  )

  const renderLotHeading = () => (
    <PageHeading title={t(Translations.TERMS_AND_CONDITIONS)} hasLogo={false}>
      <LogoContainer>
        <img src={logoLink} onError={() => setImageError(true)} alt="logo" />
      </LogoContainer>
    </PageHeading>
  )

  React.useEffect(
    () => () => {
      dispatch(termsSlice.actions.setLogoLink(''))
    },
    [dispatch]
  )

  return (
    <ThemeProvider theme={whiteTheme}>
      <Topbar onClickBack={() => dispatch(closeTerms())} transparent />
      <FullLayout hasTopBar imageBackground innerScroll>
        <PaddingContainer>
          {!imageError ? renderLotHeading() : renderStandardHeading()}
        </PaddingContainer>
        <PaddingContainer padding={2}>
          <StyledFlexBox hasTopMargin>
            {pending ? (
              <AppSpinner relative show={true} height="100px" />
            ) : (
              <AppTypography color="textPrimary" align="left" component="span">
                <Interweave content={content()} transform={transform} />
              </AppTypography>
            )}
          </StyledFlexBox>
        </PaddingContainer>
      </FullLayout>
    </ThemeProvider>
  )
}

const TermsDialog = () => {
  const showTerms = useSelector((state: AppState) => state.popup.showTerms)
  const dispatch = useAppDispatch()
  window.onkeydown = function (e: any) {
    if (e.keyCode == 8 && showTerms) {
      e.preventDefault()
      dispatch(closeTerms())
    }
  }

  return (
    <PageDialog open={showTerms} paddingBottom={'50px'} center={false}>
      <TermsAndConditions />
    </PageDialog>
  )
}

export default TermsDialog
