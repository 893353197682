import { Form, Formik } from 'formik'
import { useChangeLayout, usePushToApp } from 'hooks'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import paths from 'routing/paths'
import {
  AppButton,
  FlexBox,
  Input,
  PaddingContainer,
  PageHeading,
  SharedIcon,
} from 'shared'
import PhoneNumberContainer from 'shared/sidebar/SignUpContainer'
import { showTerms } from 'store/globalPopup/actions'
import { hideLoader, showLoader } from 'store/loader'
import { termsSlice } from 'store/slices'
import { login } from 'store/slices/customerSlice'
import { useAppDispatch } from 'store/store'
import styled from 'styled-components'
import { LoginRequest, UserRole } from 'typedef/customer'
import { TermsType } from 'typedef/terms'
import { countryCodeRegex, phoneNumberRegex } from 'utils/regex'
import { Translations, blurLastInput } from 'utils'
import * as Yup from 'yup'
import NewPhoneNumberInput from 'shared/PhoneNumberAndCountryCodeInput'
import { Typography } from '@mui/material'
import SelectLanguageDialog from 'pages/select_language_dialog/SelectLanguageDialog'
import { languageList } from 'pages/select_language_dialog/language'

const ForgotButtonWrapper = styled.div`
  text-align: right;
`

const Login: React.FC = props => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const pushToApp = usePushToApp()
  useChangeLayout()
  const { enqueueSnackbar } = useSnackbar()
  const [isLanguageDialogOpen, setIsLanguageDialogOpen] =
    React.useState<boolean>(false)

  const LoginSchema = Yup.object().shape({
    password: Yup.string().required(t(Translations.PASSWORD_CANNOT_BE_EMPTY)),
    countryCode: Yup.string().matches(
      countryCodeRegex,
      t(Translations.INVALID_COUNTRY_CODE)
    ),
    phoneNumber: Yup.string()
      .matches(phoneNumberRegex, t(Translations.PHONE_CANNOT_BE_EMPTY))
      .required(t(Translations.FIELD_REQUIRED)),
  })

  const loginUser = async (args: LoginRequest) => {
    dispatch(showLoader())
    const actionResult = await dispatch(login(args))
    dispatch(hideLoader())
    if (login.rejected.match(actionResult)) {
      enqueueSnackbar(t(Translations.WRONG_LOGIN_OR_PASSWORD))
      return
    }
    pushToApp()
  }

  const loginGuest = async () => {
    dispatch(showLoader())
    const actionResult = await dispatch(login({ mode: UserRole.Guest }))
    dispatch(hideLoader())
    if (login.rejected.match(actionResult)) {
      enqueueSnackbar(t(Translations.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN))
    } else {
      pushToApp(UserRole.Guest)
    }
  }

  const onTermsClick = () => {
    dispatch(
      termsSlice.getTerms({
        type: TermsType.Login,
      })
    )
    dispatch(showTerms())
  }
  React.useEffect(() => {
    dispatch(termsSlice.actions.setLang({ lang: i18n.language }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getLanguageName = () => {
    const language = languageList.find(l =>
      l.abbreviation.includes(i18n.resolvedLanguage)
    )
    if (language) {
      return t(language.name)
    }
    return ''
  }

  return (
    <PaddingContainer>
      <PageHeading title={t(Translations.PARKING_MADE_EASY)} />
      <Formik
        initialValues={{ phoneNumber: '', password: '', countryCode: '' }}
        onSubmit={({ phoneNumber, password, countryCode }) => {
          loginUser({
            phoneNumber: parseInt(phoneNumber),
            password,
            countryCode: parseInt(countryCode),
            mode: UserRole.User,
          })
        }}
        validationSchema={LoginSchema}
        validateOnBlur
      >
        {({ values, errors, handleChange, handleBlur, validateForm }) => (
          <Form>
            <PhoneNumberContainer>
              <NewPhoneNumberInput
                value={`${values.countryCode}${values.phoneNumber}`}
                countryCodeFieldName="countryCode"
                phoneNumberFieldName="phoneNumber"
                errors={errors.phoneNumber}
              />
            </PhoneNumberContainer>
            <Input
              name="password"
              autoComplete="current-password"
              placeholder={t(Translations.PASSWORD)}
              type="password"
              inputMode="text"
              value={values.password}
              error={!!errors.password}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={e => {
                blurLastInput(e)
              }}
            />
            <ForgotButtonWrapper>
              <AppButton
                type="button"
                onClick={() => {
                  history.push(paths.auth.forgotPassword)
                }}
              >
                {t(Translations.FORGOT_PASSWORD)}?
              </AppButton>
            </ForgotButtonWrapper>
            <FlexBox hasBottomMargin hasTopMargin>
              <AppButton
                variant="contained"
                type="submit"
                onClick={() => {
                  validateForm().then(errors => {
                    const entries = Object.entries(errors)
                    if (entries.length !== 0) {
                      entries.forEach(el => {
                        enqueueSnackbar(el[1])
                      })
                    }
                  })
                }}
              >
                {t(Translations.LOG_IN)}
              </AppButton>
              <AppButton
                variant="outlined"
                onClick={loginGuest}
                data-testid="logInAsGuest"
              >
                {t(Translations.LOG_IN_AS_GUEST)}
              </AppButton>
              <AppButton
                variant="outlined"
                onClick={() => {
                  history.push(paths.auth.signUp)
                }}
              >
                {t(Translations.SIGN_UP)}
              </AppButton>
              <AppButton
                letterCase="none"
                hasHoverBg={false}
                variant="outlined"
                onClick={() => setIsLanguageDialogOpen(true)}
              >
                <SharedIcon.LanguageIcon />
                <Typography
                  style={{
                    fontWeight: 600,
                    marginLeft: '7px',
                    fontSize: '14px',
                  }}
                >
                  {getLanguageName()}
                </Typography>
              </AppButton>
            </FlexBox>
          </Form>
        )}
      </Formik>
      <FlexBox>
        <AppButton letterCase="none" hasHoverBg={false} onClick={onTermsClick}>
          {t(Translations.TERMS_AND_CONDITIONS)}
        </AppButton>
      </FlexBox>
      <SelectLanguageDialog
        closePopup={() => setIsLanguageDialogOpen(false)}
        open={isLanguageDialogOpen}
      />
    </PaddingContainer>
  )
}

export default Login
