import { FormikErrors } from 'formik'
import i18n from 'i18next'
import { ErrorCode } from 'typedef/api'
import { Popup } from 'typedef/popup'
import Translations from 'utils/translations'

export const handleFormikErrors = (
  errors: FormikErrors<Record<any, any>>,
  handleValid: () => void,
  handleInvalid: (arg: string) => void
) => {
  const entries = Object.entries(errors)
  if (entries.length === 0) {
    handleValid()
  } else {
    entries.forEach(el => {
      if (typeof el[1] === 'string') {
        handleInvalid(el[1])
      }
    })
  }
}
/**
 * @deprecated
 * */
type GetErrorPopupProps = (e: ErrorCode) => Popup
export const getErrorPopupProps: GetErrorPopupProps = errorCode => {
  const popupProps: Popup = {
    title: i18n.t(Translations.OOPS),
    description: i18n.t(Translations.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN),
    variant: 'warning' as const,
  }

  return popupProps
}
