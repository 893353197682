import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Placeholder, PlaceholderProps, SharedIcon } from 'shared'
import Translations from 'utils/translations'
import { MyReceiptsProps } from './MyReceipts'

export const MyReceiptsPlaceholder: React.FunctionComponent<
  MyReceiptsProps & PlaceholderProps
> = props => {
  const { t } = useTranslation()

  return (
    <Placeholder
      title={t(Translations.NO_RECEIPTS_FOUND)}
      icon={<SharedIcon.Receipt />}
      {...props}
    />
  )
}
export default MyReceiptsPlaceholder
