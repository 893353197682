import { Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { formatCurrency, Translations } from 'utils'
import AppTypography from './AppTypography'
import { Divider as MUIDivider } from '@mui/material'
import { Currency } from 'typedef'
import { SharedIcon } from 'shared'

export const Divider = styled(MUIDivider)<{
  topGap?: boolean
  bottomGap?: boolean
  width?: string
}>`
  width: ${_ => _.width};
`
Divider.defaultProps = {
  width: 'auto',
}
export const Wrapper = styled.div<{ invalid?: boolean; minHeight?: number }>`
  background: ${_ =>
    !_.invalid ? _.theme.palette.grey[100] : _.theme.palette.secondary.light};
  padding: ${_ => _.theme.spacing(1)};
  min-height: ${_ => _.minHeight}px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
`
Wrapper.defaultProps = {
  minHeight: 0,
}
export const InnerRow = styled.div<{
  noBottomPadding?: boolean
  noTopPadding?: boolean
}>`
  padding: ${_ => _.theme.spacing(1)} 0;
  ${_ =>
    _.noBottomPadding &&
    css`
      padding-bottom: 0;
    `}
  ${_ =>
    _.noTopPadding &&
    css`
      padding-top: 0;
    `}
`

const StyledTimeRow = styled.div<{ invalid?: boolean }>`
  padding: ${_ => _.theme.spacing(1)} 0;
  border-width: 1px 0 0 0;
  text-align: left;
  color: ${_ => (!_.invalid ? 'inherit' : _.theme.palette.secondary.main)};
`

const TimeCell = styled.div`
  p {
    display: inline-block;
    font-weight: bold;
  }
  p:first-of-type {
    font-size: 1.5rem;
    margin-right: ${_ => _.theme.spacing(1)};
  }
`

export const TimeRow: React.FC<{ time?: string | Date; invalid?: boolean }> = ({
  time,
  invalid,
}) => {
  const { t } = useTranslation()

  return (
    <StyledTimeRow>
      <Typography color={invalid ? 'error' : 'textSecondary'} variant="body2">
        {invalid
          ? t(Translations.PARKING_EXPIRED_AT)
          : t(Translations.PARKING_EXPIRES_AT)}
      </Typography>
      <TimeCell>
        <Typography color={invalid ? 'error' : 'textPrimary'}>
          {time ? moment(time).startOf('minute').format('LT') : '-'}
        </Typography>
        <Typography color={invalid ? 'error' : 'textPrimary'}>
          {time ? moment(time).startOf('minute').format('MMM DD') : ''}
        </Typography>
      </TimeCell>
    </StyledTimeRow>
  )
}

export const TotalParkingTimeRow: React.FC<{
  durationText: string
}> = props => {
  const { t } = useTranslation()

  return (
    <StyledTimeRow>
      <Typography color="textSecondary" variant="body2">
        {t(Translations.TOTAL_PARKING_TIME)}:
      </Typography>
      <TimeCell>
        <Typography>{props.durationText}</Typography>
      </TimeCell>
    </StyledTimeRow>
  )
}

const StyledTextRow = styled.div`
  display: flex;

  > *:nth-of-type(2) {
    margin-left: auto;
  }
`
export const TextRow: React.FC<{
  leftText?: string
  rightText?: string
}> = props => {
  return (
    <>
      <StyledTextRow>
        {props.leftText && (
          <AppTypography color="textSecondary" gutterBottom>
            {props.leftText}
          </AppTypography>
        )}
        {props.rightText && (
          <AppTypography gutterBottom>{props.rightText}</AppTypography>
        )}
      </StyledTextRow>
    </>
  )
}

export const PriceRow: React.FC<{
  label?: string
  translation?: keyof typeof Translations
  amount?: number
  currency?: Currency
  negativeAmount?: boolean
}> = ({ amount, currency, label, translation, negativeAmount }) => {
  const { t } = useTranslation()
  const [text, setText] = React.useState('')
  React.useEffect(() => {
    if (typeof translation === 'string') {
      setText(t(Translations[translation]))
    }
    if (typeof label === 'string') {
      setText(label)
    }
    if (typeof translation !== 'string' && typeof label !== 'string') {
      setText('-')
    }
  }, [label, translation, t])

  if (!amount) return null
  return (
    <TextRow
      leftText={text}
      rightText={
        negativeAmount
          ? `(${formatCurrency(currency, amount)})`
          : formatCurrency(currency, amount)
      }
    />
  )
}
PriceRow.defaultProps = {
  currency: {
    cultureName: 'en_US',
    currencyFormat: null,
    currencySymbol: null,
  },
  amount: 0,
}

const StyledIconsRow = styled.div`
  padding: ${_ => _.theme.spacing(1)} 0;
  display: flex;
  div:first-of-type {
    margin-right: ${_ => _.theme.spacing(1)};
  }
`
const IconText = styled.div`
  display: flex;
  align-items: center;
`
export const IconsRow: React.FC<{
  plate?: string
  spot?: string
}> = props => {
  return (
    <StyledIconsRow>
      {props.plate && (
        <IconText>
          <SharedIcon.Car />
          <AppTypography paddingLeft={1}>
            {props.plate.toUpperCase()}
          </AppTypography>
        </IconText>
      )}
      {props.spot && (
        <IconText>
          <SharedIcon.Parking />
          <AppTypography>{props.spot}</AppTypography>
        </IconText>
      )}
    </StyledIconsRow>
  )
}
