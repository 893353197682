import React from 'react'
import { useSelector } from 'react-redux'
import { parkCarSlice, creditCardsSlice } from 'store/slices'

export const useSelectedCardIndex = () => {
  const [initSlide, setInitSlide] = React.useState(0)
  const selectedCard = useSelector(parkCarSlice.selectCardId)
  const cards = useSelector(creditCardsSlice.selectAll)

  React.useLayoutEffect(() => {
    if (selectedCard && cards.length) {
      const selectedCardIndex = cards.findIndex(
        el => el.creditCardId === selectedCard?.creditCardId
      )
      if (selectedCardIndex !== -1) {
        setInitSlide(selectedCardIndex)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return initSlide
}

export default useSelectedCardIndex
