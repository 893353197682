import React from 'react'
import { WhiteBoxContainer } from 'shared'
import { PricingList } from '.'
import { useT2Counter } from '../T2Hooks'

export interface FullListProps {
  onClick: () => void
}

export const FullList: React.FC<FullListProps> = ({ onClick }) => {
  useT2Counter()
  return (
    <WhiteBoxContainer>
      <PricingList onSelect={onClick} />
    </WhiteBoxContainer>
  )
}

export default FullList
