import T from 'utils/translations'
import { ErrorCode, ErrorResponse } from 'typedef'

export const getValidators: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.SOME_DATA_HAS_NOT_BEEN_UPDATED,
    variant: 'warning',
  },
}
export const validateCode: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.THE_TICKET_CANNOT_BE_VALIDATED_AT_THE_MOMENT,
    variant: 'warning',
  },
}

export const validatorsApiError = { getValidators, validateCode }
export default validatorsApiError
