import { useFormikContext } from 'formik'
import { useUpdateLayout } from 'hooks'
import { useParkCarPath } from 'pages/park_car/ParkCarHooks'
import { T2Fields } from 'pages/park_car/ParkCarT2'
import React from 'react'
import { useTranslation } from 'react-i18next/'
import { Translations } from 'utils'
import { ExcerptList, FullList } from '../T2Components'
export interface ParkCarT2SelectPricingProps {}

export const ParkCarT2SelectPricing: React.FC<ParkCarT2SelectPricingProps> = props => {
  const { values, setFieldValue } = useFormikContext<T2Fields>()
  const { pricings, lastListView } = values
  const { t } = useTranslation()
  const updateLayout = useUpdateLayout(
    {
      topbar: {
        title: t(Translations.PRICING_LIST),
        onClickBack: () =>
          lastListView === 'full'
            ? setFieldValue('lastListView', 'excerpt')
            : goTo('set'),
      },
    },
    [lastListView]
  )
  const [, goTo] = useParkCarPath()
  const setFullList = () => setFieldValue('lastListView', 'full')

  const onSubmit = () => {
    updateLayout({
      topbar: {
        onClickBack: () => goTo('select'),
      },
    })
    goTo('summary')
  }

  React.useEffect(() => {
    if (pricings.length === 1) {
      setFieldValue('selectedPricing', pricings[0].externalPricingId)
    }
  }, [pricings, setFieldValue])

  if (!pricings.length) {
    goTo('set')
    return null
  }

  return lastListView === 'full' ? (
    <FullList onClick={onSubmit} />
  ) : (
    <ExcerptList onClickMore={setFullList} onClickSubmit={onSubmit} />
  )
}

export default ParkCarT2SelectPricing
