import { useChangeLayout } from 'hooks'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { batch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import { AppSnackBarProvider } from 'shared'
import { receiptsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import Translations from 'utils/translations'
import MyReceiptsContainer from './MyReceiptsContainer'

const MyReceiptsView: React.FC = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const title = t(Translations.MY_RECEIPTS)
  const dispatch = useAppDispatch()

  useChangeLayout(
    {
      topbar: {
        title,
        onClickBack: () => history.push(paths.parker.myProfile),
      },
    },
    'app'
  )
  React.useEffect(() => {
    batch(() => {
      dispatch(receiptsSlice.actions.resetFinalSendReceiptStatus())
      dispatch(receiptsSlice.actions.resetFinalSendQuestionStatus())
    })
    return () =>
      batch(() => {
        dispatch(receiptsSlice.actions.resetFinalSendReceiptStatus())
        dispatch(receiptsSlice.actions.resetFinalSendQuestionStatus())
      })
  }, [dispatch])

  return (
    <AppSnackBarProvider hasBottomGutter>
      <MyReceiptsContainer />
    </AppSnackBarProvider>
  )
}

export default MyReceiptsView
