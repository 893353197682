import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AppButton,
  AppSpinner,
  AppTypography,
  FlexBox,
  SharedIcon,
} from 'shared'
import Translations from 'utils/translations'

interface ErrorPlaceholderProps {
  onClick?: () => void
  showSpinner?: boolean
  title?: string
  description?: string
  buttonTitle?: string
}
export const ErrorPlaceholder: React.FC<ErrorPlaceholderProps> = ({
  onClick = () => undefined,
  showSpinner,
  title,
  description,
  buttonTitle,
}) => {
  const { t } = useTranslation()

  return (
    <FlexBox>
      <div data-testid="warning">
        <SharedIcon.Warning />
        {showSpinner && <AppSpinner show />}
      </div>
      <AppTypography weight="bold" align="center">
        {title ? title : t(Translations.OOPS)}
      </AppTypography>
      <AppTypography>
        {description ? description : t(Translations.FAILED_PROPERLY_RETRIEVE)}
      </AppTypography>
      <AppButton color="primary" variant="outlined" onClick={onClick}>
        {buttonTitle ? buttonTitle : t(Translations.REFRESH)}
      </AppButton>
    </FlexBox>
  )
}

ErrorPlaceholder.defaultProps = {
  onClick: () => undefined,
}

export default ErrorPlaceholder
