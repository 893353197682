import React from 'react'
import { batch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import paths from 'routing/paths'
import { mapSlice, ticketsSlice } from 'store/slices'
import { AppState, useAppDispatch } from 'store/store'
import { CarStatus } from 'typedef'

export const useTicketActions = (carId: string) => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const ticket = useSelector((state: AppState) =>
    ticketsSlice.selectById(state, carId)
  )
  const onClickValidate = React.useCallback(() => {
    push(`${paths.parking.validators}/${ticket.carId}`)
  }, [push, ticket.carId])

  const onClickReceipt = () =>
    push(`${paths.parker.sendReceipt}/${ticket.carId}`)

  const onClickFindCar = React.useCallback(
    (lotId: string) => {
      batch(() => {
        dispatch(mapSlice.actions.selectLot({ lotId }))
        dispatch(mapSlice.getLotDetailsMap({ lotId }))
        dispatch(mapSlice.actions.foundParkedCar({ lotId }))
      })
      push(paths.home.map)
    },
    [dispatch, push]
  )

  const hasLotGeolocation = () =>
    Boolean(
      ticket.lot.geolocation?.latitude && ticket.lot.geolocation?.longitude
    )

  const isTicketExpired = () => ticket.carStatus === CarStatus.ParkingHasExpired

  return {
    onClickValidate,
    onClickReceipt,
    onClickFindCar,
    hasLotGeolocation: hasLotGeolocation(),
    isExpired: isTicketExpired(),
  }
}

export default useTicketActions
