import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FullLayoutProps } from 'shared/layout/FullLayout'
import { TopbarProps } from 'shared/Topbar'
import { RootState } from 'store/rootReducer'
import { LAYOUT } from './constants'
import bg from 'assets/images/bg.png'

const defaultCustomStyles = {
  headerBgUrl: bg,
  cardBgUrl: '',
}
export interface LayoutState {
  layout?: FullLayoutProps
  topbar?: TopbarProps
  bottomMenu?: boolean
  autoToogleSnackGap?: boolean
  height?: number
  loader?: boolean
  innerWidth?: number
  customStyles: {
    headerBgUrl: string
    cardBgUrl: string
  }
}
export type LayoutStateWithoutCustomStyles = Omit<LayoutState, 'customStyles'>
export const appLayoutProps: LayoutStateWithoutCustomStyles = {
  layout: {
    hasFlatBackground: true,
    hasTopBar: true,
    hasBottomBar: true,
    hasSnackbar: false,
  },
  autoToogleSnackGap: true,
  topbar: {},
  bottomMenu: true,
}
export const authLayoutProps: LayoutStateWithoutCustomStyles = {
  layout: {
    hasTopBar: true,
    hasBottomBar: true,
    narrow: true,
  },
  bottomMenu: false,
  topbar: null,
}
/**
 * state
 */
const initialState: LayoutState = {
  layout: {
    hasTopBar: true,
    hasBottomBar: true,
    narrow: true,
  },
  autoToogleSnackGap: false,
  bottomMenu: false,
  topbar: null,
  height: window.innerHeight,
  loader: false,
  innerWidth: 0,
  customStyles: defaultCustomStyles,
}

/**
 * reducers
 */
const layoutSlice = createSlice({
  name: LAYOUT,
  initialState,
  reducers: {
    setLayout(state, { payload }: PayloadAction<FullLayoutProps>) {
      state.layout = payload
    },
    setTopbar(state, { payload }: PayloadAction<TopbarProps>) {
      state.topbar = payload
    },
    setBottomMenu(state, { payload }: PayloadAction<boolean>) {
      state.bottomMenu = payload
    },
    setHeight(state, { payload }: PayloadAction<number>) {
      state.height = payload
    },
    setCenter(state, { payload }: PayloadAction<boolean>) {
      state.layout.center = payload
    },
    setSnackbarGutter(state, { payload }: PayloadAction<boolean>) {
      state.layout.hasSnackbar = payload
    },
    setInnerWidth(state, { payload }: PayloadAction<number>) {
      state.innerWidth = payload
    },
    // setAd(state, { payload }: PayloadAction<boolean>) {
    //   state.ad = payload
    // },
    setFullLayout(
      state,
      { payload }: PayloadAction<LayoutStateWithoutCustomStyles>
    ) {
      state.layout = payload?.layout ? payload?.layout : {}
      state.topbar = payload.topbar
      state.bottomMenu = payload.bottomMenu
      state.autoToogleSnackGap = payload.autoToogleSnackGap
    },
    setAuthLayout(state) {
      state.layout = authLayoutProps.layout
      state.topbar = authLayoutProps.topbar
      state.bottomMenu = authLayoutProps.bottomMenu
      state.autoToogleSnackGap = authLayoutProps.autoToogleSnackGap
    },
    setAppLayout(state) {
      state.layout = appLayoutProps.layout
      state.topbar = appLayoutProps.topbar
      state.bottomMenu = appLayoutProps.bottomMenu
      state.autoToogleSnackGap = appLayoutProps.autoToogleSnackGap
    },
    setLoader(state, { payload }: PayloadAction<Partial<boolean>>) {
      const loader = typeof payload === 'boolean' ? payload : !state.loader
      state.loader = loader
    },
    setCustomStyles(
      state,
      { payload }: PayloadAction<Partial<typeof defaultCustomStyles>>
    ) {
      state.customStyles = {
        cardBgUrl: payload?.cardBgUrl || defaultCustomStyles.cardBgUrl,
        headerBgUrl: payload?.headerBgUrl || defaultCustomStyles.headerBgUrl,
      }
    },
  },
})

export default layoutSlice.reducer
export const { actions } = layoutSlice

/**
 * selectors
 */
export const stateSelectors = (state: RootState) => state.layout
