import {
  ChangePasswordRequest,
  ConfirmRegisterData,
  ConfirmRegisterParams,
  CustomerDetails,
  EditCustomerParams,
  FindCarData,
  FindCarPayload,
  LoginRequest,
  LoginResult,
  PhoneNumberAvailableParams,
  PhoneNumberAvailableResult,
  RefreshTokenRequest,
  RefreshTokenResult,
  RegisterParams,
  RegisterResult,
  RemindPasswordParams,
  ResendRegistrationPinParams,
  ResetPasswordRequest,
  ResetPasswordResult,
} from 'typedef/customer'
import axios from '.'
import { Response } from '../typedef/api'
import {
  CUSTOMER_CHANGE_PASSWORD,
  CUSTOMER_CONFIRM_REGISTER,
  CUSTOMER_DELETE,
  CUSTOMER_EDIT,
  CUSTOMER_FIND_MY_CAR,
  CUSTOMER_GET_DETAILS,
  CUSTOMER_LOGIN,
  CUSTOMER_PHONE_NO_IS_AVAILABLE,
  CUSTOMER_REFRESH_TOKEN,
  CUSTOMER_REGISTER,
  CUSTOMER_REMIND_PASSWORD,
  CUSTOMER_RESEND_REGISTRATION_PIN,
  CUSTOMER_RESET_PASSWORD,
} from './constants'
import './mocks/CustomerApiMock'
import { getErrorCode } from './utils'

export const login = async (params: LoginRequest) => {
  try {
    const response = await axios.post<Response<LoginResult>>(
      CUSTOMER_LOGIN,
      params
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}

export const getCustomerDetails = async () => {
  try {
    const axiosResponse = await axios.get(CUSTOMER_GET_DETAILS)
    const response = axiosResponse.data as Response<CustomerDetails>
    return response.data
  } catch (err) {
    throw err
  }
}

export const refreshToken = async (args: RefreshTokenRequest) => {
  try {
    const response = await axios.post<Response<RefreshTokenResult>>(
      CUSTOMER_REFRESH_TOKEN,
      {},
      {
        headers: { Authorization: `Bearer ${args.token}` },
      }
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}

export const editCustomer = async (params: EditCustomerParams) => {
  try {
    await axios.put(CUSTOMER_EDIT, params)
    return true
  } catch (err) {
    throw err
  }
}

export const changePassword = async (args: ChangePasswordRequest) => {
  try {
    await axios.put(CUSTOMER_CHANGE_PASSWORD, args)
    return true
  } catch (err) {
    throw err
  }
}

export const deleteAccount = async (password: string): Promise<boolean> => {
  try {
    await axios.delete(CUSTOMER_DELETE, {
      data: {
        password,
      },
    })
    return true
  } catch (err) {
    throw err
  }
}

export const remindPassword = async (args: RemindPasswordParams) => {
  try {
    await axios.post(CUSTOMER_REMIND_PASSWORD, args)
    return true
  } catch (err) {
    throw err
  }
}

export const resetPassword = async (args: ResetPasswordRequest) => {
  try {
    const response = await axios.post<Response<ResetPasswordResult>>(
      CUSTOMER_RESET_PASSWORD,
      args
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}

export const register = async (params: RegisterParams) => {
  try {
    const response = await axios.post<Response<RegisterResult>>(
      CUSTOMER_REGISTER,
      params
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}

export const confirmRegister = async (params: ConfirmRegisterParams) => {
  try {
    const response = await axios.put<Response<ConfirmRegisterData>>(
      CUSTOMER_CONFIRM_REGISTER,
      params
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}

// TODO:
export const phoneNumberAvailable = async (
  params: PhoneNumberAvailableParams
) => {
  try {
    const response = await axios.get<Response<PhoneNumberAvailableResult>>(
      CUSTOMER_PHONE_NO_IS_AVAILABLE,
      {
        params,
      }
    )
    return response.data.data
  } catch (err) {
    return getErrorCode(err)
  }
}

export const resendRegistrationPin = async (
  params: ResendRegistrationPinParams
): Promise<boolean> => {
  try {
    await axios.post(CUSTOMER_RESEND_REGISTRATION_PIN, params)
    return true
  } catch {
    return false
  }
}

export const findCar = async (params: FindCarPayload) =>
  axios
    .get<Response<FindCarData>>(CUSTOMER_FIND_MY_CAR, { params })
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      throw err
    })
