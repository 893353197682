import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  CircularProgress,
  Icon,
} from '@mui/material'
import * as React from 'react'
import { SharedIcon } from 'shared'
import styled from 'styled-components'
import { CardType, CreditCard } from '../../../typedef/creditCards'
import { creditCardsSlice } from 'store/slices'
import { getCreditCardIcon } from '../../../utils/creditCardTypeMapper'
import { useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'

interface CardBarProps {
  card: CreditCard
  handleDeleteClick?: () => void
  noRightAction?: boolean
}

const CardIcon = styled.img`
  width: 45px;
  margin: 0 15px 0 0;
`

const CreditCardBar: React.FunctionComponent<CardBarProps> = ({
  card,
  handleDeleteClick,
  noRightAction,
  ...props
}) => {
  const cardIcon = getCreditCardIcon(card.cardType)
  const status = useSelector((state: RootState) =>
    creditCardsSlice.promiseStatusSelectors(state, card.creditCardId)
  ).removeCreditCardStatus

  return (
    <ListItem data-testid="creditCardBar" {...props}>
      <CardIcon src={cardIcon}></CardIcon>
      <ListItemText
        primary={`* ${card.last4Digits}`}
        secondary={CardType[card.cardType]}
      />
      {!noRightAction && (
        <ListItemSecondaryAction>
          {status.pending ? (
            <Icon style={{ paddingTop: '6px', paddingRight: '6px' }}>
              <CircularProgress size={24} />
            </Icon>
          ) : (
            <IconButton
              edge="end"
              aria-label="delete"
              data-testid="deleteIcon"
              onClick={handleDeleteClick}
            >
              {status.rejected && (
                <SharedIcon.RefreshBroken style={{ padding: '3px' }} />
              )}
              {status.idle && <SharedIcon.Delete />}
            </IconButton>
          )}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}
CreditCardBar.defaultProps = {
  handleDeleteClick: () => undefined,
}
export default CreditCardBar
