import {
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { DialogProps } from '@mui/material'
import React from 'react'
import { AppDialog, AppTypography } from 'shared'
import styled from 'styled-components'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { languageList } from './language'
import { useTranslation } from 'react-i18next'
import { Translations } from 'utils'

const CloseIconBox = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledListItem = styled(ListItem)`
  padding-left: 0px;
`

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(0),
    fontWeight: 'bold',
    color: 'black',
  },
}))

type PopupProps = {
  closePopup: () => void
  addCloseButton?: boolean
  darkBackground?: boolean
} & DialogProps

export const SelectLanguageDialog: React.FC<PopupProps> = props => {
  const { addCloseButton, closePopup, darkBackground, ...dialogProps } = props
  const { i18n } = useTranslation()
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <AppDialog
      onClose={closePopup}
      {...dialogProps}
      darkBackground={darkBackground}
    >
      {addCloseButton && (
        <CloseIconBox>
          <CloseIcon color="disabled" onClick={() => closePopup()} />
        </CloseIconBox>
      )}
      <DialogTitle data-testid="popupTitle" classes={{ root: classes.title }}>
        {t(Translations.SELECT_LANGUAGE)}
      </DialogTitle>
      <List>
        {languageList.map(lang => {
          return (
            <>
              <StyledListItem
                key={lang.abbreviation}
                onClick={() => {
                  i18n.changeLanguage(lang.abbreviation)
                  closePopup()
                }}
              >
                <ListItemIcon>{lang.flag}</ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <AppTypography
                      color="black"
                      style={{
                        fontWeight: lang.abbreviation.includes(
                          i18n.resolvedLanguage
                        )
                          ? 'bold'
                          : 'normal',
                      }}
                    >
                      {t(lang.name)}
                    </AppTypography>
                  }
                />
              </StyledListItem>
              <Divider />
            </>
          )
        })}
      </List>
    </AppDialog>
  )
}

export default SelectLanguageDialog
