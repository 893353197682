import { useFormikContext } from 'formik'
import { T2Fields } from 'pages/park_car/ParkCarT2'
import { SpotField } from 'pages/park_car/ParkCarComponents'
import React from 'react'
import { useSelector } from 'react-redux'
import { parkCarSlice } from 'store/slices'

export interface LicenseSpotProps {
  disabled?: boolean
  defaultValue?: string
}

export const Spot: React.FC<LicenseSpotProps> = props => {
  const { lot } = useSelector(parkCarSlice.stateSelectors)
  const {
    handleChange,
    handleBlur,
    errors,
    values,
  } = useFormikContext<T2Fields>()

  return (
    <SpotField
      onBlur={handleBlur}
      onChange={handleChange}
      disabled={props.disabled}
      value={values.spotNumber}
      error={Boolean(errors['spotNumber'])}
      maxSpotNumber={lot?.spot?.maxSpotNumber}
      // handleChange={handleChange}
      // spotError={!!errors.spotNumber}
    />
  )
}

export default Spot
