import { SvgIcon } from '@mui/material'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { T2Fields } from 'pages/park_car/ParkCarT2'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, FlexBox } from 'shared'
import styled, { css } from 'styled-components'
import Translations from 'utils/translations'
export interface SelectDefaultTimeProps {}

const SelectedTick: React.FC<{ color?: boolean }> = ({ color }) => (
  <SvgIcon>
    <svg
      width="24"
      height="24"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 3L5 7L11 1"
        stroke={color ? '#32A3F5' : 'transparent'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
)
const StyledButton = styled(AppButton)<{ $noColor?: boolean }>`
  font-size: 16px;
  padding-left: 2px;
  padding-right: 2px;
  ${_ =>
    _.$noColor &&
    css`
      color: black;
      filter: grayscale(1);
    `}

  .MuiButton-startIcon {
    transform: scale(0.75);
  }
`
export const SelectDefaultTime: React.FC<SelectDefaultTimeProps> = props => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<T2Fields>()
  const { defaultMinutes, startDate } = values
  const buttonValues = [
    {
      value: 30,
      text: '30 M',
    },
    {
      value: 60,
      text: '1 H',
    },
    {
      value: 120,
      text: '2 H',
    },
    {
      value: 0,
      text: t(Translations.ALL_DAY),
    },
  ]

  const onChange = (val: number) => {
    if (defaultMinutes === val) {
      setFieldValue('defaultMinutes', null)
    } else {
      setFieldValue('defaultMinutes', val)
    }
  }

  React.useEffect(() => {
    let val: string
    if (defaultMinutes !== null) {
      val = defaultMinutes
        ? (val = moment(startDate).add(defaultMinutes, 'minute').toString())
        : moment(startDate).endOf('day').toString()
    } else {
      val = null
    }
    setFieldValue('parkForDefaultTimeDate', val)
  }, [defaultMinutes, setFieldValue, startDate])

  return (
    <FlexBox horizontal horizontalGap={0.5} horizontalFullWith>
      {buttonValues.map(({ text, value }) => {
        const selected = defaultMinutes === value
        return (
          <StyledButton
            key={value}
            variant="outlined"
            minWidth="100%"
            letterCase="none"
            $noColor={!selected}
            fontWeight={selected ? 'bold' : 'normal'}
            startIcon={selected && Boolean(value) && <SelectedTick color />}
            onClick={e => onChange(value)}
          >
            {text}
          </StyledButton>
        )
      })}
    </FlexBox>
  )
}

export default SelectDefaultTime
