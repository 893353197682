import { Popup } from '../../typedef/popup'
import { CLOSE_POPUP, SHOW_POPUP, SHOW_TERMS, CLOSE_TERMS } from './constants'

export const showPopup = (popup: Popup) => ({
  type: SHOW_POPUP as typeof SHOW_POPUP,
  popup,
})
export const closePopup = () => ({
  type: CLOSE_POPUP as typeof CLOSE_POPUP,
})
export const showTerms = () => ({
  type: SHOW_TERMS as typeof SHOW_TERMS,
})
export const closeTerms = () => ({
  type: CLOSE_TERMS as typeof CLOSE_TERMS,
})

export type Actions =
  | ReturnType<typeof showPopup>
  | ReturnType<typeof closePopup>
  | ReturnType<typeof showTerms>
  | ReturnType<typeof closeTerms>
