import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import React from 'react'
import { SharedIcon } from 'shared'
import { ValidatorBarProps } from './types'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(_ => ({
  root: {
    paddingTop: 8,
    background: 'white',
    paddingBottom: 8,
  },
  listItemRoot: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  listTextRoot: {
    paddingLeft: 8,
  },
}))
export const ValidateBar: React.FC<ValidatorBarProps> = props => {
  const { name, logoDir } = props.partner
  const classes = useStyles()

  return (
    <ListItem
      data-testid="validatorBar"
      button
      onClick={props.handleClick}
      classes={{
        root: classes.listItemRoot,
      }}
    >
      <ListItemAvatar
        classes={{
          root: classes.root,
        }}
      >
        <Avatar
          src={logoDir}
          alt="Partner logo"
          variant="square"
          placeholder=""
          imgProps={{
            style: { objectFit: 'contain', background: 'transparent' },
          }}
        >
          <SharedIcon.ValidationTicketPlaceholder />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={name} classes={{ root: classes.listTextRoot }} />
    </ListItem>
  )
}

export default ValidateBar
