import React from 'react'
import { AppPopup, PopupProps } from './AppPopup'

export type SetPopupProps = Omit<PopupProps, 'open'>

const initial = {
  showPopup(arg: boolean) {
    undefined
  },
  setPopup(args: SetPopupProps) {
    undefined
  },
  mounted: false,
}
export const PopupContext = React.createContext(initial)

export const usePopup = () => {
  return React.useContext(PopupContext)
}

export const PopupProvider: React.FC = ({ children }) => {
  const [show, setShow] = React.useState(false)
  const [popupProps, setPopupProps] = React.useState<SetPopupProps>({})
  const mountedRef = React.useRef(false)
  const showPopup = (arg: boolean) => {
    if (mountedRef.current) {
      setShow(arg)
    }
  }
  const setPopup = (args: SetPopupProps) => {
    if (mountedRef.current) {
      setPopupProps(args)
    }
  }

  mountedRef.current = true
  React.useEffect(
    () => () => {
      mountedRef.current = false
    },
    []
  )

  return (
    <PopupContext.Provider
      value={{
        showPopup,
        setPopup,
        mounted: show,
      }}
    >
      {children}
      <AppPopup
        closePopup={() => setShow(false)}
        // TransitionProps={{
        //   onExited: () => setPopupProps({}),
        // }}
        {...popupProps}
        open={show}
      />
    </PopupContext.Provider>
  )
}

export default PopupProvider
