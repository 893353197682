import { Form, Formik } from 'formik'
import { useChangeLayout, usePushToApp } from 'hooks'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AppButton,
  FlexBox,
  Input,
  PaddingContainer,
  PageHeading,
} from 'shared'
import PhoneNumberContainer from 'shared/sidebar/SignUpContainer'
import { showTerms } from 'store/globalPopup/actions'
import { hideLoader, showLoader } from 'store/loader'
import { termsSlice } from 'store/slices'
import { login } from 'store/slices/customerSlice'
import { useAppDispatch } from 'store/store'
import { LoginRequest, UserRole } from 'typedef/customer'
import { TermsType } from 'typedef/terms'
import { countryCodeRegex, phoneNumberRegex } from 'utils/regex'
import { Translations, blurLastInput } from 'utils'
import * as Yup from 'yup'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { contactInformationsSlice, parkCarSlice } from 'store/slices'
import { ThemeProvider } from '@mui/material/styles'
import { whiteTheme } from 'themes'
import styled from 'styled-components'
import { paths } from 'routing'
import NewPhoneNumberInput from 'shared/PhoneNumberAndCountryCodeInput'

const ForgotButtonWrapper = styled.div`
  text-align: right;
`

export const LoginRedirect = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const pushToApp = usePushToApp()
  useChangeLayout()
  const { enqueueSnackbar } = useSnackbar()
  const { paymentRedirectionAvailable } = useSelector(
    contactInformationsSlice.stateSelectors
  )
  const { lot } = useSelector(parkCarSlice.stateSelectors)
  const { carId } = useParams<{ carId: string }>()

  const externalLogoUrl = lot ? lot.logoLink : undefined
  const title = lot ? lot.lotName : t(Translations.PARKING_MADE_EASY)

  const LoginSchema = Yup.object().shape({
    password: Yup.string().required(t(Translations.PASSWORD_CANNOT_BE_EMPTY)),
    countryCode: Yup.string().matches(
      countryCodeRegex,
      t(Translations.INVALID_COUNTRY_CODE)
    ),
    phoneNumber: Yup.string()
      .matches(phoneNumberRegex, t(Translations.PHONE_CANNOT_BE_EMPTY))
      .required(t(Translations.FIELD_REQUIRED)),
  })

  const loginUser = async (args: LoginRequest) => {
    dispatch(showLoader())
    const actionResult = await dispatch(login(args))
    dispatch(hideLoader())
    if (login.rejected.match(actionResult)) {
      enqueueSnackbar(t(Translations.WRONG_LOGIN_OR_PASSWORD))
      return
    }
    if (carId && paymentRedirectionAvailable !== null) {
      return
    } else {
      pushToApp()
    }
  }

  const onTermsClick = () => {
    dispatch(
      termsSlice.getTerms({
        type: TermsType.Login,
      })
    )
    dispatch(showTerms())
  }
  React.useEffect(() => {
    dispatch(termsSlice.actions.setLang({ lang: i18n.language }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={whiteTheme}>
      <PaddingContainer>
        <PageHeading
          hasLogo={!lot}
          externalLogoUrl={externalLogoUrl}
          title={title}
        />
        <Formik
          initialValues={{ phoneNumber: '', password: '', countryCode: '' }}
          onSubmit={({ phoneNumber, password, countryCode }) => {
            loginUser({
              phoneNumber: parseInt(phoneNumber),
              password,
              countryCode: parseInt(countryCode),
              mode: UserRole.User,
            })
          }}
          validationSchema={LoginSchema}
          validateOnBlur
        >
          {({ values, errors, handleChange, handleBlur, validateForm }) => (
            <Form>
              <PhoneNumberContainer>
                <NewPhoneNumberInput
                  value={`${values.countryCode}${values.phoneNumber}`}
                  countryCodeFieldName="countryCode"
                  phoneNumberFieldName="phoneNumber"
                  errors={errors.phoneNumber}
                />
              </PhoneNumberContainer>
              <Input
                name="password"
                autoComplete="current-password"
                placeholder={t(Translations.PASSWORD)}
                type="password"
                inputMode="text"
                value={values.password}
                error={!!errors.password}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={e => {
                  blurLastInput(e)
                }}
              />
              <ForgotButtonWrapper>
                <AppButton
                  type="button"
                  onClick={() => {
                    history.push(`${paths.parking.forgotPassword}/${carId}`)
                  }}
                >
                  {t(Translations.FORGOT_PASSWORD)}?
                </AppButton>
              </ForgotButtonWrapper>
              <FlexBox hasBottomMargin hasTopMargin>
                <AppButton
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    validateForm().then(errors => {
                      const entries = Object.entries(errors)
                      if (entries.length !== 0) {
                        entries.forEach(el => {
                          enqueueSnackbar(el[1])
                        })
                      }
                    })
                  }}
                >
                  {t(Translations.LOG_IN)}
                </AppButton>
              </FlexBox>
            </Form>
          )}
        </Formik>
        <FlexBox>
          <AppButton
            letterCase="none"
            hasHoverBg={false}
            onClick={onTermsClick}
          >
            {t(Translations.TERMS_AND_CONDITIONS)}
          </AppButton>
        </FlexBox>
      </PaddingContainer>
    </ThemeProvider>
  )
}
