import { Form, Formik, FormikHandlers } from 'formik'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import paths from 'routing/paths'
import { AppButton, AppStepper, FlexBox, Input, PageHeading } from 'shared'
import PhoneNumberContainer from 'shared/sidebar/SignUpContainer'
import { phoneNumberAvailable } from 'store/customer/thunks'
import {
  countryCodeRegex,
  phoneNumberRegex,
  Translations,
  blurLastInput,
} from 'utils'
import * as Yup from 'yup'
import { PersonalData } from './SignUp'
import NewPhoneNumberInput from 'shared/PhoneNumberAndCountryCodeInput'

interface PersonalDataStepProps {
  handleSubmit(): void
  personalData: PersonalData
  resetStep: () => void
  setPersonalData(data: PersonalData): void
  backToLogin: boolean
}

const PersonalDataStep: React.FunctionComponent<
  PersonalDataStepProps
> = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  React.useEffect(() => {
    props.resetStep()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const SignupSchema = Yup.object().shape({
    firstName: Yup.string().required(t(Translations.FIRSTNAME_CANNOT_BE_EMPTY)),
    lastName: Yup.string().required(t(Translations.LASTNAME_CANNOT_BE_EMPTY)),
    email: Yup.string()
      .email(t(Translations.INVALID_EMAIL))
      .required(t(Translations.EMAIL_CANNOT_BE_EMPTY)),
    code: Yup.string().matches(
      countryCodeRegex,
      t(Translations.INVALID_COUNTRY_CODE)
    ),
    phoneNumber: Yup.string()
      .matches(phoneNumberRegex, t(Translations.INVALID_PHONE_NUMBER))
      .required(t(Translations.PHONE_CANNOT_BE_EMPTY)),
  })

  const customHandleBlur = (
    e: React.FocusEvent,
    handleBlur: FormikHandlers['handleBlur'],
    values: PersonalData
  ) => {
    handleBlur(e)
    props.setPersonalData(values)
  }

  return (
    <>
      <PageHeading title={t(Translations.PERSONAL_DATA)} normalizeIcon={false}>
        <AppStepper steps={[1, 2, 3]} activeStep={0} />
      </PageHeading>
      <Formik
        initialValues={{
          firstName: props.personalData.firstName,
          lastName: props.personalData.lastName,
          phoneNumber: props.personalData.phoneNumber,
          email: props.personalData.email,
          code: props.personalData.code,
        }}
        onSubmit={data => {
          props.handleSubmit()
        }}
        validationSchema={SignupSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          validateForm,
          submitForm,
        }) => {
          return (
            <Form>
              <Input
                name="firstName"
                type="text"
                inputMode="text"
                placeholder={t(Translations.FIRST_NAME)}
                value={values.firstName}
                onChange={handleChange}
                onBlur={e => customHandleBlur(e, handleBlur, values)}
                error={!!errors.firstName}
                maxLength={50}
                autoFocus
              />
              <Input
                name="lastName"
                type="text"
                inputMode="text"
                placeholder={t(Translations.LAST_NAME)}
                value={values.lastName}
                onChange={handleChange}
                onBlur={e => customHandleBlur(e, handleBlur, values)}
                error={!!errors.lastName}
                maxLength={50}
              />
              <PhoneNumberContainer>
                <NewPhoneNumberInput
                  value={`${values.code}${values.phoneNumber}`}
                  countryCodeFieldName="code"
                  phoneNumberFieldName="phoneNumber"
                  errors={errors.phoneNumber}
                  customHandleBlur={customHandleBlur}
                />
              </PhoneNumberContainer>
              <Input
                name="email"
                type="email"
                inputMode="email"
                placeholder={t(Translations.EMAIL)}
                value={values.email}
                onChange={handleChange}
                onBlur={e => customHandleBlur(e, handleBlur, values)}
                error={!!errors.email}
                onKeyDown={e => {
                  blurLastInput(e)
                }}
              />
              <FlexBox hasTopMargin>
                <AppButton
                  variant="contained"
                  type="button"
                  onClick={e =>
                    validateForm().then(errors => {
                      const entries = Object.entries(errors)
                      if (entries.length !== 0) {
                        entries.forEach(el => {
                          enqueueSnackbar(el[1])
                        })
                      } else {
                        dispatch(
                          phoneNumberAvailable({
                            phoneNumber: values.phoneNumber,
                            phoneCountryCode: parseInt(values.code),
                            callback: submitForm,
                          })
                        )
                      }
                    })
                  }
                >
                  {t(Translations.NEXT)}
                </AppButton>
                {props.backToLogin && (
                  <AppButton
                    variant="outlined"
                    type="button"
                    onClick={() => history.push(paths.auth.login)}
                  >
                    {t(Translations.BACK_TO_LOGIN)}
                  </AppButton>
                )}
              </FlexBox>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default PersonalDataStep
