import { LoginRedirectLoader } from 'pages/auth/login/LoginRedirectLoader'
import { SignUp } from 'pages/auth/signup'
import Map from 'pages/map/MapView'
import AddCar from 'pages/my_cars/AddCar'
import {
  ChangePasswordView,
  DeleteAccountView,
  EditProfileView,
} from 'pages/my_profile'
import { MyReceiptsView, SendReceipt } from 'pages/my_receipts'
import { ContactInformationsPage } from 'pages/my_receipts/receiptsAndNotifications/ContactInformationsPage'
import ReceiptsAndNotificationsPage from 'pages/my_receipts/receiptsAndNotifications/ReceiptsAndNotificationsPage'
import { Parker } from 'pages/parker'
import { ParkCar } from 'pages/park_car'
import AwaitTransactionPage from 'pages/park_car/AwaitTransation'
import {
  AddCreditCard,
  AddCreditCard as ParkCarAddCreditCard,
  ManageCards as ParkCarManageCards,
  SelectCreditCard as ParkCarSelectCreditCard,
} from 'pages/park_car_credit_card/'
import { AddTimeSelectCardPage } from 'pages/park_car_credit_card/AddTimeSelectCardPage'
import { AddTimeSummaryPage, ParkCarSummaryPage } from 'pages/park_car_summary'
import ParkCarSummarySuccessPage from 'pages/park_car_summary/ParkCarSummarySuccessPage'
import PcodeSignIn from 'pages/pcode_redirect/PcodeSingIn'
import Question from 'pages/question/Question'
import { Tickets } from 'pages/tickets'
import { UpfrontValidate } from 'pages/validators/UpfrontValidate'
import { UpfrontValidationView } from 'pages/validators/UpfrontValidationView'
import Validate from 'pages/validators/Validate'
import ValidatorsView from 'pages/validators/ValidatorsView'
import * as React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import paths from 'routing/paths'

interface SignedInRoutesProps {}

const SignedInRoutes = (props: SignedInRoutesProps) => {
  const location = useLocation()

  return (
    <Switch location={location}>
      <Route path={paths.parker.myProfile} exact component={Parker} />
      <Route path={paths.home.map} component={Map} />
      <Route path={paths.parker.edit} component={EditProfileView} />
      <Route
        path={paths.parker.changePassword}
        component={ChangePasswordView}
      />
      <Route path={paths.parker.delete} component={DeleteAccountView} />
      <Route path={paths.parker.addCar} component={AddCar} />
      <Route path={paths.parker.myReceipts} component={MyReceiptsView} />
      <Route
        path={`${paths.parker.sendReceipt}/:carId`}
        component={SendReceipt}
      />
      <Route path={`${paths.parker.question}/:carId`} component={Question} />
      <Route path={paths.parker.addCard} component={AddCreditCard} />
      <Route
        path={`${paths.parker.receiptsAndNotifications}/:carId`}
        component={ReceiptsAndNotificationsPage}
      />
      {/* add card on singup (need to be logged in) */}
      <Route path={paths.auth.addCard} component={SignUp} />

      {/**
       * PARKING
       */}
      <Route
        exact
        path={`${paths.parking.upfrontValidate}/:parkerId`}
        component={UpfrontValidationView}
      />
      <Route
        exact
        path={`${paths.parking.upfrontValidate}/:parkerId/${paths.parking.validate}/:couponId`}
        component={UpfrontValidate}
      />
      <Route
        exact
        path={`${paths.parking.validators}/:carId`}
        component={ValidatorsView}
      />
      <Route
        exact
        path={`${paths.parking.validators}/:carId/${paths.parking.validate}/:couponId`}
        component={Validate}
      />
      <Route path={`${paths.parking.parkCar}/:lotId`} component={ParkCar} />
      <Route
        path={`${paths.parking.contactInformations}/:carId`}
        component={ContactInformationsPage}
      />
      <Route
        path={`${paths.parking.login}/:carId`}
        component={LoginRedirectLoader}
      />
      <Route
        path={`${paths.parking.selectCard}/:ticketId`}
        component={ParkCarSelectCreditCard}
      />
      <Route
        path={`${paths.parking.transaction}/:carId/transactionId/:transactionId`}
        component={AwaitTransactionPage}
      />
      <Route
        path={`${paths.parking.transaction}/:carId`}
        component={AwaitTransactionPage}
      />
      <Route
        path={`${paths.parking.manageCards}/:ticketId`}
        component={ParkCarManageCards}
      />
      <Route
        path={`${paths.parking.addCard}/:ticketId`}
        component={ParkCarAddCreditCard}
      />
      <Route
        path={`${paths.parking.pay}/:ticketId`}
        component={ParkCarSummaryPage}
      />
      <Route
        path={`${paths.parking.paid}/:ticketId`}
        component={ParkCarSummarySuccessPage}
      />
      {/**
       * ADD TIME
       */}
      <Route path={`${paths.parking.addTime}/:carId`} component={ParkCar} />
      <Route
        path={`${paths.parking.addTimePay}/:ticketId`}
        component={AddTimeSummaryPage}
      />
      <Route
        path={`${paths.parking.addTimeSelectCard}/:ticketId`}
        component={AddTimeSelectCardPage}
      />
      <Route
        path={`${paths.parking.addTimeAddCard}/:ticketId`}
        component={ParkCarAddCreditCard}
      />
      <Route
        path={`${paths.parking.addTimeManageCards}/:ticketId`}
        component={ParkCarManageCards}
      />
      {/**
       * SMS
       **/}
      <Route path={`${paths.sms.park}/:token`} component={ParkCar} />
      <Route path={`${paths.sms.map}/:token`} component={Map} />
      <Route path={`${paths.sms.corp}/:token`} component={Map} />
      <Route path={paths.parking.tickets} component={Tickets} />
      {/* <Route path={paths.parking.addTime} component={} />  */}
      <Route exact strict path={`/:pcode`} component={PcodeSignIn} />
      <Redirect to={paths.home.map} />
    </Switch>
  )
}

export default SignedInRoutes
