import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, FlexBox } from 'shared'
import { Translations } from 'utils'

interface TicketUnpaidButtonsProps {
  onClickPay: () => void
}
export const TicketUnpaidButtons: React.FC<
  TicketUnpaidButtonsProps
> = props => {
  const { t } = useTranslation()
  return (
    <FlexBox horizontal hasTopMargin>
      <AppButton
        color="primary"
        variant="contained"
        minWidth="130px"
        onClick={props.onClickPay}
      >
        {t(Translations.PAY)}
      </AppButton>
    </FlexBox>
  )
}
interface TicketValidButtonsProps {
  onClickValidate: () => void
  onClickAddTime: () => void
  validateEnable: boolean
  addTimeEnable: boolean
}
export const TicketValidButtons: React.FC<TicketValidButtonsProps> = props => {
  const { t } = useTranslation()
  return (
    <FlexBox horizontal hasTopMargin>
      {props.validateEnable && (
        <AppButton
          color="primary"
          variant="outlined"
          minWidth="130px"
          onClick={props.onClickValidate}
        >
          {t(Translations.VALIDATE)}
        </AppButton>
      )}
      {props.addTimeEnable && (
        <AppButton
          color="primary"
          variant="contained"
          minWidth="130px"
          onClick={props.onClickAddTime}
        >
          {t(Translations.ADD_TIME)}
        </AppButton>
      )}
    </FlexBox>
  )
}

interface TicketExpiredButtonsProps {
  onClickReceipt: () => void
  onClickParkAgain: () => void
  parkAgainEnable: boolean
  isExternalService?: boolean
}
export const TicketExpiredButtons: React.FC<
  TicketExpiredButtonsProps
> = props => {
  const { t } = useTranslation()
  return (
    <FlexBox horizontal hasTopMargin>
      {!props.isExternalService && (
        <AppButton
          color="primary"
          variant="outlined"
          minWidth="130px"
          onClick={props.onClickReceipt}
        >
          {t(Translations.RECEIPT)}
        </AppButton>
      )}
      {props.parkAgainEnable && (
        <AppButton
          color="primary"
          variant="contained"
          minWidth="130px"
          onClick={props.onClickParkAgain}
        >
          {t(Translations.PARK_AGAIN)}
        </AppButton>
      )}
    </FlexBox>
  )
}
