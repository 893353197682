import { useTokenValidation } from 'hooks'
import React from 'react'
import { useHistory } from 'react-router-dom'
import paths from 'routing/paths'
import { customerSlice, layoutSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'

export const useRefreshSession = (initLocation: string) => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const validToken = useTokenValidation()

  const validateUser = React.useCallback(async () => {
    const homeRegex = new RegExp(`/home/`)
    const authRegex = new RegExp(`/auth/`)
    const parkerRegex = new RegExp(`/parker/`)
    const parkingRegex = new RegExp(`/parking/`)
    if (
      initLocation === '/' ||
      initLocation.match(homeRegex) ||
      initLocation.match(authRegex) ||
      initLocation.match(parkerRegex) ||
      initLocation.match(parkingRegex)
    ) {
      if (!validToken) return
      if (validToken) {
        dispatch(layoutSlice.actions.setLoader())
        push(initLocation)

        const actionResult = await dispatch(
          customerSlice.refreshToken({ token: validToken.refresh })
        )
        dispatch(layoutSlice.actions.setLoader())

        if (customerSlice.refreshToken.fulfilled.match(actionResult)) {
          push(initLocation)
        } else {
          push(paths.root.root)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  React.useEffect(() => {
    validateUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useRefreshSession
