import * as React from 'react'

export function usePrevious<T>(value: T) {
  const ref = React.useRef() as any
  React.useEffect(() => {
    ref.current = value
  })
  return ref.current as T
}

export default usePrevious
