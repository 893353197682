import { FormControlLabel } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppCheckbox } from 'shared'
import Translations from 'utils/translations'

export interface AllDayTickProps {
  endDateTime: string
  allDayUntilMessage?: string
  disabled?: boolean
  isChecked: boolean
  setIsChecked: (e: boolean) => void
}

export const AllDayTick: React.FC<AllDayTickProps> = props => {
  const { t } = useTranslation()
  const label = props.allDayUntilMessage
    ? props.allDayUntilMessage
    : `${t(Translations.ALL_DAY_UNTIL)}: ${moment(props.endDateTime).format(
        'LT'
      )}`

  return (
    <FormControlLabel
      disabled={props.disabled}
      control={
        <AppCheckbox
          value={props.isChecked}
          onChange={() => {
            props.setIsChecked(!props.isChecked)
          }}
        />
      }
      label={label}
    />
  )
}

export default AllDayTick
