import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AppButton,
  FlexBox,
  Input,
  PaddingContainer,
  PageHeading,
} from 'shared'
import * as Yup from 'yup'
import { Form } from '../../shared/styled/typography/Form'
import Translations from '../../utils/translations'

interface ChangePasswordProps {
  onClickCancel: () => void
  onSubmit: (currentPassword: string, password: string) => void
}

const ChangePassword = (props: ChangePasswordProps) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(
      t(Translations.CURRENT_PASSWORD_CANNOT_BE_EMPTY)
    ),
    password: Yup.string()
      .required(t(Translations.PASSWORD_CANNOT_BE_EMPTY))
      .matches(
        /(?=.*[A-Z]{1,})(?=.*[0-9]{1,})(?=.*[^A-Za-z0-9])(?=.*.{8,})/,
        t(Translations.MINIMUM_8_CHARACTERS_AT_LEAST_ONE_CAPITAL)
      ),
    confirmPassword: Yup.string()
      .required(t(Translations.CONFIRM_PASSWORD_CANNOT_BE_EMPTY))
      .oneOf([Yup.ref('password'), null], t(Translations.PASSWORD_NOT_MATCH)),
  })
  return (
    <PaddingContainer>
      <PageHeading
        title={t(Translations.CHANGE_PASSWORD)}
        subtitle={t(
          Translations.PLEASE_PROVIDE_YOUR_CURRENT_PASSWORD_AND_SET_THE_NEW_ONE
        )}
      />
      <Formik
        initialValues={{
          currentPassword: '',
          password: '',
          confirmPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={({ currentPassword, password }) => {
          props.onSubmit(currentPassword, password)
        }}
        validateOnChange={false}
        validateOnMount={false}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          validateForm,
        }) => (
          <Form>
            <Input
              id="currentPassword"
              data-testid="currentPassword"
              type="password"
              value={values.currentPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t(Translations.CURRENT_PASSWORD)}
              autoComplete="off"
              autoFocus
              error={!!errors.currentPassword}
            />
            <Input
              id="password"
              data-testid="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t(Translations.NEW_PASSWORD)}
              autoComplete="off"
              error={!!errors.password}
            />
            <Input
              id="confirmPassword"
              data-testid="confirmPassword"
              type="password"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t(Translations.CONFIRM_NEW_PASSWORD)}
              autoComplete="off"
              error={!!errors.confirmPassword}
            />
            <FlexBox hasTopMargin>
              <AppButton
                variant="contained"
                type="button"
                onClick={() => {
                  validateForm().then(errors => {
                    const entries = Object.entries(errors)
                    if (entries.length === 0) {
                      handleSubmit()
                    } else {
                      entries.forEach(el => {
                        enqueueSnackbar(el[1])
                      })
                    }
                  })
                }}
              >
                {t(Translations.CHANGE)}
              </AppButton>
              <AppButton variant="outlined" onClick={props.onClickCancel}>
                {t(Translations.CANCEL)}
              </AppButton>
            </FlexBox>
          </Form>
        )}
      </Formik>
    </PaddingContainer>
  )
}

export default ChangePassword
