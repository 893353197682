import { useFormikContext } from 'formik'
import React from 'react'
import { T2Fields } from 'pages/park_car/ParkCarT2'

export interface useEndDateProps {}

export const useEndDate = () => {
  const { values, setFieldValue } = useFormikContext<T2Fields>()
  const {
    parkForCustomTimeDate,
    parkForDefaultTimeDate,
    parkUntilDate,
    periodMode,
    endDate,
  } = values

  React.useEffect(() => {
    let val: string | null = null
    if (periodMode === 'parkFor') {
      if (typeof parkForCustomTimeDate === 'string') {
        val = parkForCustomTimeDate
      }
      if (typeof parkForDefaultTimeDate === 'string') {
        val = parkForDefaultTimeDate
      }
    }
    if (periodMode === 'parkUntil') {
      if (typeof parkUntilDate === 'string') {
        val = parkUntilDate
      }
    }

    setFieldValue('endDate', val)
  }, [
    parkForCustomTimeDate,
    parkForDefaultTimeDate,
    parkUntilDate,
    periodMode,
    setFieldValue,
  ])

  return [endDate, periodMode]
}

export default useEndDate
