import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from 'store/rootReducer'
import { FETCH, ADS } from './constants'

export interface Ad {
  advertisementUrl: string
  scriptString?: string
  fullHtmlString?: string
}
export interface AdState {
  scriptString?: string
  adsToAppend?: string[]
}
const adsAdapter = createEntityAdapter<Ad>({
  selectId: ad => ad.advertisementUrl,
})

const initialState = adsAdapter.getInitialState<AdState>({
  scriptString: '',
  adsToAppend: [],
})

/**
 * thunks
 */
export const getAd = createAsyncThunk<Ad, string>(
  `${ADS}${FETCH}/getAd`,
  async (params, thunkApi) => {
    try {
      const resp = await axios.request<string>({
        headers: { 'Access-Control-Allow-Origin': '*' },
        method: 'get',
        url: params,
        responseType: 'text',
      })

      if (!resp.data) throw Error

      let scriptString = resp.data.substring(
        resp.data.lastIndexOf('<script>') + 8,
        resp.data.lastIndexOf('</script>')
      )
      if (resp.data.lastIndexOf('</script>') === -1) {
        scriptString = ''
      }

      return {
        advertisementUrl: params,
        scriptString,
        fullHtmlString: resp.data,
      }
    } catch (error) {
      throw error
    }
  },
  {
    condition: (arg, api) => {
      const { ads } = api.getState() as RootState
      if (!arg) return false
      return !ads.ids.find(el => el === arg)
    },
  }
)

/**
 * reducers
 */
const adsSlice = createSlice({
  name: ADS,
  initialState,
  reducers: {
    removeScriptString(state) {
      state.scriptString = ''
    },
    addAdToAppend(state, { payload }: PayloadAction<string>) {
      state.adsToAppend = [...state.adsToAppend, payload]
    },
    addAllAdsToAppend(state) {
      state.adsToAppend = state.ids.map(el => el) as string[]
    },
    /** @description empty string removes all */
    removeAdsToAppend(state, { payload }: PayloadAction<string[] | 'all'>) {
      if (payload === 'all') {
        state.adsToAppend = []
      } else if (Array.isArray(payload)) {
        payload.forEach(payEl => {
          state.adsToAppend = state.adsToAppend.filter(el => el !== payEl)
        })
      } else {
        console.warn('removeAdToAppend - wrong payload')
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getAd.fulfilled, (state, action) => {
      if (!state.scriptString) {
        state.scriptString = action.payload.scriptString
      }
      adsAdapter.upsertOne(state, action.payload)
      const fwdAction = adsSlice.actions.addAdToAppend(action.meta.arg)
      adsSlice.caseReducers.addAdToAppend(state, fwdAction)
    })
  },
})

/**
 *  selectors
 */
export const stateSelectors = (state: RootState) => state.ads

export const { selectAll, selectById, selectIds } =
  adsAdapter.getSelectors<RootState>(stateSelectors)

export const selectAdsToAppend = (state: RootState) =>
  stateSelectors(state).adsToAppend.map(el => selectById(state, el))
export const { actions } = adsSlice
export default adsSlice.reducer
