import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, DetailsBox } from 'shared'
import { GetPriceData } from 'typedef/price'
import { Currency } from 'typedef/ticket'
import Translations from 'utils/translations'

export interface ParkingExpiresSectionProps extends GetPriceData {
  changePricingBtn?: boolean
  changePricingOnClick?: () => void
  currency: Currency
}

export const ParkingExpiresSection: React.FC<
  ParkingExpiresSectionProps
> = props => {
  const { t } = useTranslation()
  const label = props.convenienceFeeName
    ? props.convenienceFeeName
    : t(Translations.CONVENIENCE_FEE)

  return (
    <DetailsBox.Wrapper>
      <DetailsBox.TimeRow time={props.parkingExpireDateTime} />
      {(Boolean(props.amount) || Boolean(props.convenienceFee)) && (
        <>
          <DetailsBox.Divider />
          <DetailsBox.InnerRow noBottomPadding>
            <DetailsBox.PriceRow
              currency={props.currency}
              translation="PARKING_FEE"
              amount={props.amount}
            />
            <DetailsBox.PriceRow
              label={label}
              amount={props.convenienceFee}
              currency={props.currency}
            />
            {!!props.validations?.length &&
              props.validations.map(validator => (
                <DetailsBox.PriceRow
                  key={validator.order}
                  label={`${t(Translations.VALIDATED)} (${validator.name})`}
                  amount={validator.amount}
                  currency={props.currency}
                  negativeAmount
                />
              ))}
          </DetailsBox.InnerRow>
        </>
      )}
      {props.changePricingBtn && (
        <>
          <DetailsBox.Divider />
          <DetailsBox.InnerRow noBottomPadding>
            <AppButton
              textAlign="center"
              color="primary"
              onClick={props.changePricingOnClick}
            >
              {t(Translations.CHANGE_PRICING)}
            </AppButton>
          </DetailsBox.InnerRow>
        </>
      )}
      {props.children}
    </DetailsBox.Wrapper>
  )
}

export default ParkingExpiresSection
