import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={295} height={168} viewBox="0 0 295 168" fill="none" {...props}>
      <g filter="url(#prefix__filter0_d)">
        <rect x={4} width={287} height={160} rx={15} fill="#F26E21" />
      </g>
      <path
        d="M171.293 0C171.293 91 87 103 87 160h189c8.284 0 15-6.716 15-15V15c0-8.284-6.7-15-14.984-15H171.293z"
        fill="#EC5E21"
      />
      <rect
        x={24.5}
        y={21.5}
        width={42.154}
        height={32}
        rx={3.5}
        fill="url(#prefix__paint1_linear)"
        stroke="#B1831F"
      />
      <path
        d="M38.695 41.307c-.202.801-.31 1.654-.31 2.538 0 4.673 3.03 8.462 6.769 8.462 3.738 0 6.77-3.789 6.77-8.462 0-.884-.11-1.737-.31-2.538-.18-.705-.537-2.454-.537-3.808 0-1.354 1.128-3.102 1.692-3.808h14.385M51.923 42.154h15.23M38.385 33.691H24M38.385 42.154H24M51.71 29.038c-.752-3.65-3.402-6.347-6.556-6.347-3.739 0-6.77 3.789-6.77 8.462 0 .884.11 1.737.31 2.538.18.706.537 2.454.537 3.808 0 1.354-.358 3.103-.536 3.808"
        stroke="#B1831F"
      />
      <rect x={217} y={19} width={54} height={36} rx={2} fill="#fff" />
      <path
        d="M245.362 40.175c2.025 0 3.666-1.606 3.666-3.587 0-1.982-1.641-3.588-3.666-3.588s-3.667 1.606-3.667 3.588c0 1.981 1.642 3.587 3.667 3.587z"
        fill="#F26E21"
      />
      <path
        d="M225.612 38.306c-.432.379-.99.543-1.875.543h-.367v-4.504h.367c.885 0 1.421.153 1.875.55.473.41.756 1.042.756 1.695 0 .654-.283 1.31-.756 1.716zm-1.6-5.115H222v6.81h2.002c1.062 0 1.83-.243 2.503-.786a3.386 3.386 0 001.274-2.616c0-2.01-1.548-3.408-3.767-3.408zM228.412 40.002h1.368v-6.81h-1.368v6.81zM233.125 35.804c-.821-.296-1.063-.49-1.063-.856 0-.43.43-.756 1.02-.756.411 0 .747.163 1.107.55l.714-.908a3.122 3.122 0 00-2.063-.756c-1.241 0-2.189.837-2.189 1.95 0 .94.442 1.42 1.726 1.87.537.183.81.305.947.388.274.174.411.418.411.704 0 .552-.452.96-1.064.96-.652 0-1.178-.316-1.494-.909l-.883.828c.63.897 1.388 1.297 2.431 1.297 1.42 0 2.42-.92 2.42-2.237 0-1.083-.462-1.573-2.02-2.125zM235.578 36.6c0 2.003 1.621 3.555 3.705 3.555.59 0 1.094-.113 1.716-.397v-1.564c-.548.531-1.033.745-1.654.745-1.378 0-2.357-.97-2.357-2.35 0-1.306 1.01-2.338 2.295-2.338.651 0 1.147.224 1.716.766v-1.563c-.6-.295-1.096-.417-1.685-.417-2.073 0-3.736 1.584-3.736 3.562zM252.088 37.766l-1.874-4.575h-1.494l2.979 6.985h.736l3.031-6.985h-1.484l-1.894 4.575zM256.088 40.002h3.882v-1.153h-2.514v-1.84h2.418v-1.153h-2.418v-1.511h2.514V33.19h-3.882v6.81zM262.642 36.326h-.398v-2.064h.42c.854 0 1.317.349 1.317 1.011 0 .684-.463 1.053-1.339 1.053zm2.747-1.125c0-1.276-.904-2.01-2.484-2.01h-2.03v6.81h1.368v-2.736h.18L264.316 40H266l-2.211-2.868c1.033-.205 1.6-.89 1.6-1.932z"
        fill="#0B161F"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={149}
          y1={160}
          x2={291}
          y2={160}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EC5E21" />
          <stop offset={1} stopColor="#EC5E21" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={24}
          y1={21}
          x2={67.154}
          y2={54}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C966" />
          <stop offset={0.516} stopColor="#FFE7BD" />
          <stop offset={1} stopColor="#E1A448" />
        </linearGradient>
        <filter
          id="prefix__filter0_d"
          x={0}
          y={0}
          width={295}
          height={168}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent
