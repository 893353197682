import { useFormikContext } from 'formik'
import { T2Fields } from 'pages/park_car/ParkCarT2'
import { useParkCarPath } from 'pages/park_car/ParkCarHooks'
import React from 'react'
import { AppButton, DetailsBox } from 'shared'

export interface SummaryBoxProps {}

export const SummaryBox: React.FC<SummaryBoxProps> = props => {
  const [_, goTo] = useParkCarPath()
  const { values } = useFormikContext<T2Fields>()
  const { selectedPricing, pricings, licencePlate, spotNumber } = values
  const pricing = pricings.find(el => el.externalPricingId === selectedPricing)

  const hasChargeFees = () => {
    if (!pricing?.fees?.length) return false
    const totalAmount = pricing.fees
      .map(el => el.amount)
      .reduce((prev, curr) => prev + curr)
    if (!totalAmount) return false
    return true
  }

  return (
    <DetailsBox.Wrapper>
      <DetailsBox.IconsRow plate={licencePlate} spot={spotNumber} />
      <DetailsBox.Divider />
      <DetailsBox.TimeRow time={pricing.parkingExpireDateTime} />
      {hasChargeFees() && (
        <>
          <DetailsBox.Divider />
          <DetailsBox.InnerRow>
            {pricing.fees.map((el, i) => (
              <DetailsBox.PriceRow
                label={el.label}
                amount={el.amount}
                key={i}
              />
            ))}
          </DetailsBox.InnerRow>
        </>
      )}
      {Boolean(pricings.length > 1) && (
        <>
          <DetailsBox.Divider />
          <DetailsBox.InnerRow noBottomPadding>
            <AppButton
              variant="text"
              translation="CHANGE"
              textAlign="center"
              onClick={() => goTo('select')}
            />
          </DetailsBox.InnerRow>
        </>
      )}
    </DetailsBox.Wrapper>
  )
}

export default SummaryBox
