import { List, Slide } from '@mui/material'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { TransitionGroup } from 'react-transition-group'
import { removeCar } from 'store/slices/carsSlice'
import { useAppDispatch } from 'store/store'
import { showPopup } from '../../store/globalPopup/actions'
import { Car } from '../../typedef/car'
import Translations from '../../utils/translations'
import CarBar from './CarBar'

interface MyCarsProps {
  cars: Car[]
}

export const MyCars: React.FunctionComponent<MyCarsProps> = props => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const deleteCar = async (carId: string) => {
    const resultAction = await dispatch(removeCar({ carId }))
    if (removeCar.rejected.match(resultAction)) {
      enqueueSnackbar(t(Translations.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN))
    }
  }
  const handlePopup = (carId: string) =>
    dispatch(
      showPopup({
        title: t(Translations.DELETE_CAR),
        description: t(Translations.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_YOUR_CAR),
        variant: 'delete',
        handleAdditionalButtonClick: () => deleteCar(carId),
      })
    )

  return (
    <List dense>
      <TransitionGroup>
        {props?.cars?.map((v, i, arr) => (
          <Slide key={v.carId} direction="left" appear={false}>
            <div>
              <CarBar
                key={i}
                car={v}
                carPlate={v.plate}
                handleDeleteClick={() => handlePopup(v.carId)}
                divider={i < arr.length - 1}
              />
            </div>
          </Slide>
        ))}
      </TransitionGroup>
    </List>
  )
}

export default MyCars
