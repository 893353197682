import { DialogTitle } from '@mui/material'
import { DialogProps } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { closePopup } from '../../store/globalPopup/actions'
import { AppDialog, SharedIcon } from 'shared'
import PopupButtons from './PopupButtons'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const Text = styled(Typography)`
  padding-bottom: ${_ => _.theme.spacing(3)};
`

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(1.5),
    fontWeight: 'bold',
  },
}))
/**
 * @deprecated
 */
export interface IPopup {
  variant?: 'info' | 'success' | 'warning' | 'error' | 'delete'
  title?: string
  description?: string
  okButtonClick?: () => void
  handleAdditionalButtonClick?: () => any
}
export type PopupProps = IPopup & DialogProps

const PopupIcon: React.FC<{ variant: PopupProps['variant'] }> = props => {
  const icon = {
    info: <SharedIcon.PopupPlaceholder.InfoIcon />,
    success: <SharedIcon.PopupPlaceholder.SuccessIcon />,
    warning: <SharedIcon.PopupPlaceholder.WarningIcon />,
    error: <SharedIcon.PopupPlaceholder.ErrorIcon />,
    delete: <SharedIcon.PopupPlaceholder.ErrorIcon />,
  }

  return <div>{icon[props.variant]}</div>
}

export const Popup: React.FC<PopupProps> = props => {
  const {
    variant,
    title,
    description,
    handleAdditionalButtonClick,
    okButtonClick,
    ...dialogProps
  } = props
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleCloseClick = () => {
    dispatch(closePopup())
    if (okButtonClick) {
      okButtonClick()
    }
  }

  const handleAdditionalClick = () => {
    dispatch(closePopup())
    if (handleAdditionalButtonClick) {
      handleAdditionalButtonClick()
    }
  }

  return (
    <AppDialog {...dialogProps} onClose={handleCloseClick}>
      <PopupIcon variant={variant} />
      <DialogTitle data-testid="popupTitle" classes={{ root: classes.title }}>
        {title}
      </DialogTitle>
      <Text data-testid="popupDescription">{description}</Text>
      <PopupButtons
        variant={variant}
        handleAdditionalButtonClick={handleAdditionalClick}
        handleCloseClick={handleCloseClick}
      />
    </AppDialog>
  )
}
Popup.defaultProps = {
  fullWidth: false,
  variant: 'warning',
}
