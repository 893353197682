import { useChangeLayout } from 'hooks'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import {
  AppSnackBarProvider,
  AppSpinner,
  Placeholder,
  SharedIcon,
  WhiteBoxContainer,
} from 'shared'
import { customerSlice } from 'store/slices'
import Translations from '../../utils/translations'
import { FindCarsContainer } from './FindCarsContainer'
interface FindCarViewProps {}

export const FindCarView: React.FunctionComponent<FindCarViewProps> = props => {
  const { t } = useTranslation()
  const title = t(Translations.FIND_CAR)
  const { push } = useHistory()
  const { pending } = useSelector(
    customerSlice.promiseStatusSelectors
  ).findCarStatus
  useChangeLayout(
    {
      topbar: {
        title,
        onClickBack: () => push(paths.home.map),
      },
    },
    'app'
  )

  return (
    <AppSnackBarProvider hasBottomGutter>
      <WhiteBoxContainer>
        <AppSpinner show={pending} />
        <Placeholder
          title={t(Translations.FIND_CAR)}
          icon={<SharedIcon.Cars />}
        ></Placeholder>
        <FindCarsContainer />
      </WhiteBoxContainer>
    </AppSnackBarProvider>
  )
}

export default FindCarView
