import { useChangeLayout } from 'hooks'
import { SelectedLotTitle } from 'pages/park_car/ParkCarComponents'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { paths } from 'routing'
import {
  AppButton,
  AppSpinner,
  FlexBox,
  Placeholder,
  SharedIcon,
  WhiteBoxContainer,
} from 'shared'
import { RootState } from 'store/rootReducer'
import { parkCarSlice, ticketsSlice } from 'store/slices'
import { AppState, useAppDispatch } from 'store/store'
import { Translations } from 'utils'
import PaymentSummaryDetails from './ParkCarSummaryComponents/PaymentSummaryDetails'

export interface PaymentSummaryContainerProps {}

const ParkCarSummarySuccessPage: React.FC<
  PaymentSummaryContainerProps
> = props => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const { t } = useTranslation()
  const { ticketId } = useParams<{ ticketId: string }>()

  const ticket = useSelector((state: RootState) =>
    ticketsSlice.selectById(state, ticketId)
  )
  const { getTicketStatus } = useSelector((state: AppState) =>
    ticketsSlice.promiseStatusSelectors(state, ticketId)
  )

  const fetchData = () => dispatch(ticketsSlice.getTicket({ carId: ticketId }))

  React.useEffect(() => {
    if (ticket)
      dispatch(
        parkCarSlice.getLotDetailsPark({
          carId: ticketId,
          lotId: ticket.lot.lotId,
        })
      )
  }, [dispatch, ticket, ticketId])

  React.useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, ticketId])

  const onOkClick = React.useCallback(() => {
    dispatch(
      ticketsSlice.actions.setSelectedTicket({
        ticketId: ticket.carId,
      })
    )
    push(paths.parking.tickets)
  }, [dispatch, ticket, push])

  useChangeLayout(
    {
      topbar: {
        title: t(Translations.PARK_CAR),
        hasGoBack: false,
        rightIcon: null,
      },
      bottomMenu: false,
    },
    'app',
    []
  )

  if (!ticketId) {
    return <Redirect to={paths.home.map} />
  }

  const showElement = () => {
    const spinner =
      getTicketStatus.pendingRejected || getTicketStatus.pendingIdle
    const error = getTicketStatus.pendingRejected || getTicketStatus.rejected
    const summary =
      Boolean(ticket) &&
      (getTicketStatus.fulfilled || getTicketStatus.pendingFulfilled)

    return { spinner, error, summary }
  }

  return (
    <WhiteBoxContainer minHeight={150}>
      <AppSpinner show={showElement().spinner} />

      {showElement().summary && (
        <>
          <Placeholder
            icon={<SharedIcon.SuccessTick />}
            title={t(Translations.PAYMENT_SUCCESSFUL)}
            minHeight={120}
          />

          <SelectedLotTitle />

          <FlexBox>
            <PaymentSummaryDetails {...ticket} />
            <AppButton variant="contained" color="primary" onClick={onOkClick}>
              {t(Translations.OK)}
            </AppButton>
          </FlexBox>
        </>
      )}
    </WhiteBoxContainer>
  )
}

export default ParkCarSummarySuccessPage
