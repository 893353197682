import americanExpress from '../assets/icons/credit_cards/american_express.svg'
import dinersClub from '../assets/icons/credit_cards/diners_club.svg'
import discover from '../assets/icons/credit_cards/discover.svg'
import jcb from '../assets/icons/credit_cards/jcb.svg'
import masterCard from '../assets/icons/credit_cards/master_card.svg'
import unknown from '../assets/icons/credit_cards/unknown.svg'
import visa from '../assets/icons/credit_cards/visa.svg'
import { CardType } from '../typedef/creditCards'

export const getCreditCardIcon = (type: CardType) => {
  switch (type) {
    case CardType.MasterCard:
      return masterCard
    case CardType.DinersClub:
      return dinersClub
    case CardType.Visa:
      return visa
    case CardType.JCB:
      return jcb
    case CardType.Discover:
      return discover
    case CardType.AmericanExpress:
      return americanExpress
    default:
      return unknown
  }
}

export const getCreditCardType = (creditCardNumber: string): CardType => {
  const dict = [
    [CardType.Visa, /^4[0-9 ]*$/],
    [
      CardType.MasterCard,
      /^(5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9 ]*$/,
    ],
    [CardType.AmericanExpress, /^3[47][0-9 ]*$/],
    [CardType.DinersClub, /^3(0[0-5]|[68][0-9])[0-9 ]*$/],
    [CardType.Discover, /^6(011|5[0-9])[0-9 ]*$/],
    [CardType.JCB, /^(2131|1800|35)[0-9 ]*$/],
  ]

  const cardNumber = creditCardNumber.split(' ').join('')

  for (let i = 0; i < dict.length; i++) {
    const regexp = dict[i][1] as RegExp

    if (regexp.test(cardNumber)) return dict[i][0] as CardType
  }

  return CardType.Invalid
}
