import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as customerApiError from 'api/apiErrors/CustomerApiError'
import { removeAuthHeader, setAuthHeader } from 'api/auth'
import * as customerApi from 'api/CustomerApi'
import { getErrorCode, getErrorMessage } from 'api/utils'
import { RootState } from 'store/rootReducer'
import { CUSTOMER, FETCH, initialPromiseStatus } from 'store/slices/constants'
import * as sliceUtil from 'store/slices/slice.utils'
import {
  ChangePasswordRequest,
  ConfirmRegisterData,
  ConfirmRegisterParams,
  CustomerDetails,
  EditCustomerParams,
  FindCarData,
  FindCarPayload,
  LoginRequest,
  LoginResult,
  RefreshTokenRequest,
  RefreshTokenResult,
  RegisterParams,
  RegisterResult,
  RemindPasswordParams,
  ResetPasswordRequest,
  ResetPasswordResult,
  UserRole,
  ErrorData,
  FetchStatus,
} from 'typedef'
import tokensStorageService from 'utils/tokenStorageService'
import { mapSlice, ticketsSlice } from '.'
import { CustomerState } from './customerSlice.types'

/**
 * state
 */
export const initialPromiseStatuses = {
  getCustomerDetails: initialPromiseStatus,
  refreshToken: initialPromiseStatus,
  findCar: initialPromiseStatus,
  deleteAccount: initialPromiseStatus,
}
export const initialState: CustomerState = {
  details: null,
  tokens: null,
  role: null,
  corporationLotsToken: '',
  corporationLotsGlobalPcode: '',
  fetchingEditCustomerDetailsStatus: FetchStatus.Idle,
  promisesStatus: initialPromiseStatuses,
}

/**
 * thunks
 */
export const register = createAsyncThunk<
  RegisterResult,
  RegisterParams,
  { rejectValue: ErrorData }
>(`${CUSTOMER}${FETCH}/register`, async (arg, thunkApi) => {
  try {
    const response = await customerApi.register(arg)
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      customerApiError.register
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const login = createAsyncThunk<
  LoginResult,
  LoginRequest,
  { rejectValue: ErrorData }
>(`${CUSTOMER}${FETCH}/login`, async (arg, thunkApi) => {
  try {
    const response = await customerApi.login(arg)
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      customerApiError.getCustomerDetails
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const remindPassword = createAsyncThunk<
  boolean,
  RemindPasswordParams,
  { rejectValue: ErrorData }
>(`${CUSTOMER}${FETCH}/remindPassword`, async (arg, thunkApi) => {
  try {
    const response = await customerApi.remindPassword(arg)
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      customerApiError.remindPassword
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const resetPassword = createAsyncThunk<
  ResetPasswordResult,
  ResetPasswordRequest,
  { rejectValue: ErrorData }
>(`${CUSTOMER}${FETCH}/resetPassword`, async (arg, thunkApi) => {
  try {
    const response = await customerApi.resetPassword(arg)
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      customerApiError.resetPassword
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})
export const changePassword = createAsyncThunk<
  boolean,
  ChangePasswordRequest,
  { rejectValue: ErrorData }
>(`${CUSTOMER}${FETCH}/changePassword`, async (arg, thunkApi) => {
  try {
    const response = await customerApi.changePassword(arg)
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      customerApiError.changePassword
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const confirmRegister = createAsyncThunk<
  ConfirmRegisterData,
  ConfirmRegisterParams,
  { rejectValue: ErrorData }
>(`${CUSTOMER}${FETCH}/confirmRegister`, async (arg, thunkApi) => {
  try {
    const response = await customerApi.confirmRegister(arg)
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      customerApiError.confirmRegister
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const refreshToken = createAsyncThunk<
  RefreshTokenResult,
  RefreshTokenRequest,
  { rejectValue: ErrorData }
>(
  `${CUSTOMER}${FETCH}/refreshToken`,
  async (arg, thunkApi) => {
    try {
      const response = await customerApi.refreshToken(arg)
      return response
    } catch (error) {
      const errorMessage = getErrorMessage(
        getErrorCode(error),
        customerApiError.refreshToken
      )
      return thunkApi.rejectWithValue(errorMessage)
    }
  },
  {
    condition: (arg, api) => {
      const { customer } = api.getState() as RootState
      return !customer.promisesStatus.refreshToken.status.includes(
        FetchStatus.Pending
      )
    },
  }
)

export const getCustomerDetails = createAsyncThunk<
  CustomerDetails,
  null,
  { rejectValue: ErrorData }
>(
  `${CUSTOMER}${FETCH}/getCustomerDetails`,
  async (params, thunkApi) => {
    try {
      const res = await customerApi.getCustomerDetails()

      return res
    } catch (error) {
      const errorMessage = getErrorMessage(
        getErrorCode(error),
        customerApiError.getCustomerDetails
      )
      return thunkApi.rejectWithValue(errorMessage)
    }
  },
  {
    condition: (arg, api) => {
      const { customer } = api.getState() as RootState
      return !customer.promisesStatus.getCustomerDetails.status.includes(
        FetchStatus.Pending
      )
    },
  }
)
export const editCustomerDetails = createAsyncThunk<
  boolean,
  EditCustomerParams
>('customer/editCustomerDetails', async arg => {
  const response = await customerApi.editCustomer(arg)
  return response
})

export const deleteAccount = createAsyncThunk<
  boolean,
  string,
  { rejectValue: ErrorData }
>('customer/deleteAccount', async (password, thunkApi) => {
  try {
    const response = await customerApi.deleteAccount(password)
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      customerApiError.getCustomerDetails
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})
export const findCar = createAsyncThunk<
  FindCarData,
  FindCarPayload,
  { rejectValue: ErrorData }
>(
  `${CUSTOMER}${FETCH}/findCar`,
  async (args, thunkApi) => {
    try {
      const resp = await customerApi.findCar(args)
      thunkApi.dispatch(mapSlice.actions.addLots({ lots: [resp.ticket.lot] }))
      thunkApi.dispatch(
        ticketsSlice.actions.addTicket({
          ticket: resp.ticket,
        })
      )
      return resp
    } catch (error) {
      const errorMessage = getErrorMessage(
        getErrorCode(error),
        customerApiError.findCar
      )
      return thunkApi.rejectWithValue(errorMessage)
    }
  },
  {
    condition: (arg, api) => {
      const { customer } = api.getState() as RootState
      return !customer.promisesStatus.findCar.status.includes(
        FetchStatus.Pending
      )
    },
  }
)

const customerSlice = createSlice({
  name: CUSTOMER,
  initialState,
  reducers: {
    setLogin(state, { payload }: PayloadAction<LoginResult>) {
      state.role = payload.role
      state.tokens = payload.tokens
      setAuthHeader(payload.tokens.auth)
      tokensStorageService.set(payload.tokens)
    },
    setLogout(state) {
      state.role = null
      state.tokens = null
      removeAuthHeader()
      tokensStorageService.clear()
    },
    setDetails(state, { payload }: PayloadAction<CustomerDetails>) {
      state.details = payload
    },
    setRole(state, { payload }: PayloadAction<UserRole>) {
      state.role = payload
    },
    setCorporationToken(state, { payload }: PayloadAction<string>) {
      state.corporationLotsToken = payload
    },
    setCorporationGlobalPcode(state, { payload }: PayloadAction<string>) {
      state.corporationLotsGlobalPcode = payload
    },
    destroySession() {
      // middleware is listening to the action
      // destroySession.ts, rootReducer.ts
    },
  },
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, action) => {
      customerSlice.caseReducers.setLogin(state, action)
    })
    /**
     * refreshToken
     */
    builder.addCase(refreshToken.pending, ({ promisesStatus }) => {
      promisesStatus.refreshToken = sliceUtil.pendingPromise(
        promisesStatus.refreshToken
      )
    })
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.promisesStatus.refreshToken = sliceUtil.fulfilledPromise()
      customerSlice.caseReducers.setLogin(state, action)
    })
    builder
      .addCase(refreshToken.rejected, (state, action) => {
        state.promisesStatus.refreshToken = sliceUtil.rejectedPromise(
          action.payload
        )
      })
      // Get Customer Details
      .addCase(getCustomerDetails.pending, ({ promisesStatus }) => {
        promisesStatus.getCustomerDetails = sliceUtil.pendingPromise(
          promisesStatus.getCustomerDetails
        )
      })
      .addCase(getCustomerDetails.fulfilled, (state, { payload }) => {
        state.promisesStatus.getCustomerDetails = sliceUtil.fulfilledPromise()
        state.details = payload
      })
      .addCase(confirmRegister.fulfilled, (state, action) => {
        customerSlice.caseReducers.setLogin(state, {
          ...action,
          payload: { ...action.payload, role: state.role },
        })
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        customerSlice.caseReducers.setLogin(state, {
          ...action,
          payload: { ...action.payload, role: state.role },
        })
      })
      .addCase(getCustomerDetails.rejected, (state, { payload }) => {
        state.promisesStatus.getCustomerDetails =
          sliceUtil.rejectedPromise(payload)
      })

      // Edit customer details
      .addCase(editCustomerDetails.pending, state => {
        state.fetchingEditCustomerDetailsStatus = FetchStatus.Pending
      })
      .addCase(editCustomerDetails.fulfilled, (state, action) => {
        state.fetchingEditCustomerDetailsStatus = FetchStatus.Fulfilled
        if (!!state.details) {
          state.details.firstName = action.meta.arg.firstName
          state.details.lastName = action.meta.arg.lastName
          state.details.email = action.meta.arg.email
        }
      })
      .addCase(editCustomerDetails.rejected, (state, action) => {
        state.fetchingEditCustomerDetailsStatus = FetchStatus.Rejected
      })

      // Delete customer
      .addCase(deleteAccount.fulfilled, (state, { payload }) => {
        state.promisesStatus.deleteAccount = sliceUtil.fulfilledPromise()
        customerSlice.caseReducers.setLogout(state)
      })
      .addCase(deleteAccount.pending, state => {
        state.promisesStatus.deleteAccount = sliceUtil.pendingPromise(
          state.promisesStatus.deleteAccount
        )
      })
      .addCase(deleteAccount.rejected, (state, { payload }) => {
        state.promisesStatus.deleteAccount = sliceUtil.rejectedPromise(payload)
      })

      /**
       * findCar
       */
      .addCase(findCar.pending, (state, action) => {
        state.promisesStatus.findCar = sliceUtil.pendingPromise(
          state.promisesStatus.findCar
        )
      })
      .addCase(findCar.fulfilled, (state, action) => {
        state.promisesStatus.findCar = sliceUtil.fulfilledPromise()
      })
      .addCase(findCar.rejected, (state, action) => {
        state.promisesStatus.findCar = sliceUtil.rejectedPromise(action.payload)
      })
  },
})

export default customerSlice.reducer
export const { actions } = customerSlice
export const selectCustomerStatus = (state: RootState) => ({
  customerSignedOut: !state.customer.role,
  customerSignedIn: state.customer.role === UserRole.User,
  customerSignedInAsGuest: state.customer.role === UserRole.Guest,
})

export const stateSelectors = (state: RootState) => state.customer

export const selectEditCustomerDetailsFetchStatus = (state: RootState) =>
  state.customer.fetchingEditCustomerDetailsStatus

export const promiseStatusSelectors = (state: RootState) => {
  const promises = stateSelectors(state).promisesStatus
  return {
    findCarStatus: sliceUtil.fetchStatus(promises.findCar),
    getCustomerDetailsStatus: sliceUtil.fetchStatus(
      promises.getCustomerDetails
    ),
    deleteAccountStatus: sliceUtil.fetchStatus(promises.deleteAccount),
  }
}
