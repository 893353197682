import { TERMS_AND_CONDITIONS } from 'api/constants'
import i18n from 'i18n'
import { GetTermsAndConditionsResult } from 'typedef/terms'
import T from 'utils/translations'
import mock from '.'

mock.onGet(TERMS_AND_CONDITIONS).reply(config => {
  return [
    200,
    {
      data: {
        text: i18n.t(T.TERMS_AND_CONDITIONS_CONTENT),
      } as GetTermsAndConditionsResult,
    },
  ]
})
// mock.onGet('/Tickets').networkErrorOnce()
