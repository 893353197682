import { Alert, AlertProps } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import AppTypography, { AppTypographyProps } from './AppTypography'
import { makeStyles } from '@mui/styles'

interface AlertWrapperProps {
  /** MUI spacing */
  gutterTop?: number
}
const AlertWrapper = styled.div<AlertWrapperProps>`
  padding-top: ${_ => _.theme.spacing(_.gutterTop)};
`
AlertWrapper.defaultProps = {
  gutterTop: 2,
}
export interface AppAlertProps extends AlertProps, AlertWrapperProps {
  typographyProps?: AppTypographyProps
}

const useStyles = makeStyles(_ => ({
  message: { width: '100%' },
}))

export const AppAlert: React.FC<AppAlertProps> = ({
  typographyProps,
  gutterTop,
  ...props
}) => {
  const classes = useStyles()

  return (
    <AlertWrapper gutterTop={gutterTop}>
      <Alert
        classes={{
          message: classes.message,
        }}
        {...props}
      >
        <AppTypography {...typographyProps}>{props.children}</AppTypography>
      </Alert>
    </AlertWrapper>
  )
}
AppAlert.defaultProps = {
  severity: 'info',
  icon: false,
  typographyProps: {
    color: 'primary',
    weight: 'bold',
    align: 'center',
  },
}
export default AppAlert
