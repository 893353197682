import { Button, ButtonProps as MUIButtonProps } from '@mui/material'
import styled, { css } from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Translations } from 'utils'

export interface AppButtonProps extends MUIButtonProps {
  letterCase?: 'lowercase' | 'uppercase' | 'none'
  hasHoverBg?: boolean
  fontWeight?: number | string
  minWidth?: string
  topGutter?: number
  textAlign?: 'left' | 'center' | 'right' | 'inherit'
  /** text is to auto translate */
  translation?: keyof typeof Translations
}

const ButtonWrapper = styled.div<AppButtonProps>`
  padding-top: ${_ => _.topGutter}px;
  text-align: ${_ => _.textAlign};
  button {
    border-radius: 28px;
    font-weight: ${_ => _.fontWeight};
    min-width: ${_ => _.minWidth};
    text-transform: ${_ => _.letterCase};
    :hover {
      ${_ =>
        !_.hasHoverBg
          ? css`
              background-color: inherit;
            `
          : {}}
    }
  }
`

export const AppButton: React.FC<AppButtonProps> = ({
  letterCase,
  hasHoverBg,
  fontWeight,
  minWidth,
  topGutter,
  textAlign,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <ButtonWrapper
      letterCase={letterCase}
      hasHoverBg={hasHoverBg}
      fontWeight={fontWeight}
      minWidth={minWidth}
      topGutter={topGutter}
      textAlign={textAlign}
    >
      <Button data-testid="button" {...props}>
        {props.translation
          ? t(Translations[props.translation])
          : props.children}
      </Button>
    </ButtonWrapper>
  )
}
AppButton.defaultProps = {
  letterCase: 'uppercase',
  hasHoverBg: true,
  fontWeight: 'bold',
  minWidth: '180px',
  topGutter: 0,
  textAlign: 'inherit',
  type: 'button',
}
export default AppButton
