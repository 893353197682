import { Currency, CurrencyFormat } from 'typedef/ticket'
import { layoutSlice } from 'store/slices'
import {
  LotCustomStyles,
  CustomStylesElement,
  CustomStylesValue,
} from 'typedef'
import bg from 'assets/images/bg.png'
import localCurrency from 'locale-currency'

export const centsToDollars = (amount?: number, currency?: string) => {
  if (typeof amount !== 'number') {
    return !!currency ? `${currency} -` : '-'
  }

  return !!currency
    ? `${currency}${(amount / 100).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`
    : (amount / 100).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
}
/**
 * @param number: with country code (+48123123123)
 * @param contryCode: integer
 */
export const trimCountryCode = (number: string, countryCode: number) => {
  if (!number || !countryCode) return ''
  return number.slice(1).replace(countryCode.toString(), '')
}

export const isPageRefreshed = () => {
  return window.performance && performance.navigation.type === 1
}

export const formatCurrency = (currency: Currency, amount = 0) => {
  if (!currency) return null
  const { cultureName, currencyFormat, currencySymbol } = currency
  if (
    currencyFormat !== CurrencyFormat.BeforeAmount &&
    currencyFormat !== CurrencyFormat.AfterAmount
  ) {
    if (currencySymbol) {
      return `${currencySymbol}${(amount / 100).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`
    } else {
      if (!cultureName) return '-'
      const formattedCultureName = cultureName.replace('_', '-')
      const currency = localCurrency.getCurrency(cultureName)
      return new Intl.NumberFormat(formattedCultureName, {
        style: 'currency',
        currency,
      }).format(amount / 100)
    }
  }
  if (currencyFormat === CurrencyFormat.BeforeAmount) {
    return `${currencySymbol}${(amount / 100).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`
  }
  if (currencyFormat === CurrencyFormat.AfterAmount) {
    return `${(amount / 100).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}${currencySymbol}`
  }
}

export const chunk = (arr: any[], size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )

export const parseCustomStyles = (
  arg?: LotCustomStyles[]
): layoutSlice.LayoutState['customStyles'] => {
  let cardBgUrl = '#fff'
  let headerBgUrl = bg
  if (!arg) return { cardBgUrl, headerBgUrl }
  const header = arg.find(el => el.elementType === CustomStylesElement.Header)
  const cardBg = arg.find(
    el => el.elementType === CustomStylesElement.CardBackground
  )
  if (header && header.valueType === CustomStylesValue.Url) {
    headerBgUrl = header.value
  }
  if (cardBg && cardBg.valueType === CustomStylesValue.Url) {
    cardBgUrl = cardBg.value
  }
  return { cardBgUrl, headerBgUrl }
}
