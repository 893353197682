import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Translations } from 'utils'
import { Input } from './Input'
import React from 'react'

export const PhoneNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`

export const PhoneNumberInput: typeof Input = props => {
  const { t } = useTranslation()
  return (
    <Input
      placeholder={t(Translations.PHONE_NUMBER)}
      type="tel"
      autoComplete="tel"
      name="phoneNumber"
      autoFocus
      inputMode="numeric"
      fullWidth
      data-testid="phoneNumberInput"
      {...props}
    />
  )
}
export { default as CountryCodeInput } from './CountryCodeInput'
export default PhoneNumberInput
