import { useFormikContext } from 'formik'
import { PeriodMode, T2Fields } from 'pages/park_car/ParkCarT2'
import React from 'react'
import { AppTabButtons } from 'shared'
import { AppButtonProps } from 'shared/AppButton'
export interface PeriodSwitchProps {}

export const PeriodSwitch: React.FC<PeriodSwitchProps> = props => {
  const { setFieldValue, values } = useFormikContext<T2Fields>()

  const periodButtonsOptions: {
    name: PeriodMode
    translation: AppButtonProps['translation']
  }[] = [
    { name: 'parkFor', translation: 'PARK_FOR' },
    {
      name: 'parkUntil',
      translation: 'PARK_UNTIL',
    },
  ]

  return (
    <AppTabButtons
      selected={values.periodMode}
      onClick={v => setFieldValue('periodMode', v)}
      options={periodButtonsOptions}
    />
  )
}

export default PeriodSwitch
