import { unwrapResult } from '@reduxjs/toolkit'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, AppPopup, AppTypography, FlexBox } from 'shared'
import { contactInformationsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import styled from 'styled-components'
import { CompanyContactInfo } from 'typedef/companyDetails'
import Translations from 'utils/translations'

export interface NeedHelpPopupProps {
  showNeedHelpPopup: boolean
  companyDetails?: CompanyContactInfo
  lotId: string
  closePopup: () => void
}

const StyledLink = styled.a`
  color: ${props => props.theme.palette.primary.main};
  font-weight: bold;
  text-decoration: none;
  margin-left: 4px;
`

export const NeedHelpPopup = ({
  closePopup,
  showNeedHelpPopup,
  lotId,
}: NeedHelpPopupProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [companyDetails, setCompanyDetails] =
    React.useState<CompanyContactInfo>(null)
  const [companyDetailsError, setCompanyDetailsError] = React.useState(false)

  const fetchCompanyContactInfo = React.useCallback(async () => {
    const promise = await dispatch(
      contactInformationsSlice.getCompanyContactInfo({
        lotId,
      })
    )
    if (
      contactInformationsSlice.getCompanyContactInfo.fulfilled.match(promise)
    ) {
      const res = unwrapResult(promise)
      setCompanyDetails(res)
    } else setCompanyDetailsError(true)
  }, [lotId, dispatch])

  const handleClosePopup = () => {
    closePopup()
    if (companyDetailsError) {
      setCompanyDetailsError(false)
      fetchCompanyContactInfo()
    }
  }

  React.useEffect(() => {
    fetchCompanyContactInfo()
  }, [fetchCompanyContactInfo])

  return (
    <AppPopup
      open={showNeedHelpPopup}
      variant="info"
      fullWidth={false}
      closePopup={closePopup}
      customIcon={
        <AppTypography gutterBottom display="inline" weight={'bold'}>
          {t(Translations.CONTACT_INFORMATION)}
        </AppTypography>
      }
    >
      <FlexBox hasBottomMargin hasTopMargin marginSpacing={2}>
        {companyDetailsError ? (
          <AppTypography gutterBottom display="inline">
            {t(Translations.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN)}
          </AppTypography>
        ) : (
          <>
            <AppTypography gutterBottom display="inline">
              {t(Translations.COMPANY_NAME)}: {companyDetails?.companyName}
            </AppTypography>
            <AppTypography gutterBottom display="inline">
              {t(Translations.PHONE)}:
              <StyledLink href={`tel:${companyDetails?.phone}`}>
                {companyDetails?.phone}
              </StyledLink>
            </AppTypography>
            <AppTypography gutterBottom display="inline">
              {t(Translations.WEBSITE)}:
              <StyledLink
                target="_blank"
                rel="noopener noreferrer"
                href={`https://${companyDetails?.website}`}
              >
                {companyDetails?.website}
              </StyledLink>
            </AppTypography>
          </>
        )}
      </FlexBox>
      <FlexBox>
        <AppButton
          variant="contained"
          color="primary"
          onClick={handleClosePopup}
        >
          {t(Translations.OK)}
        </AppButton>
      </FlexBox>
    </AppPopup>
  )
}

export default NeedHelpPopup
