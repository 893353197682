import React from 'react'
import { useSelector } from 'react-redux'
import { carsSlice, customerSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'

export interface useGetCarsProps {}

export const useGetCars = () => {
  const dispatch = useAppDispatch()
  const { getCarsStatus } = useSelector(carsSlice.promiseStatusSelectors)
  const { customerSignedIn } = useSelector(customerSlice.selectCustomerStatus)

  React.useEffect(() => {
    if (getCarsStatus.idle && customerSignedIn) {
      dispatch(carsSlice.getCars())
    }
  }, [customerSignedIn, dispatch, getCarsStatus.idle])
}

export default useGetCars
