import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, batch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppButton } from 'shared'
import { RootState } from 'store/rootReducer'
import {
  carsSlice,
  receiptsSlice,
  customerSlice,
  sliceUtil,
  creditCardsSlice,
  ticketsSlice,
} from 'store/slices'
import { useAppDispatch } from 'store/store'
import { Translations } from 'utils'
import * as ParkerComponent from '.'
export interface ParkerContainerProps {}

export const ParkerContainer: React.FC<ParkerContainerProps> = props => {
  const snackbarKey = React.useRef(null)
  const dispatch = useAppDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const { getCarsStatus } = useSelector(carsSlice.promiseStatusSelectors)
  const { getReceiptsStatus } = useSelector(
    receiptsSlice.promiseStatusSelectors
  )
  const { getCustomerDetails } = useSelector(
    customerSlice.stateSelectors
  ).promisesStatus

  const getCustomerDetailsStatus = sliceUtil.fetchStatus(getCustomerDetails)
  const { getCreditCardsStatus } = useSelector(
    creditCardsSlice.promiseStatusSelectors
  )

  const { ticketId } = useParams<{ ticketId: string }>()
  const ticket = useSelector((state: RootState) =>
    ticketsSlice.selectById(state, ticketId)
  )

  const reFetchData = React.useCallback(
    (close?: boolean) => {
      batch(() => {
        if (getCustomerDetailsStatus.rejected) {
          dispatch(customerSlice.getCustomerDetails())
        }
        if (getCarsStatus.rejected) {
          dispatch(carsSlice.getCars())
        }
        if (getReceiptsStatus.rejected) {
          dispatch(receiptsSlice.getReceipts())
        }
        if (getCreditCardsStatus.rejected) {
          dispatch(
            creditCardsSlice.getCreditCards({ lotId: ticket?.lot?.lotId })
          )
        }
        if (close) {
          closeSnackbar(snackbarKey.current)
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      closeSnackbar,
      dispatch,
      getCarsStatus.rejected,
      getCreditCardsStatus.rejected,
      getCustomerDetailsStatus.rejected,
      getReceiptsStatus.rejected,
    ]
  )

  const action = React.useCallback(
    (key: any) => (
      <AppButton
        color="primary"
        minWidth="auto"
        size="small"
        onClick={() => {
          closeSnackbar(key)
          reFetchData()
        }}
      >
        {t(Translations.REFRESH)}
      </AppButton>
    ),
    [closeSnackbar, reFetchData, t]
  )

  React.useEffect(() => {
    if (getCustomerDetailsStatus.idle) {
      dispatch(customerSlice.getCustomerDetails())
    }
    if (getCarsStatus.idle) {
      dispatch(carsSlice.getCars())
    }
    if (getReceiptsStatus.idle) {
      dispatch(receiptsSlice.getReceipts())
    }
    if (getCreditCardsStatus.idle) {
      dispatch(creditCardsSlice.getCreditCards({ lotId: ticket?.lot?.lotId }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    getCarsStatus.idle,
    getCustomerDetailsStatus.idle,
    getReceiptsStatus.idle,
    getCreditCardsStatus.idle,
  ])

  React.useEffect(() => {
    if (
      (getCustomerDetailsStatus.rejected ||
        getCarsStatus.rejected ||
        getReceiptsStatus.rejected ||
        getCreditCardsStatus.rejected) &&
      !getCustomerDetailsStatus.pending &&
      !getCarsStatus.pending &&
      !getReceiptsStatus.pending &&
      !getCreditCardsStatus.pending
    ) {
      snackbarKey.current = enqueueSnackbar(
        t(Translations.SOME_DATA_HAS_NOT_BEEN_UPDATED),
        {
          persist: true,
          key: 'fetchError',
          action,
        }
      )
    }
  }, [
    dispatch,
    enqueueSnackbar,
    getCustomerDetailsStatus.rejected,
    getCarsStatus.rejected,
    getReceiptsStatus.rejected,
    getCreditCardsStatus.rejected,
    t,
    action,
    getCustomerDetailsStatus.pending,
    getCarsStatus.pending,
    getReceiptsStatus.pending,
    getCreditCardsStatus.pending,
  ])

  return (
    <>
      <ParkerComponent.MyProfile onClickRefresh={() => reFetchData(true)} />
      <ParkerComponent.MyCreditCards onClickRefresh={() => reFetchData(true)} />
      <ParkerComponent.LastReceipt onClickRefresh={() => reFetchData(true)} />
      <ParkerComponent.MyCars onClickRefresh={() => reFetchData(true)} />
    </>
  )
}

export default ParkerContainer
