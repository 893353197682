import { Divider, List, ListItem } from '@mui/material'
import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, AppDialog, AppTypography, FlexBox } from 'shared'
import {
  PaymentOperation,
  PaymentOperationType,
  Settlement,
} from 'typedef/ticket'
import { formatCurrency, Translations } from 'utils'
import { makeStyles } from '@mui/styles'

interface DetailsDialogProps {
  settlement: Settlement
  show: boolean
  onClick: () => void
}

const useStyles = makeStyles(() => ({
  item: {
    justifyContent: 'space-between',
  },
}))

const DetailsDialog: React.FC<DetailsDialogProps> = props => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    convenienceFee,
    currency,
    finalAmount,
    paymentOperationList,
    convenienceFeeName,
  } = props.settlement

  const label = (type: PaymentOperationType, name: string | undefined) => {
    const nameText = !!name ? name : ''
    switch (type) {
      case PaymentOperationType.Payment:
        return `${t(t(Translations.PARKED))} ${nameText}`
      case PaymentOperationType.Coupon:
        return `${t(Translations.VALIDATED)} (${nameText})`
      case PaymentOperationType.BuyMoreTime:
        return `${t(Translations.BUY_MORE_TIME)} ${nameText}`
    }
  }

  const value = (type: PaymentOperationType, value: number) => {
    const val = formatCurrency(currency, value)
    return type === PaymentOperationType.Coupon ? `(${val})` : `${val}`
  }

  return (
    <AppDialog
      open={props.show}
      onBackdropClick={props.onClick}
      fullWidth={false}
    >
      <AppTypography weight="bold" align="center" color="textPrimary">
        {t(Translations.TICKET_DETAILS)}
      </AppTypography>
      <List dense>
        {paymentOperationList.map((el: PaymentOperation) => (
          <ListItem key={nanoid()} className={classes.item} disableGutters>
            <AppTypography>{label(el.type, el.name)}</AppTypography>
            <AppTypography>{value(el.type, el.amount)}</AppTypography>
          </ListItem>
        ))}

        {!!convenienceFee && (
          <ListItem className={classes.item} disableGutters>
            <AppTypography>
              {convenienceFeeName || t(Translations.CONVENIENCE_FEE)}
            </AppTypography>
            <AppTypography>
              {formatCurrency(currency, convenienceFee)}
            </AppTypography>
          </ListItem>
        )}
      </List>
      <Divider />
      <AppTypography
        align="right"
        weight="bold"
        variant="subtitle1"
        variantMapping={{
          subtitle1: 'p',
        }}
      >
        {t(Translations.TOTAL)}
        {': '}
        {formatCurrency(currency, finalAmount)}
      </AppTypography>
      <FlexBox hasTopMargin>
        <AppButton onClick={props.onClick} color="primary" variant="contained">
          {t(Translations.OK)}
        </AppButton>
      </FlexBox>
    </AppDialog>
  )
}

export default DetailsDialog
