import { unwrapResult } from '@reduxjs/toolkit'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { batch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { paths } from 'routing'
import { TRANSITION_TIME } from 'shared/layout/constants'
import ParkingRatesModal from 'shared/ParkingRates/ParkingRatesModal'
import { RootState } from 'store/rootReducer'
import {
  customerSlice,
  mapSlice,
  parkCarSlice,
  sliceUtil,
  ticketsSlice,
  simulatePriceSlice,
} from 'store/slices'
import { SelectParkedCar } from 'store/slices/ticketsSlice.types'
import { useAppDispatch } from 'store/store'
import { chunk, Translations } from 'utils'
import * as MapComponent from './MapComponents'
import MapSelectCarDialog from './MapComponents/SelectCarDialog'
import MapSearchView from './MapSearchView'
import { apiDateFormat } from 'utils'

export interface MapContainerProps {}

export const MapContainer: React.FC<MapContainerProps> = props => {
  const [searchValue, setSearchValue] = React.useState('')
  const searchBoxRef = React.useRef(null)
  const [showSearchView, setShowSearchView] = React.useState(false)
  const [showSelectCar, setShowSelectCar] = React.useState(false)
  const [disabledFindCar, setDisabledFindCar] = React.useState(true)
  const [showPricingModal, setShowPricingModal] = React.useState(false)
  const [showSelectLotDialog, setShowSelectLotDialog] = React.useState(false)
  const availableCalls = useSelector(
    simulatePriceSlice.selectAvailableCallsNumber
  )

  const { pricingMode, simulateEndTime, simulateStartTime } = useSelector(
    simulatePriceSlice.stateSelectors
  )
  const selectMissingPricesLots = useSelector(
    simulatePriceSlice.selectMissingPricesLots
  )
  const dispatch = useAppDispatch()
  const selectedLot = useSelector((state: RootState) =>
    mapSlice.selectById(state, state.map.selectedLot)
  )
  const { enqueueSnackbar } = useSnackbar()

  const { t } = useTranslation()
  const { promisesStatus, findCarMode, myPosition, geoError } = useSelector(
    mapSlice.stateSelectors
  )
  const { customerSignedIn, customerSignedInAsGuest } = useSelector(
    customerSlice.selectCustomerStatus
  )
  const getLotDetailsMapStatus = sliceUtil.fetchStatus(
    promisesStatus.getLotDetailsMap
  )
  const parkedCars = useSelector(ticketsSlice.selectParkedCars)
  const { push } = useHistory()

  const search = React.useCallback(async () => {
    dispatch(mapSlice.actions.unselectLot())
    const promise = await dispatch(
      mapSlice.getLotsByPcode({
        pcode: searchValue.trim(),
      })
    )
    if (mapSlice.getLotsByPcode.fulfilled.match(promise)) {
      const { lots } = unwrapResult(promise)
      searchBoxRef.current.blur()
      if (lots.length === 1) {
        batch(() => {
          dispatch(customerSlice.actions.setCorporationGlobalPcode(''))
          dispatch(customerSlice.actions.setCorporationToken(''))
          dispatch(
            mapSlice.actions.selectLot({ lotId: lots[0].lotId, position: true })
          )
          dispatch(mapSlice.actions.setZoom(17))
          dispatch(mapSlice.getLotDetailsMap({ lotId: lots[0].lotId }))
          setShowSearchView(false)
        })
      } else if (lots.length > 1) {
        if (myPosition) {
          batch(() => {
            dispatch(mapSlice.actions.focusMapOnMyLocation())
            dispatch(mapSlice.actions.setZoom(12))
            setShowSearchView(false)
            dispatch(
              customerSlice.actions.setCorporationGlobalPcode(
                searchValue.trim()
              )
            )
          })
        } else {
          batch(() => {
            dispatch(mapSlice.getLotDetailsMap({ lotId: lots[0].lotId }))
            dispatch(
              mapSlice.actions.selectLot({
                lotId: lots[0].lotId,
                position: true,
              })
            )
            dispatch(mapSlice.actions.setZoom(1))
            setShowSearchView(false)
            dispatch(
              customerSlice.actions.setCorporationGlobalPcode(
                searchValue.trim()
              )
            )
          })
        }
      } else {
        enqueueSnackbar(t(Translations.NO_PARKING_FOUND))
      }
    } else {
      enqueueSnackbar(t(Translations.NO_PARKING_FOUND))
    }
  }, [dispatch, enqueueSnackbar, searchValue, t, myPosition])

  const onClickGps = () => {
    if (myPosition) {
      dispatch(mapSlice.actions.focusMapOnMyLocation())
    } else {
      dispatch(mapSlice.actions.setGeo(true))
    }
  }

  const onClickFindCar = React.useCallback(() => {
    if (customerSignedInAsGuest && !parkedCars.length && !findCarMode) {
      return push(paths.parking.findCar)
    }
    if (customerSignedIn && !parkedCars.length && !findCarMode) {
      return enqueueSnackbar(t(Translations.NO_PARKED_CARS))
    }
    if (!findCarMode) {
      setShowSelectCar(true)
    } else {
      dispatch(mapSlice.actions.exitParkedCarMode())
    }
  }, [
    customerSignedIn,
    customerSignedInAsGuest,
    dispatch,
    enqueueSnackbar,
    findCarMode,
    parkedCars.length,
    push,
    t,
  ])

  const onClickSelectCar = React.useCallback(
    (arg: SelectParkedCar) => {
      batch(() => {
        dispatch(mapSlice.getLotDetailsMap({ lotId: arg.lot.lotId }))
        dispatch(mapSlice.actions.foundParkedCar({ lotId: arg.lot.lotId }))
        setShowSelectCar(false)
      })
    },

    [dispatch]
  )

  const onParkCarClick = React.useCallback(async () => {
    if (!selectedLot?.lotId) return
    dispatch(parkCarSlice.actions.resetPromiseStatus('getLotDetailsPark'))
    if (selectedLot.landingPageUrl) {
      window.location.href = selectedLot.landingPageUrl
    } else push(`${paths.parking.parkCar}/${selectedLot.lotId}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [push, selectedLot])

  const onTicketClick = (ticketId: string) => {
    dispatch(
      ticketsSlice.actions.setSelectedTicket({
        ticketId,
      })
    )
    push(paths.parking.tickets)
  }
  const onClickPricing = React.useCallback(async () => {
    if (!pricingMode) {
      setShowPricingModal(true)
    } else {
      dispatch(simulatePriceSlice.actions.resetState())
    }
  }, [dispatch, pricingMode])

  const handleCalculate = React.useCallback(
    async (entryDate: string, exitDate: string) => {
      const simulateStartTime = apiDateFormat(entryDate)
      const simulateEndTime = apiDateFormat(exitDate)
      dispatch(simulatePriceSlice.actions.setPricingMode(true))
      dispatch(
        simulatePriceSlice.actions.setDates({
          simulateEndTime,
          simulateStartTime,
        })
      )
      setShowPricingModal(false)
    },
    [dispatch]
  )

  React.useEffect(() => {
    if (customerSignedIn) {
      parkedCars.length ? setDisabledFindCar(false) : setDisabledFindCar(true)
    }
    if (customerSignedInAsGuest) {
      setDisabledFindCar(false)
    }
  }, [customerSignedIn, customerSignedInAsGuest, parkedCars.length])

  React.useEffect(() => {
    if (geoError) {
      enqueueSnackbar(geoError, {
        key: 'geoError',
        onExited: () => dispatch(mapSlice.actions.setGeoError(null)),
      })
    }
  }, [dispatch, enqueueSnackbar, geoError])

  React.useEffect(() => {
    if (selectMissingPricesLots.length && pricingMode && availableCalls) {
      const parkings = chunk(selectMissingPricesLots, 4)[0]
      dispatch(
        simulatePriceSlice.getSimulatedPrices({
          simulateEndTime,
          simulateStartTime,
          parkings,
        })
      )
    }
  }, [
    selectMissingPricesLots,
    pricingMode,
    dispatch,
    simulateEndTime,
    simulateStartTime,
    availableCalls,
  ])
  return (
    <>
      <MapComponent.SearchBox
        onSearchClick={search}
        showSearchView={setShowSearchView}
        isSearchView={showSearchView}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        pending={getLotDetailsMapStatus.pending}
        ref={searchBoxRef}
      />
      <MapComponent.NavButtons
        onClickGps={onClickGps}
        onClickFindCar={onClickFindCar}
        findCarMode={findCarMode}
        disabledFindCar={disabledFindCar}
        pricingMode={pricingMode}
        onClickPricing={onClickPricing}
      />
      <CSSTransition
        in={showSearchView}
        timeout={TRANSITION_TIME}
        classNames="search-view"
        unmountOnExit
      >
        <MapSearchView hideSearchView={() => setShowSearchView(false)} />
      </CSSTransition>
      <MapComponent.Map
        showSelectLotDialog={() => setShowSelectLotDialog(true)}
      />

      <MapComponent.SelectLotDialog
        open={showSelectLotDialog}
        closeDialog={() => setShowSelectLotDialog(false)}
      />
      <ParkingRatesModal
        handleCalculate={handleCalculate}
        open={showPricingModal}
        handleClose={() => setShowPricingModal(false)}
        data-testid="parkingRatesModal"
        title={t(Translations.SEARCH_BEST_PARKING_PRICING)}
      />
      {selectedLot && (
        <MapComponent.LotInfo
          lot={selectedLot}
          loading={getLotDetailsMapStatus.pending}
          onParkCarClick={onParkCarClick}
          onTicketClick={onTicketClick}
        />
      )}
      {showSelectCar && (
        <MapSelectCarDialog
          cars={parkedCars}
          open={showSelectCar}
          closeDialog={() => setShowSelectCar(false)}
          onClick={onClickSelectCar}
        />
      )}
    </>
  )
}

export default MapContainer
