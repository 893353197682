import { BottomNavigation, BottomNavigationAction } from '@mui/material'
import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import paths from 'routing/paths'
import { SharedIcon } from 'shared'
import { BOTTOM_BAR_HEIGHT } from 'shared/layout/constants'
import styled from 'styled-components'
import { makeStyles } from '@mui/styles'

const StyledNavLink = styled(NavLink)`
  &.selected {
    path,
    rect,
    circle {
      stroke: ${_ => _.theme.palette.primary.main};
    }
    circle {
      fill: #fff;
    }
  }
`
const MenuIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    height: 30px;
  }
`
const StyledBottomNavigation = styled(BottomNavigation)`
  button {
    padding: 6px !important;
  }
  button:first-of-type {
    /* for vertical align */
    padding-bottom: 9px !important;
  }
`
const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    padding: 0,
    width: '100%',
    height: BOTTOM_BAR_HEIGHT,
    boxSizing: 'border-box',
    boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 3,
    left: '50%',
    transform: 'translateX(-50%)',
  },
}))

export default function AppBottomNavigation() {
  const classes = useStyles()
  const history = useHistory()

  return (
    <StyledBottomNavigation className={classes.root}>
      <BottomNavigationAction
        onClick={e => {
          e.preventDefault()
          history.push(paths.home.map)
        }}
        icon={
          <StyledNavLink to={paths.home.map} activeClassName="selected">
            <MenuIcon>
              <SharedIcon.MenuMap />
            </MenuIcon>
          </StyledNavLink>
        }
      />
      <BottomNavigationAction
        onClick={e => {
          e.preventDefault()
          history.push(paths.parking.tickets)
        }}
        icon={
          <StyledNavLink to={paths.parking.tickets} activeClassName="selected">
            <MenuIcon>
              <SharedIcon.Parking />
            </MenuIcon>
          </StyledNavLink>
        }
      />
      <BottomNavigationAction
        onClick={e => {
          e.preventDefault()
          history.push(paths.parker.myProfile)
        }}
        icon={
          <StyledNavLink to={paths.parker.myProfile} activeClassName="selected">
            <MenuIcon>
              <SharedIcon.User />
            </MenuIcon>
          </StyledNavLink>
        }
      />
    </StyledBottomNavigation>
  )
}
