import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  AppButton,
  FlexBox,
  FullLayout,
  PaddingContainer,
  PageHeading,
  SharedIcon,
} from 'shared'
import Translations from '../../../../utils/translations'

interface SendMailCompleteProps {
  goBack: () => void
}

const SendMailComplete: React.FunctionComponent<
  SendMailCompleteProps
> = props => {
  const { t } = useTranslation()

  return (
    <FullLayout hasTopBar imageBackground>
      <PaddingContainer>
        <PageHeading
          title={t(Translations.EMAIL_WAS_SENT)}
          subtitle={t(Translations.PLEASE_CHECK_YOUR_INBOX)}
        >
          <SharedIcon.Mail />
        </PageHeading>
        <FlexBox hasTopMargin>
          <AppButton variant="outlined" onClick={props.goBack}>
            {t(Translations.OK)}
          </AppButton>
        </FlexBox>
      </PaddingContainer>
    </FullLayout>
  )
}

export default SendMailComplete
