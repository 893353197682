import { useChangeLayout } from 'hooks'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import paths from 'routing/paths'
import { AppSpinner, ErrorPlaceholder, WhiteBoxContainer } from 'shared'
import { parkCarSlice, validatorsSlice } from 'store/slices/'
import { useAppDispatch } from 'store/store'
import { GetValidatorsParams } from 'typedef/validator'
import Translations from 'utils/translations'
import { Validators } from '.'
import ValidatorsPlaceholder from './ValidatorsPlaceholder'

export const UpfrontValidationView = () => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const partners = useSelector(validatorsSlice.selectAllValidators)
  const status = useSelector(
    validatorsSlice.promiseStatusSelectors
  ).getValidatorsStatus

  const { lot, pricingPayload, providedPlate, providedSpotNumber } =
    useSelector(parkCarSlice.stateSelectors)

  const title = t(Translations.VALIDATE)

  useChangeLayout(
    {
      layout: {
        hasBottomBar: false,
      },
      topbar: {
        title,
        onClickBack: () =>
          push({
            pathname: `${paths.parking.parkCar}/${lot.lotId}`,
            state: {
              preValidationSettings: pricingPayload,
              providedPlate: providedPlate,
              providedSpotNumber: providedSpotNumber,
            },
          }),
      },
      bottomMenu: false,
    },
    'app'
  )

  const fetch = React.useCallback(async () => {
    if (!lot?.lotId) return
    const params: GetValidatorsParams = {
      lotId: lot.lotId,
    }
    const actionResult = await dispatch(validatorsSlice.getValidators(params))
    if (validatorsSlice.getValidators.rejected.match(actionResult)) {
      if (actionResult.meta.condition) return
      enqueueSnackbar(t(Translations.FETCH_RECEIPTS_ERROR))
    }
  }, [dispatch, enqueueSnackbar, t, lot])

  React.useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!lot?.parkerId) {
    push(paths.home.map)
    return null
  }

  return (
    <WhiteBoxContainer minHeight={partners?.length ? 50 : 150}>
      {status.pending ? (
        <AppSpinner show />
      ) : (
        <>
          {status.rejected && <ErrorPlaceholder onClick={fetch} />}
          {status.fulfilled && (
            <>
              {!!partners.length && (
                <Validators parkerId={lot?.parkerId} partners={partners} />
              )}
              {!partners.length && <ValidatorsPlaceholder />}
            </>
          )}
        </>
      )}
    </WhiteBoxContainer>
  )
}
