import { PayloadAction } from '@reduxjs/toolkit'
import { Bounds } from 'google-map-react'
import { ErrorData, FetchStatus, GetLotsPayload } from 'typedef'
import { initialPromiseStatus } from './constants'
import { PromiseStatus } from './slices.types'

type Action = PayloadAction<
  undefined,
  string,
  {
    arg: any
    requestId: string
  },
  never
>
export const pendingPromise = (
  promiseStatus: PromiseStatus,
  action?: Action
) => ({
  ...promiseStatus,
  status: promiseStatus.status.concat(FetchStatus.Pending),
  requestId: action?.meta?.requestId,
})
export const rejectedPromise = (errors: ErrorData) => ({
  ...errors,
  status: [FetchStatus.Rejected],
})
export const fulfilledPromise = () => ({
  ...initialPromiseStatus,
  status: [FetchStatus.Fulfilled],
  time: new Date().getTime(),
})

function isPromiseStatus(x: any): x is PromiseStatus {
  return x.status !== undefined
}

export const fetchStatus = (arg?: FetchStatus[] | PromiseStatus) => {
  if (!arg)
    return {
      idle: true,
      pending: false,
      pendingIdle: false,
      pendingFulfilled: false,
      pendingRejected: false,
      fulfilled: false,
      rejected: false,
    }

  let statuses: FetchStatus[] | PromiseStatus
  if (isPromiseStatus(arg)) {
    statuses = arg.status
  } else {
    statuses = arg
  }

  return {
    idle: statuses.includes(FetchStatus.Idle) && statuses.length === 1,
    pending: statuses.includes(FetchStatus.Pending),
    pendingIdle:
      statuses.filter(
        el => el === FetchStatus.Pending || el === FetchStatus.Idle
      ).length === 2,
    pendingFulfilled:
      statuses.filter(
        el => el === FetchStatus.Pending || el === FetchStatus.Fulfilled
      ).length === 2,
    pendingRejected:
      statuses.filter(
        el => el === FetchStatus.Pending || el === FetchStatus.Rejected
      ).length === 2,
    fulfilled:
      statuses.includes(FetchStatus.Fulfilled) && statuses.length === 1,
    rejected: statuses.includes(FetchStatus.Rejected) && statuses.length === 1,
  }
}

type MapBoundsToState = (bounds: Bounds) => number[]
/** @returns [nw.lng,  se.lat,  se.lng, nw.lat] (cluster order) */
export const mapMapBoundsToCluster: MapBoundsToState = bounds => [
  bounds.nw.lng,
  bounds.se.lat,
  bounds.se.lng,
  bounds.nw.lat,
]

/** @params [nw.lng,  se.lat,  se.lng, nw.lat] (cluster order)
 *  @returns API object
 */
export const mapClusterBoundsToApi = (el: number[]): GetLotsPayload => {
  const rev = el.reverse()
  return {
    northEastBoundLat: rev[0],
    northEastBoundLng: rev[1],
    southWestBoundLat: rev[2],
    southWestBoundLng: rev[3],
  }
}

/** @returns [nw.lng,  se.lat,  se.lng, nw.lat] (cluster order) */
export const mapApiBoundsToCluster = (arg: GetLotsPayload): number[] => [
  arg.southWestBoundLng,
  arg.southWestBoundLat,
  arg.northEastBoundLng,
  arg.northEastBoundLat,
]
