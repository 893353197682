import { ThemeProvider } from '@mui/material/styles'

import { unwrapResult } from '@reduxjs/toolkit'
import { useTokenValidation } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { paths } from 'routing'
import { AppSpinner, PaddingContainer, PageHeading } from 'shared'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'
import { customerSlice, layoutSlice, mapSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { whiteTheme } from 'themes'
import Translations from 'utils/translations'

export interface MapSmsPageProps {}

export const MapSmsPage: React.FC<MapSmsPageProps> = props => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation()
  const history = useHistory()
  const { token } = useParams<{ token: string }>()
  const { setPopup, showPopup } = usePopup()
  const { myPosition, position, geoState } = useSelector(
    mapSlice.stateSelectors
  )
  const effectedRef = React.useRef(false)
  const isCorporation = history.location.pathname.match(
    new RegExp(`^${paths.sms.corp}.*`)
  )
  const validToken = useTokenValidation()
  const { refreshToken } = customerSlice
  const { setLoader } = layoutSlice.actions

  const fetchLots = async () => {
    await trySignIn()
    effectedRef.current = true
    setLoading(true)
    showPopup(false)
    const promise = await dispatch(
      mapSlice.getLots({
        northEastBoundLat: position.lat + 0.033,
        northEastBoundLng: position.lng - 0.033,
        southWestBoundLat: position.lat - 0.033,
        southWestBoundLng: position.lng + 0.033,
        token,
      })
    )
    if (mapSlice.getLots.rejected.match(promise)) {
      setLoading(false)
      setPopup({
        ...promise.payload,
        disableBackdropClick: true,
        buttons: [
          { onClick: () => fetchLots() },
          {
            onClick: () => {
              dispatch(customerSlice.actions.destroySession())
              push(paths.auth.login)
            },
            text: t(Translations.BACK_TO_LOGIN),
          },
        ],
      })
      showPopup(true)
    } else {
      const res = unwrapResult(promise)
      if (isCorporation && res.lots.length && geoState !== 'granted') {
        await dispatch(mapSlice.getLotDetailsMap({ lotId: res.lots[0].lotId }))
        dispatch(
          mapSlice.actions.selectLot({
            lotId: res.lots[0]?.lotId,
            position: true,
          })
        )
        dispatch(mapSlice.actions.setZoom(1))
      } else if (
        isCorporation &&
        res.lots.length &&
        geoState === 'granted' &&
        myPosition
      ) {
        dispatch(mapSlice.actions.focusMapOnMyLocation())
        dispatch(mapSlice.actions.setZoom(12))
      }
      setLoading(false)
      push(paths.home.map)
    }
  }

  const trySignIn = async () => {
    if (validToken) {
      dispatch(setLoader(true))
      await dispatch(refreshToken({ token: validToken.refresh }))
      dispatch(setLoader(false))
    }
  }

  React.useEffect(() => {
    if (geoState && !effectedRef.current) {
      if (geoState === 'granted' && myPosition) {
        fetchLots()
      }
      if (geoState !== 'granted') {
        fetchLots()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoState, myPosition])
  React.useEffect(() => {
    if (isCorporation) {
      dispatch(customerSlice.actions.setCorporationToken(token))
    }
    setLoading(true)
    const timer = setTimeout(() => {
      if (!effectedRef.current) {
        fetchLots()
      }
    }, 5000)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <ThemeProvider theme={whiteTheme}>
      <PaddingContainer>
        <PageHeading title={t(Translations.PARKING_MADE_EASY)} />
        {loading && <AppSpinner show relative height="150px" />}
      </PaddingContainer>
    </ThemeProvider>
  )
}

export default MapSmsPage
