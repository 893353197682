import T from 'utils/translations'
import { ErrorCode, ErrorResponse } from 'typedef'

// TODO:
export const getCreditCards: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.CANNOT_FETCH_CREDIT_CARDS_LIST,
    variant: 'warning',
  },
}

export const addCreditCard: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description:
      T.YOUR_CARD_CANNOT_BE_ADDED_AT_THE_MOMENT_PLEASE_TRY_AGAIN_LATER,
    variant: 'warning',
  },
  [ErrorCode.IncorrectParameterException]: {
    title: T.CANNOT_ADD_CREDIT_CARD,
    description: T.YOUR_CARD_HAS_BEEN_REJECTED,
    variant: 'error',
  },
}

export const creditCardsApiError = { getCreditCards, addCreditCard }
export default creditCardsApiError
