import { ExternalPricingOption, GetLotDetailsParkData } from 'typedef'
import * as Yup from 'yup'
import { licencePlateRegex, Translations } from 'utils'
import { spotNumberSchema } from 'pages/park_car/fields'
import { TFunction } from 'i18next'

export type PeriodMode = 'parkFor' | 'parkUntil'
export interface TimeElements {
  minute: number
  hour: number
  day: number
}
export interface T2Fields {
  licencePlate: string
  spotNumber: string
  isSpotEnabled: boolean
  currentTime?: string
  startDate?: string
  endDate?: string | null
  periodMode: 'parkFor' | 'parkUntil'
  parkUntilDate: string | null
  parkForCustomTimeDate: string | null
  minute: number
  hour: number
  day: number
  parkForDefaultTimeDate: string | null
  defaultMinutes: number | null
  pricings: ExternalPricingOption[]
  selectedPricing: string
  lastListView: 'excerpt' | 'full'
  pricingExpirationDateUtc?: string
}

export const parkCarT2Schema = (
  t: TFunction,
  lot?: GetLotDetailsParkData,
  BMT?: boolean
) =>
  Yup.object().shape({
    licencePlate: Yup.string()
      .required(t(Translations.LICENCE_PLATE_IS_REQUIRED))
      .matches(
        licencePlateRegex,
        t(Translations.ONLY_ALPHANUMERIC_CHARACTERS_ARE_ALLOWED)
      ),

    spotNumber: spotNumberSchema(t, lot),
  })
