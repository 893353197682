import { useFormikContext } from 'formik'
import { T2Fields } from 'pages/park_car/ParkCarT2'
import { TotalText } from 'pages/park_car/ParkCarComponents'
import React from 'react'
import { ExternalPricingOption } from 'typedef'

export interface TotalAmountProps {}

export const TotalAmount: React.FC<TotalAmountProps> = props => {
  const [selected, setSelected] = React.useState<ExternalPricingOption | null>(
    null
  )
  const { values } = useFormikContext<T2Fields>()
  const { selectedPricing, pricings } = values
  React.useEffect(() => {
    if (selectedPricing) {
      const pricing = pricings.find(
        el => el.externalPricingId === selectedPricing
      )
      setSelected(pricing)
    } else {
      setSelected(null)
    }
  }, [pricings, selectedPricing])

  return <TotalText disabled={!selectedPricing} amount={selected?.amount} />
}

export default TotalAmount
