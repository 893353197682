import i18n from 'i18n'
import { ErrorCode, FetchStatus, Response, ErrorData } from 'typedef'
import { defaultErrorData } from './apiErrors/DefaultApiError'

export const isErrorCode = (value: any): boolean => value in ErrorCode

export const getErrorCode = (err: any): ErrorCode => {
  return err?.response?.data?.errors
    ? (err?.response?.data as Response)?.errors[0]?.errorCode
    : ErrorCode.OtherError
}
export function hasErrorCode(toBeDetermined: any): toBeDetermined is ErrorCode {
  if (toBeDetermined in ErrorCode) {
    return true
  }
  return false
}

type ErrorMessage = (
  defaultErrorData: any,
  e?: any,
  errorData?: any
) => ErrorData

export const errorMessage: ErrorMessage = (
  defaultErrorData,
  errorCode,
  errorData
) => {
  let combinedErrorData = defaultErrorData
  if (errorData) {
    combinedErrorData = { ...defaultErrorData, ...errorData }
  }
  if (!Number.isInteger(errorCode)) {
    return combinedErrorData[ErrorCode.OtherError]
  }
  let error = combinedErrorData[errorCode]
  if (!error) {
    error = {}
  }
  error.code = errorCode
  error.title = combinedErrorData[errorCode].title
  error.description = combinedErrorData[errorCode].description
  error.variant = combinedErrorData[errorCode].variant
  if (!error?.variant) {
    error.variant = defaultErrorData[errorCode].variant
  }
  if (!error?.title) {
    error.title = defaultErrorData[errorCode].title
  }
  if (!error?.description) {
    error.description = defaultErrorData[errorCode].description
  }

  return error
}

type GetErrorMessage = (
  errorCode?: number,
  errorData?: Partial<Record<ErrorCode, Partial<ErrorData>>>
) => ErrorData
export const getErrorMessage: GetErrorMessage = (errorCode, errorData) => {
  const error = errorMessage(defaultErrorData, errorCode, errorData)
  return {
    ...error,
    title: i18n.t(error.title),
    description: i18n.t(error.description),
  }
}

/**
 * @deprecated
 */
export const fetchStatusIs = (status: FetchStatus) => ({
  idle: status === FetchStatus.Idle,
  pending: status === FetchStatus.Pending,
  fulfilled: status === FetchStatus.Fulfilled,
  rejected: status === FetchStatus.Rejected,
})

/**
 * @deprecated, moved to slice utils
 */
export const getPromiseStatus = (statuses: FetchStatus[]) => ({
  idle: statuses.includes(FetchStatus.Idle),
  pending: statuses.includes(FetchStatus.Pending),
  pendingIdle:
    statuses.filter(el => el === FetchStatus.Pending || el === FetchStatus.Idle)
      .length === 2,
  pendingFulfilled:
    statuses.filter(
      el => el === FetchStatus.Pending || el === FetchStatus.Fulfilled
    ).length === 2,
  pendingRejected:
    statuses.filter(
      el => el === FetchStatus.Pending || el === FetchStatus.Rejected
    ).length === 2,
  fulfilled: statuses.includes(FetchStatus.Fulfilled),
  rejected: statuses.includes(FetchStatus.Rejected),
})
