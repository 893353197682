import { DialogTitle, Typography } from '@mui/material'
import { DialogProps } from '@mui/material'
import React from 'react'
import { AppDialog, SharedIcon } from 'shared'
import styled from 'styled-components'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'

/**
 * NOTE: Popup with better customization for use inside components
 */
const Text = styled(Typography)`
  padding-bottom: ${_ => _.theme.spacing(3)};
`
const CloseIconBox = styled.div`
  display: flex;
  justify-content: flex-end;
`

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(1.5),
    fontWeight: 'bold',
  },
}))

type PopupProps = {
  variant: 'info' | 'success' | 'warning' | 'error' | 'delete'
  title?: string
  description?: string
  closePopup: () => void
  customIcon?: JSX.Element
  addCloseButton?: boolean
  darkBackground?: boolean
} & DialogProps

const PopupIcon: React.FC<{ variant: PopupProps['variant'] }> = props => {
  const icon = {
    info: <SharedIcon.PopupPlaceholder.InfoIcon />,
    success: <SharedIcon.PopupPlaceholder.SuccessIcon />,
    warning: <SharedIcon.PopupPlaceholder.WarningIcon />,
    error: <SharedIcon.PopupPlaceholder.ErrorIcon />,
    delete: <SharedIcon.PopupPlaceholder.ErrorIcon />,
  }

  return <div>{icon[props.variant]}</div>
}

export const AppPopup: React.FC<PopupProps> = props => {
  const {
    variant,
    title,
    description,
    customIcon,
    addCloseButton,
    closePopup,
    darkBackground,
    ...dialogProps
  } = props

  const classes = useStyles()

  return (
    <AppDialog
      onClose={closePopup}
      {...dialogProps}
      darkBackground={darkBackground}
    >
      {addCloseButton && (
        <CloseIconBox>
          <CloseIcon color="disabled" onClick={() => closePopup()} />
        </CloseIconBox>
      )}
      {customIcon ? <div>{customIcon}</div> : <PopupIcon variant={variant} />}
      {title && (
        <DialogTitle data-testid="popupTitle" classes={{ root: classes.title }}>
          {title}
        </DialogTitle>
      )}
      {description && <Text data-testid="popupDescription">{description}</Text>}
      {props.children}
    </AppDialog>
  )
}

export default AppPopup
