import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={295} height={168} viewBox="0 0 295 168" fill="none" {...props}>
      <g filter="url(#prefix__filter0_d)">
        <rect x={4} width={287} height={160} rx={15} fill="#1565C0" />
      </g>
      <path
        d="M171.293 0C171.293 91 87 103 87 160h189c8.284 0 15-6.716 15-15V15c0-8.284-6.7-15-14.984-15H171.293z"
        fill="#0A58B0"
      />
      <rect
        x={24.5}
        y={21.5}
        width={42.154}
        height={32}
        rx={3.5}
        fill="url(#prefix__paint1_linear)"
        stroke="#B1831F"
      />
      <path
        d="M38.695 41.307c-.202.801-.31 1.654-.31 2.538 0 4.673 3.03 8.462 6.769 8.462 3.738 0 6.77-3.789 6.77-8.462 0-.884-.11-1.737-.31-2.538-.18-.705-.537-2.454-.537-3.808 0-1.354 1.128-3.102 1.692-3.808h14.385M51.923 42.154h15.23M38.385 33.691H24M38.385 42.154H24M51.71 29.038c-.752-3.65-3.402-6.347-6.556-6.347-3.739 0-6.77 3.789-6.77 8.462 0 .884.11 1.737.31 2.538.18.706.537 2.454.537 3.808 0 1.354-.358 3.103-.536 3.808"
        stroke="#B1831F"
      />
      <rect x={217} y={19} width={54} height={36} rx={2} fill="#fff" />
      <path
        d="M239.929 31.215l-2.103 12.476h3.364l2.105-12.476h-3.366zM235.006 31.23l-3.295 8.508-.351-1.285c-.65-1.548-2.494-3.771-4.66-5.172l3.013 10.404 3.559-.006 5.297-12.452-3.563.002z"
        fill="#1565C0"
      />
      <path
        d="M230.083 32.121c-.195-.76-.762-.987-1.465-1.014h-5.215l-.044.249c4.059.995 6.744 3.393 7.859 6.276l-1.135-5.511zM250.112 33.606a6.154 6.154 0 012.518.478l.304.144.455-2.704a8.606 8.606 0 00-3.014-.524c-3.324 0-5.666 1.692-5.685 4.117-.021 1.792 1.669 2.793 2.946 3.39 1.311.612 1.75 1.002 1.744 1.548-.01.835-1.045 1.218-2.011 1.218-1.347 0-2.063-.188-3.167-.653l-.434-.2-.473 2.793c.787.35 2.24.649 3.748.665 3.536 0 5.833-1.672 5.858-4.264.014-1.417-.883-2.5-2.827-3.387-1.176-.579-1.896-.964-1.889-1.548 0-.518.611-1.073 1.927-1.073zM262.021 31.229h-2.599c-.807 0-1.407.222-1.762 1.035l-4.994 11.434h3.532s.576-1.538.707-1.874l4.307.005c.101.435.41 1.869.41 1.869h3.121l-2.722-12.47zm-4.148 8.041c.277-.716 1.34-3.487 1.34-3.487-.018.034.274-.721.448-1.191l.227 1.076.779 3.602h-2.794z"
        fill="#1565C0"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={87}
          y1={160}
          x2={291}
          y2={160}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0A58B0" />
          <stop offset={1} stopColor="#0A58B0" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={24}
          y1={21}
          x2={67.154}
          y2={54}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C966" />
          <stop offset={0.516} stopColor="#FFE7BD" />
          <stop offset={1} stopColor="#E1A448" />
        </linearGradient>
        <filter
          id="prefix__filter0_d"
          x={0}
          y={0}
          width={295}
          height={168}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent
