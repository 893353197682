import mock from '.'
import { Response } from '../../typedef/api'
import { cards } from '__fixtures__/myCreditCards'
import { GetCreditCardsData, AddCreditCardData } from 'typedef/creditCards'
import { nanoid } from '@reduxjs/toolkit'

mock.onGet('/CreditCard').reply(config => {
  return [
    200,
    {
      data: { creditCards: cards },
    } as Response<GetCreditCardsData>,
  ]
})

mock.onDelete('/CreditCard').reply(config => {
  const data = config.data

  if (!data) return [400]

  return [200, {} as Response<boolean>]
})

mock.onPost('/CreditCard').reply(config => {
  console.log({ config })
  return [
    200,
    {
      // errors: [{ errorCode: 4020 }],
      data: { creditCardId: nanoid() },
    } as Response<AddCreditCardData>,
  ]
})
