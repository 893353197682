import * as React from 'react'
import { connect } from 'react-redux'
import { Popup, PopupProps } from 'shared'
import { AppState } from '../../store/store'

interface GlobalPopupProps {
  popupProps: PopupProps
}

const GlobalPopup: React.FunctionComponent<GlobalPopupProps> = props => {
  return <Popup {...props.popupProps} />
}

const mapStateToProps = (state: AppState): GlobalPopupProps => ({
  popupProps: { ...state.popup.popup, open: state.popup.show },
})

export default connect(mapStateToProps, {})(GlobalPopup)
