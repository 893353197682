import axios from 'api'
import { Response } from 'typedef/api'
import { LogActionRequest } from 'typedef/log'
import { LOG } from './constants'

export const logAction = async (params: LogActionRequest) => {
  try {
    const response = await axios.post<Response<boolean>>(LOG, params)
    return response.data.data
  } catch (err) {
    throw err
  }
}
