import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  SvgIcon,
} from '@mui/material'
import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppDialog, AppTypography, SharedIcon } from 'shared'
import { SelectParkedCar } from 'store/slices/ticketsSlice.types'
import { Translations } from 'utils'
import { makeStyles } from '@mui/styles'

export interface SelectCarDialogProps {
  cars: SelectParkedCar[]
  open: boolean
  onClick: (arg: SelectParkedCar) => void
  closeDialog: () => void
}

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 36,
  },
  rootDialog: {
    minWidth: 280,
  },
}))

export const SelectCarDialog: React.FC<SelectCarDialogProps> = props => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <AppDialog
      open={props.open}
      onBackdropClick={props.closeDialog}
      fullWidth={false}
    >
      <AppTypography
        weight="bold"
        align="center"
        color="textPrimary"
        gutterBottom
        classes={{ root: classes.rootDialog }}
      >
        {t(Translations.FIND_CAR)}
      </AppTypography>
      <List>
        {props.cars.map(el => (
          <React.Fragment key={nanoid()}>
            <ListItem button onClick={() => props.onClick(el)}>
              <ListItemIcon className={classes.root}>
                <SharedIcon.Plate />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <AppTypography color="textPrimary">
                    {el.plate || el.lot.lotName}
                  </AppTypography>
                }
              />
              <ListItemSecondaryAction>
                <SvgIcon>
                  <SharedIcon.Outlined.Pin />
                </SvgIcon>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </AppDialog>
  )
}

export default SelectCarDialog
