import tokensStorageService from 'utils/tokenStorageService'

const useTokenValidation = () => {
  const token = tokensStorageService.get()
  if (!token) {
    return false
  }
  if (token.expires < Date.now()) {
    return false
  }
  if (token.expires > Date.now()) {
    return token
  }
  return false
}

export default useTokenValidation
