import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { externalParkCarPath, ParkMode, PathView } from '../constants'

export const useParkCarPath = () => {
  const { lotId, carId } = useParams<{ lotId?: string; carId?: string }>()
  const [parkMode, setParkMode] = React.useState<ParkMode>(
    lotId ? 'parkCar' : 'addTime'
  )
  const { push } = useHistory()

  React.useEffect(() => {
    if (carId) {
      setParkMode('addTime')
      return
    }
    if (lotId) {
      setParkMode('parkCar')
      return
    }
  }, [carId, lotId])

  const path = React.useCallback(
    (view?: PathView, isLink?: boolean) =>
      externalParkCarPath(parkMode, isLink && (lotId || carId), view),
    [carId, lotId, parkMode]
  )
  const onClick = React.useCallback(
    (view?: PathView, state?: unknown) => push(path(view, true), state),
    [path, push]
  )

  return [path, onClick] as const
}

export default useParkCarPath
