import T from 'utils/translations'
import { ErrorCode, ErrorResponse } from 'typedef'

export const sendReceipt: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.YOUR_RECEIPT_CANNOT_BE_SENT,
    variant: 'warning',
  },
}
export const getReceipts: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.UNEXPECTED_ERROR_PLEASE_TRY_AGAIN,
    variant: 'warning',
  },
}
export const sendQuestion: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.YOUR_QUESTION_CANNOT_BE_SENT,
    variant: 'warning',
  },
}

export const ReceiptsApiError = {
  sendReceipt,
  getReceipts,
  sendQuestion,
}
export default ReceiptsApiError
