import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, FlexBox } from 'shared'
import styled from 'styled-components'
import Translations from 'utils/translations'

const StyledAppButton = styled(AppButton)`
  width: 100%;
  border-radius: 5px !important;
  padding: 20px 30px;
  border-color: ${_ => _.theme.palette.divider};
`
export interface SelectPricingSectionProps {
  onClick: () => void
}

export const SelectPricingSection: React.FC<SelectPricingSectionProps> = props => {
  const { t } = useTranslation()

  return (
    <FlexBox>
      <StyledAppButton
        size="large"
        variant="outlined"
        color="primary"
        onClick={props.onClick}
      >
        {t(Translations.SELECT_PRICING)}
      </StyledAppButton>
    </FlexBox>
  )
}

export default SelectPricingSection
