import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { parkCarSlice, ticketsSlice } from 'store/slices'
import { ParkCarState } from 'store/slices/parkCarSlice types'
import { AppState, useAppDispatch } from 'store/store'
import { PaymentOperationType, Validations } from 'typedef'
import useParkMode from './useParkMode'

export const useParkDetails = () => {
  const dispatch = useAppDispatch()
  const { parkingDetails } = useSelector(parkCarSlice.stateSelectors)
  const { pricingPayload, addTimeCard, isPaymentRequired } = useSelector(
    parkCarSlice.stateSelectors
  )

  const parkMode = useParkMode()
  const {
    parkingExpireDateTime,
    parkingStartDateTime,
    totalAmount,
    upfrontCoupons,
    fees,
  } = parkingDetails
  const { ticketId, carId } = useParams<{ ticketId: string; carId: string }>()
  const ticket = useSelector((state: AppState) =>
    ticketsSlice.selectById(state, ticketId || carId)
  )
  const setIsPaymentRequired = (arg: boolean) =>
    dispatch(parkCarSlice.actions.setIsPaymentRequired(arg))

  React.useEffect(() => {
    if (ticket) {
      const validations: Validations[] = ticket.settlement.paymentOperationList
        .filter(e => e.type === PaymentOperationType.Coupon)
        .map(e => ({ name: e.validatorName, amount: e.amount }))
      dispatch(
        parkCarSlice.actions.updateParkingDetails<
          Partial<ParkCarState['parkingDetails']>
        >({
          parkingExpireDateTime:
            parkMode === 'parkCar'
              ? ticket?.parkingExpireDateTime
              : parkingExpireDateTime,
          parkingStartDateTime:
            parkMode === 'addMoreTime'
              ? ticket.parkingExpireDateTime
              : ticket.parkingBeginDateTime,
          totalAmount:
            parkMode === 'parkCar'
              ? ticket.settlement.finalAmount
              : totalAmount,
          fees:
            parkMode === 'parkCar'
              ? [
                  {
                    amount: ticket.settlement.convenienceFee,
                    label: ticket.settlement.convenienceFeeName,
                  },
                ]
              : fees,
          currency: ticket.settlement.currency,
          upfrontCoupons: parkMode === 'parkCar' ? validations : upfrontCoupons,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    parkMode,
    parkingExpireDateTime,
    parkingStartDateTime,
    ticket,
    totalAmount,
  ])

  return {
    ...parkingDetails,
    pricingPayload,
    addTimeCard,
    isPaymentRequired,
    setIsPaymentRequired,
  }
}

export default useParkDetails
