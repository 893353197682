import axios from '.'
import { Response } from '../typedef/api'
import {
  AddCreditCardData,
  AddCreditCardPayload,
  GetCreditCardsPayload,
  GetCreditCardsData,
  RemoveCreditCardData,
  RemoveCreditCardPayload,
} from '../typedef/creditCards'
import { CREDIT_CARD } from './constants'
import './mocks/CreditCardApiMock'

export const getCreditCards = async (arg: GetCreditCardsPayload) => {
  try {
    const params = { lotId: arg.lotId }
    const response = await axios.get<Response<GetCreditCardsData>>(
      CREDIT_CARD,
      { params }
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}

export const addCreditCard = async (arg: AddCreditCardPayload) => {
  try {
    const response = await axios.post<Response<AddCreditCardData>>(
      CREDIT_CARD,
      arg
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}

export const removeCreditCard = async (arg: RemoveCreditCardPayload) => {
  try {
    const lotId = arg.lotId ? `${arg.lotId}/` : ''
    await axios.delete<Response<RemoveCreditCardData>>(
      `${CREDIT_CARD}/${lotId}${arg.creditCardId}`
    )
    return true
  } catch (err) {
    throw err
  }
}
