import { useSelector } from 'react-redux'
import { parkCarSlice } from 'store/slices'

export const useWantMonthly = () => {
  const { lot } = useSelector(parkCarSlice.stateSelectors)

  const showWantMonthly = () => {
    if (!lot?.monthlyParkerInfo && lot?.selfRegisterUrl) return true
    return false
  }
  const handle = () => {
    if (!lot?.selfRegisterUrl)
      throw Error('Missing "I want monthly parking" url.')
    window.open(lot?.selfRegisterUrl, '_blank')
  }
  return {
    isWantMonthly: showWantMonthly(),
    wantMonthlyHandler: handle,
  }
}

export default useWantMonthly
