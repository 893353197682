// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, { mockedAxios } from '.'
import { Response } from '../typedef/api'
import {
  ExternalPricingData,
  GetExternalPricingPayload,
  GetPriceData,
  GetPricePayload,
} from '../typedef/price'
import { PRICE, PRICE_EXTERNAL_PRICING } from './constants'
import './mocks/PriceApiMock'

export const getPrice = async (params: GetPricePayload) => {
  try {
    const response = await axios.get<Response<GetPriceData>>(PRICE, {
      params,
    })
    return response.data.data
  } catch (err) {
    throw err
  }
}
export const getExternalPricing = async (params: GetExternalPricingPayload) => {
  try {
    const response = await axios.get<Response<ExternalPricingData>>(
      PRICE_EXTERNAL_PRICING,
      {
        params,
      }
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}
