import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { batch, useSelector } from 'react-redux'
import { mapSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import Translations from 'utils/translations'

export enum GeolocationPositionErrorValues {
  PERMISSION_DENIED = 1,
  POSITION_UNAVAILABLE = 2,
  TIMEOUT = 3,
}

export const useGeolocation = () => {
  const { isGeoOn, selectedLot } = useSelector(mapSlice.stateSelectors)
  const [isOn, setIsOn] = useState(false)
  const dispatch = useAppDispatch()
  const [state, setState] = useState({
    accuracy: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    latitude: null,
    longitude: null,
    speed: null,
    timestamp: Date.now(),
    error: '',
  })
  const { t } = useTranslation()
  let mounted = true
  const watchId = useRef<number | null>(null)

  const turnOffGeo = () => {
    batch(() => {
      setIsOn(false)
      dispatch(mapSlice.actions.setGeo(false))
      dispatch(mapSlice.actions.setMyPosition(null))
      dispatch(mapSlice.actions.setGeoState('denined'))
      mounted = false
      navigator.geolocation.clearWatch(watchId.current)
      watchId.current = null
    })
  }

  const geoErrorMessageHandler = (errCode: number): string => {
    switch (errCode) {
      case GeolocationPositionErrorValues.PERMISSION_DENIED:
        return t(Translations.USER_DENIED_GEOLOCALIZATION)
      case GeolocationPositionErrorValues.POSITION_UNAVAILABLE:
        return t(Translations.POSITION_UNAVAILABLE)
      case GeolocationPositionErrorValues.TIMEOUT:
        return t(Translations.POSITION_GEOLOCALIZATION_TIMEOUT)
      default:
        return t(Translations.ERROR)
    }
  }

  const onError = (err: any) => {
    setState({
      accuracy: null,
      altitude: null,
      altitudeAccuracy: null,
      heading: null,
      latitude: null,
      longitude: null,
      speed: null,
      timestamp: Date.now(),
      error: geoErrorMessageHandler(err.code),
    })
    turnOffGeo()
    dispatch(mapSlice.actions.setGeoError(geoErrorMessageHandler(err.code)))
  }

  const onGetCurrentPositionError = (err: any) => {
    if (mounted) {
      onError(err)
    }
  }

  const handleEvent = (event: any) => {
    setState({
      accuracy: event.coords.accuracy,
      altitude: event.coords.altitude,
      altitudeAccuracy: event.coords.altitudeAccuracy,
      heading: event.coords.heading,
      latitude: event.coords.latitude,
      speed: event.coords.speed,
      timestamp: event.timestamp,
      longitude: event.coords.longitude,
      error: '',
    })
    if (!selectedLot)
      dispatch(
        mapSlice.actions.setMyPosition({
          heading: event.coords.heading,
          lat: event.coords.latitude,
          lng: event.coords.longitude,
        })
      )
  }

  const onGetCurrentPosition = (event: any) => {
    if (mounted) {
      setIsOn(true)
      dispatch(mapSlice.actions.setGeoState('granted'))
      dispatch(
        mapSlice.actions.setPosition({
          lat: event.coords.latitude,
          lng: event.coords.longitude,
        })
      )
      handleEvent(event)
    }
  }

  const onWatchPosition = (event: any) => {
    if (mounted) {
      handleEvent(event)
    }
  }

  const turnOnGeo = () => {
    setState({ ...state, error: '' })
    navigator.geolocation.getCurrentPosition(
      onGetCurrentPosition,
      onGetCurrentPositionError,
      { enableHighAccuracy: true }
    )
    watchId.current = navigator.geolocation.watchPosition(
      onWatchPosition,
      onError
    )
  }

  useEffect(() => {
    if (isGeoOn && !isOn) {
      turnOnGeo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGeoOn, isOn])

  return { state, turnOnGeo, turnOffGeo }
}

export default useGeolocation
