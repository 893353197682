import mock from '.'
import { Response } from 'typedef/api'
import { GetValidatorsData, ValidateCodeData } from 'typedef/validator'
import { partners } from '__fixtures__/validators'
import { COUPON } from 'api/constants'

// mock.onGet(COUPON).networkError()
mock.onGet(COUPON).reply(config => {
  return [
    200,
    {
      data: { partners },
    } as Response<GetValidatorsData>,
  ]
})

// mock.onPost(COUPON).networkError()
mock.onPost(COUPON).reply(config => {
  return [
    200,
    {
      data: true,
    } as Response<ValidateCodeData>,
  ]
})
