import React from 'react'
import { layoutSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import * as S from './Layout.styles'

export interface FullLayoutProps {
  center?: boolean
  hasTopBar?: boolean
  hasBottomBar?: boolean
  hasFlatBackground?: boolean
  whiteBackground?: boolean
  imageBackground?: boolean
  hasSnackbar?: boolean
  narrow?: boolean
  innerScroll?: boolean
  ad?: boolean
}
export const FullLayout: React.FC<FullLayoutProps> = ({
  center,
  hasTopBar,
  hasBottomBar,
  hasFlatBackground,
  whiteBackground,
  imageBackground,
  ad,
  narrow,
  hasSnackbar,
  innerScroll,
  children,
  ...props
}) => {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })
  const height = React.useRef(window.innerHeight)
  const width = React.useRef(window.innerWidth)
  const innerRef = React.useRef(null)
  const dispatch = useAppDispatch()

  const handleResize = React.useCallback(() => {
    if (window.innerHeight !== height.current) {
      height.current = window.innerHeight
      dispatch(layoutSlice.actions.setHeight(window.innerHeight))
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }
    if (window.innerWidth !== width.current) {
      width.current = window.innerWidth
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
      dispatch(layoutSlice.actions.setInnerWidth(innerRef.current.clientWidth))
    }
  }, [dispatch])

  React.useLayoutEffect(() => {
    window.addEventListener('resize', handleResize, {})
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  React.useEffect(() => {
    dispatch(layoutSlice.actions.setInnerWidth(innerRef.current.clientWidth))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [narrow])

  return (
    <S.OuterContainer
      height={dimensions.height}
      hasTopBar={hasTopBar}
      hasBottomBar={hasBottomBar}
      hasFlatBackground={hasFlatBackground}
      whiteBackground={whiteBackground}
      imageBackground={imageBackground}
      hasAd={ad}
    >
      <S.InnerContainer
        ref={innerRef}
        center={center}
        narrow={narrow}
        innerScroll={innerScroll}
      >
        {hasTopBar && <S.TopBarSpace />}
        {children}
        <S.BottomBarSpace
          hasBottomBar={hasBottomBar}
          hasAd={ad}
          hasSnackbar={hasSnackbar}
        />
      </S.InnerContainer>
    </S.OuterContainer>
  )
}
FullLayout.defaultProps = {}
export default FullLayout
