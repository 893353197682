import { getErrorMessage } from 'api/utils'
import { useParkMode } from 'pages/park_car/ParkCarHooks'
import useAutoClearTimeExceedingPopup from 'pages/park_car/ParkCarHooks/useAutoClearTimeExceedingPopup'
import { useExpiredPricingPopup } from 'pages/park_car/ParkCarT2/T2Hooks'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import paths from 'routing/paths'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'
import { RootState } from 'store/rootReducer'
import { mapSlice, parkCarSlice, ticketsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { ErrorCode, ErrorData } from 'typedef'

export const useFailedPay = () => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const { ticketId, carId } = useParams<{ ticketId: string; carId: string }>()
  const ticket = useSelector((state: RootState) =>
    ticketsSlice.selectById(state, ticketId)
  )
  const setExpiredPricingPopup = useExpiredPricingPopup()
  const setAutoClearTimeExceedingPopup = useAutoClearTimeExceedingPopup()
  const { setPopup, showPopup } = usePopup()
  const parkMode = useParkMode()

  const onFailClick = React.useCallback(() => {
    dispatch(parkCarSlice.actions.setTicket({ carId: carId || ticketId }))
    if (parkMode === 'addMoreTime') {
      return push(`${paths.parking.addTime}/${ticketId || carId}`)
    }
    if (parkMode === 'parkCar') {
      return push(`${paths.parking.parkCar}/${ticket?.lot.lotId}`)
    }
  }, [dispatch, parkMode, push, ticket?.lot.lotId, ticketId, carId])

  const onSessionExpiredClick = () => {
    dispatch(mapSlice.actions.selectLot({ lotId: ticket?.lot.lotId }))
    push(paths.root.root)
  }

  const setFailedPopup = () =>
    setPopup({
      ...getErrorMessage(ErrorCode.FailedSaveCarPayment),
      buttons: [
        {
          onClick: onFailClick,
        },
      ],
      disableBackdropClick: true,
    })

  const setExpiredPopup = () =>
    setPopup({
      ...getErrorMessage(ErrorCode.PaymentTimeExpired),
      buttons: [
        {
          onClick: () => {
            onSessionExpiredClick()
          },
        },
      ],
      disableBackdropClick: true,
    })

  const handleRejection = (payload: ErrorData) => {
    if (payload.code === ErrorCode.PaymentTimeExpired) {
      setExpiredPopup()
    } else if (payload.code === ErrorCode.CurrentPricingIsExpired) {
      setExpiredPricingPopup()
    } else if (payload.code === ErrorCode.AutoClearTimeExeeded) {
      setAutoClearTimeExceedingPopup()
      push(paths.parking.tickets)
    } else {
      setFailedPopup()
    }
    showPopup(true)
  }

  return { handleRejection, setExpiredPopup, onSessionExpiredClick }
}

export default useFailedPay
