import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { batch, useSelector } from 'react-redux'
import {
  AppTypography,
  Placeholder,
  SharedIcon,
  WhiteBoxContainer,
} from 'shared'
import { mapSlice, ticketsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import styled from 'styled-components'
import { Lot } from 'typedef/lot'
import { CarStatus } from 'typedef/ticket'
import Translations from 'utils/translations'

const MapSearchViewContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  background: #fff;
  padding-top: 60px;
  box-sizing: border-box;
`
export interface MapSearchViewProps {
  hideSearchView: () => void
}
const StyledList = styled(List)`
  padding: 0;
  li {
    padding: 0;
  }
`

export const MapSearchView: React.FC<MapSearchViewProps> = props => {
  const { t } = useTranslation()
  const history = useSelector(ticketsSlice.selectParkingHistory)
  const dispatch = useAppDispatch()

  const onClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    lot: Lot
  ) => {
    e.stopPropagation()

    batch(() => {
      dispatch(mapSlice.actions.selectLot({ lotId: lot.lotId }))
      dispatch(mapSlice.getLotDetailsMap({ lotId: lot.lotId }))
      dispatch(
        mapSlice.actions.setPosition({
          lat: lot.geolocation.latitude,
          lng: lot.geolocation.longitude,
        })
      )
      dispatch(mapSlice.actions.setZoom(17))
      props.hideSearchView()
    })
  }

  const renderHistory = () => (
    <StyledList dense>
      {history.map((el, i, arr) => (
        <React.Fragment key={el.lotId}>
          <ListItem disableGutters button dense onClick={e => onClick(e, el)}>
            <ListItemIcon>
              {el.carStatus === CarStatus.Parked ? (
                <SharedIcon.HistoryList.Clock />
              ) : (
                <SharedIcon.HistoryList.Pin />
              )}
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <AppTypography color="textPrimary">{el.lotName}</AppTypography>
              }
              secondary={
                <AppTypography weight="bold" color="textPrimary">
                  {el.locationName}
                </AppTypography>
              }
            />
          </ListItem>
          {i < arr.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </StyledList>
  )

  const onContainerClick = () => props.hideSearchView()

  return (
    <MapSearchViewContainer onClick={onContainerClick}>
      <WhiteBoxContainer noShadow>
        {!!history.length ? (
          renderHistory()
        ) : (
          <Placeholder
            icon={<SharedIcon.NoFound />}
            title={t(Translations.NO_SEARCH_HISTORY)}
          />
        )}
      </WhiteBoxContainer>
    </MapSearchViewContainer>
  )
}

export default MapSearchView
