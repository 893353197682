import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import { useSelector } from 'react-redux'
import { Settings } from 'react-slick'
import { AppCarousel } from 'shared'
import { ticketsSlice } from 'store/slices'
import { TicketState } from 'store/slices/ticketsSlice.types'
import Ticket from './Ticket'

const responsive: Settings['responsive'] = [
  {
    breakpoint: 640,
    settings: { centerMode: false, slidesToShow: 1 },
  },
]
export interface TicketsCarouselProps {
  tickets: TicketState[]
}

const TicketsCarousel: React.FC<TicketsCarouselProps> = props => {
  const ticketsRef = React.useRef([])
  const [minHeight, setMinHeight] = React.useState(0)
  const { selectedTicket } = useSelector(ticketsSlice.stateSelectors)
  const selectedTicketIndex = props.tickets.findIndex(
    el => el.carId === selectedTicket?.carId
  )
  const initialSlide = () =>
    selectedTicketIndex > -1 ? selectedTicketIndex : 0

  const renderTickets = props.tickets.map((ticket, i) => (
    <Ticket
      key={nanoid()}
      ticket={ticket}
      ref={el => (ticketsRef.current[i] = el)}
      minHeight={minHeight}
    />
  ))

  React.useEffect(() => {
    const maxHeight = Math.max(
      ...ticketsRef.current.map(el => el.clientHeight as number)
    )
    if (typeof maxHeight === 'number') {
      setMinHeight(maxHeight)
    }
  }, [props.tickets])

  if (!props.tickets?.length) return null
  if (props.tickets.length === 1) {
    return <Ticket ticket={props.tickets[0]} center />
  }

  return (
    <AppCarousel
      initialSlide={initialSlide()}
      centerMode
      slidesToShow={props.tickets.length === 1 ? undefined : 2}
      responsive={responsive}
      adaptiveHeight={false}
    >
      {renderTickets}
    </AppCarousel>
  )
}

export default TicketsCarousel
