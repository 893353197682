import { useFormikContext } from 'formik'
import React from 'react'
import { Counter } from 'shared'
import { T2Fields } from '../fields'
import { useExpiredPricingPopup } from '../T2Hooks'

export interface RemaingTimeProps {}

export const RemaingTime: React.FC<RemaingTimeProps> = props => {
  const { values } = useFormikContext<T2Fields>()
  const showPopup = useExpiredPricingPopup()

  return (
    <Counter
      onEnd={showPopup}
      endTime={values.pricingExpirationDateUtc}
      title="RATE_EXPIRES_IN"
    />
  )
}

export default RemaingTime
