import { createTheme } from '@mui/material/styles'
import { CustomTheme } from './theme'

export const whiteTheme = (theme: CustomTheme) =>
  createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        main: '#fff',
      },
      text: {
        primary: '#fff',
      },
    },
    components: {
      ...theme.components,
      MuiTypography: {
        styleOverrides: {
          paragraph: {
            color: '#fff',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          text: {
            color: '#fff',
            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.3)' },
          },
          containedPrimary: {
            color: '#FF3333',
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
            '&:disabled': {
              color: '#fff',
              backgroundColor: '#D2D2D2',
            },
          },
          outlinedPrimary: {
            color: '#fff',
            borderColor: '#fff',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
            },
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          line: {
            color: '#fff',
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          text: {
            fill: '#fff',
          },
          root: {
            fill: 'rgba(255,255,255,.2)',
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          colorPrimary: {
            color: '#fff',
          },
        },
      },
    },
  })
