import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import React from 'react'
import { SharedIcon } from 'shared'
import { makeStyles } from '@mui/styles'
import { Lot } from 'typedef/lot'
export interface LotNameProps {
  lot: Lot
  hasRightElement?: boolean
  onClickFindCar?: (lotId: string) => void
}
const useStyles = makeStyles(_ => ({
  root: {
    width: 50,
    overflow: 'initial',
    backgroundColor: 'inherit',
  },
}))

export const LotName: React.FC<LotNameProps> = ({
  lot,
  hasRightElement,
  onClickFindCar,
}) => {
  const classes = useStyles()

  return (
    <ListItem data-testid="receiptBar" disableGutters>
      <ListItemAvatar>
        <Avatar
          src={lot.logoLink}
          alt="Parking logo"
          classes={{ root: classes.root }}
          variant="square"
          imgProps={{
            style: { objectFit: 'contain', background: 'transparent' },
          }}
        >
          <SharedIcon.LotAvatarPlaceholder />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={lot.locationName} secondary={lot.lotName} />
      {hasRightElement && (
        <IconButton onClick={() => onClickFindCar(lot.lotId)}>
          <SharedIcon.Pin />
        </IconButton>
      )}
    </ListItem>
  )
}
LotName.defaultProps = {}
export default LotName
