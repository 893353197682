import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import ReceiptBar from './ReceiptBar'
import { Divider, List } from '@mui/material'
import { nanoid } from '@reduxjs/toolkit'
import { Receipt } from 'typedef/receipt'
export interface MyReceiptsProps {
  receipts?: Receipt[]
}

const MyReceipts: React.FunctionComponent<MyReceiptsProps> = props => {
  const history = useHistory()
  if (!props.receipts || !props.receipts.length) return null

  return (
    <List dense>
      {props.receipts.map((v, i, arr) => (
        <React.Fragment key={nanoid()}>
          <ReceiptBar
            receipt={v}
            handleMailIconClick={() =>
              history.push(`${paths.parker.sendReceipt}/${v.carId}`)
            }
            handleQuestionIconClick={() =>
              history.push(`${paths.parker.question}/${v.carId}`)
            }
          />
          {i < arr.length - 1 && <Divider variant="middle" component="li" />}
        </React.Fragment>
      ))}
    </List>
  )
}
export default MyReceipts
