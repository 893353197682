import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, AppPopup, AppTypography, FlexBox } from 'shared'
import CodeInput from 'shared/CodeInput'
import styled from 'styled-components'
import Translations from 'utils/translations'

export interface CodeStepPopupProps {
  showCodeStepPopup: boolean
  closePopup: () => void
  submitForm: () => void
  handleResendCode: () => void
  pin: string
  setPin: React.Dispatch<React.SetStateAction<string>>
}

const StyledCodeInput = styled(CodeInput)`
  > input {
    border: 1px solid #dbdbdb;
  }
`

export const CodeStepPopup = ({
  closePopup,
  showCodeStepPopup,
  submitForm,
  handleResendCode,
  pin,
  setPin,
}: CodeStepPopupProps) => {
  const { t } = useTranslation()
  const [codeValid, setCodeValid] = useState(false)

  //DODAĆ TRANSLACJE
  return (
    <AppPopup
      open={showCodeStepPopup}
      variant="info"
      fullWidth={false}
      closePopup={closePopup}
      customIcon={
        <AppTypography gutterBottom display="inline" weight={'bold'}>
          {t(Translations.TYPE_CONFIRMATION_CODE)}
        </AppTypography>
      }
    >
      <FlexBox hasBottomMargin hasTopMargin marginSpacing={2}>
        <AppTypography gutterBottom display="inline">
          {t(Translations.MESSAGE_WITH_CONFIRMATION_CODE_HAS_BEEN_SENT)}
        </AppTypography>
      </FlexBox>
      <StyledCodeInput
        codeLength={4}
        handleValidation={setCodeValid}
        setPin={setPin}
      />
      <Box marginTop={2}>
        <AppTypography display="inline" fontSize={'14px'}>
          {t(Translations.YOU_DIDNT_RECEIVE_THE_CODE)}
        </AppTypography>

        <AppButton
          size={'small'}
          variant="text"
          onClick={handleResendCode}
          style={{ padding: 0 }}
        >
          {t(Translations.RESEND_CODE)}
        </AppButton>
      </Box>
      <FlexBox hasTopMargin>
        <AppButton
          variant="contained"
          onClick={submitForm}
          disabled={!codeValid || pin.length < 4}
        >
          {t(Translations.CONFIRM)}
        </AppButton>
        <AppButton variant="outlined" color="primary" onClick={closePopup}>
          {t(Translations.CANCEL)}
        </AppButton>
      </FlexBox>
    </AppPopup>
  )
}

export default CodeStepPopup
