import { Grid } from '@mui/material'
import { nanoid, unwrapResult } from '@reduxjs/toolkit'
import AddCreditCardForm from 'pages/my_credit_cards/CreditCardsComponents/AddCreditCardForm'
import CardWrapperFullDetails from 'pages/my_credit_cards/CreditCardsComponents/CardWrapperFullDetails'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppSpinner, WhiteBoxContainer } from 'shared'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'
import { RootState } from 'store/rootReducer'
import { creditCardsSlice, customerSlice, ticketsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { AddCreditCardPayload, CardType, CreditCard } from 'typedef/creditCards'
import { asterisks2Digits } from 'utils/creditCard'
import { getCreditCardType } from 'utils/creditCardTypeMapper'
import usePaymentCard from './creditCardHooks/usePaymentCard'

export interface AddCreditCardProps {
  carId: string
  payPath: string
}

export const AddCreditCard: React.FC<AddCreditCardProps> = props => {
  const [cardType, setCardType] = React.useState(CardType.Invalid)
  const [cardNumber, setCardNumber] = React.useState('')
  const [month, setMonth] = React.useState('')
  const [year, setYear] = React.useState('')
  const { setPaymentCard } = usePaymentCard()
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const { setPopup, showPopup } = usePopup()
  const ticket = useSelector((state: RootState) =>
    ticketsSlice.selectById(state, props.carId)
  )

  const { addCreditCardStatus } = useSelector(
    creditCardsSlice.promiseStatusSelectors
  )
  const { customerSignedIn, customerSignedInAsGuest } = useSelector(
    customerSlice.selectCustomerStatus
  )

  const changeCardType = (cardNumber: string) => {
    setCardNumber(cardNumber)
    const cardType = getCreditCardType(cardNumber)
    setCardType(cardType)
  }

  const addCreditCard = async (arg: AddCreditCardPayload) => {
    if (customerSignedIn) {
      const promise = await dispatch(
        creditCardsSlice.addCreditCard({ ...arg, lotId: ticket?.lot?.lotId })
      )
      if (creditCardsSlice.addCreditCard.fulfilled.match(promise)) {
        const res = unwrapResult(promise)
        dispatch(creditCardsSlice.actions.resetPromiseStatus('addCreditCard'))
        setPaymentCard(res)
        push(props.payPath)
      } else {
        if (promise.payload.variant === 'error') {
          setPopup(promise.payload)
        }
        if (promise.payload.variant === 'warning') {
          setPopup({
            ...promise.payload,
            buttons: [{ onClick: () => addCreditCard(promise.meta.arg) }, {}],
          })
        }
        showPopup(true)
      }
    }
    if (customerSignedInAsGuest) {
      const tempCard: CreditCard = {
        lotId: ticket?.lot?.lotId,
        cardType: getCreditCardType(arg.ccNumber),
        creditCardId: nanoid(),
        last4Digits: arg.ccNumber.slice(-4),
        ccNumber: arg.ccNumber,
        expirationMonth: arg.expirationMonth,
        expirationYear: arg.expirationYear,
        zipCode: arg.zipCode,
      }
      dispatch(creditCardsSlice.actions.addCard(tempCard))
      setPaymentCard(tempCard)
      push(props.payPath)
    }
  }

  const showElement = () => {
    const spinner = addCreditCardStatus.pending
    return { spinner }
  }

  return (
    <WhiteBoxContainer>
      <AppSpinner show={showElement().spinner} />
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={8}>
          <CardWrapperFullDetails
            digits={asterisks2Digits(cardNumber)}
            type={cardType}
            month={month}
            year={year}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <AddCreditCardForm
            setCardType={changeCardType}
            setMonth={setMonth}
            setYear={setYear}
            addCreditCard={addCreditCard}
            disabled={showElement().spinner}
          />
        </Grid>
      </Grid>
    </WhiteBoxContainer>
  )
}
