import { unwrapResult } from '@reduxjs/toolkit'
import usePaymentCard from 'pages/park_car_credit_card/CreditCardComponents/creditCardHooks/usePaymentCard'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { paths } from 'routing'
import {
  creditCardsSlice,
  customerSlice,
  parkCarSlice,
  ticketsSlice,
} from 'store/slices'
import { AppState, useAppDispatch } from 'store/store'
import { ErrorCode } from 'typedef'
import useGetTicket from './useGetTicket'

export const useInitParkCar = (initLoad = true) => {
  const { carId, lotId } = useParams<{ lotId?: string; carId?: string }>()
  const [isLoading, setIsLoading] = React.useState(true)
  const [isDone, setIsDone] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const [, getTicket] = useGetTicket()
  const { setPaymentCard } = usePaymentCard()
  const { getTicketsStatus, getTicketStatus } = useSelector((state: AppState) =>
    ticketsSlice.promiseStatusSelectors(state, carId)
  )

  const { customerSignedInAsGuest } = useSelector(
    customerSlice.selectCustomerStatus
  )
  const { getLotDetailsParkStatus } = useSelector(
    parkCarSlice.promiseStatusSelectors
  )
  const fetchData = React.useCallback(async () => {
    setIsLoading(true)
    dispatch(parkCarSlice.actions.setIsPaymentRequired(false))
    dispatch(parkCarSlice.actions.resetPromiseStatus('parkCarRedirect'))
    dispatch(creditCardsSlice.actions.resetPromiseStatus('getCreditCards'))

    if (!customerSignedInAsGuest && carId) {
      getTicket()
    }
    if (customerSignedInAsGuest) {
      dispatch(ticketsSlice.getTickets())
    }
    const promise = await dispatch(
      parkCarSlice.getLotDetailsPark({ lotId, carId })
    )
    if (parkCarSlice.getLotDetailsPark.fulfilled.match(promise)) {
      const res = unwrapResult(promise)
      setPaymentCard(res.creditCardUsed)
      dispatch(
        parkCarSlice.actions.setHasCardBeenUsed(Boolean(res.creditCardUsed))
      )
    } else {
      if (promise.payload.code === ErrorCode.CarNotFound) {
        push(paths.parking.tickets)
      }
    }
  }, [
    carId,
    customerSignedInAsGuest,
    dispatch,
    getTicket,
    lotId,
    push,
    setPaymentCard,
  ])

  const setStatusParkCar = React.useCallback(() => {
    // loading state
    if (!customerSignedInAsGuest && !getLotDetailsParkStatus.pending) {
      setIsLoading(false)
    }
    if (
      customerSignedInAsGuest &&
      !getLotDetailsParkStatus.pending &&
      !getTicketsStatus.pending
    ) {
      setIsLoading(false)
    }

    // success state
    if (!customerSignedInAsGuest && getLotDetailsParkStatus.fulfilled) {
      setIsDone(true)
      setIsError(false)
    }
    if (
      customerSignedInAsGuest &&
      getLotDetailsParkStatus.fulfilled &&
      getTicketsStatus.fulfilled
    ) {
      setIsDone(true)
      setIsError(false)
    }

    // fail state
    if (!customerSignedInAsGuest && getLotDetailsParkStatus.rejected) {
      setIsError(true)
    }
    if (
      customerSignedInAsGuest &&
      (getLotDetailsParkStatus.rejected || getTicketsStatus.rejected)
    ) {
      setIsError(true)
    }
  }, [
    customerSignedInAsGuest,
    getLotDetailsParkStatus.fulfilled,
    getLotDetailsParkStatus.pending,
    getLotDetailsParkStatus.rejected,
    getTicketsStatus.fulfilled,
    getTicketsStatus.pending,
    getTicketsStatus.rejected,
  ])

  const setStatusAddTime = React.useCallback(() => {
    // loading state
    if (
      !customerSignedInAsGuest &&
      !getLotDetailsParkStatus.pending &&
      !getTicketStatus.pending
    ) {
      setIsLoading(false)
    }
    if (
      customerSignedInAsGuest &&
      !getLotDetailsParkStatus.pending &&
      !getTicketsStatus.pending
    ) {
      setIsLoading(false)
    }

    // success state
    if (
      !customerSignedInAsGuest &&
      getLotDetailsParkStatus.fulfilled &&
      getTicketStatus.fulfilled
    ) {
      setIsDone(true)
      setIsError(false)
    }
    if (
      customerSignedInAsGuest &&
      getLotDetailsParkStatus.fulfilled &&
      getTicketsStatus.fulfilled
    ) {
      setIsDone(true)
      setIsError(false)
    }

    // fail state
    if (
      !customerSignedInAsGuest &&
      (getLotDetailsParkStatus.rejected || getTicketStatus.rejected)
    ) {
      setIsError(true)
    }
    if (
      customerSignedInAsGuest &&
      (getLotDetailsParkStatus.rejected || getTicketsStatus.rejected)
    ) {
      setIsError(true)
    }
  }, [
    customerSignedInAsGuest,
    getLotDetailsParkStatus.fulfilled,
    getLotDetailsParkStatus.pending,
    getLotDetailsParkStatus.rejected,
    getTicketStatus.fulfilled,
    getTicketStatus.pending,
    getTicketStatus.rejected,
    getTicketsStatus.fulfilled,
    getTicketsStatus.pending,
    getTicketsStatus.rejected,
  ])

  React.useEffect(() => {
    if (lotId) {
      setStatusParkCar()
    }
    if (carId) {
      setStatusAddTime()
    }
  }, [carId, lotId, setStatusAddTime, setStatusParkCar])

  React.useEffect(() => {
    if (initLoad) {
      fetchData()
      return () => {
        dispatch(parkCarSlice.actions.resetPromiseStatus('getLotDetailsPark'))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return { isDone, isLoading, isError, fetchData }
}

export default useInitParkCar
