import { InputAdornment } from '@mui/material'
import { TextField, StandardTextFieldProps } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ErrorIconSvg } from '../assets/icons/input_error_icon.svg'

export interface TextAreaProps extends StandardTextFieldProps {}

const ErrorIconStyled = styled(ErrorIconSvg)`
  align-self: center;
  margin-right: 10px;
`

export const TextArea: React.FC<TextAreaProps> = props => {
  const { ...other } = props
  return (
    <TextField
      multiline
      variant="standard"
      InputProps={{
        endAdornment: props.error && (
          <InputAdornment position="end">
            <ErrorIconStyled width={30} height={30} />
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
      {...other}
    ></TextField>
  )
}

export default TextArea
