import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { refreshTokenMiddlaware, destroySession } from 'store/middlewares'
import rootReducer, { RootState } from './rootReducer'

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      refreshTokenMiddlaware,
      destroySession,
    ]),
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type { RootState as AppState }
export default store
