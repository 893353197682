import { Typography } from '@mui/material'
import styled from 'styled-components'

export const LoginInfo = styled(Typography)`
  font-size: 14px;
  line-height: 1.2;
`

export const LoginInfoSeparator = styled(Typography)`
  font-size: 14px;
  line-height: 1.8;
`

export const LoginInfoContainer = styled.div`
  display: flex;
  justify-content: center;
`
