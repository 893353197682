import React from 'react'
import { useTranslation } from 'react-i18next'
import { Placeholder, SharedIcon } from 'shared'
import Translations from 'utils/translations'
export interface TicketsPlaceholderProps {}

const TicketsPlaceholder: React.FC<TicketsPlaceholderProps> = props => {
  const { t } = useTranslation()
  return (
    <Placeholder
      title={t(Translations.NO_PARKED_CARS)}
      icon={<SharedIcon.ParkedCars />}
    />
  )
}

export default TicketsPlaceholder
