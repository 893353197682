import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from 'store/rootReducer'
import { ticketsSlice } from 'store/slices'
import { AppState, useAppDispatch } from 'store/store'

export const useGetTicket = () => {
  const dispatch = useAppDispatch()
  const { carId: carIdParam, ticketId } = useParams<{
    carId?: string
    ticketId?: string
  }>()
  const [carId, setCarId] = React.useState('')
  const { getTicketStatus } = useSelector((state: AppState) =>
    ticketsSlice.promiseStatusSelectors(state, carId || ticketId)
  )
  const ticket = useSelector((state: RootState) =>
    ticketsSlice.selectById(state, carId || ticketId)
  )
  React.useEffect(() => {
    if (carIdParam || ticketId) {
      setCarId(carIdParam || ticketId)
    }
  }, [carIdParam, ticketId])
  const getTicket = React.useCallback(
    (carIdProp?: string) => {
      let id = carId
      if (carIdProp) {
        id = carIdProp
        setCarId(id)
      }
      if (!id) return
      return dispatch(ticketsSlice.getTicket({ carId: id }))
    },
    [carId, dispatch]
  )
  React.useEffect(() => {
    if (!ticket) {
      getTicket()
    }
  }, [getTicket, ticket])

  return [getTicketStatus.pending, getTicket, ticket] as const
}

export default useGetTicket
