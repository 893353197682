import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AppButton,
  AppTypography,
  FlexBox,
  PhoneNumberContainer,
  Placeholder,
  SharedIcon,
} from 'shared'
import * as Yup from 'yup'
import { Input } from 'shared/Input'
import {
  countryCodeRegex,
  phoneNumberRegex,
  Translations,
  blurLastInput,
} from 'utils'
import CodeStepPopup from '../CodeStepPopup'
import { ReceiptsAndNotificationsProps, RedirectType } from './types'
import { useResendCode } from './useResendCode'
import { useSendVerificationSMS } from './useSendVerificationSMS'
import { useSubmitForm } from './useSubmitForm'
import NewPhoneNumberInput from 'shared/PhoneNumberAndCountryCodeInput'
import { useGetTicket } from 'pages/park_car/ParkCarHooks'
import HybridRecaptcha from '../../../shared/ReCaptcha/HybridRecaptcha';
import {validateFeature } from '../../../shared/ReCaptcha/HybridRecaptcha';

const ReceiptsAndNotifications = ({ carId }: ReceiptsAndNotificationsProps) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [showCodeStepPopup, setShowCodeStepPopup] = useState(false)
  const [pin, setPin] = useState('')
  const [confirmToken, setConfirmToken] = useState('')
  const [, , ticket] = useGetTicket()
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  const handleResendCode = useResendCode(confirmToken, setConfirmToken)
  const handleSubmitWithPhoneNumber = useSendVerificationSMS(
    setShowCodeStepPopup,
    setConfirmToken,
    carId,
    recaptchaToken
  )
  const handleSubmit = useSubmitForm(
    RedirectType.ReceiptsAndNotifications,
    carId,
    confirmToken,
    pin
  )
  
  const handleTokenReceived = (token: string) => {
    setRecaptchaToken(token);
  };

  const handleRecaptchaSuccess = () => {
    setRecaptchaVerified(true);
  };

  const handleRecaptchaFailure = () => {
    enqueueSnackbar(t(Translations.RECAPTCHA_VERIFICATION_FAILED), { variant: 'error' });
    setRecaptchaVerified(false);
  };
  const sendReceiptSchema = Yup.object().shape({
    email: Yup.string().email(t(Translations.INVALID_EMAIL)),
    code: Yup.string().matches(
      countryCodeRegex,
      t(Translations.INVALID_COUNTRY_CODE)
    ),
    phoneNumber: Yup.string().matches(
      phoneNumberRegex,
      t(Translations.INVALID_PHONE_NUMBER)
    ),
  })

  const title = ticket?.externalServiceDeliversReceipts
    ? t(Translations.NOTIFICATIONS)
    : t(Translations.RECEIPTS_AND_NOTIFICATIONS)
  const subtitle = ticket?.externalServiceDeliversReceipts
    ? t(Translations.PARKING_NOTIFICATIONS)
    : t(Translations.TO_RECEIVE_PARKING_EXPIRATION_TEXT_MESSAGES)

  const icon = <SharedIcon.Receipt />

  const initialValues = {
    email: '',
    phoneNumber: '',
    code: '',
  }

  return (
    <>
      <Placeholder title={title} icon={icon}></Placeholder>
      <AppTypography style={{ marginBottom: '10px' }}>{subtitle}</AppTypography>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={sendReceiptSchema}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          validateForm,
          submitForm,
        }) => (
          <Form>
            <PhoneNumberContainer>
              <NewPhoneNumberInput
                value={`${values.code}${values.phoneNumber}`}
                countryCodeFieldName="code"
                phoneNumberFieldName="phoneNumber"
                errors={errors.phoneNumber}
              />
            </PhoneNumberContainer>
            {!ticket?.externalServiceDeliversReceipts && (
              <Input
                placeholder={t(Translations.EMAIL).toUpperCase()}
                name="email"
                inputMode="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.email}
                autoFocus
                onKeyDown={e => {
                  blurLastInput(e)
                }}
              ></Input>
            )}
            <FlexBox hasTopMargin>
              <HybridRecaptcha
                onValidationSuccess={handleRecaptchaSuccess}
                onValidationFailure={handleRecaptchaFailure}
                onTokenReceived={handleTokenReceived}
                feature={validateFeature.AddNumberForNotification}
              />
              <AppButton
                variant="contained"
                color="primary"
                type="button"
                disabled={!values.email && !values.phoneNumber}
                onClick={() => {
                  if (!recaptchaVerified) {
                    enqueueSnackbar(t(Translations.PLEASE_COMPLETE_RECAPTCHA_VERIFICATION), { variant: 'warning' });
                    return;
                  }
                  validateForm().then(errors => {
                    const entries = Object.entries(errors)
                    if (entries.length === 0) {
                      handleSubmitWithPhoneNumber(values)
                    } else {
                      enqueueSnackbar(entries[0][1])
                    }
                  })
                }}
              >
                {t(Translations.CONFIRM)}
              </AppButton>
            </FlexBox>
            <CodeStepPopup
              closePopup={() => setShowCodeStepPopup(false)}
              showCodeStepPopup={showCodeStepPopup}
              submitForm={submitForm}
              pin={pin}
              setPin={setPin}
              handleResendCode={handleResendCode}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ReceiptsAndNotifications
