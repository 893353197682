import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, AppPopup, AppTypography, FlexBox, SharedIcon } from 'shared'
import Translations from 'utils/translations'

export interface ConfirmPopupProps {
  showConfirmPopup: boolean
  spotNumber: string
  closePopup: () => void
  onConfirmClick: () => void
}

export const ConfirmPopup: React.FC<ConfirmPopupProps> = ({
  closePopup,
  onConfirmClick,
  showConfirmPopup,
  spotNumber,
}) => {
  const { t } = useTranslation()

  return (
    <AppPopup
      open={showConfirmPopup}
      variant="warning"
      fullWidth={false}
      closePopup={closePopup}
      customIcon={<SharedIcon.SpotNumber spotNumber={spotNumber} />}
    >
      <FlexBox hasBottomMargin hasTopMargin marginSpacing={2}>
        <div>
          <AppTypography gutterBottom display="inline">
            {`${t(Translations.PLEASE_CONFIRM_YOU_ARE_IN_SPOT)} `}
          </AppTypography>
          <AppTypography gutterBottom display="inline" weight="bold">
            <b>{spotNumber}</b>
          </AppTypography>
        </div>
      </FlexBox>
      <FlexBox>
        <AppButton variant="contained" color="primary" onClick={onConfirmClick}>
          {t(Translations.CONFIRM)}
        </AppButton>
        <AppButton variant="outlined" color="primary" onClick={closePopup}>
          {t(Translations.CANCEL)}
        </AppButton>
      </FlexBox>
    </AppPopup>
  )
}

export default ConfirmPopup
