import { Fade } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { adsSlice, layoutSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import styled from 'styled-components'
import { BOTTOM_BAR_HEIGHT, INNER_WIDTH } from './layout/constants'

const AdSpace = styled.div<{ height: string }>`
  height: ${_ => _.height};
`
const AdWrapper = styled.div<{ height: string }>`
  position: fixed;
  bottom: ${BOTTOM_BAR_HEIGHT}px;
  height: ${_ => _.height};
  left: 50%;
  width: 100%;
  max-width: ${INNER_WIDTH}px;
  z-index: 1;
  transform: translateX(-50%);
  img {
    height: ${_ => _.height};
  }
`

export interface AdProps {}

export const Ad: React.FC<AdProps> = props => {
  const adsToAppend = useSelector(adsSlice.selectAdsToAppend)
  const [initAd, setInitAd] = React.useState('')
  const { scriptString } = useSelector(adsSlice.stateSelectors)
  const { innerWidth, height } = useSelector(layoutSlice.stateSelectors)
  const [adHeight, setAdHeight] = React.useState('0')
  const [runScript, setRunScript] = React.useState(false)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (typeof innerWidth === 'number' && typeof height === 'number') {
      const adHeight = Math.round((innerWidth * 6) / 14)
      const maxHeight = Math.round(Math.min(height / 3, 200))
      if (adHeight > maxHeight) {
        setAdHeight(`${maxHeight}px`)
      } else {
        setAdHeight(`${adHeight}px`)
      }
    }
  }, [height, innerWidth])

  React.useEffect(() => {
    if (initAd && scriptString && !runScript) {
      try {
        Function(scriptString)()
        setRunScript(true)
      } catch (err) {
        console.warn('Ads script load failed', err)
        dispatch(adsSlice.actions.removeScriptString())
      }
    }
  }, [scriptString, runScript, initAd, dispatch])

  React.useEffect(() => {
    if (!initAd && adsToAppend.length) {
      setInitAd(adsToAppend[0].fullHtmlString)
      dispatch(
        adsSlice.actions.removeAdsToAppend([adsToAppend[0].advertisementUrl])
      )
    }
  }, [adsToAppend, dispatch, initAd])

  React.useEffect(() => {
    if (initAd && adsToAppend.length && runScript) {
      adsToAppend.forEach(el => {
        const fragment = document
          .createRange()
          .createContextualFragment(el.fullHtmlString)
        fragment
          .querySelectorAll('.slide')
          ?.forEach(node =>
            document
              .getElementById('tez-slideshow-container')
              ?.appendChild(node)
          )
      })
      dispatch(
        adsSlice.actions.removeAdsToAppend(
          adsToAppend.map(({ advertisementUrl }) => advertisementUrl)
        )
      )
    }
  }, [adsToAppend, dispatch, initAd, runScript])

  React.useEffect(
    () => () => {
      dispatch(adsSlice.actions.addAllAdsToAppend())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <Fade in={Boolean(initAd)}>
      <div>
        {initAd && <AdSpace height={adHeight} />}
        <AdWrapper
          height={initAd ? adHeight : '0'}
          dangerouslySetInnerHTML={{ __html: initAd }}
        />
      </div>
    </Fade>
  )
}

export default Ad
