import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { showTerms } from 'store/globalPopup/actions'
import { mapSlice, termsSlice, ticketsSlice } from 'store/slices'
import { AppState, useAppDispatch } from 'store/store'
import { TermsType } from 'typedef'

export const useTerms = () => {
  const dispatch = useAppDispatch()
  const { carId, lotId, ticketId } = useParams<{
    lotId?: string
    carId?: string
    ticketId?: string
  }>()
  const [newLotId, setNewLotId] = React.useState(lotId)
  const ticket = useSelector((state: AppState) =>
    ticketsSlice.selectById(state, carId || ticketId)
  )

  const lot = useSelector((state: AppState) =>
    mapSlice.selectById(state, newLotId)
  )

  const monthly = useSelector(
    (state: AppState) => state.parkCar.lot?.monthlyParkerInfo
  )

  React.useEffect(() => {
    if (lotId) {
      setNewLotId(lotId)
    }
    if (ticket) {
      setNewLotId(ticket.lot.lotId)
    }
  }, [lotId, ticket])

  const onTermsClick = () => {
    if (!lot) return
    dispatch(
      termsSlice.getTerms({
        type: monthly ? TermsType.Monthly : TermsType.Transient,
        lotId: newLotId,
      })
    )
    dispatch(termsSlice.actions.setLogoLink(lot.logoLink))
    dispatch(showTerms())
  }

  return onTermsClick
}

export default useTerms
