import axios, { mockedAxios } from '.'

export const setAuthHeader = (token: string) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  mockedAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const removeAuthHeader = () => {
  delete axios.defaults.headers.common['Authorization']
  delete mockedAxios.defaults.headers.common['Authorization']
}
