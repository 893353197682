import React from 'react'
import { AppButton } from 'shared'
import { AppButtonProps } from 'shared/AppButton'
import Translations from 'utils/translations'

export const SubmitButton: React.FC<AppButtonProps> = props => {
  return <AppButton variant="contained" {...props} />
}
SubmitButton.defaultProps = {
  translation: Translations.PAY,
}
export default SubmitButton
