import React from 'react'
import * as layoutSlice from 'store/slices/layoutSlice'
import { useAppDispatch } from 'store/store'

export const useChangeLayout = (
  layoutProps: layoutSlice.LayoutStateWithoutCustomStyles = { layout: {} },
  type: 'auth' | 'app' = 'auth',
  deps: React.DependencyList = []
) => {
  const dispatch = useAppDispatch()
  const layouts = {
    auth: layoutSlice.authLayoutProps,
    app: layoutSlice.appLayoutProps,
  }
  const combinedLayout: layoutSlice.LayoutStateWithoutCustomStyles = {
    ...layouts[type],
    ...layoutProps,
    layout: {
      ...layouts[type].layout,
      ...layoutProps?.layout,
    },
    topbar:
      'topbar' in layoutProps ? layoutProps?.topbar : layouts[type].topbar,
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  React.useLayoutEffect(() => {
    dispatch(layoutSlice.actions.setFullLayout(combinedLayout))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return combinedLayout
}

export default useChangeLayout
