import { unwrapResult } from '@reduxjs/toolkit'
import { TEZ_PAY_API_URL } from 'api/config'
import { useSnackbar } from 'notistack'
import React from 'react'
import { parkCarSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'

export const useParkCarRedirect = () => {
  const dispatch = useAppDispatch()
  const [isLoading, setisLoading] = React.useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const redirect = React.useCallback(
    async (carId: string, recaptchaToken: string) => {
      setisLoading(true)
      const promise = await dispatch(parkCarSlice.parkCarRedirect({ carId, recaptchaToken }))
      const redirectRes = unwrapResult(promise)
      if (parkCarSlice.parkCarRedirect.fulfilled.match(promise)) {
        window.location.href = `${TEZ_PAY_API_URL}${redirectRes.token}`
      } else {
        setisLoading(false)
        enqueueSnackbar(promise.payload.description)
      }
    },
    [dispatch, enqueueSnackbar]
  )
  return [isLoading, redirect] as const
}

export default useParkCarRedirect
