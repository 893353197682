import React, { useState, useEffect, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from '../../../src/api';
import { VERIFY_HUMAN } from '../../../src/api/constants';
import { Response } from '../../typedef/api';
import { CAPTCHA_V2_ENABLE, CAPTCHA_V3_ENABLE, CAPTCHA_V2_KEY, CAPTCHA_V3_KEY
  , CAPTCHA_VALIDATE_REGISTRATION, CAPTCHA_VALIDATE_RESEND_PIN
  , CAPTCHA_VALIDATE_FORGOT_PASSWORD, CAPTCHA_VALIDATE_ADD_NUMBER_FOR_NOTIFICATION
  , CAPTCHA_VALIDATE_PAYMENT   } from 'api/config'

enum reCapthaVersion{
  v2 = 2, 
  v3 = 3
}

export enum validateFeature{
  Registration = 1,
  ResendPin = 2,
  ForgotPassword = 3,
  AddNumberForNotification = 4,
  Payment = 5
}

interface HybridRecaptchaProps {
  onValidationSuccess: () => void;
  onValidationFailure?: () => void;
  onTokenReceived: (token: string) => void;
  feature: validateFeature;
}

const HybridRecaptchaContent: React.FC<HybridRecaptchaProps> = ({
  onValidationSuccess,
  onValidationFailure,
  onTokenReceived, 
  feature,
}): React.ReactElement => {
  const [showRecaptchaV2, setShowRecaptchaV2] = useState(false);  
  const [isRecaptchaValid, setIsRecaptchaValid] = useState(false); 
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleRecaptchaV3 = useCallback(async (): Promise<void> => {
    if(!CAPTCHA_V3_ENABLE && CAPTCHA_V2_ENABLE){
      setShowRecaptchaV2(true);
      return;
    }

    if (!CAPTCHA_V3_ENABLE || !featureIsOn(feature)) {
      onValidationSuccess();
      return;
    }

    if (isRecaptchaValid || showRecaptchaV2) {          
      return;
    }

    if (!CAPTCHA_V3_KEY || !executeRecaptcha) {
      console.warn('reCAPTCHA v3 is not ready.');
      setTimeout(handleRecaptchaV3, 1000);
      return;
    }

    try {
      const token = await executeRecaptcha('verify_user');
      const isValid = await validateRecaptcha(token, reCapthaVersion.v3);

      if (isValid) {
        setIsRecaptchaValid(true);
        onTokenReceived(token); 
        onValidationSuccess();
        sessionStorage.setItem('recaptchaToken',token);
      } else if (CAPTCHA_V2_ENABLE) {
        setShowRecaptchaV2(true);
      } else {
        onValidationFailure?.();
      }
    } catch (error) {
      console.error('Error during reCAPTCHA v3 validation:', error);
    }
  }, [executeRecaptcha, onValidationSuccess, onValidationFailure, onTokenReceived, showRecaptchaV2, feature, isRecaptchaValid]);
  
const handleRecaptchaV2 = useCallback(async (token: string | null): Promise<void> => {    
    if (!featureIsOn(feature)) {
      onValidationSuccess();
      return;
    }
    if (!token) {
      onValidationFailure?.();
      return;
    }

    try {
      const isValid = await validateRecaptcha(token, reCapthaVersion.v2);

      if (isValid) {        
        setIsRecaptchaValid(true);
        onTokenReceived(token); 
        onValidationSuccess();
        sessionStorage.setItem('recaptchaToken',token);
      } else {
        onValidationFailure?.();
      }
    } catch (error) {
      console.error('Error during reCAPTCHA v2 validation:', error);      
    }  
  }, [onValidationSuccess, onValidationFailure, onTokenReceived, feature]);

  const validateRecaptcha = async (token: string, version: reCapthaVersion): Promise<boolean> => {
    try {
      const response = await axios.post<Response<boolean>>(`${VERIFY_HUMAN}`,
        {token: token, version: version}
      );
      return response.data?.data ?? false;
    } catch (error) {
      console.error(`reCAPTCHA ${version} validation error:`, error);
      return false;
    }
  };

  useEffect(() => {      
    if (isRecaptchaValid) {          
      return;
    }        
    if (executeRecaptcha) {
      handleRecaptchaV3();        
    }         
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRecaptcha]);  

  const featureIsOn = (feature: validateFeature) => {
    switch (feature) {
      case validateFeature.Registration:
        return CAPTCHA_VALIDATE_REGISTRATION;
      case validateFeature.ResendPin:
        return CAPTCHA_VALIDATE_RESEND_PIN;
      case validateFeature.ForgotPassword:
        return CAPTCHA_VALIDATE_FORGOT_PASSWORD;
      case validateFeature.AddNumberForNotification:
        return CAPTCHA_VALIDATE_ADD_NUMBER_FOR_NOTIFICATION;
      case validateFeature.Payment:
        return CAPTCHA_VALIDATE_PAYMENT;
      default:
        return true;
    }
  };

  return (
    <div>
      {showRecaptchaV2 && CAPTCHA_V2_KEY && (
        <ReCAPTCHA            
           sitekey={CAPTCHA_V2_KEY}
           onChange={handleRecaptchaV2}                  
         />         
      )}
    </div>
  );
};

interface AppProps {
  onValidationSuccess: () => void;
  onValidationFailure?: () => void;
  onTokenReceived: (token: string) => void;  
  feature: validateFeature;
}

const HybridRecaptcha: React.FC<AppProps> = ({ onValidationSuccess, onValidationFailure, onTokenReceived, feature }) => (  
    <HybridRecaptchaContent
      onValidationSuccess={onValidationSuccess}
      onValidationFailure={onValidationFailure}
      onTokenReceived={onTokenReceived}
      feature={feature} 
    />
);

export default HybridRecaptcha;