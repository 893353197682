import { PromiseStatus } from './slices.types'
import { FetchStatus } from 'typedef'

export const ADS = 'ads'
export const CARS = 'cars'
export const COUPON = 'cupon'
export const CREDIT_CARDS = 'creditCards'
export const CUSTOMER = 'customer'
export const FETCH = '/fetch'
export const LAYOUT = 'layout'
export const MAP = 'map'
export const PARK_CAR = 'parkCar'
export const RECEIPTS = 'receipts'
export const SIMULATE_PRICE = 'simulatePrice'
export const TERMS = 'terms'
export const TERMS_OF_SERVICE = 'termsOfService'
export const TICKETS = 'tickets'
export const CONTACT_INFORMATIONS = 'contactInformations'

export const initialPromiseStatus: PromiseStatus = {
  description: '',
  title: '',
  variant: 'warning',
  status: [FetchStatus.Idle],
  requestId: null,
  time: null,
  code: null,
}
