import { Typography, TypographyProps } from '@mui/material'
import React from 'react'
import styled, { css } from 'styled-components'

export interface AppTypographyProps extends TypographyProps {
  weight?: string | number
  paddingLeft?: number
  /** in rems */
  size?: number
  line?: number
  disabled?: boolean
  textCase?: 'uppercase' | 'lowercase' | 'inherit'
  component?: any //can't get proper type for Typography
  customColor?: string
}

export const StyledTypographyWrapper = styled.div<AppTypographyProps>`
  > * {
    font-weight: ${_ => _.weight};
    text-transform: ${_ => _.textCase};
    padding-left: ${_ => _.theme.spacing(_.paddingLeft)};
    ${_ =>
      _.customColor &&
      css`
        color: ${_.customColor};
      `}
    ${_ =>
      _.disabled &&
      css`
        color: ${_.theme.palette.text.disabled};
      `}
    ${_ =>
      _.size &&
      css`
        font-size: ${_.size}rem;
      `}
    ${_ =>
      _.line &&
      css`
        line-height: ${_.line};
      `}
  }
`
export const AppTypography: React.FC<AppTypographyProps> = ({
  weight,
  paddingLeft,
  textCase,
  size,
  line,
  disabled,
  customColor,
  ...props
}) => {
  return (
    <StyledTypographyWrapper
      weight={weight}
      paddingLeft={paddingLeft}
      disabled={disabled}
      size={size}
      textCase={textCase}
      line={line}
      customColor={customColor}
    >
      <Typography {...props}>{props.children}</Typography>
    </StyledTypographyWrapper>
  )
}
AppTypography.defaultProps = {
  weight: 'normal',
  paddingLeft: 0,
  textCase: 'inherit',
}
export default AppTypography
