import { Avatar, Divider, Grid, ListItemText, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  AppButton,
  AppSpinner,
  AppTypography,
  FlexBox,
  SharedIcon,
} from 'shared'
import { BOTTOM_BAR_HEIGHT } from 'shared/layout/constants'
import { RootState } from 'store/rootReducer'
import { customerSlice, mapSlice, ticketsSlice } from 'store/slices'
import { LotState } from 'store/slices/mapSlice.types'
import styled from 'styled-components'
import { getDateWithCustomTime } from 'utils'
import Translations from 'utils/translations'
const SelectedAreaContainer = styled.div`
  max-width: 370px;
  margin: 0 auto;
  position: fixed;
  bottom: calc(${BOTTOM_BAR_HEIGHT}px + 16px);
  left: 0;
  right: 0;
  z-index: 3;
`

const ClosedInfo = styled.span`
  color: ${props => props.theme.palette.error.main};
`

const useStyles = makeStyles(_ => ({
  root: {
    width: 80,
    overflow: 'initial',
    backgroundColor: 'inherit',
  },
  gridRoot: {
    padding: 0,
  },
  gridAvatarRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperRoot: {
    borderRadius: 8,
  },
  capacityMessage: {
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    background: '-webkit-linear-gradient(#000000,#eee)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
}))
export interface SelectedLotInfoProps {
  lot: LotState
  loading: boolean
  onParkCarClick: () => void
  onTicketClick: (ticketId: string) => void
}

export const SelectedLotInfo: React.FC<SelectedLotInfoProps> = ({
  lot,
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { hasParkedCar, parkedCar } = useSelector((state: RootState) =>
    mapSlice.selectHasParkedCar(state, lot.lotId)
  )
  const [canParkCar, setCanParkCar] = React.useState(true)
  const [capacityFullMessageTrim, setCapacityFullMessageTrim] =
    React.useState(true)
  const { customerSignedInAsGuest } = useSelector(
    customerSlice.selectCustomerStatus
  )
  const tickets = useSelector(ticketsSlice.selectSortedParkedTickets)

  const isClosedForWholeDay = () => {
    if (!lot?.currentDateTimeLocal) {
      return true
    } else if (lot?.openingHours) {
      return (
        lot?.openingHours[moment(lot?.currentDateTimeLocal).day()]
          ?.openingHoursInDay.length === 0
      )
    }
    return false
  }

  const isLocationStopped = () => {
    return lot?.isLocationStopped
  }

  const isCapacityFull = () => {
    return lot?.lotCapacity?.lotIsFull
  }

  const isParkingClosed = () => {
    if (!lot?.currentDateTimeLocal) return true
    let isClosed = true
    lot?.openingHours[
      moment(lot?.currentDateTimeLocal).day()
    ].openingHoursInDay.forEach(el => {
      if (
        moment(lot?.currentDateTimeLocal).isBetween(
          getDateWithCustomTime(lot?.currentDateTimeLocal, el.startTime)
            ?.fullMomentDate,
          getDateWithCustomTime(lot?.currentDateTimeLocal, el.endTime)
            ?.fullMomentDate
        )
      ) {
        isClosed = false
      }
    })
    return isClosed
  }
  const navigate = React.useCallback(() => {
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${lot.geolocation.latitude},${lot.geolocation.longitude}&travelmode=driving&dir_action=navigate`,
      '_blank'
    )
  }, [lot])

  const lotAddress = lot.hasDetails
    ? `${lot.address?.street}${
        lot.address?.city ? `, `.concat(lot.address?.city) : ''
      }`
    : null

  const openingHours = () => {
    if (!lot?.openingHours) return '-'
    let hoursString = ''
    lot?.openingHours[
      moment(lot?.currentDateTimeLocal).day()
    ].openingHoursInDay.forEach(({ endTime, startTime }, i, arr) => {
      const start =
        getDateWithCustomTime(
          lot?.currentDateTimeLocal,
          startTime
        )?.fullMomentDate.format('LT') || ''
      const end =
        getDateWithCustomTime(
          lot?.currentDateTimeLocal,
          endTime
        )?.fullMomentDate.format('LT') || ''
      const newString = `${start} -   
        ${end}${i + 1 < arr.length ? ', ' : ''}`
      hoursString = hoursString + newString
    })

    return hoursString
  }

  const lotOpenOrClosedInfo = () => {
    if (isLocationStopped()) {
      return (
        <AppTypography color="error" style={{ padding: '4px 2px' }}>
          {lot.locationStoppedMessage ??
            t(Translations.TEXT2PARK_IS_CURRENTLY_NOT_AVAILABLE)}
        </AppTypography>
      )
    } else if (isCapacityFull()) {
      return (
        <AppTypography
          className={
            capacityFullMessageTrim ? classes.capacityMessage : undefined
          }
          onClick={() => setCapacityFullMessageTrim(prevState => !prevState)}
        >
          {lot.lotCapacity.capacityFullWEBMessage}
        </AppTypography>
      )
    } else if (isClosedForWholeDay()) {
      return (
        <AppTypography color="textPrimary" style={{ padding: '4px 0' }}>
          {t(Translations.TODAY_OPEN)}:{' '}
          <ClosedInfo>{t(Translations.TODAY_CLOSED)}</ClosedInfo>
        </AppTypography>
      )
    } else {
      return (
        <AppTypography color="textPrimary" style={{ padding: '4px 0' }}>
          {t(Translations.TODAY_OPEN)}: {openingHours()}
        </AppTypography>
      )
    }
  }

  const NoCarsParkingBtns = () => (
    <FlexBox horizontal>
      <AppButton color="primary" onClick={navigate} minWidth="140px">
        {t(Translations.NAVIGATE)}
      </AppButton>
      {canParkCar && lot?.hasDetails && (
        <AppButton
          color="primary"
          onClick={props.onParkCarClick}
          minWidth="140px"
          disabled={
            isParkingClosed() || isLocationStopped() || isCapacityFull()
          }
        >
          {isParkingClosed()
            ? t(Translations.CLOSED)
            : t(Translations.PARK_CAR)}
        </AppButton>
      )}
    </FlexBox>
  )

  const WithCarsParkingBtns = () => (
    <FlexBox horizontal>
      <AppButton
        color="primary"
        onClick={navigate}
        minWidth={canParkCar ? 'auto' : '120px'}
      >
        {t(Translations.NAVIGATE)}
      </AppButton>
      <AppButton
        color="primary"
        onClick={() => props.onTicketClick(parkedCar?.carId)}
        minWidth={canParkCar ? 'auto' : '120px'}
      >
        {t(Translations.TICKET)}
      </AppButton>
      {canParkCar && lot?.hasDetails && (
        <AppButton
          color="primary"
          onClick={props.onParkCarClick}
          minWidth="auto"
          disabled={
            isParkingClosed() || isLocationStopped() || isCapacityFull()
          }
        >
          {isParkingClosed()
            ? t(Translations.CLOSED)
            : t(Translations.PARK_ANOTHER_CAR)}
        </AppButton>
      )}
    </FlexBox>
  )

  React.useEffect(() => {
    if (customerSignedInAsGuest && tickets.length) {
      setCanParkCar(false)
    } else {
      setCanParkCar(true)
    }
  }, [customerSignedInAsGuest, tickets.length])

  return (
    <SelectedAreaContainer>
      <Paper classes={{ root: classes.paperRoot }}>
        <Grid container spacing={2}>
          <Grid item xs={12} container>
            <Grid item xs={3} classes={{ root: classes.gridAvatarRoot }}>
              {props.loading && !lot?.hasDetails ? (
                <AppSpinner show relative />
              ) : (
                <Avatar
                  src={lot.logoLink}
                  variant="square"
                  sizes=""
                  classes={{ root: classes.root }}
                  imgProps={{
                    style: { objectFit: 'contain', background: 'transparent' },
                  }}
                >
                  <SharedIcon.LotAvatarPlaceholder />
                </Avatar>
              )}
            </Grid>
            <Grid item xs={9}>
              <ListItemText
                disableTypography
                primary={
                  <AppTypography weight="bold" color="textPrimary">
                    {lot.lotName}
                  </AppTypography>
                }
                secondary={
                  <AppTypography color="textPrimary">
                    {lotAddress ? lotAddress : '-'}
                  </AppTypography>
                }
              />
              <Divider />
              {lotOpenOrClosedInfo()}
              <Divider />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {hasParkedCar ? <WithCarsParkingBtns /> : <NoCarsParkingBtns />}
          </Grid>
        </Grid>
      </Paper>
      {props.children}
    </SelectedAreaContainer>
  )
}

export default SelectedLotInfo
