import { useFormikContext } from 'formik'
import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { parkCarSlice } from 'store/slices'
import { ParkCarState } from 'store/slices/parkCarSlice types'
import { useAppDispatch } from 'store/store'
import { useParkCarPath } from '../ParkCarHooks'
import { T2Fields } from './fields'
import { useExternalPricing, useCurrentTime } from './T2Hooks'
import {
  ParkCarT2SelectPricing,
  ParkCarT2SetTime,
  ParkCarT2Summary,
} from './T2Views'

export interface ParkCarT2SwitchProps {
  initFetch: () => void
}

export const ParkCarT2Switch: React.FC<ParkCarT2SwitchProps> = props => {
  useCurrentTime()
  const location = useLocation()
  const [parkCarPath, goTo] = useParkCarPath()
  const [loading, getExternalPricing] = useExternalPricing()
  const { state } = useLocation<{ fullRefresh: boolean }>()
  const { values } = useFormikContext<T2Fields>()
  const dispatch = useAppDispatch()
  const { licencePlate, spotNumber } = values

  React.useEffect(() => {
    if (state.fullRefresh) {
      props.initFetch()
      dispatch(
        parkCarSlice.actions.updateParkingDetails<
          Partial<ParkCarState['parkingDetails']>
        >({
          spot: spotNumber,
          plate: licencePlate,
        })
      )
      goTo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.fullRefresh])

  return (
    <Switch location={location}>
      <Route
        exact
        path={parkCarPath('select')}
        render={() => <ParkCarT2SelectPricing />}
      />
      <Route
        exact
        path={parkCarPath('summary')}
        render={() => <ParkCarT2Summary />}
      />
      <Route
        path={parkCarPath()}
        render={() => (
          <ParkCarT2SetTime loading={loading} onSubmit={getExternalPricing} />
        )}
      />
    </Switch>
  )
}

export default ParkCarT2Switch
