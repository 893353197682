import {
  GetValidatorsData,
  GetValidatorsParams,
  ValidateCodeParams,
  ValidateCodeUpfrontParams,
} from 'typedef/validator'
import axios from '.'
import { Response } from '../typedef/api'
import { COUPON, UPFRONT_COUPON } from './constants'
import './mocks/ValidatorsApiMock'

export const getValidators = async (params: GetValidatorsParams) => {
  try {
    const result = await axios.get<Response<GetValidatorsData>>(COUPON, {
      params,
    })
    return result.data.data
  } catch (err) {
    throw err
  }
}

export const validateCode = async (params: ValidateCodeParams) => {
  try {
    const { data } = await axios.post<Response<boolean>>(COUPON, params)
    return data.data
  } catch (err) {
    throw err
  }
}

export const validateCodeUpfront = async (
  params: ValidateCodeUpfrontParams
) => {
  try {
    const { data } = await axios.post<Response<boolean>>(UPFRONT_COUPON, params)
    return data.data
  } catch (err) {
    throw err
  }
}
