import { Slide, Divider, List } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group'
import { showPopup } from 'store/globalPopup/actions'
import { RootState } from 'store/rootReducer'
import { creditCardsSlice, customerSlice, ticketsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { CreditCard } from 'typedef/creditCards'
import Translations from 'utils/translations'
import CreditCardBar from './CreditCardBar'

interface CreditCardsListProps {
  creditCards?: CreditCard[]
}

export const CreditCardsList: React.FunctionComponent<CreditCardsListProps> =
  props => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const { ticketId } = useParams<{ ticketId: string }>()
    const ticket = useSelector((state: RootState) =>
      ticketsSlice.selectById(state, ticketId)
    )
    const { customerSignedIn, customerSignedInAsGuest } = useSelector(
      customerSlice.selectCustomerStatus
    )

    const handlePopup = (creditCardId: string) =>
      dispatch(
        showPopup({
          title: t(Translations.DELETE_CREDIT_CARD),
          description: t(
            Translations.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_YOUR_CREDIT_CARD
          ),
          variant: 'delete',
          handleAdditionalButtonClick: () => {
            if (customerSignedIn) {
              dispatch(
                creditCardsSlice.removeCreditCard({
                  creditCardId,
                  lotId: ticket?.lot?.lotId,
                })
              )
            }

            if (customerSignedInAsGuest) {
              dispatch(creditCardsSlice.actions.removeCard(creditCardId))
            }
          },
        })
      )

    return (
      <List dense>
        <TransitionGroup>
          {props?.creditCards?.map((el, i, arr) => (
            <Slide key={el.creditCardId} direction="left" appear={false}>
              <div>
                <CreditCardBar
                  key={el.creditCardId}
                  card={el}
                  handleDeleteClick={() => handlePopup(el.creditCardId)}
                />
                {arr.length - 1 > i && (
                  <Divider variant="middle" component="li" />
                )}
              </div>
            </Slide>
          ))}
        </TransitionGroup>
      </List>
    )
  }

export default CreditCardsList
