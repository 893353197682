import * as React from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'

const StyledSpotNumberIcon = styled(Typography)`
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  left: 0;
  font-size: 48px;
  transform: translateY(-45%);
`
interface SpotNumberIconProps {
  spotNumber: string
}
export const SpotNumberIcon: React.FC<SpotNumberIconProps> = props => {
  return (
    <div style={{ position: 'relative' }}>
      <SvgComponent />
      <StyledSpotNumberIcon color="primary" variant="subtitle2">
        {props.spotNumber}
      </StyledSpotNumberIcon>
    </div>
  )
}

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={242} height={198} viewBox="0 0 242 198" fill="none" {...props}>
      <rect
        x={53.457}
        y={173.129}
        width={47.485}
        height={20.386}
        rx={10.193}
        transform="rotate(-30 53.457 173.129)"
        fill="#DCF3FF"
      />
      <rect
        x={13.999}
        y={133.574}
        width={27.151}
        height={20.363}
        rx={10.181}
        transform="rotate(-30 13.999 133.574)"
        fill="#DCF3FF"
      />
      <rect
        x={172.582}
        y={10.195}
        width={20.386}
        height={20.386}
        rx={10.193}
        transform="rotate(-30 172.582 10.195)"
        fill="#DCF3FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.004 87.892c-4.875 2.815-6.545 9.048-3.73 13.924 2.814 4.875 9.048 6.545 13.923 3.73L158.279 41.99c4.875-2.814 6.546-9.048 3.731-13.924-2.814-4.875-9.048-6.545-13.924-3.73L38.004 87.892zm75.054 50.827c-4.756 2.856-6.361 9.009-3.577 13.832 2.815 4.875 9.049 6.546 13.924 3.731l84.679-48.889c4.875-2.815 6.546-9.049 3.731-13.924-2.793-4.837-8.951-6.519-13.808-3.796l-33.467 19.322-.116.065-.618.358c.106-.064.212-.128.315-.194-4.808 2.488-10.77.777-13.505-3.96-2.736-4.739-1.234-10.763 3.331-13.68-.103.053-.206.108-.308.165l.631-.364.077-.045 34.841-20.116.078-.044 15.741-9.088c4.876-2.815 6.546-9.049 3.731-13.924-2.807-4.863-9.017-6.537-13.886-3.752l-33.861 19.55-.037.02-98.874 57.086c-4.876 2.814-6.546 9.048-3.731 13.923 2.814 4.876 9.048 6.546 13.923 3.731l18.011-10.398-.02.012 13.811-7.974c4.875-2.815 11.109-1.144 13.923 3.731 2.815 4.875 1.145 11.109-3.73 13.924l-1.209.698zm39.328-93.325l-1.588.917c-4.875 2.814-6.545 9.048-3.73 13.923 2.801 4.853 8.991 6.53 13.856 3.77l-80.723 46.605-.057.033 25.427-14.68c4.876-2.815 6.546-9.05 3.731-13.924-2.803-4.856-9-6.533-13.866-3.764l56.882-32.841.068-.039z"
        fill="#DCF3FF"
      />
      <circle
        cx={230.519}
        cy={120.143}
        r={5.259}
        stroke="#DCF3FF"
        strokeWidth={2.605}
      />
      <circle
        cx={40.915}
        cy={52.493}
        r={3.901}
        stroke="#DCF3FF"
        strokeWidth={2.605}
      />
      <circle
        cx={114.204}
        cy={188.204}
        r={3.901}
        stroke="#DCF3FF"
        strokeWidth={2.605}
      />
      <rect
        x={147.031}
        y={5.203}
        width={12.67}
        height={2.263}
        rx={1.131}
        fill="#DCF3FF"
      />
      <rect
        x={154.497}
        width={12.67}
        height={2.263}
        rx={1.131}
        transform="rotate(90 154.497 0)"
        fill="#DCF3FF"
      />
      <rect
        x={190.924}
        y={150.801}
        width={9.503}
        height={2.263}
        rx={1.131}
        fill="#DCF3FF"
      />
      <rect
        x={196.806}
        y={147.293}
        width={9.503}
        height={2.263}
        rx={1.131}
        transform="rotate(90 196.806 147.293)"
        fill="#DCF3FF"
      />
      <rect
        x={10.599}
        y={104.871}
        width={9.503}
        height={2.263}
        rx={1.131}
        fill="#DCF3FF"
      />
      <rect
        x={16.48}
        y={101.363}
        width={9.503}
        height={2.263}
        rx={1.131}
        transform="rotate(90 16.48 101.363)"
        fill="#DCF3FF"
      />
      <circle
        cx={121.5}
        cy={103.5}
        r={73}
        fill="#fff"
        stroke="#32A3F5"
        strokeWidth={3}
      />
    </svg>
  )
}

export default SpotNumberIcon
