import { ButtonProps } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton } from 'shared'
import FlexBox from 'shared/FlexBox'
import Translations from '../../utils/translations'

export interface PopupButtonsProps {
  variant?: 'info' | 'success' | 'warning' | 'error' | 'delete'
  handleAdditionalButtonClick: () => void
  handleCloseClick: () => void
}

const PopupButtons: React.FC<PopupButtonsProps> = ({
  variant = 'info',
  handleCloseClick,
  handleAdditionalButtonClick,
}) => {
  const { t } = useTranslation()

  const buttonColor: {
    info: ButtonProps['color']
    error: ButtonProps['color']
    success: ButtonProps['color']
    warning: ButtonProps['color']
    delete: ButtonProps['color']
  } = {
    info: 'primary',
    error: 'secondary',
    success: 'primary',
    warning: 'primary',
    delete: 'secondary',
  }

  const buttonText = {
    info: t(Translations.OK),
    error: t(Translations.OK),
    success: t(Translations.OK),
    warning: t(Translations.CLOSE),
    delete: t(Translations.CANCEL),
  }
  const buttonAdditionalText = {
    info: t(Translations.TRY_AGAIN),
    error: t(Translations.TRY_AGAIN),
    success: t(Translations.TRY_AGAIN),
    warning: t(Translations.TRY_AGAIN),
    delete: t(Translations.DELETE),
  }

  const hasAdditionalButton = variant === 'warning' || variant === 'delete'

  return (
    <FlexBox>
      {hasAdditionalButton && (
        <AppButton
          variant="contained"
          color={buttonColor[variant]}
          onClick={handleAdditionalButtonClick}
          data-testid="popupAdditionalButton"
        >
          {buttonAdditionalText[variant]}
        </AppButton>
      )}
      <AppButton
        variant={hasAdditionalButton ? 'outlined' : 'contained'}
        color={buttonColor[variant]}
        onClick={handleCloseClick}
        data-testid="popupCloseButton"
      >
        {buttonText[variant]}
      </AppButton>
    </FlexBox>
  )
}

export default PopupButtons
