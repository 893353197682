export enum TermsType {
  Login = 1,
  Transient = 2,
  Monthly = 3,
}

export enum UserLanguage {
  En_US = 1,
  En_GB = 2,
  De_de = 3,
  It_it = 4,
}

export interface GetTermsAndConditionsParams {
  type: TermsType
  lotId?: string
  languageCode?: string //ISO Language Code
}

export interface GetTermsAndConditionsResult {
  text: string //Simple HTML with tags <p> <b> <br/>
}

export interface ShowForCustomerResult {
  termsAndConditionsID: string
  show: boolean
}

export interface ShowForCustomerParams {
  language: UserLanguage
}

export interface AcceptToSParams {
  termsAndConditionsId: string
  clientDateTime: Date
}

export interface TermsOfService {
  termsAndConditionsId: string
  show: boolean
  termsAndConditionsContent: string
}

export interface GetTermsOfServiceContentParams {
  id: string
  language: UserLanguage
}
