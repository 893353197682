import { fetchStatusIs } from 'api/utils'
import { useChangeLayout } from 'hooks'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import paths from 'routing/paths'
import { AppSpinner, WhiteBoxContainer } from 'shared'
import { showPopup } from 'store/globalPopup/actions'
import { RootState } from 'store/rootReducer'
import { ticketsSlice } from 'store/slices'
import {
  selectSendValidateCodeStatus,
  selectValidatorById,
  validateCode,
} from 'store/slices/validatorsSlice'
import { useAppDispatch } from 'store/store'
import Translations from 'utils/translations'
import { ValidateProps } from './types'
import ValidateTicket from './ValidateTicket'

export const Validate: React.FC<ValidateProps> = props => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { carId, couponId } = props.match.params
  const partner = useSelector((state: RootState) =>
    selectValidatorById(state, couponId)
  )

  const { pending } = fetchStatusIs(
    useSelector((state: RootState) => selectSendValidateCodeStatus(state))
  )
  const title = t(Translations.VALIDATE)
  useChangeLayout(
    {
      topbar: {
        title,
        onClickBack: () => {
          dispatch(ticketsSlice.getTicket({ carId }))
          history.push(`${paths.parking.validators}/${carId}`)
        },
      },
      bottomMenu: false,
    },
    'app'
  )
  if (!partner) {
    history.push(paths.parking.tickets)
    return null
  }
  const handleSubmit = async (validationCode: string) => {
    const actionResult = await dispatch(
      validateCode({
        carId,
        couponId: partner.couponId,
        partnerId: partner.partnerId,
        validationCode,
      })
    )

    if (validateCode.rejected.match(actionResult)) {
      dispatch(
        showPopup({
          ...actionResult.payload,
          handleAdditionalButtonClick: () => handleSubmit(validationCode),
        })
      )
    } else {
      dispatch(ticketsSlice.getTicket({ carId }))

      dispatch(
        showPopup({
          variant: 'success',
          title: `${t(Translations.SUCCESS)}!`,
          description: t(Translations.TICKET_HAS_BEEN_VALIDATED),
          okButtonClick: () => history.push(paths.parking.tickets),
        })
      )
    }
  }

  return (
    <WhiteBoxContainer>
      <AppSpinner show={pending} />
      <ValidateTicket partner={partner} handleSubmit={handleSubmit} />
    </WhiteBoxContainer>
  )
}

export default Validate
