/**navigator in second and last if has has type 'never'
 * code is from official docs
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
 */
const constNavigator = navigator as any
export const useTouchScreen = () => {
  let hasTouchScreen = false

  if ('maxTouchPoints' in constNavigator) {
    hasTouchScreen = constNavigator.maxTouchPoints > 0
  } else if ('msMaxTouchPoints' in constNavigator) {
    hasTouchScreen = constNavigator.msMaxTouchPoints > 0
  } else {
    const mQ = window.matchMedia && matchMedia('(pointer:coarse)')
    if (mQ && mQ.media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches
    } else if ('orientation' in window) {
      hasTouchScreen = true // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = constNavigator.userAgent
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
    }
  }
  return hasTouchScreen
}

export default useTouchScreen
