import React from 'react'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

const responsive: Settings['responsive'] = [
  {
    breakpoint: 600,
    settings: { centerMode: false },
  },
]

const AppCarousel: React.FC<Settings> = props => {
  return <Slider {...props}>{props.children}</Slider>
}
AppCarousel.defaultProps = {
  adaptiveHeight: true,
  slidesToScroll: 1,
  infinite: false,
  centerMode: true,
  draggable: true,
  swipeToSlide: true,
  responsive,
  variableWidth: true,
  arrows: false,
  touchMove: true,
}

export default AppCarousel
