import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { LotApiError } from 'api/apiErrors/LotApiError'
import * as lotApi from 'api/LotApi'
import { getErrorCode, getErrorMessage } from 'api/utils'
import { Bounds, Coords } from 'google-map-react'
import { RootState } from 'store/rootReducer'
import { AppState } from 'store/store'
import {
  CarStatus,
  ErrorData,
  GetLotDetailsMapData,
  GetLotDetailsMapPayload,
  GetLotsData,
  GetLotsPayload,
} from 'typedef'
import { customerSlice, sliceUtil, ticketsSlice } from '.'
import { FETCH, initialPromiseStatus, MAP } from './constants'
import { LotState, MapState, MyPosition } from './mapSlice.types'

/**
 * state
 */
const initialPromiseStatuses = {
  getLots: initialPromiseStatus,
  getLotDetailsMap: initialPromiseStatus,
}

const initialZoom = 14

export const initialMapPosition: Coords = {
  lat: 33.11395709999999,
  lng: -96.8024388,
}
export const initialBounds = [
  -96.835397784375, 33.08066546844681, -96.769479815625, 33.14723611938656,
]

const mapAdapter = createEntityAdapter<LotState>({
  selectId: en => en.lotId,
})
const initialState = mapAdapter.getInitialState<MapState>({
  promisesStatus: initialPromiseStatuses,
  position: initialMapPosition,
  zoom: initialZoom,
  selectedLot: null,
  lotsToSelect: [],
  myPosition: null,
  findCarMode: false,
  cachedMapPosition: {
    position: null,
    zoom: initialZoom,
    bounds: null,
  },
  bounds: initialBounds,
  isGeoOn: false,
  geoError: '',
  geoState: null,
  boundsToOmit: [],
  noClusteredLots: [],
})

/**
 * thunks
 */
export const getLots = createAsyncThunk<
  GetLotsData,
  GetLotsPayload,
  { rejectValue: ErrorData }
>(`${MAP}${FETCH}/getLots`, async (params, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState
    const token = state.customer.corporationLotsToken
    const pcode = state.customer.corporationLotsGlobalPcode
    const additionalParam = token || pcode
    const argsWithAdditionalParam = token
      ? { ...params, token }
      : { ...params, pcode }
    const args = additionalParam ? argsWithAdditionalParam : params
    const resp = await lotApi.getLots(args)
    if (resp.newTokens) {
      thunkApi.dispatch(customerSlice.actions.setLogin(resp.newTokens))
    }
    return resp
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      LotApiError.getLots
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const getLotsByPcode = createAsyncThunk<
  GetLotsData,
  GetLotsPayload,
  { rejectValue: ErrorData }
>(`${MAP}${FETCH}/getLotsByPcode`, async (params, thunkApi) => {
  try {
    const resp = await lotApi.getLots(params)
    return resp
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      LotApiError.getLots
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const getLotDetailsMap = createAsyncThunk<
  GetLotDetailsMapData,
  GetLotDetailsMapPayload,
  { rejectValue: ErrorData & { skip?: boolean } }
>(`${MAP}${FETCH}/getLotDetailsMap`, async (params, thunkApi) => {
  try {
    const res = await lotApi.getLotDetailsMap(params)
    const { map } = thunkApi.getState() as AppState
    const { requestId } = map.promisesStatus.getLotDetailsMap

    if (thunkApi.requestId !== requestId) {
      const errorMessage = { ...getErrorMessage(), skip: true }
      return thunkApi.rejectWithValue(errorMessage)
    }
    return res
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      LotApiError.getLots
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

/**
 * reducers
 */
const mapSlice = createSlice({
  name: MAP,
  initialState,
  reducers: {
    setGeo(state, { payload }: PayloadAction<boolean>) {
      state.isGeoOn = payload
    },
    setGeoError(state, { payload }: PayloadAction<string | null>) {
      state.geoError = payload
    },
    setGeoState(state, { payload }: PayloadAction<string | null>) {
      state.geoState = payload
    },
    addLots(state, { payload }: PayloadAction<{ lots: LotState[] }>) {
      if (payload?.lots) {
        const lots = payload.lots.filter(el => el.geolocation)
        mapAdapter.upsertMany(state, lots)
      }
    },
    addLot(state, { payload }: PayloadAction<LotState>) {
      mapAdapter.upsertOne(state, payload)
    },
    removeLots(state) {
      mapAdapter.removeAll(state)
    },
    setPosition(state, { payload }: PayloadAction<Coords>) {
      state.position = payload
    },
    unsetFindCarMode(state) {
      state.findCarMode = false
    },
    foundParkedCar(state, { payload }: PayloadAction<{ lotId: string }>) {
      const lot = mapAdapter.getSelectors().selectById(state, payload.lotId)
      state.cachedMapPosition = {
        zoom: state.zoom,
        position: state.position,
        bounds: state.bounds,
      }
      state.findCarMode = true
      state.zoom = 17
      state.selectedLot = lot.lotId
      state.position = {
        lat: lot?.geolocation?.latitude,
        lng: lot?.geolocation?.longitude,
      }
    },
    exitParkedCarMode(state) {
      state.findCarMode = false
      state.selectedLot = null
      state.zoom = state.cachedMapPosition.zoom
      state.position = {
        lat: state.cachedMapPosition.position.lat,
        lng: state.cachedMapPosition.position.lng,
      }
      state.cachedMapPosition = null
    },
    setMyPosition(state, { payload }: PayloadAction<MyPosition | null>) {
      state.myPosition = payload
    },
    setCachedPosition(
      state,
      {
        payload,
      }: PayloadAction<{
        position: Coords | null
        zoom: number
        bounds: number[]
      }>
    ) {
      state.cachedMapPosition = payload
    },
    focusMapOnMyLocation(state) {
      if (state.myPosition) {
        state.position = state.myPosition
      } else {
        console.warn('No gps location')
      }
    },
    setZoom(state, { payload }: PayloadAction<number>) {
      state.zoom = payload
    },
    setDefaultZoom(state) {
      state.zoom = initialZoom
    },
    toggleFindCarMode(state) {
      state.findCarMode = !state.findCarMode
    },
    selectLot(
      state,
      { payload }: PayloadAction<{ lotId: string; position?: boolean }>
    ) {
      if (!payload?.lotId) return
      const lot = mapAdapter.getSelectors().selectById(state, payload.lotId)
      state.selectedLot = lot.lotId
      if (payload.position) {
        state.position = {
          lat: lot.geolocation.latitude,
          lng: lot.geolocation.longitude,
        }
        // state.zoom = initialZoom
      }
    },
    setLotsToSelect(state, { payload }: PayloadAction<{ ids: string[] }>) {
      state.lotsToSelect = payload.ids
    },
    unsetLotsToSelect(state) {
      state.lotsToSelect = []
    },
    unselectLot(state) {
      state.selectedLot = null
    },
    setBounds(state, { payload }: PayloadAction<Bounds>) {
      state.bounds = sliceUtil.mapMapBoundsToCluster(payload)
    },
    resetPromiseStatus(
      state,
      { payload }: PayloadAction<keyof MapState['promisesStatus']>
    ) {
      state.promisesStatus[payload] = initialPromiseStatus
    },
    resetState(state) {
      state = initialState
    },
    addBoundsToOmit(
      state,
      { payload, ...action }: PayloadAction<GetLotsPayload>
    ) {
      state.boundsToOmit = [
        ...state.boundsToOmit,
        sliceUtil.mapApiBoundsToCluster(payload),
      ]
    },
    removeBoundsFromOmit(state, { payload }: PayloadAction<GetLotsPayload>) {
      const payloadBoundsString = sliceUtil
        .mapApiBoundsToCluster(payload)
        .toString()
      state.boundsToOmit = state.boundsToOmit.filter(
        el => !(el.toString() === payloadBoundsString)
      )
    },
    resetBoundsToOmit(state) {
      state.boundsToOmit = []
    },
    setNoClusteredLots(state, { payload }: PayloadAction<string[]>) {
      state.noClusteredLots = payload
    },
  },
  extraReducers: builder => {
    builder
      /**
       * getLots
       */
      .addCase(getLots.fulfilled, (state, action) => {
        mapSlice.caseReducers.addLots(state, action)
        state.promisesStatus.getLots = sliceUtil.fulfilledPromise()
      })
      .addCase(getLots.pending, (state, action) => {
        const fwdAction = mapSlice.actions.addBoundsToOmit(action.meta.arg)
        mapSlice.caseReducers.addBoundsToOmit(state, fwdAction)
        state.promisesStatus.getLots = sliceUtil.pendingPromise(
          state.promisesStatus.getLots,
          action
        )
      })
      .addCase(getLots.rejected, (state, action) => {
        const fwdAction = mapSlice.actions.removeBoundsFromOmit(action.meta.arg)
        mapSlice.caseReducers.removeBoundsFromOmit(state, fwdAction)
        state.promisesStatus.getLots = sliceUtil.rejectedPromise(action.payload)
      })
      /**
       * getLotsByPcode
       */
      .addCase(getLotsByPcode.fulfilled, (state, action) => {
        mapSlice.caseReducers.removeLots(state)
        mapSlice.caseReducers.addLots(state, action)
        state.promisesStatus.getLots = sliceUtil.fulfilledPromise()
      })
      .addCase(getLotsByPcode.pending, (state, action) => {
        state.promisesStatus.getLots = sliceUtil.pendingPromise(
          state.promisesStatus.getLots,
          action
        )
      })
      .addCase(getLotsByPcode.rejected, (state, action) => {
        state.promisesStatus.getLots = sliceUtil.rejectedPromise(action.payload)
      })
      /**
       * getLotDetailsMap
       */
      .addCase(getLotDetailsMap.pending, ({ promisesStatus }, action) => {
        promisesStatus.getLotDetailsMap = sliceUtil.pendingPromise(
          promisesStatus.getLotDetailsMap,
          action
        )
      })
      .addCase(getLotDetailsMap.rejected, ({ promisesStatus }, action) => {
        // eslint-disable-next-line
        if (promisesStatus.getLotDetailsMap.requestId != action.meta.requestId)
          return
        promisesStatus.getLotDetailsMap = sliceUtil.rejectedPromise(
          action.payload
        )
      })
      .addCase(getLotDetailsMap.fulfilled, (state, action) => {
        state.promisesStatus.getLotDetailsMap = sliceUtil.fulfilledPromise()
        mapSlice.reducer(
          state,
          mapSlice.actions.addLot({ ...action.payload, hasDetails: true })
        )
      })
  },
})

export default mapSlice.reducer
export const { actions } = mapSlice

/**
 * selectors
 */
export const { selectAll, selectById, selectEntities, selectIds, selectTotal } =
  mapAdapter.getSelectors((state: RootState) => state.map)

export const selectByIds = (state: RootState, ids: string[] = []) => {
  const entities = selectEntities(state)
  return ids.map(id => entities[id]).filter(el => el)
}
export const selectLotsToSelect = (state: RootState) => {
  return selectByIds(state, state.map.lotsToSelect)
}
export const selectLotByTicketId = (state: RootState, id?: string) => {
  if (!id) {
    return null
  }
  const ticket = ticketsSlice.selectById(state, id)
  if (!ticket) {
    return null
  }
  return selectById(state, ticket.lot.lotId)
}

export const stateSelectors = (state: RootState) => state.map
export const selectHasParkedCar = (state: RootState, lotId: string) => {
  const parkedCar = ticketsSlice
    .selectParkedCars(state)
    .find(el => el.lot.lotId === lotId && el.carStatus === CarStatus.Parked)
  return { hasParkedCar: Boolean(parkedCar), parkedCar }
}
export const selectParkedLots = (state: RootState) => {
  const lotIds = ticketsSlice.selectParkedCars(state).map(el => el.lot.lotId)
  if (!lotIds.length) return []
  return lotIds.map(el => state.map.entities[el]).filter(el => el?.geolocation)
}
export const selectManyAreas = (state: RootState, coords: Coords) => {
  const lots = selectAll(state).filter(el => {
    if (!el.geolocation?.latitude || !el.geolocation?.longitude) return false
    if (
      el.geolocation.latitude === coords.lat &&
      el.geolocation.longitude === coords.lng
    )
      return true
    return false
  })
  return lots.map(el => el.lotId)
}

export const selectMapLots = (state: RootState) => {
  return selectAll(state)
    .filter(el => {
      if (!el.geolocation) return false
      return true
    })
    .map(el => {
      const lotsIds = selectManyAreas(state, {
        lat: el.geolocation.latitude,
        lng: el.geolocation.longitude,
      })
      const manyLots = Boolean(lotsIds.filter(elm => elm !== el.lotId).length)

      return { ...el, lotsIds, manyLots }
    })
}

export const selectLotsIdsWithinMap = (state: RootState) => {
  const [wLng, sLat, eLng, nLat] = stateSelectors(state).bounds
  const lots = selectAll(state).filter(el => {
    if (!el.geolocation) return false
    return true
  })

  const filter = (el: LotState) => {
    const { latitude: lat, longitude: lng } = el.geolocation
    return lng >= wLng && lng <= eLng && lat <= nLat && lat >= sLat
  }
  return lots.filter(filter).map(el => el.lotId)
}

/** @returns [nw.lng,  se.lat,  se.lng, nw.lat] (cluster order) */
export const selectUnfetchedBounds = (state: RootState) => {
  const [wLng, sLat, eLng, nLat] = stateSelectors(state).bounds
  const { boundsToOmit } = stateSelectors(state)
  const shouldSkip = boundsToOmit.some(
    ([wLngO, sLatO, eLngO, nLatO]) =>
      wLngO <= wLng && sLatO <= sLat && eLngO >= eLng && nLatO >= nLat
  )
  return shouldSkip ? [] : [wLng, sLat, eLng, nLat]
}
