import React from 'react'
import ParkCarT2Form from './ParkCarT2Form'
import ParkCarT2Switch from './ParkCarT2Switch'

export interface ParkCarT2Props {
  initFetch: () => void
}

export const ParkCarT2: React.FC<ParkCarT2Props> = props => {
  return (
    <ParkCarT2Form>
      <ParkCarT2Switch initFetch={props.initFetch} />
    </ParkCarT2Form>
  )
}

export default ParkCarT2
