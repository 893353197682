import { Ticket, CarStatus, PaymentOperationType } from 'typedef/ticket'

import { cars } from './myCars'
import { mapLot } from './lots'

import moment from 'moment'

export const tickets: Partial<Ticket>[] = [
  {
    addTimeEnable: true,
    carId: 'id1',
    advertisementUrl: 'https://i.ibb.co/CBRvxDm/Untitled.png',
    carStatus: CarStatus.Parked,
    currentDateTime: moment().toISOString(true),
    parkingExpireDateTime: moment().add(30, 'minute').toISOString(true),
    plate: cars[0].plate,
    validateEnable: true,
    isMonthlyParker: true,
    spot: 'A01',
    lot: mapLot,
    settlement: {
      parkingAmount: 200,
      currency: {
        currencySymbol: '$',
        cultureName: 'dolar',
        currencyFormat: 1,
      },
      finalAmount: 300,
      convenienceFee: 100,
      paymentOperationList: [
        {
          amount: 100,
          dateAtLocal: moment().toString(),
          dateAtUTC: moment().toString(),
          type: PaymentOperationType.Payment,
        },
        {
          amount: 150,
          dateAtUTC: moment().toString(),
          dateAtLocal: moment().toString(),
          type: PaymentOperationType.Coupon,
          name: 'Coupon',
        },
        {
          amount: 100,
          dateAtUTC: moment().toString(),
          dateAtLocal: moment().toString(),
          type: PaymentOperationType.BuyMoreTime,
        },
      ],
    },
  },
  {
    addTimeEnable: true,
    carId: 'id2',
    advertisementUrl:
      'https://tezadvnodeprod.blob.core.windows.net/tezadvnodeprod/banners/15/4b43edfb-4170-5950-34cd-418cf9b5e5f0.jpg',
    carStatus: CarStatus.Parked,
    currentDateTime: moment().subtract(30, 'minute').toISOString(true),
    parkingExpireDateTime: moment().subtract(1, 'minute').toISOString(true),
    plate: cars[1].plate,
    validateEnable: true,
    isMonthlyParker: true,
    spot: 'A02',
    lot: mapLot,
    settlement: {
      parkingAmount: 200,
      currency: {
        currencySymbol: '$',
        cultureName: 'dolar',
        currencyFormat: 1,
      },
      finalAmount: 350,
      convenienceFee: 150,
      paymentOperationList: [
        {
          amount: 100,
          dateAtUTC: moment().toString(),
          dateAtLocal: moment().toString(),
          type: PaymentOperationType.Payment,
        },
        {
          amount: 150,
          dateAtLocal: moment().toString(),
          dateAtUTC: moment().toString(),
          type: PaymentOperationType.Coupon,
        },
        {
          amount: 100,
          dateAtUTC: moment().toString(),
          dateAtLocal: moment().toString(),
          type: PaymentOperationType.BuyMoreTime,
        },
      ],
    },
  },
]
