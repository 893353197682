import { useUpdateLayout } from 'hooks'
import { CancelParkCarDialog } from 'pages/park_car_credit_card/CreditCardComponents'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { paths } from 'routing'
import { AppSnackBarProvider } from 'shared'
import { customerSlice } from 'store/slices'
import Translations from 'utils/translations'
import SelectCardGuestWrapper from './SelectCardGuestWrapper'
import SelectCardWrapper from './SelectCardWrapper'

export const SelectCreditCardPage: React.FC = props => {
  const { ticketId } = useParams<{ ticketId: string }>()
  const [showDialog, setShowDialog] = React.useState(false)
  const { t } = useTranslation()
  const title = t(Translations.PARK_CAR)
  const { customerSignedIn, customerSignedInAsGuest } = useSelector(
    customerSlice.selectCustomerStatus
  )

  const onClickRight = () => {
    setShowDialog(true)
  }

  useUpdateLayout({
    topbar: {
      title,
      hasGoBack: false,
      rightIcon: 'close',
      onClickRight,
    },
    bottomMenu: false,
    layout: {
      hasFlatBackground: true,
      narrow: false,
    },
  })

  if (!ticketId) {
    return <Redirect to={paths.home.map} />
  }

  return (
    <AppSnackBarProvider>
      {customerSignedIn && (
        <SelectCardWrapper
          paymentPath={`${paths.parking.pay}/${ticketId}`}
          manageCardPath={`${paths.parking.manageCards}/${ticketId}`}
          addCardPath={`${paths.parking.addCard}/${ticketId}`}
          ticketId={ticketId}
        />
      )}
      {customerSignedInAsGuest && (
        <SelectCardGuestWrapper
          paymentPath={`${paths.parking.pay}/${ticketId}`}
          manageCardPath={`${paths.parking.manageCards}/${ticketId}`}
          addCardPath={`${paths.parking.addCard}/${ticketId}`}
          ticketId={ticketId}
        />
      )}
      <CancelParkCarDialog
        open={showDialog}
        closeDialog={() => setShowDialog(false)}
      />
    </AppSnackBarProvider>
  )
}

export default SelectCreditCardPage
