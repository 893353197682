import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@mui/material'
import React from 'react'
import { AppTypography, SharedIcon } from 'shared'
import { Currency } from 'typedef'
import { formatCurrency } from 'utils'
import { makeStyles } from '@mui/styles'

export interface SelectPriceItemProps {
  onClick?: () => void
  label?: string
  amount?: number
  currency?: Currency
  selected?: boolean
  divider?: boolean
}

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 0,
    width: 25,
    marginRight: 6,
  },
}))

export const SelectPriceItem: React.FC<SelectPriceItemProps> = props => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <ListItem button onClick={props.onClick} selected={props.selected}>
        <ListItemIcon className={classes.root}>
          <SvgIcon color="disabled">
            <SharedIcon.Label />
          </SvgIcon>
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <AppTypography color="textPrimary">{props.label}</AppTypography>
          }
        />
        <ListItemText
          disableTypography
          secondary={
            <AppTypography color="textPrimary" align="right">
              {formatCurrency(props.currency, props.amount)}
            </AppTypography>
          }
        />
      </ListItem>
      {props.divider && <Divider />}
    </React.Fragment>
  )
}
SelectPriceItem.defaultProps = {
  amount: 0,
  label: '-',
  onClick: () => undefined,
  currency: {
    cultureName: 'en_US',
    currencyFormat: null,
    currencySymbol: null,
  },
}
export default SelectPriceItem
