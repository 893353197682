import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Placeholder, PlaceholderProps, SharedIcon } from 'shared'
import Translations from 'utils/translations'

export const ValidatorsPlaceholder: React.FunctionComponent<PlaceholderProps> = props => {
  const { t } = useTranslation()

  return (
    <Placeholder
      title={t(Translations.NO_VALIDATORS_FOUND)}
      icon={<SharedIcon.Validator />}
      {...props}
    />
  )
}
export default ValidatorsPlaceholder
