import { useFormikContext } from 'formik'
import { useUpdateLayout } from 'hooks'
import { SelectedLotTitle, TermsButton } from 'pages/park_car/ParkCarComponents'
import { useParkMode } from 'pages/park_car/ParkCarHooks'
import {
  License,
  PeriodSwitch,
  SelectCustomTime,
  SelectDefaultTime,
  SelectParkUntil,
  Spot,
  SubmitButton,
} from 'pages/park_car/ParkCarT2/T2Components'
import { useEndDate, useResetForm } from 'pages/park_car/ParkCarT2/T2Hooks'
import React from 'react'
import { useTranslation } from 'react-i18next/'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import {
  AppSpinner,
  DetailsBox,
  FlexBox,
  GridWrapper,
  TextDivider,
  WhiteBoxContainer,
} from 'shared'
import { parkCarSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { Translations } from 'utils'
import { T2Fields } from '../fields'

export interface ParkCarT2SetTimeProps {
  onSubmit: () => void
  loading: boolean
}

export const ParkCarT2SetTime: React.FC<ParkCarT2SetTimeProps> = props => {
  const [endDate, periodMode] = useEndDate()
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const parkMode = useParkMode()
  const resetForm = useResetForm()
  const { values } = useFormikContext<T2Fields>()
  const { spotNumber, parkForDefaultTimeDate, isSpotEnabled } = values
  const { t } = useTranslation()
  useUpdateLayout({
    topbar: {
      title: t(Translations.PARK_CAR),
      onClickBack: () => {
        dispatch(parkCarSlice.actions.resetParkingDetails())
        push(parkMode === 'parkCar' ? paths.home.map : paths.parking.tickets)
      },
    },
  })

  React.useEffect(() => {
    resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <WhiteBoxContainer minHeight={100}>
        <GridWrapper>
          <SelectedLotTitle />
          <FlexBox horizontal horizontalFullWith>
            <License disabled={props.loading || parkMode === 'addMoreTime'} />
            {isSpotEnabled && (
              <Spot
                disabled={props.loading || parkMode === 'addMoreTime'}
                defaultValue={spotNumber}
              />
            )}
          </FlexBox>
        </GridWrapper>
      </WhiteBoxContainer>
      <WhiteBoxContainer minHeight={150}>
        <GridWrapper>
          <PeriodSwitch />
          {periodMode === 'parkUntil' && <SelectParkUntil />}
          {periodMode === 'parkFor' && (
            <GridWrapper>
              <SelectCustomTime disabled={Boolean(parkForDefaultTimeDate)} />
              <TextDivider translation="OR" />
              <SelectDefaultTime />
            </GridWrapper>
          )}
          <DetailsBox.Wrapper>
            <DetailsBox.TimeRow time={endDate} />
          </DetailsBox.Wrapper>
          <FlexBox>
            <SubmitButton
              onClick={props.onSubmit}
              disabled={!endDate || props.loading}
              translation="SUBMIT"
            />
            <TermsButton />
          </FlexBox>
        </GridWrapper>
        <AppSpinner show={props.loading} />
      </WhiteBoxContainer>
    </>
  )
}

export default ParkCarT2SetTime
