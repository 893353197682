import { useFormikContext } from 'formik'
import { useCounter } from 'shared'
import { T2Fields } from '../fields'
import useExpiredPricingPopup from './useExpiredPricingPopup'

export const useT2Counter = () => {
  const { values } = useFormikContext<T2Fields>()
  const showPopup = useExpiredPricingPopup()
  useCounter(values.pricingExpirationDateUtc, showPopup)
}

export default useT2Counter
