// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, { mockedAxios } from '.'
import { Response } from '../typedef/api'
import {
  GetLotDetailsMapData,
  GetLotDetailsMapPayload,
  GetLotDetailsParkData,
  GetLotDetailsParkPayload,
  GetLotsData,
  GetLotsPayload,
  GetSimulatedPricesRequest,
  GetSimulatedPricesResponse,
} from 'typedef'
import {
  LOTS,
  LOTS_DETAILS_MAP,
  LOTS_DETAILS_PARK,
  LOTS_SIMULATED_PRICES,
} from './constants'
import './mocks/LotsApiMock'
import './mocks/TicketsApiMock'

export const getLots = async (params: GetLotsPayload) => {
  try {
    const response = await axios.get<Response<GetLotsData>>(LOTS, {
      params,
    })
    return response.data.data
  } catch (err) {
    throw err
  }
}

export const getLotDetailsMap = async (params: GetLotDetailsMapPayload) => {
  try {
    const response = await axios.get<Response<GetLotDetailsMapData>>(
      LOTS_DETAILS_MAP,
      {
        params,
      }
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}

export const getLotDetailsPark = async (params: GetLotDetailsParkPayload) => {
  try {
    const response = await axios.get<Response<GetLotDetailsParkData>>(
      LOTS_DETAILS_PARK,
      {
        params,
      }
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}

export const getSimulatedPrices = async (params: GetSimulatedPricesRequest) =>
  axios
    .post<Response<GetSimulatedPricesResponse>>(LOTS_SIMULATED_PRICES, params)
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      throw err
    })
