import { useFormikContext } from 'formik'
import { T2Fields } from 'pages/park_car/ParkCarT2'
import { LicenseField } from 'pages/park_car/ParkCarComponents'
import React from 'react'

export interface LicenseProps {
  disabled?: boolean
}

export const License: React.FC<LicenseProps> = props => {
  const {
    handleBlur,
    errors,
    values,
    handleChange,
  } = useFormikContext<T2Fields>()

  return (
    <LicenseField
      onBlur={handleBlur}
      onChange={handleChange}
      disabled={props.disabled}
      value={values.licencePlate}
      error={Boolean(errors['licencePlate'])}
      maxLength={10}
    />
  )
}

export default License
