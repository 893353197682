import React from 'react'
import { useHistory } from 'react-router-dom'

export const useDebugRouter = () => {
  const { location } = useHistory()
  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log(
        `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(
          location.state
        )}`
      )
    }
  }, [location.pathname, location.search, location.state])
}

export default useDebugRouter
