import { ThemeProvider } from '@mui/material/styles'
import { Form, Formik } from 'formik'
import { useChangeLayout } from 'hooks'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import paths from 'routing/paths'
import {
  AppButton,
  AppSnackBarProvider,
  AppSpinner,
  FlexBox,
  Input,
  PaddingContainer,
  PageHeading,
} from 'shared'
import PopupProvider, { usePopup } from 'shared/popup_provider/AppPopupProvider'
import TextArea from 'shared/TextArea'
import { receiptsSlice } from 'store/slices'
import { AppState, useAppDispatch } from 'store/store'
import { whiteTheme } from 'themes'
import Translations from 'utils/translations'
import * as Yup from 'yup'
export interface QuestionProps {
  carId: string
}

export const Question: React.FC<QuestionProps> = ({ carId }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { setPopup, showPopup } = usePopup()
  const { sendQuestionStatus } = useSelector((state: AppState) =>
    receiptsSlice.promiseStatusSelectors(state, carId)
  )
  const receipt = useSelector((state: AppState) =>
    receiptsSlice.selectReceiptById(state, carId)
  )

  const QuestionSchema = Yup.object().shape({
    email: Yup.string()
      .email(t(Translations.INVALID_EMAIL))
      .required(t(Translations.EMAIL_CANNOT_BE_EMPTY)),
    message: Yup.string()
      .required(t(Translations.QUESTION_CANNOT_BE_EMPTY))
      .test('message', t(Translations.QUESTION_CANNOT_BE_EMPTY), (val: any) => {
        if (!val) return false
        if (val.trim().length < 3) return false
        return true
      }),
  })
  const handleSubmit = async (values: { email: string; message: string }) => {
    const promise = await dispatch(
      receiptsSlice.sendQuestion({
        email: values.email,
        message: values.message.trim(),
        carId,
      })
    )
    if (receiptsSlice.sendQuestion.rejected.match(promise)) {
      setPopup({
        ...promise.payload,
        buttons: [
          {
            onClick() {
              handleSubmit(values)
              showPopup(false)
            },
          },
          {},
        ],
      })
      showPopup(true)
    } else {
      setPopup({
        variant: 'success',
        title: t(Translations.SUCCESS),
        description: t(Translations.YOUR_QUESTION_HAS_BEEN_SENT),
        buttons: [
          {
            onClick() {
              history.push(paths.parker.myReceipts)
            },
          },
        ],
      })
      showPopup(true)
    }
  }

  return (
    <Formik
      initialValues={{
        email: receipt?.clientMail || '',
        message: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={QuestionSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values, errors, handleChange, validateForm, submitForm }) => {
        return (
          <Form>
            <Input
              name="email"
              autoFocus={receipt?.clientMail ? false : true}
              autoComplete="email"
              placeholder={t(Translations.YOUR_EMAIL_ADDRESS)}
              value={values.email}
              onChange={handleChange}
              error={!!errors.email}
              data-testid="emailInput"
            />
            <TextArea
              name="message"
              autoFocus={receipt?.clientMail ? true : false}
              placeholder={t(Translations.QUESTION)}
              rows={8}
              value={values.message}
              onChange={handleChange}
              error={!!errors.message}
            />
            <FlexBox hasTopMargin>
              <AppButton
                variant="contained"
                onClick={() =>
                  validateForm().then(errors => {
                    const entries = Object.entries(errors)
                    if (entries.length === 0) {
                      submitForm()
                    } else {
                      enqueueSnackbar(entries[0][1])
                    }
                  })
                }
                disabled={sendQuestionStatus.pending}
                data-testid="sendQuestion"
              >
                {t(Translations.SEND)}
              </AppButton>
              <AppButton
                variant="outlined"
                type="button"
                onClick={() => history.push(paths.parker.myReceipts)}
                data-testid="cancel"
              >
                {t(Translations.CANCEL)}
              </AppButton>
            </FlexBox>
          </Form>
        )
      }}
    </Formik>
  )
}

export const QuestionPage: React.FC = props => {
  const { push } = useHistory()
  const { t } = useTranslation()
  const { carId } = useParams<{ carId: string }>()
  const { sendQuestionStatus } = useSelector((state: AppState) =>
    receiptsSlice.promiseStatusSelectors(state, carId)
  )
  const receipt = useSelector((state: AppState) =>
    receiptsSlice.selectReceiptById(state, carId)
  )
  useChangeLayout({
    layout: { hasTopBar: true },
    topbar: {
      transparent: true,
      onClickBack: () => push(paths.parker.myReceipts),
    },
  })

  if (!carId || !receipt) {
    return <Redirect to={paths.parker.myReceipts} />
  }

  return (
    <PopupProvider>
      <ThemeProvider theme={whiteTheme}>
        <AppSnackBarProvider>
          <PaddingContainer hasTopBar>
            <AppSpinner
              show={sendQuestionStatus.pending}
              darkBackground
              fixed
              color="secondary"
            />
            <PageHeading title={t(Translations.SEND_QUESTION)}></PageHeading>
            <Question carId={carId} />
          </PaddingContainer>
        </AppSnackBarProvider>
      </ThemeProvider>
    </PopupProvider>
  )
}

export default QuestionPage
