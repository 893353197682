import { unwrapResult } from '@reduxjs/toolkit'
import { Formik, FormikValues } from 'formik'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import {
  AppButton,
  AppSpinner,
  FlexBox,
  Input,
  PaddingContainer,
  PageHeading,
} from 'shared'
import { showPopup } from 'store/globalPopup/actions'
import { customerSlice } from 'store/slices'
import { deleteAccount } from 'store/slices/customerSlice'
import { useAppDispatch } from 'store/store'
import { ErrorCode } from 'typedef/api'
import * as Yup from 'yup'
import { Form } from '../../shared/styled/typography/Form'
import Translations from '../../utils/translations'

interface DeleteAccountProps {}

const DeleteAccount = (props: DeleteAccountProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { pending } = useSelector(
    customerSlice.promiseStatusSelectors
  ).deleteAccountStatus

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(t(Translations.FIELD_REQUIRED)),
  })

  const onSubmit = async ({ password }: FormikValues) => {
    const errorPopup = () => {
      dispatch(
        showPopup({
          variant: 'warning',
          title: t(Translations.OOPS),
          description: `${t(
            Translations.YOUR_ACCOUNT_CANNOT_BE_DELETED_AT_THE_MOMENT
          )}. ${t(Translations.PLEASE_TRY_AGAIN_LATER)}.`,
          okButtonClick: () => undefined,
          handleAdditionalButtonClick: () => onSubmit({ password }),
        })
      )
    }

    const actionResult = await dispatch(deleteAccount(password))

    if (deleteAccount.rejected.match(actionResult)) {
      const error = actionResult.payload.code
      if (error === ErrorCode.IncorrectUserOrPassword) {
        enqueueSnackbar(t(Translations.INVALID_PASSWORD))
        return
      }
      if (error === ErrorCode.HasParkedCars) {
        enqueueSnackbar(t(Translations.HAS_PARKED_CARS))
        return
      }
      errorPopup()
    }
    if (deleteAccount.fulfilled.match(actionResult)) {
      const res = unwrapResult(actionResult)

      if (!res) {
        errorPopup()
      } else {
        dispatch(
          showPopup({
            variant: 'success',
            title: `${t(Translations.SUCCESS)}!`,
            description: t(Translations.YOUR_ACCOUNT_HAS_BEEN_DELETED),
            okButtonClick: () => history.push(paths.root.root),
          })
        )
      }
    }
  }

  return (
    <PaddingContainer>
      <PageHeading
        title={t(Translations.DELETE_MY_ACCOUNT)}
        subtitle={`${t(
          Translations.PLEASE_PROVIDE_YOUR_PASSWORD_TO_REMOVE_YOUR_ACCOUNT
        )}.`}
      />
      <AppSpinner show={pending} />
      <Formik
        initialValues={{
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnMount={false}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          validateForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Input
              id="password"
              type="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t(Translations.PASSWORD)}
              autoComplete="off"
              error={!!errors.password}
              autoFocus
              data-testid="deleteAccountPassword"
            />
            <FlexBox hasTopMargin>
              <AppButton
                type="submit"
                variant="contained"
                onClick={() => {
                  validateForm().then(errors => {
                    const entries = Object.entries(errors)
                    if (entries.length !== 0) {
                      entries.forEach(el => {
                        enqueueSnackbar(el[1])
                      })
                    }
                  })
                }}
              >
                {t(Translations.DELETE)}
              </AppButton>
              <AppButton
                type="button"
                variant="outlined"
                onClick={() => {
                  history.push(paths.parker.edit)
                }}
              >
                {t(Translations.CANCEL)}
              </AppButton>
            </FlexBox>
          </Form>
        )}
      </Formik>
    </PaddingContainer>
  )
}

export default DeleteAccount
