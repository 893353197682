import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { paths } from 'routing'
import { AppButton, AppPopup, FlexBox } from 'shared'
import { useCounterContext } from 'shared/CounterProvider/CounterProvider'
import { parkCarSlice, ticketsSlice } from 'store/slices'
import { AppState, useAppDispatch } from 'store/store'
import Translations from 'utils/translations'
import usePaymentCard from './creditCardHooks/usePaymentCard'

export interface CancelParkCarDialogProps {
  closeDialog: () => void
  open: boolean
}

export const CancelParkCarDialog: React.FC<
  CancelParkCarDialogProps
> = props => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const { t } = useTranslation()
  const { unsetPaymentCard } = usePaymentCard()
  const { clearCounter } = useCounterContext()
  const { ticketId } = useParams<{ ticketId: string }>()
  const ticket = useSelector((state: AppState) =>
    ticketsSlice.selectById(state, ticketId)
  )

  const onYesClick = () => {
    let path = paths.parking.tickets
    if (ticket) {
      path = `${paths.parking.parkCar}/${ticket.lot.lotId}`
    }
    push(path)
    unsetPaymentCard()
    clearCounter()
    dispatch(parkCarSlice.actions.resetParkingDetails())
    props.closeDialog()
  }
  return (
    <AppPopup
      open={props.open}
      closePopup={props.closeDialog}
      variant="error"
      fullWidth={false}
      title={t(Translations.CANCEL_PARKING_PROCESS)}
      description={t(
        Translations.ARE_YOU_SURE_YOU_WANT_TO_CANCEL_PARKING_PROCESS
      )}
    >
      <FlexBox>
        <AppButton color="secondary" variant="contained" onClick={onYesClick}>
          {t(Translations.YES)}
        </AppButton>
        <AppButton
          color="secondary"
          variant="outlined"
          onClick={props.closeDialog}
        >
          {t(Translations.NO)}
        </AppButton>
      </FlexBox>
    </AppPopup>
  )
}

export default CancelParkCarDialog
