import { useChangeLayout, usePushToApp } from 'hooks'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useHistory } from 'react-router'
import { ConditionalRoute } from 'routing'
import paths from 'routing/paths'
import { AppButton, FlexBox, PaddingContainer } from 'shared'
import PageDialog from 'shared/PageDialog'
import { showTerms } from 'store/globalPopup/actions'
import { termsSlice } from 'store/slices'
import { actions } from 'store/slices/customerSlice'
import { useAppDispatch } from 'store/store'
import { UserRole } from 'typedef'
import { TermsType } from 'typedef/terms'
import Translations from '../../../utils/translations'
import CodeStep from './CodeStep'
import PersonalDataStep from './PersonalDataStep'
import RegistrationComplete from './RegistrationComplete'
import SetPasswordStep from './SetPasswordStep'

export interface PersonalData {
  firstName: string
  lastName: string
  code: string
  phoneNumber: string
  email: string
}
const SignUp = () => {
  const { t } = useTranslation()
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [completeStep, setCompleteStep] = React.useState(0)
  const [personalData, setPersonalData] = React.useState<PersonalData>({
    firstName: '',
    lastName: '',
    code: '',
    phoneNumber: '',
    email: '',
  })
  const [confirmToken, setConfirmToken] = React.useState('')
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const pushToApp = usePushToApp()
  useChangeLayout()

  React.useEffect(() => {
    if (completeStep === 1) {
      push(paths.auth.setPassword)
    }
    if (completeStep === 2) {
      push(paths.auth.code)
    }
  }, [completeStep, push])

  const submitPassword = (token: string) => {
    setConfirmToken(token)
    setCompleteStep(2)
  }
  const submitCode = () => {
    setShowSuccess(true)
  }

  return (
    <>
      <PaddingContainer>
        <Switch>
          <Route exact path={paths.auth.signUp}>
            <>
              <PersonalDataStep
                personalData={personalData}
                setPersonalData={setPersonalData}
                resetStep={() => setCompleteStep(0)}
                handleSubmit={() => setCompleteStep(1)}
                backToLogin={true}
              />
              <FlexBox hasTopMargin>
                <AppButton
                  letterCase="none"
                  hasHoverBg={false}
                  onClick={() => {
                    dispatch(
                      termsSlice.getTerms({
                        type: TermsType.Login,
                      })
                    )
                    dispatch(showTerms())
                  }}
                >
                  {t(Translations.TERMS_AND_CONDITIONS)}
                </AppButton>
              </FlexBox>
            </>
          </Route>
          <ConditionalRoute
            path={paths.auth.setPassword}
            redirectTo={paths.auth.signUp}
            shouldRedirect={completeStep !== 1}
          >
            <SetPasswordStep
              handleSubmit={submitPassword}
              personalData={personalData}
              goBack={() => push(paths.auth.signUp)}
            />
          </ConditionalRoute>
          <ConditionalRoute
            path={paths.auth.code}
            redirectTo={paths.auth.signUp}
            shouldRedirect={completeStep !== 2}
          >
            <CodeStep confirmToken={confirmToken} onSubmit={submitCode} />
          </ConditionalRoute>
        </Switch>
      </PaddingContainer>
      <PageDialog open={showSuccess}>
        <RegistrationComplete
          onClick={() => {
            pushToApp()
            dispatch(actions.setRole(UserRole.User))
          }}
        />
      </PageDialog>
    </>
  )
}

export default SignUp
