import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  AppButton,
  AppSpinner,
  ErrorPlaceholder,
  FlexBox,
  WhiteBoxContainer,
} from 'shared'
import { ticketsSlice } from 'store/slices'
import { TicketState } from 'store/slices/ticketsSlice.types'
import { useAppDispatch } from 'store/store'
import { Lot } from 'typedef/lot'
import Translations from 'utils/translations'
import SelectLotDialog from './SelectLotDialog'
import TicketsCarousel from './TicketsCarousel'
import TicketsPlaceholder from './TicketsPlaceholder'

export interface TicketsContainerProps {
  showDialog: boolean
  closeDialog: () => void
  onParkCar: () => void
  tickets: TicketState[]
  lots: Lot[]
}

const TicketsContainer: React.FC<TicketsContainerProps> = props => {
  const snackbarKey = React.useRef(null)
  const { t } = useTranslation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()

  const status = useSelector(ticketsSlice.promiseStatusSelectors)
    .getTicketsStatus

  const reFetchData = (close?: boolean) => {
    dispatch(ticketsSlice.getTickets())
    if (close) {
      closeSnackbar(snackbarKey.current)
    }
  }

  const action = (key: any) => (
    <AppButton
      color="primary"
      minWidth="auto"
      size="small"
      onClick={() => {
        closeSnackbar(key)
        reFetchData()
      }}
    >
      {t(Translations.REFRESH)}
    </AppButton>
  )

  React.useEffect(() => {
    if (status.rejected) {
      snackbarKey.current = enqueueSnackbar(
        t(Translations.SOME_DATA_HAS_NOT_BEEN_UPDATED),
        {
          persist: true,
          key: 'fetchError',
          action,
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.rejected])

  React.useEffect(() => {
    if (status.pending && Boolean(props.tickets.length)) {
      enqueueSnackbar(t(Translations.REFRESHING_DATA), {
        key: 'refresh',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.pending, props.tickets.length])

  React.useEffect(() => {
    dispatch(ticketsSlice.getTickets())
  }, [dispatch])

  return (
    <>
      {!!props.tickets.length && <TicketsCarousel tickets={props.tickets} />}
      {!props.tickets.length && (
        <WhiteBoxContainer>
          {status.pendingIdle && <AppSpinner show relative height="150px" />}
          {(status.rejected || status.pendingRejected) && (
            <ErrorPlaceholder
              showSpinner={status.pending}
              onClick={() => reFetchData(true)}
            />
          )}
          {(status.pendingFulfilled || status.fulfilled) && (
            <TicketsPlaceholder>
              <AppSpinner show={status.pending} />
            </TicketsPlaceholder>
          )}
          <FlexBox
            hasTopMargin={
              status.rejected || status.pendingRejected ? false : true
            }
          >
            <AppButton
              variant="contained"
              color="primary"
              onClick={props.onParkCar}
            >
              {t(Translations.PARK_CAR)}
            </AppButton>
          </FlexBox>
        </WhiteBoxContainer>
      )}
      <SelectLotDialog
        lots={props.lots}
        open={props.showDialog}
        closeDialog={props.closeDialog}
      />
    </>
  )
}

export default TicketsContainer
