import {
  AcceptToSParams,
  GetTermsAndConditionsParams,
  GetTermsAndConditionsResult,
  GetTermsOfServiceContentParams,
  ShowForCustomerParams,
  ShowForCustomerResult,
} from 'typedef/terms'
import axios from '.'
import { Response } from '../typedef/api'
import {
  ACCEPT_TOS,
  SHOW_FOR_CUSTOMER,
  TERMS_AND_CONDITIONS,
} from './constants'
import './mocks/TermsAndConditionsApiMock'

export const getTerms = async (params: GetTermsAndConditionsParams) => {
  try {
    const response = await axios.get<Response<GetTermsAndConditionsResult>>(
      TERMS_AND_CONDITIONS,
      { params }
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}

export const showForCustomer = async (params: ShowForCustomerParams) => {
  try {
    const response = await axios.get<Response<ShowForCustomerResult>>(
      SHOW_FOR_CUSTOMER,
      { params }
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}

export const accept = async (params: AcceptToSParams) => {
  try {
    const response = await axios.post<Response<boolean>>(ACCEPT_TOS, params)
    return response.data.data
  } catch (err) {
    throw err
  }
}

export const getTermsOfServiceContent = async (
  params: GetTermsOfServiceContentParams
) => {
  try {
    const response = await axios.get<Response<string>>(
      `${TERMS_AND_CONDITIONS}/${params.id}?language=${params.language}`
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}
