import React from 'react'
import styled from 'styled-components'
import AppButton, { AppButtonProps } from './AppButton'
import FlexBox from './FlexBox'

export const ButtonsWrapper = styled(FlexBox)`
  border-radius: 28px;
  background: rgba(50, 163, 245, 0.2);
  .MuiButton-outlined {
    background: white;
    border-color: #32a3f5;
  }
`
export type AppTabButtonsOptions = Partial<AppButtonProps> &
  Required<Pick<AppButtonProps, 'name'>>

export interface AppTabButtonsProps {
  selected: string
  onClick: (value: string) => void
  options: AppTabButtonsOptions[]
}

export const AppTabButtons: React.FC<AppTabButtonsProps> = ({
  selected,
  onClick,
  options,
}) => {
  if (!options || !options.length) return null
  return (
    <ButtonsWrapper horizontal horizontalGap={0} horizontalFullWith>
      {options.map(el => (
        <AppButton
          key={el.name}
          letterCase="none"
          variant={el.name === selected ? 'outlined' : 'text'}
          onClick={() => setTimeout(() => onClick(el.name), 200)}
          minWidth="100%"
          translation={el.translation}
          {...el}
        />
      ))}
    </ButtonsWrapper>
  )
}

export default AppTabButtons
