import { useChangeLayout } from 'hooks'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import paths from 'routing/paths'
import { AppSpinner, ErrorPlaceholder, WhiteBoxContainer } from 'shared'
import { ticketsSlice, validatorsSlice } from 'store/slices/'
import { AppState, useAppDispatch } from 'store/store'
import { GetValidatorsParams } from 'typedef/validator'
import Translations from 'utils/translations'
import { Validators } from '.'
import { ValidatorsViewProps } from './types'
import ValidatorsPlaceholder from './ValidatorsPlaceholder'

const ValidatorsView: React.FC<ValidatorsViewProps> = props => {
  const { t } = useTranslation()
  const { push } = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const partners = useSelector(validatorsSlice.selectAllValidators)
  const status = useSelector(
    validatorsSlice.promiseStatusSelectors
  ).getValidatorsStatus

  const { carId } = props.match.params
  const ticket = useSelector((state: AppState) =>
    ticketsSlice.selectById(state, carId)
  )
  const title = t(Translations.VALIDATE)
  useChangeLayout(
    {
      topbar: {
        title,
        onClickBack: () => push(paths.parking.tickets),
      },
    },
    'app'
  )

  const fetch = React.useCallback(async () => {
    if (!carId || !ticket?.lot.lotId) return
    const params: GetValidatorsParams = {
      carId,
      lotId: ticket?.lot.lotId,
    }
    const actionResult = await dispatch(validatorsSlice.getValidators(params))
    if (validatorsSlice.getValidators.rejected.match(actionResult)) {
      if (actionResult.meta.condition) return
      enqueueSnackbar(t(Translations.FETCH_RECEIPTS_ERROR))
    }
  }, [carId, dispatch, enqueueSnackbar, t, ticket?.lot.lotId])

  React.useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!ticket) {
    push(paths.parker.myProfile)
    return null
  }

  return (
    <WhiteBoxContainer minHeight={partners?.length ? 50 : 150}>
      {status.pending ? (
        <AppSpinner show />
      ) : (
        <>
          {status.rejected && <ErrorPlaceholder onClick={fetch} />}
          {status.fulfilled && (
            <>
              {!!partners.length && (
                <Validators carId={carId} partners={partners} />
              )}
              {!partners.length && <ValidatorsPlaceholder />}
            </>
          )}
        </>
      )}
    </WhiteBoxContainer>
  )
}

export default ValidatorsView
