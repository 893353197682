import React from 'react'
import { DetailsBox } from 'shared'

export interface MonthlyParkingExpiresSectionProps {
  expiresAt: string
}

export const MonthlyParkingExpiresSection: React.FC<MonthlyParkingExpiresSectionProps> =
  props => {
    return (
      <DetailsBox.Wrapper>
        <DetailsBox.TimeRow time={props.expiresAt} />
      </DetailsBox.Wrapper>
    )
  }

export default MonthlyParkingExpiresSection
