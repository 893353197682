import { IconButton, Typography, Toolbar } from '@mui/material'
import { Close, Add, ArrowBack } from '@mui/icons-material'
import bg from 'assets/images/bg.png'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { INNER_WIDTH } from 'shared/layout/constants'
import { layoutSlice } from 'store/slices'
import styled, { css } from 'styled-components'

export interface TopbarProps {
  title?: string
  hasGoBack?: boolean
  onClickBack?: () => void
  onClickRight?: () => void
  rightIcon?: 'close' | 'add' | (() => JSX.Element)
  transparent?: boolean
}

const StyledTopbar = styled.div<{
  transparent?: boolean
  backgroundImage?: string
}>`
  position: fixed;
  box-shadow: 4px 8px 4px rgba(0, 0, 0, 0.1);
  ${_ =>
    _.transparent
      ? css`
          background: transparent;
          box-shadow: none;
        `
      : css`
          background-image: url(${_.backgroundImage});
          background-repeat: no-repeat;
          background-size: cover;
        `}

  top: 0;
  padding: 0 ${_ => _.theme.spacing(1)};
  width: 100%;
  box-sizing: border-box;
  z-index: 5;
  left: 0;
`
StyledTopbar.defaultProps = {
  backgroundImage: bg,
}
const Wrapper = styled.div<{ transparent?: boolean }>`
  width: 100%;
  max-width: ${_ => INNER_WIDTH}px;
  margin: auto;
`
const Title = styled(Typography)`
  color: white;
  display: block;
  margin: 0 auto;
`
const RightContainer = styled.div`
  position: absolute;
  left: 100%;
  transform: translateX(-100%);
`
const LeftButtonContainer = styled.div`
  position: absolute;
  left: auto;
`

export const Topbar: React.FC<TopbarProps> = props => {
  const history = useHistory()
  const { headerBgUrl } = useSelector(layoutSlice.stateSelectors).customStyles
  const onClickBack = () => {
    if (props.onClickBack) {
      props.onClickBack()
    } else {
      history.goBack()
    }
  }
  return (
    <StyledTopbar transparent={props.transparent} backgroundImage={headerBgUrl}>
      <Wrapper>
        <Toolbar disableGutters variant="dense">
          {props.hasGoBack && (
            <LeftButtonContainer>
              <IconButton onClick={onClickBack} data-testid="goBackButton">
                <ArrowBack htmlColor="#fff" />
              </IconButton>
            </LeftButtonContainer>
          )}
          <Title>{props.title}</Title>
          <RightContainer>
            {props.rightIcon && (
              <IconButton onClick={props.onClickRight}>
                {props.rightIcon === 'close' && <Close htmlColor="#fff" />}
                {props.rightIcon === 'add' && <Add htmlColor="#fff" />}
                {props.rightIcon &&
                  typeof props.rightIcon !== 'string' &&
                  props.rightIcon()}
              </IconButton>
            )}
          </RightContainer>
        </Toolbar>
      </Wrapper>
    </StyledTopbar>
  )
}
Topbar.defaultProps = {
  title: '',
  hasGoBack: true,
  onClickRight: () => undefined,
}

export default Topbar
