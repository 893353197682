import MyReceipts from 'pages/my_receipts/MyReceipts'
import MyReceiptsPlaceholder from 'pages/my_receipts/MyReceiptsPlaceholder'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import {
  AppButton,
  AppSpinner,
  AppTypography,
  ErrorPlaceholder,
  FlexBox,
  WhiteBoxContainer,
} from 'shared'
import { receiptsSlice } from 'store/slices'
import Translations from 'utils/translations'

interface LastReceiptProps {
  onClickRefresh: () => void
}
const LastReceipt: React.FC<LastReceiptProps> = props => {
  const { getReceiptsStatus: status } = useSelector(
    receiptsSlice.promiseStatusSelectors
  )
  const receipts = useSelector(receiptsSlice.selectAllReceipts)
  const { t } = useTranslation()
  const history = useHistory()

  const shouldRenderError = () =>
    !receipts.length && (status.fulfilled || status.pendingFulfilled)
  return (
    <WhiteBoxContainer>
      <AppTypography weight="bold" variant="h2">
        {t(Translations.LAST_RECEIPT)}
      </AppTypography>
      {status.pendingIdle && <AppSpinner show relative height="100px" />}
      {!receipts.length && (status.rejected || status.pendingRejected) && (
        <ErrorPlaceholder
          showSpinner={status.pending}
          onClick={props.onClickRefresh}
        />
      )}
      {!!receipts.length && <MyReceipts receipts={[receipts[0]]} />}
      {shouldRenderError() && (
        <MyReceiptsPlaceholder>
          {status.pending && <AppSpinner show />}
        </MyReceiptsPlaceholder>
      )}
      {receipts.length > 1 && (
        <FlexBox>
          <AppButton
            variant="outlined"
            color="primary"
            topGutter={!shouldRenderError() ? 0 : 15}
            onClickCapture={() => history.push(paths.parker.myReceipts)}
            data-testid="seeMoreButton"
          >
            {t(Translations.SEE_MORE)}
          </AppButton>
        </FlexBox>
      )}
    </WhiteBoxContainer>
  )
}

export default LastReceipt
