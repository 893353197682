import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  AppButton,
  AppSpinner,
  AppTypography,
  FlexBox,
  PhoneNumberContainer,
  Placeholder,
  SharedIcon,
} from 'shared'
import * as Yup from 'yup'
import { Input } from 'shared/Input'
import { customerSlice, contactInformationsSlice } from 'store/slices'
import {
  countryCodeRegex,
  phoneNumberRegex,
  Translations,
  blurLastInput,
} from 'utils'
import CodeStepPopup from '../CodeStepPopup'
import {
  ReceiptsAndNotificationsFormValues,
  ContactInformationsProps,
  RedirectType,
} from './types'
import { useResendCode } from './useResendCode'
import { useSendVerificationSMS } from './useSendVerificationSMS'
import { useSubmitForm } from './useSubmitForm'
import { useGetTicket, useParkCar } from 'pages/park_car/ParkCarHooks'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import { LoginInfo, LoginInfoSeparator, LoginInfoContainer } from './styles'
import NewPhoneNumberInput from 'shared/PhoneNumberAndCountryCodeInput'
import HybridRecaptcha from '../../../shared/ReCaptcha/HybridRecaptcha';
import {validateFeature } from '../../../shared/ReCaptcha/HybridRecaptcha';


export const ContactInformations = ({ carId }: ContactInformationsProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, , paymentRequired] = useParkCar()
  const [showCodeStepPopup, setShowCodeStepPopup] = useState(false)
  const [pin, setPin] = useState('')
  const [confirmToken, setConfirmToken] = useState('')
  const { customerSignedInAsGuest } = useSelector(
    customerSlice.selectCustomerStatus
  )
  const { contactDataRequired, paymentRedirectionAvailable } = useSelector(
    contactInformationsSlice.stateSelectors
  )
  const [, , ticket] = useGetTicket()
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  
  const handleResendCode = useResendCode(confirmToken, setConfirmToken)
  const handleSubmitWithPhoneNumber = useSendVerificationSMS(
    setShowCodeStepPopup,
    setConfirmToken,
    carId, 
    recaptchaToken
  )

  const handleTokenReceived = (token: string) => {
    setRecaptchaToken(token);
  };

  const handleRecaptchaSuccess = () => {
    setRecaptchaVerified(true);
  };

  const handleRecaptchaFailure = () => {
    enqueueSnackbar(t(Translations.RECAPTCHA_VERIFICATION_FAILED), { variant: 'error' });
    setRecaptchaVerified(false);
  };


  const handleSubmit = useSubmitForm(
    RedirectType.ContactInformations,
    carId,
    confirmToken,
    pin,
    paymentRedirectionAvailable, 
    recaptchaToken
  )

  const handleOnConfirm = (
    values: ReceiptsAndNotificationsFormValues,
    handleOnSubmit: () => void
  ) => {
    if (!recaptchaVerified) {
      enqueueSnackbar(t(Translations.PLEASE_COMPLETE_RECAPTCHA_VERIFICATION), { variant: 'warning' });
      return;
    }
    if (values.phoneNumber) {
      handleSubmitWithPhoneNumber(values)
    } else {
      handleOnSubmit()
    }
  }

  const contactInformationsSchema = Yup.object().shape({
    email:
      !ticket?.externalServiceDeliversReceipts &&
      Yup.string()
        .email(t(Translations.INVALID_EMAIL))
        .required(t(Translations.EMAIL_CANNOT_BE_EMPTY)),
    code:
      customerSignedInAsGuest &&
      Yup.string().matches(
        countryCodeRegex,
        t(Translations.INVALID_COUNTRY_CODE)
      ),
    phoneNumber:
      customerSignedInAsGuest &&
      Yup.string()
        .matches(phoneNumberRegex, t(Translations.INVALID_PHONE_NUMBER))
        .required(t(Translations.PHONE_CANNOT_BE_EMPTY)),
  })

  const title = ticket?.externalServiceDeliversReceipts
    ? t(Translations.NOTIFICATIONS)
    : t(Translations.RECEIPTS_AND_NOTIFICATIONS)
  const icon = <SharedIcon.Receipt />
  const initialValues = { email: '', phoneNumber: '', code: '' }
  const subtitle = ticket?.externalServiceDeliversReceipts
    ? t(Translations.NOTIFICATIONS_SUBTITLE)
    : customerSignedInAsGuest
    ? t(Translations.PLEASE_ENTER_YOUR_MOBILE_PHONE)
    : t(Translations.PLEASE_ENTER_YOUR_EMAIL)

  return (
    <>
      {isLoading && <AppSpinner show height="100px" />}
      <Placeholder title={ticket ? title : ''} icon={icon}></Placeholder>
      {ticket ? (
        <>
          <AppTypography style={{ marginBottom: '10px' }}>
            {subtitle}
          </AppTypography>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={contactInformationsSchema}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              validateForm,
              submitForm,
            }) => (
              <Form>
                {customerSignedInAsGuest && (
                  <PhoneNumberContainer>
                    <NewPhoneNumberInput
                      value={`${values.code}${values.phoneNumber}`}
                      countryCodeFieldName="code"
                      phoneNumberFieldName="phoneNumber"
                      errors={errors.phoneNumber}
                    />
                  </PhoneNumberContainer>
                )}
                {!ticket?.externalServiceDeliversReceipts && (
                  <Input
                    placeholder={t(Translations.EMAIL).toUpperCase()}
                    name="email"
                    inputMode="email"
                    type="email"
                    data-testid="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.email}
                    onKeyDown={e => {
                      blurLastInput(e)
                    }}
                  ></Input>
                )}
                <FlexBox hasTopMargin>
                  <HybridRecaptcha
                    onValidationSuccess={handleRecaptchaSuccess}
                    onValidationFailure={handleRecaptchaFailure}
                    onTokenReceived={handleTokenReceived}
                    feature={validateFeature.AddNumberForNotification}
                  />
                  <AppButton
                    variant="contained"
                    color="primary"
                    type="button"
                    data-testid="nextButton"
                    disabled={!values.email && !values.phoneNumber}
                    onClick={() => {
                      validateForm().then(errors => {
                        const entries = Object.entries(errors)
                        if (entries.length === 0) {
                          handleOnConfirm(values, submitForm)
                        } else {
                          enqueueSnackbar(entries[0][1])
                        }
                      })
                    }}
                  >
                    {t(Translations.NEXT)}
                  </AppButton>
                  {!contactDataRequired && (
                    <AppButton
                      variant="outlined"
                      color="primary"
                      type="button"
                      onClick={() =>{
                          if (!recaptchaVerified) {
                            enqueueSnackbar(t(Translations.PLEASE_COMPLETE_RECAPTCHA_VERIFICATION), { variant: 'warning' });
                            return;
                          }
                          paymentRequired({
                            carId,
                            paymentRedirectionAvailable,
                          }, recaptchaToken)
                        }
                      }
                      data-testid="skipButton"
                    >
                      {t(Translations.SKIP)}
                    </AppButton>
                  )}
                  {customerSignedInAsGuest && (
                    <>
                      <LoginInfo>
                        {t(Translations.ALREADY_HAVE_AN_ACCOUNT)}
                      </LoginInfo>
                      <LoginInfoContainer>
                        <AppButton
                          style={{ paddingTop: 0, paddingRight: 5 }}
                          minWidth="0"
                          type="button"
                          letterCase="none"
                          hasHoverBg={false}
                          color="primary"
                          onClick={() =>
                            history.push(`${paths.parking.login}/${carId}`)
                          }
                          data-testid="loginButton"
                        >
                          {t(Translations.LOGIN)}
                        </AppButton>
                        <LoginInfoSeparator>
                          {t(Translations.OR)}
                        </LoginInfoSeparator>
                        <AppButton
                          style={{ paddingTop: 0, paddingLeft: 5 }}
                          minWidth="0"
                          type="button"
                          letterCase="none"
                          hasHoverBg={false}
                          color="primary"
                          onClick={() =>
                            history.push(`${paths.parking.signUp}/${carId}`)
                          }
                          data-testid="registerButton"
                        >
                          {t(Translations.SIGN_UP)}
                        </AppButton>
                      </LoginInfoContainer>
                    </>
                  )}
                </FlexBox>
                <CodeStepPopup
                  closePopup={() => setShowCodeStepPopup(false)}
                  showCodeStepPopup={showCodeStepPopup}
                  submitForm={submitForm}
                  pin={pin}
                  setPin={setPin}
                  handleResendCode={handleResendCode}
                />
              </Form>
            )}
          </Formik>
        </>
      ) : (
        ''
      )}
    </>
  )
}
