import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 69 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.78 58.995l-7.357 7.357-11.88 11.88a7.107 7.107 0 01-10.042 0L10.137 58.868l-.106-.105 7.357.042c2.072.55 4.249.824 6.49.824 2.156 0 4.249-.274 6.257-.782l13.868.064 14.776.084z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.906 10.1c-13.466-13.467-35.303-13.467-48.79 0a35.465 35.465 0 00-5.412 6.997c4.693-5.285 11.521-8.604 19.131-8.604 14.122 0 25.58 11.458 25.58 25.579v.36c0 .38-.022.782-.043 1.162v.021c-.444 7.653-4.249 14.396-9.978 18.772a25.554 25.554 0 01-9.301 4.503 25.501 25.501 0 01-6.258.782c-2.24 0-4.418-.296-6.49-.824-5.665-1.48-10.538-4.942-13.962-9.487 1.628 3.383 3.815 6.654 6.606 9.466l.105.105C20.96 69.798 37.26 71.891 50.24 65.253a34.675 34.675 0 008.52-6.194l.105-.105c13.508-13.551 13.508-35.389.042-48.855z"
        fill="url(#prefix__paint1_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.384 19.38c8.244 0 14.924 6.638 15.03 14.84v-.17c0-14.12-11.458-25.578-25.58-25.578-7.61 0-14.459 3.319-19.13 8.604-5.264 8.984-6.11 19.85-2.537 29.447.359.973.76 1.924 1.205 2.854 3.424 4.545 8.297 7.912 13.963 9.392 2.22.634 4.312.845 6.553.845 2.156 0 4.228-.232 6.236-.74a26.115 26.115 0 009.407-4.545c5.708-4.376 9.376-11.146 9.84-18.798-.591 7.716-7.018 13.804-14.882 13.867h-.105c-8.308 0-15.03-6.722-15.03-15.03v-.106c.063-8.223 6.764-14.882 15.03-14.882z"
        fill="url(#prefix__paint2_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.26 65.19a1.659 1.659 0 010 2.325L39.542 78.233a7.107 7.107 0 01-10.042 0L10.137 58.87l-.106-.106a34.5 34.5 0 01-6.617-9.365 25.605 25.605 0 0013.973 9.386c2.072.55 4.25.825 6.49.825 2.157 0 4.25-.275 6.258-.782a25.296 25.296 0 005.052-1.86c2.748-1.353 6.046-.846 8.202 1.331l.613.613 6.258 6.279z"
        fill="url(#prefix__paint3_linear)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={10.004}
          y1={69.543}
          x2={58.786}
          y2={69.543}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7A77" />
          <stop offset={1} stopColor="#FF7A77" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={3.395}
          y1={34.489}
          x2={69.005}
          y2={34.489}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7A77" />
          <stop offset={1} stopColor="#FF7A77" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={0.016}
          y1={34.054}
          x2={49.429}
          y2={34.054}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5B61" />
          <stop offset={1} stopColor="#C7314A" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear"
          x1={3.395}
          y1={64.864}
          x2={50.739}
          y2={64.864}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C7314A" />
          <stop offset={1} stopColor="#FF5B61" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgComponent
