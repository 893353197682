import { useFormikContext } from 'formik'
import React from 'react'
import { AppSpinner, FlexBox, GridWrapper, WhiteBoxContainer } from 'shared'
import { T2Fields } from '../fields'
import { SelectedLotTitle, TermsButton } from 'pages/park_car/ParkCarComponents'
import {
  useParkCarPath,
  useParkCar,
  useParkMode,
} from 'pages/park_car/ParkCarHooks'
import {
  RemaingTime,
  SubmitButton,
  SummaryBox,
  TotalAmount,
} from 'pages/park_car/ParkCarT2/T2Components'
import useBuyMoreTime from 'pages/park_car/ParkCarHooks/useBuyMoreTime'
import { useUpdateLayout } from 'hooks'
import { Translations } from 'utils'
import { useTranslation } from 'react-i18next/'

export interface ParkCarT2SummaryProps {}

export const ParkCarT2Summary: React.FC<ParkCarT2SummaryProps> = props => {
  const [isLoadingParkCar, parkCar] = useParkCar()
  const [isLoadingBMT, buyMoreTime] = useBuyMoreTime()
  const parkMode = useParkMode()
  const { values } = useFormikContext<T2Fields>()
  const [, goTo] = useParkCarPath()
  const { spotNumber, licencePlate, selectedPricing } = values
  const { t } = useTranslation()
  useUpdateLayout({
    topbar: {
      title: t(Translations.PARK_CAR),
    },
  })

  const onClick = () => {
    if (parkMode === 'parkCar') {
      parkCar({
        plate: licencePlate,
        spot: spotNumber,
      })
    }
    if (parkMode === 'addMoreTime') {
      buyMoreTime()
    }
  }

  if (!selectedPricing) {
    goTo('select')
    return null
  }

  return (
    <WhiteBoxContainer>
      <AppSpinner show={isLoadingParkCar || isLoadingBMT} />
      <GridWrapper>
        <SelectedLotTitle />
        <RemaingTime />
        <SummaryBox />
        <FlexBox>
          <TotalAmount />
          <SubmitButton
            onClick={onClick}
            disabled={isLoadingParkCar || isLoadingBMT}
          />
          <TermsButton />
        </FlexBox>
      </GridWrapper>
    </WhiteBoxContainer>
  )
}

export default ParkCarT2Summary
