import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { batch, useSelector } from 'react-redux'
import { AppDialog, AppTypography, SharedIcon } from 'shared'
import { RootState } from 'store/rootReducer'
import { mapSlice, simulatePriceSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { Translations } from 'utils'

const useStyles = makeStyles(theme => ({
  rootPrimaryText: {
    paddingRight: 12,
  },
  rootItem: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  rootIcon: {
    minWidth: 36,
  },
}))

export interface ListElementProps {
  divider: boolean
  id: string
  onLotClick: (id: string) => void
  hasPrice?: boolean
}
export const ListElement: React.FC<ListElementProps> = props => {
  const classes = useStyles()
  const { palette } = useTheme()
  const lot = useSelector((state: RootState) =>
    mapSlice.selectById(state, props.id)
  )
  const simulatedPrice = useSelector((state: RootState) =>
    simulatePriceSlice.selectPriceString(state, props.id)
  )
  return (
    <>
      <ListItem
        button
        onClick={() => props.onLotClick(props.id)}
        classes={{ root: classes.rootItem }}
      >
        <ListItemIcon classes={{ root: classes.rootIcon }}>
          <SharedIcon.Outlined.Room width={32} height={32} />
        </ListItemIcon>
        <ListItemText
          primary={
            <AppTypography size={0.9} weight={600}>
              {lot.lotName}
            </AppTypography>
          }
          secondary={`(${lot.pCode})`}
          classes={{ primary: classes.rootPrimaryText }}
        ></ListItemText>
        {props.hasPrice && (
          <AppTypography
            customColor={palette.pin.main}
            weight="bold"
            onClick={() => props.onLotClick(props.id)}
          >
            {simulatedPrice || '-'}
          </AppTypography>
        )}
      </ListItem>
      {props.divider && <Divider />}{' '}
    </>
  )
}

export interface MapSelectLotDialogProps {
  open: boolean
  closeDialog: () => void
}

export const MapSelectLotDialog: React.FC<MapSelectLotDialogProps> = props => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { lotsToSelect } = useSelector(mapSlice.stateSelectors)
  const { pricingMode } = useSelector(simulatePriceSlice.stateSelectors)

  const onLotClick = (id: string) => {
    batch(() => {
      dispatch(mapSlice.actions.selectLot({ lotId: id }))
      dispatch(mapSlice.getLotDetailsMap({ lotId: id }))
    })
    props.closeDialog()
  }

  return (
    <AppDialog
      fullWidth={false}
      open={props.open}
      onClose={() => {
        props.closeDialog()
        dispatch(mapSlice.actions.unsetLotsToSelect())
      }}
    >
      <AppTypography
        weight="bold"
        align="center"
        color="textPrimary"
        gutterBottom
      >
        {t(Translations.SELECT_LOT)}
      </AppTypography>
      <List>
        {lotsToSelect.map((el, i, arr) => (
          <React.Fragment key={el}>
            <ListElement
              id={el}
              onLotClick={onLotClick}
              hasPrice={pricingMode}
              divider={arr.length - 1 > i}
            />
          </React.Fragment>
        ))}
      </List>
    </AppDialog>
  )
}

export default MapSelectLotDialog
