import { Grow } from '@mui/material'
import CreditCardsList from 'pages/my_credit_cards/CreditCardsComponents/CreditCardsList'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  AppSpinner,
  ErrorPlaceholder,
  Placeholder,
  SharedIcon,
  WhiteBoxContainer,
} from 'shared'
import { creditCardsSlice } from 'store/slices'
import Translations from 'utils/translations'

export interface MyCreditCardsProps {
  onClickRefresh: () => void
}

export const MyCreditCards: React.FC<MyCreditCardsProps> = props => {
  const { t } = useTranslation()
  const status = useSelector(
    creditCardsSlice.promiseStatusSelectors
  ).getCreditCardsStatus
  const cards = useSelector(creditCardsSlice.selectAll)

  const shouldRenderError = () =>
    !cards.length && (status.rejected || status.pendingRejected)
  return (
    <WhiteBoxContainer minHeight={!!cards.length ? 60 : undefined}>
      <AppSpinner show={status.pendingIdle} />
      {shouldRenderError() && (
        <ErrorPlaceholder
          showSpinner={status.pending}
          onClick={props.onClickRefresh}
        />
      )}
      <Grow in={!!cards.length} appear={false}>
        <div>
          <CreditCardsList creditCards={cards} />
        </div>
      </Grow>

      {!cards.length && (status.fulfilled || status.pendingFulfilled) && (
        <Placeholder
          title={t(Translations.NO_CREDIT_CARDS_FOUND)}
          icon={<SharedIcon.NoCreditCard />}
        />
      )}
    </WhiteBoxContainer>
  )
}

export default MyCreditCards
