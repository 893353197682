import { UserLanguage } from 'typedef'

export const getLanguageEnum = () => {
  const language = navigator.language.toLocaleLowerCase()
  switch (true) {
    case language.includes('gb'):
      return UserLanguage.En_GB
    case language.includes('de'):
      return UserLanguage.De_de
    case language.includes('it'):
      return UserLanguage.It_it
    default:
      return UserLanguage.En_US
  }
}
