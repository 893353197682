import { makeStyles } from '@mui/styles'
import { Dialog, DialogProps } from '@mui/material'
import React from 'react'

export interface AppDialogProps extends DialogProps {
  darkBackground?: boolean
}
const useStyles = makeStyles(theme => ({
  paper: (props: AppDialogProps) => ({
    padding: theme.spacing(3),
    borderRadius: '10px',
    textAlign: 'center',
    boxSizing: 'border-box',
    minWidth: 300,
    scrollbarWidth: 'thin',
    scrollbarColor: '#b3b3b3 white',
    '@media only screen and (max-device-width: 767px) and (prefers-color-scheme: dark) and (-webkit-min-device-pixel-ratio:0)':
      {
        backgroundColor: props.darkBackground ? '#585858' : 'white',
        color: props.darkBackground ? 'white' : 'black',
      },
  }),
}))

export const AppDialog: React.FC<AppDialogProps> = props => {
  const classes = useStyles(props)
  return (
    <Dialog
      {...props}
      classes={{ paper: classes.paper }}
      data-testid="appDialog"
    >
      {props.children}
    </Dialog>
  )
}

export default AppDialog
