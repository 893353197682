import { Response } from 'typedef/api'
import {
  AddContactInformationsData,
  AddContactInformationsPayload,
  GetContactInformationsData,
  GetReceiptsData,
  SendQuestionPayload,
  SendReceiptsData,
  SendReceiptsPayload,
  SendVerificationSMSData,
  SendVerificationSMSPayload,
} from 'typedef/receipt'
import axios from '.'
import {
  CUSTOMER_ADD_GUEST_NOTIFICATION,
  CUSTOMER_GET_GUEST_NOTIFICATION,
  RECEIPT,
  RECEIPT_QUESTION,
  SMS_RESEND_VERIFICATION_SMS,
  SMS_SEND_VERIFICATION_SMS,
} from './constants'
import './mocks/ReceipstApiMock'

export const getReceipts = async () => {
  try {
    const { data } = await axios.get<Response<GetReceiptsData>>(RECEIPT)
    return data.data
  } catch (err) {
    throw err
  }
}

export const sendReceipt = async (params: SendReceiptsPayload) => {
  try {
    const { data } = await axios.post<Response<SendReceiptsData>>(
      RECEIPT,
      params
    )
    return data.data
  } catch (err) {
    throw err
  }
}

export const sendQuestion = async (params: SendQuestionPayload) => {
  try {
    const { data } = await axios.post<Response<null>>(RECEIPT_QUESTION, params)
    return data.data
  } catch (err) {
    throw err
  }
}

export const addContactInformations = async (
  params: AddContactInformationsPayload
) => {
  try {
    const response = await axios.put<Response<AddContactInformationsData>>(
      CUSTOMER_ADD_GUEST_NOTIFICATION,
      params
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}

export const sendVerificationSMS = async (
  params: SendVerificationSMSPayload
) => {
  try {
    const response = await axios.post<Response<SendVerificationSMSData>>(
      SMS_SEND_VERIFICATION_SMS,
      params
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}

export const resendVerificationSMS = async (
  params: SendVerificationSMSData
) => {
  try {
    const response = await axios.post<Response<SendVerificationSMSData>>(
      SMS_RESEND_VERIFICATION_SMS,
      params
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}

export const getContactInformations = async () => {
  try {
    const response = await axios.get<Response<GetContactInformationsData>>(
      CUSTOMER_GET_GUEST_NOTIFICATION
    )
    return response.data.data
  } catch (err) {
    throw err
  }
}
