import { CreditCard } from './creditCards'
import { Ticket } from './ticket'

export enum LoginMode {
  User = 1,
  Guest = 2,
}

export enum UserRole {
  User = 1,
  Guest = 2,
}

export interface Tokens {
  auth: string
  expires: number
  refresh: string
}

export interface CustomerTokens {
  tokens: Tokens
  role: UserRole
}

export interface CustomerDetails {
  firstName?: string
  lastName?: string
  phoneNumberCountryCode?: number
  phoneNumber?: string
  email?: string
}

export interface RegisterParams {
  firstName: string
  lastName: string
  phoneCountryCode: number
  phoneNumber: string
  email: string
  password: string
  creditCard?: CreditCard
  recaptchaToken: string
}

export interface RegisterResult {
  confirmToken: string
}

export interface ConfirmRegisterParams {
  confirmToken: string
  pin: string
}
export interface ConfirmRegisterData {
  tokens: Tokens
  role: UserRole
}

export interface PhoneNumberAvailableParams {
  phoneCountryCode: number
  phoneNumber: string
}

export interface ResendRegistrationPinParams {
  confirmToken: string
  recaptchaToken: string
}

export interface EditCustomerParams {
  firstName: string
  lastName: string
  email: string
}

export interface PhoneNumberAvailableResult {
  is: boolean
}

export interface LoginRequest {
  phoneNumber?: number
  password?: string
  countryCode?: number
  mode: UserRole
}

export interface LoginResult {
  tokens: Tokens
  role: UserRole
}
export interface RemindPasswordParams {
  phoneCountryCode: number
  phoneNumber: string
  recaptchaToken: string
}

export interface ResetPasswordRequest {
  confirmToken: string
  password: string
}

export interface ResetPasswordResult {
  tokens: Tokens
  role: UserRole
}
export interface ChangePasswordRequest {
  password: string
  newPassword: string
}

export interface ResetPasswordResult {
  tokens: Tokens
  role: UserRole
}

export interface RefreshTokenRequest {
  token: string
}
export type RefreshTokenResult = LoginResult
export interface FindCarPayload {
  plate?: string
  ccLast4Digits?: string
}
export interface FindCarData {
  ticket: Ticket
  tokens?: Tokens
}
