import { useChangeLayout } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { paths } from 'routing'
import { AppSnackBarProvider, WhiteBoxContainer } from 'shared'
import PopupProvider from 'shared/popup_provider'
import { Translations } from 'utils'
import { ContactInformations } from './ContactInformations'
import { contactInformationsSlice } from 'store/slices'
import { CancelParkCarDialog } from 'pages/park_car_credit_card/CreditCardComponents'

export const ContactInformationsPage = () => {
  const [showDialog, setShowDialog] = React.useState(false)
  const { t } = useTranslation()
  const title = t(Translations.PARK_CAR)
  const { carId } = useParams<{ carId: string }>()
  const { paymentRedirectionAvailable } = useSelector(
    contactInformationsSlice.stateSelectors
  )
  useChangeLayout(
    {
      topbar: {
        title,
        hasGoBack: false,
        rightIcon: 'close',
        onClickRight: () => setShowDialog(true),
      },
      bottomMenu: false,
    },
    'app'
  )

  if (!carId || paymentRedirectionAvailable === null) {
    return <Redirect to={paths.parking.tickets} />
  }

  return (
    <AppSnackBarProvider hasBottomGutter>
      <PopupProvider>
        <WhiteBoxContainer>
          <ContactInformations carId={carId} />
          <CancelParkCarDialog
            open={showDialog}
            closeDialog={() => setShowDialog(false)}
          />
        </WhiteBoxContainer>
      </PopupProvider>
    </AppSnackBarProvider>
  )
}
