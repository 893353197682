import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import paths from 'routing/paths'
import { layoutSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { refreshToken } from 'store/slices/customerSlice'
import { useTokenValidation } from 'hooks'
import { AppSpinner, PaddingContainer, PageHeading } from 'shared'
import { whiteTheme } from 'themes'
import { useTranslation } from 'react-i18next'
import { Translations } from 'utils'

export const LandingPageRedirect = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { push } = useHistory()

  const { setLoader } = layoutSlice.actions
  const { lotId } = useParams<{ lotId: string }>()

  const validToken = useTokenValidation()
  const trySignIn = async () => {
    if (validToken) {
      dispatch(setLoader(true))
      await dispatch(refreshToken({ token: validToken.refresh }))
      dispatch(setLoader(false))
    }
  }

  const redirectFromLandinPage = async () => {
    await trySignIn()
    push(`${paths.parking.parkCar}/${lotId}`)
  }

  React.useEffect(() => {
    redirectFromLandinPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={whiteTheme}>
      <PaddingContainer>
        <PageHeading title={t(Translations.PARKING_MADE_EASY)} />
        <AppSpinner show relative height="150px" />
      </PaddingContainer>
    </ThemeProvider>
  )
}
