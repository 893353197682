import { useChangeLayout, useUpdateTicket } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { paths } from 'routing'
import { AppSnackBarProvider } from 'shared'
import { ticketsSlice } from 'store/slices'
import Translations from 'utils/translations'
import TicketsContainer from './TicketComponents/TicketsContainer'

export interface TicketsGuestViewProps {}

const TicketsGuestView: React.FC<TicketsGuestViewProps> = () => {
  const [showDialog, setShowDialog] = React.useState(false)
  const [canParkCar, setCanParkCar] = React.useState(false)
  useUpdateTicket()
  const { t } = useTranslation()
  const history = useHistory()
  const tickets = useSelector(ticketsSlice.selectParkedTickets)
  const expiredTickets = useSelector(ticketsSlice.selectSortedExpiredTickets)
  const { fulfilled } = useSelector(
    ticketsSlice.promiseStatusSelectors
  ).getTicketsStatus
  const lots = useSelector(ticketsSlice.selectParkAnotherCarLots)
  const onParkCar = () =>
    !!tickets.length ? setShowDialog(true) : history.push(paths.home.map)
  const title = React.useCallback(
    () =>
      `${tickets.length} ${t(
        tickets.length === 1
          ? Translations.CAR_PARKED
          : Translations.CARS_PARKED
      )}`,
    [tickets.length, t]
  )

  useChangeLayout(
    {
      layout: {
        ad: true,
      },
      topbar: {
        title: title(),
        hasGoBack: false,
        rightIcon: canParkCar ? 'add' : null,
        onClickRight: onParkCar,
      },
    },
    'app',
    [tickets.length, canParkCar]
  )
  React.useEffect(() => {
    if (tickets.length || !fulfilled) {
      setCanParkCar(false)
    } else {
      setCanParkCar(true)
    }
  }, [canParkCar, fulfilled, tickets.length])
  return (
    <AppSnackBarProvider hasBottomGutter>
      <TicketsContainer
        showDialog={showDialog}
        closeDialog={() => setShowDialog(false)}
        onParkCar={onParkCar}
        tickets={[...tickets, ...expiredTickets]}
        lots={lots}
      />
    </AppSnackBarProvider>
  )
}

export default TicketsGuestView
