import { createGlobalStyle } from 'styled-components'
import bg from 'assets/images/bg.png'

export const GlobalStyle = createGlobalStyle`
body, html {     
height: 100%;
margin: 0;
overscroll-behavior-y: contain;
scrollbar-width: none;
}
html::-webkit-scrollbar,
body::-webkit-scrollbar,
#root::-webkit-scrollbar {
 display: none;
}
#root {
background-image: url(${bg});
scrollbar-width: none;
background-size: cover;
top: 0;
width: 100%;
min-height: 100%;
margin: 0 auto;
.snackbarProvider-anchorOriginBottomCenter {
    z-index:2;
}
/* for lists */
.item-enter {
opacity: 0;
}
.item-enter-active {
opacity: 1;

transition: all 250ms ease-in;
}
.item-exit {
opacity: 1;
}
.item-exit-active {
opacity: 0;
transition: all 250ms ease-in;
}
}
`
