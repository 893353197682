import { useFormikContext } from 'formik'
import moment from 'moment'
import { useParkMode } from 'pages/park_car/ParkCarHooks'
import React from 'react'
import { T2Fields } from '../fields'

export interface useMinDateProps {}

export const useMinDate = () => {
  const { values } = useFormikContext<T2Fields>()
  const { currentTime, startDate } = values
  const parkMode = useParkMode()
  const [minDate, setMinDate] = React.useState(null)

  React.useEffect(() => {
    let minDate = ''
    if (parkMode === 'parkCar') {
      minDate = moment(currentTime)
        .add(1, 'minute')
        .startOf('minute')
        .toString()
    }
    if (parkMode === 'addMoreTime') {
      if (moment(startDate) > moment(currentTime)) {
        minDate = moment(startDate)
          .add(1, 'minute')
          .startOf('minute')
          .toString()
      } else {
        minDate = moment(currentTime)
          .add(1, 'minute')
          .startOf('minute')
          .toString()
      }
    }
    if (!minDate) throw Error('Invalid min date')
    setMinDate(minDate)
  }, [currentTime, parkMode, startDate])

  return minDate
}

export default useMinDate
