export const API_URL = (window as any).appConfig?.API_URL
export const TEZ_PAY_API_URL = (window as any).appConfig?.TEZ_PAY_API_URL
export const GOOGLE_KEY = (window as any).appConfig?.GOOGLE_KEY

export const CAPTCHA_V2_KEY = (window as any).appConfig?.CAPTCHA_V2_KEY
export const CAPTCHA_V3_KEY = (window as any).appConfig?.CAPTCHA_V3_KEY
export const CAPTCHA_V2_ENABLE = (window as any).appConfig?.CAPTCHA_V2_ENABLE
export const CAPTCHA_V3_ENABLE = (window as any).appConfig?.CAPTCHA_V3_ENABLE
export const CAPTCHA_VALIDATE_REGISTRATION = (window as any).appConfig?.VALIDATE_FEATURE.REGISTRATION
export const CAPTCHA_VALIDATE_RESEND_PIN = (window as any).appConfig?.VALIDATE_FEATURE.RESEND_PIN
export const CAPTCHA_VALIDATE_FORGOT_PASSWORD = (window as any).appConfig?.VALIDATE_FEATURE.FORGOT_PASSWORD
export const CAPTCHA_VALIDATE_ADD_NUMBER_FOR_NOTIFICATION = (window as any).appConfig?.VALIDATE_FEATURE.ADD_NUMBER_FOR_NOTIFICATION
export const CAPTCHA_VALIDATE_PAYMENT = (window as any).appConfig?.VALIDATE_FEATURE.PAYMENT
