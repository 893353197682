/* eslint-disable react-hooks/exhaustive-deps */
import { useFormikContext } from 'formik'
import { useUpdateLayout } from 'hooks'
import { initialValues } from 'pages/park_car/ParkCarT2/ParkCarT2Form'
import React from 'react'
import { parkCarSlice } from 'store/slices'
import { ParkCarState } from 'store/slices/parkCarSlice types'
import { useAppDispatch } from 'store/store'
import { T2Fields } from '../fields'

export const useResetForm = () => {
  const updateLayout = useUpdateLayout({}, undefined, true)
  const { setFieldValue } = useFormikContext<T2Fields>()
  const dispatch = useAppDispatch()
  const resetForm = React.useCallback(() => {
    setFieldValue('lastListView', initialValues['lastListView'])
    setFieldValue('pricings', initialValues['pricings'])
    setFieldValue(
      'pricingExpirationDateUtc',
      initialValues['pricingExpirationDateUtc']
    )
    setFieldValue('selectedPricing', initialValues['selectedPricing'])
    dispatch(
      parkCarSlice.actions.updateParkingDetails<
        Partial<ParkCarState['parkingDetails']>
      >({ externalPricingId: initialValues['selectedPricing'] })
    )
  }, [setFieldValue, updateLayout])

  return resetForm
}

export default useResetForm
