import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import { receiptsApiError } from 'api/apiErrors'
import * as receiptsApi from 'api/ReceiptsApi'
import { getErrorCode, getErrorMessage } from 'api/utils'
import { RootState } from 'store/rootReducer'
import {
  GetReceiptsData,
  Receipt,
  SendQuestionPayload,
  ErrorData,
  SendReceiptsData,
  SendReceiptsPayload,
  FetchStatus,
} from 'typedef'
import { RECEIPTS, FETCH, initialPromiseStatus } from './constants'
import { ReceiptsState } from './receiptsSlice.types'
import * as sliceUtil from './slice.utils'

const receiptsAdapter = createEntityAdapter<Receipt>({
  selectId: receipt => receipt.carId,
})

const initialState = receiptsAdapter.getInitialState<ReceiptsState>({
  error: null,
  promisesStatus: {
    sendReceipt: {},
    sendQuestion: {},
    getReceipts: initialPromiseStatus,
  },
})

export const getReceipts = createAsyncThunk<
  GetReceiptsData,
  null,
  { rejectValue: ErrorData }
>(
  `${RECEIPTS}/${FETCH}/getReceipts`,
  async (_, thunkApi) => {
    try {
      const response = await receiptsApi.getReceipts()
      return response
    } catch (error) {
      const errorMessage = getErrorMessage(
        getErrorCode(error),
        receiptsApiError.getReceipts
      )
      return thunkApi.rejectWithValue(errorMessage)
    }
  },
  {
    condition: (arg, api) => {
      const { receipts } = api.getState() as RootState
      return !receipts.promisesStatus.getReceipts.status.includes(
        FetchStatus.Pending
      )
    },
  }
)

export const sendReceipt = createAsyncThunk<
  SendReceiptsData,
  SendReceiptsPayload,
  { rejectValue: ErrorData }
>(`${RECEIPTS}/${FETCH}/sendReceipt`, async (arg, thunkApi) => {
  try {
    const response = await receiptsApi.sendReceipt(arg)
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      receiptsApiError.sendReceipt
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const sendQuestion = createAsyncThunk<
  null,
  SendQuestionPayload,
  { rejectValue: ErrorData }
>(
  `${RECEIPTS}/${FETCH}/sendQuestion`,
  async (arg, thunkApi) => {
    try {
      await receiptsApi.sendQuestion(arg)
      return null
    } catch (error) {
      const errorMessage = getErrorMessage(
        getErrorCode(error),
        receiptsApiError.sendQuestion
      )
      return thunkApi.rejectWithValue(errorMessage)
    }
  },
  {
    condition: (arg, api) => {
      const { receipts } = api.getState() as RootState
      if (!receipts.promisesStatus.sendQuestion[arg.carId]) return true
      return !sliceUtil.fetchStatus(
        receipts.promisesStatus.sendQuestion[arg.carId]
      ).pending
    },
  }
)

const receiptsSlice = createSlice({
  name: RECEIPTS,
  initialState,
  reducers: {
    resetFinalSendReceiptStatus(state) {
      const keysArr = Object.keys(state.promisesStatus.sendReceipt)
      keysArr.forEach(key => {
        let status = [FetchStatus.Idle]
        if (
          state.promisesStatus.sendReceipt[key]?.status.includes(
            FetchStatus.Pending
          )
        ) {
          status = status.concat(FetchStatus.Pending)
        }
        state.promisesStatus.sendReceipt[key] = {
          ...initialPromiseStatus,
          status,
        }
      })
    },
    resetFinalSendQuestionStatus(state) {
      const keysArr = Object.keys(state.promisesStatus.sendQuestion)
      keysArr.forEach(key => {
        let status = [FetchStatus.Idle]
        if (
          state.promisesStatus.sendQuestion[key]?.status.includes(
            FetchStatus.Pending
          )
        ) {
          status = status.concat(FetchStatus.Pending)
        }
        state.promisesStatus.sendQuestion[key] = {
          ...initialPromiseStatus,
          status,
        }
      })
    },
  },
  extraReducers: builder => {
    /**
     * Fetch receipts
     **/
    builder
      .addCase(getReceipts.pending, ({ promisesStatus }, action) => {
        promisesStatus.getReceipts = sliceUtil.pendingPromise(
          promisesStatus.getReceipts,
          action
        )
      })
      .addCase(getReceipts.fulfilled, (state, action) => {
        // eslint-disable-next-line
        if (state.promisesStatus.getReceipts.requestId != action.meta.requestId)
          return
        state.promisesStatus.getReceipts = sliceUtil.fulfilledPromise()
        receiptsAdapter.setAll(
          state,
          action.payload ? action.payload.receipts : []
        )
      })
      .addCase(getReceipts.rejected, (state, action) => {
        // eslint-disable-next-line
        if (state.promisesStatus.getReceipts.requestId != action.meta.requestId)
          return
        state.promisesStatus.getReceipts = sliceUtil.rejectedPromise(
          action.payload
        )
      })

      /**
       * Send receipt
       */
      .addCase(sendReceipt.fulfilled, (state, action) => {
        state.promisesStatus.sendReceipt[action.meta.arg.carId] = {
          ...initialPromiseStatus,
          status: [FetchStatus.Fulfilled],
        }
      })
      .addCase(sendReceipt.pending, (state, action) => {
        const { carId } = action.meta.arg
        state.promisesStatus.sendReceipt[carId] = {
          ...initialPromiseStatus,
          status: [FetchStatus.Pending],
        }
      })
      .addCase(sendReceipt.rejected, (state, action) => {
        const { carId } = action.meta.arg
        state.promisesStatus.sendReceipt[carId] = {
          ...initialPromiseStatus,
          status: [FetchStatus.Rejected],
        }
      })

      /**
       * Send question
       */
      .addCase(sendQuestion.pending, (state, action) => {
        const { carId } = action.meta.arg
        const currentStatus = Boolean(state.promisesStatus.sendQuestion[carId])
          ? state.promisesStatus.sendQuestion[carId]
          : initialPromiseStatus
        state.promisesStatus.sendQuestion[carId] = sliceUtil.pendingPromise(
          currentStatus,
          action
        )
      })
      .addCase(sendQuestion.fulfilled, (state, action) => {
        const { carId } = action.meta.arg
        state.promisesStatus.sendQuestion[carId] = sliceUtil.fulfilledPromise()
      })
      .addCase(sendQuestion.rejected, (state, action) => {
        const { carId } = action.meta.arg
        state.promisesStatus.sendQuestion[carId] = sliceUtil.rejectedPromise(
          action.payload
        )
      })
  },
})

export default receiptsSlice.reducer
export const { actions } = receiptsSlice

const receiptsSelectors = receiptsAdapter.getSelectors(
  (state: RootState) => state.receipts
)
export const {
  selectAll: selectAllReceipts,
  selectById: selectReceiptById,
  selectIds: selectReceiptIds,
} = receiptsSelectors

export const stateSelectors = (state: RootState) => state.receipts
export const promiseStatusSelectors = (state: RootState, carId?: string) => {
  const promises = stateSelectors(state).promisesStatus
  return {
    getReceiptsStatus: sliceUtil.fetchStatus(promises.getReceipts),
    sendReceiptStatus: carId
      ? sliceUtil.fetchStatus(promises.sendReceipt[carId])
      : sliceUtil.fetchStatus(),
    sendQuestionStatus: carId
      ? sliceUtil.fetchStatus(promises.sendQuestion[carId])
      : sliceUtil.fetchStatus(),
  }
}
