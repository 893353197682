import GoogleMapReact, { ChangeEventValue, MapOptions } from 'google-map-react'
import * as React from 'react'
import { batch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { BOTTOM_BAR_HEIGHT } from 'shared/layout/constants'
import { RootState } from 'store/rootReducer'
import { layoutSlice, simulatePriceSlice, sliceUtil } from 'store/slices'
import * as mapSlice from 'store/slices/mapSlice'
import { useAppDispatch } from 'store/store'
import styled from 'styled-components'
import useSupercluster from 'use-supercluster'
import CarMarker from './CarMarker'
import GpsMarker from './GpsMarker'
import LotMarker from './LotMarker'
import { GOOGLE_KEY } from 'api/config'

type MapProps = {
  showSelectLotDialog: () => void
}
const MapWrapper = styled.div<{ hasBottomBar?: boolean; height?: number }>`
  width: 100%;
  position: relative;
  height: ${_ => _.height - (_.hasBottomBar ? BOTTOM_BAR_HEIGHT : 0)}px;
  @media only screen and (orientation: landscape) {
    height: ${_ => _.height}px;
  }
`

const mapOptions: MapOptions = {
  disableDefaultUI: true,
  gestureHandling: 'greedy',
}

const key = GOOGLE_KEY

export const Map: React.FC<MapProps> = props => {
  const dispatch = useAppDispatch()
  const { token } = useParams<{ token: string }>()
  const { pricingMode } = useSelector(simulatePriceSlice.stateSelectors)
  const { bottomMenu, height } = useSelector(layoutSlice.stateSelectors)
  const selectedLot = useSelector((state: RootState) =>
    mapSlice.selectById(state, state.map.selectedLot)
  )
  const parkedLots = useSelector(mapSlice.selectParkedLots)
  const { position, zoom, myPosition, findCarMode, bounds } = useSelector(
    mapSlice.stateSelectors
  )
  const lots = useSelector(mapSlice.selectMapLots)
  const boundsToFetch = useSelector(mapSlice.selectUnfetchedBounds)

  const points = lots.map(lot => ({
    type: 'Feature',
    properties: {
      lotId: lot.lotId,
      lotsIds: lot.lotsIds,
      manyLots: lot.manyLots,
    },
    geometry: {
      type: 'Point',
      coordinates: [lot.geolocation.longitude, lot.geolocation.latitude],
    },
  }))

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 100 },
  })

  React.useEffect(() => {
    if (boundsToFetch.length) {
      dispatch(
        mapSlice.getLots({
          ...sliceUtil.mapClusterBoundsToApi(boundsToFetch),
          token,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boundsToFetch, dispatch, token])

  React.useEffect(
    () => () => {
      dispatch(mapSlice.actions.unselectLot())
    },
    [dispatch]
  )

  const onChildClick = (pointId: string, childProps: any) => {
    const { lat, lng, isCluster, lotId, lotsIds, manyLots } = childProps
    if (isCluster) {
      const dashIdx = pointId.indexOf('-')
      const clusterLng = pointId.slice(dashIdx + 1)
      const clusterLat = pointId.slice(0, dashIdx)
      const clusterId = clusters.find(el => {
        const [longitude, latitude] = el.geometry.coordinates
        return (
          longitude.toString() === clusterLng &&
          latitude.toString() === clusterLat
        )
      })?.id
      if (!clusterId) {
        console.warn('cluster id error')
        return
      }
      const expansionZoom = Math.min(
        supercluster.getClusterExpansionZoom(parseInt(clusterId)),
        20
      )
      batch(() => {
        dispatch(mapSlice.actions.setZoom(expansionZoom))
        dispatch(mapSlice.actions.setPosition({ lat, lng }))
      })
      return
    }
    if (manyLots) {
      dispatch(mapSlice.actions.setLotsToSelect({ ids: lotsIds }))
      props.showSelectLotDialog()
      return
    }
    batch(() => {
      dispatch(mapSlice.actions.selectLot({ lotId }))
      dispatch(mapSlice.getLotDetailsMap({ lotId }))
    })
  }
  const onClick = React.useCallback(
    () => dispatch(mapSlice.actions.unselectLot()),
    [dispatch]
  )
  const onChange: (value: ChangeEventValue) => any = ({ center, bounds }) => {
    dispatch(mapSlice.actions.setBounds(bounds))
    dispatch(mapSlice.actions.setPosition(center))
  }
  React.useEffect(() => {
    const noClusteredLots = clusters
      .filter(el => el.properties.lotId)
      .map(el => el.properties.lotId)
    dispatch(mapSlice.actions.setNoClusteredLots(noClusteredLots))
  }, [clusters, dispatch])

  return (
    <>
      <MapWrapper hasBottomBar={bottomMenu} height={height}>
        <GoogleMapReact
          bootstrapURLKeys={{ key }}
          resetBoundsOnResize
          center={position}
          zoom={zoom}
          options={mapOptions}
          draggable
          onChange={onChange}
          onChildClick={onChildClick}
          onClick={onClick}
          onZoomAnimationEnd={zoom => dispatch(mapSlice.actions.setZoom(zoom))}
        >
          {clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates
            const {
              cluster: isCluster,
              point_count: pointCount,
              lotId,
              lotsIds,
              manyLots,
            } = cluster.properties
            return (
              <LotMarker
                key={lotId ? `lot-${lotId}` : `${latitude}-${longitude}`}
                lat={latitude}
                lng={longitude}
                lotId={lotId}
                isCluster={isCluster}
                isSelected={lotId && selectedLot && selectedLot.lotId === lotId}
                pointCount={pointCount}
                manyLots={manyLots}
                lotsIds={lotsIds}
                pricingMode={pricingMode}
              />
            )
          })}
          {myPosition?.lat && myPosition?.lng && (
            <GpsMarker
              lat={myPosition.lat}
              heading={myPosition.heading}
              lng={myPosition.lng}
            />
          )}
          {findCarMode &&
            !!parkedLots.length &&
            parkedLots.map((el, i) => (
              <CarMarker
                key={`car-${el.lotId}-${i}`}
                lat={el.geolocation.latitude}
                lng={el.geolocation.longitude}
                lotId={el.lotId}
              />
            ))}
        </GoogleMapReact>
      </MapWrapper>
    </>
  )
}

export default Map
