import { useTranslation } from 'react-i18next'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'
import { VerificationType } from 'typedef/receipt'
import { Translations } from 'utils'
import { contactInformationsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { hideLoader, showLoader } from 'store/loader'
import { unwrapResult } from '@reduxjs/toolkit'

export const useResendCode = (
  confirmToken: string,
  setConfirmToken: React.Dispatch<React.SetStateAction<string>>
) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { setPopup, showPopup } = usePopup()

  const handleResendCode = async () => {
    dispatch(showLoader())
    const promise = await dispatch(
      contactInformationsSlice.resendVerificationSMS({
        confirmToken,
        verificationType: VerificationType.SavingGuestsPhoneNumber,
      })
    )
    dispatch(hideLoader())
    if (
      contactInformationsSlice.resendVerificationSMS.fulfilled.match(promise)
    ) {
      const res = unwrapResult(promise)
      setConfirmToken(res.confirmToken)
      setPopup({
        variant: 'success',
        title: t(Translations.SUCCESS),
        description: ' ',
      })
      showPopup(true)
    } else {
      setPopup({
        ...promise.payload,
      })
      showPopup(true)
    }
  }
  return handleResendCode
}
