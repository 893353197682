import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DetailsBox } from 'shared'
import styled, { css, keyframes } from 'styled-components'
import Translations from 'utils/translations'
import AppTypography from './AppTypography'

const rotate = keyframes`
	from { transform: rotate(0deg);   }
	to   { transform: rotate(360deg); }
`
const SpinnerWrapper = styled.div`
  width: 170px;
  height: 170px;
  position: relative;
  margin: auto;
`

const Spinner = styled.div<{ isRunning?: boolean }>`
  ${_ =>
    _.isRunning &&
    css`
      animation: ${rotate} 1s linear infinite;
    `}
  background: ${_ =>
    _.isRunning ? _.theme.palette.primary.main : _.theme.palette.divider};
  border-radius: 50%;
  height: 170px;
  width: 170px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    ${_ =>
      _.isRunning &&
      css`
        background: linear-gradient(0deg, #dbdbdb 50%, #dbdbdbe6 100%) 0% 0%,
          linear-gradient(90deg, #dbdbdbe6 0%, hsla(0, 0%, 100%, 0.6) 100%) 100%
            0%,
          linear-gradient(
              180deg,
              hsla(0, 0%, 100%, 0.6) 0%,
              hsla(0, 0%, 100%, 0.3) 100%
            )
            100% 100%,
          linear-gradient(
              360deg,
              hsla(0, 0%, 100%, 0.3) 0%,
              hsla(0, 0%, 100%, 0) 100%
            )
            0% 100%;
      `}
    background-repeat: no-repeat;
    background-size: 50% 50%;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
  }
  &:after {
    content: '';
    position: absolute;
    background: #f5f5f5;
    border-radius: 50%;
    top: 3%;
    bottom: 3%;
    left: 3%;
    right: 3%;
  }
`
const SpinnerContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

export interface TimeSpinnerProps {
  isRunning?: boolean
  parkingBeginDateTime?: string
  parkingExpireDateTime?: string
  /**in minutes;
   * additional times for payment and leave lot;
   * will be substracted from expireDateTime*/
  graceMinutes?: number
  currentDateTime?: string
  refCurrentTime?: number
  carId: string
}

export const TimeSpinner: React.FC<TimeSpinnerProps> = ({
  isRunning,
  currentDateTime,
  parkingBeginDateTime,
  refCurrentTime,
  graceMinutes,
  parkingExpireDateTime,
  ...props
}) => {
  const { t } = useTranslation()
  const [elapsedTime, setElapsedTime] = React.useState('')

  const getElapsedTimeString = () => {
    const runningDuration = moment.duration(
      moment(currentDateTime).diff(parkingBeginDateTime) +
        Date.now() -
        refCurrentTime,
      'milliseconds'
    )
    const pausedDuration = moment.duration(
      moment(parkingExpireDateTime).diff(parkingBeginDateTime) -
        graceMinutes * 60000,
      'milliseconds'
    )

    return (isRunning ? runningDuration : pausedDuration).format('HH:mm:ss', {
      trim: false,
    })
  }

  React.useEffect(() => {
    setElapsedTime(getElapsedTimeString())
    let timer: any
    if (isRunning) {
      timer = setInterval(() => {
        setElapsedTime(getElapsedTimeString())
      }, 1000)
    }
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning])

  return (
    <SpinnerWrapper>
      <Spinner isRunning={isRunning} />
      <SpinnerContent>
        <AppTypography
          variant="body1"
          color="textSecondary"
          size={0.9}
          disabled
        >
          {t(Translations.PARKING_TIMER)}:
        </AppTypography>
        <AppTypography variant="subtitle2" size={2} line={1.3}>
          {elapsedTime}
        </AppTypography>
        <DetailsBox.Divider />
        <AppTypography variant="h2" size={0.9} line={1.3} textCase="uppercase">
          {moment(parkingBeginDateTime).format('MMM DD')}
        </AppTypography>
      </SpinnerContent>
    </SpinnerWrapper>
  )
}

export default TimeSpinner
