import * as React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
export interface ConditionalRouteProps {
  redirectTo: string
  shouldRedirect: boolean
}

const ConditionalRoute: React.SFC<RouteProps & ConditionalRouteProps> = ({
  redirectTo,
  shouldRedirect,
  ...props
}) => {
  return (
    <Route {...props} path={props.path}>
      {shouldRedirect ? <Redirect to={redirectTo} /> : props.children}
    </Route>
  )
}

export default ConditionalRoute
