import {
  CircularProgress,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
} from '@mui/material'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { SharedIcon } from 'shared'
import { RootState } from 'store/rootReducer'
import { promiseStatusSelectors } from 'store/slices/receiptsSlice'
import { Receipt } from 'typedef/receipt'
import { formatCurrency, getDate } from 'utils'
export interface ReceiptBarProps {
  receipt: Receipt
  handleMailIconClick: () => void
  handleQuestionIconClick: () => void
}

const ReceiptBar: React.FunctionComponent<ReceiptBarProps> = props => {
  const { sendReceiptStatus, sendQuestionStatus } = useSelector(
    (state: RootState) => promiseStatusSelectors(state, props.receipt.carId)
  )

  return (
    <ListItem data-testid="receiptBar">
      <ListItemText
        primary={getDate(props.receipt.dateInUTC)}
        secondary={props.receipt.locationName}
      />
      <ListItemText
        style={{ textAlign: 'right', paddingRight: '10px' }}
        primary={formatCurrency(
          props.receipt.amount.currency,
          props.receipt.amount.totalAmount
        )}
      />
      <ListItemIcon>
        {sendReceiptStatus.pending ? (
          <Icon>
            <CircularProgress size={22} style={{ padding: '4px' }} />
          </Icon>
        ) : sendReceiptStatus.fulfilled ? (
          <SharedIcon.HistoryList.SendComplete />
        ) : (
          <IconButton
            edge="end"
            aria-label="delete"
            data-testid="sendMailIcon"
            onClick={props.handleMailIconClick}
            disabled={props.receipt.externalServiceDeliversReceipts}
            style={
              props.receipt.externalServiceDeliversReceipts
                ? { opacity: 0.3 }
                : {}
            }
          >
            {sendReceiptStatus.rejected && (
              <SharedIcon.RefreshBroken style={{ padding: '3px' }} />
            )}
            {sendReceiptStatus.idle && <SharedIcon.Message />}
          </IconButton>
        )}
      </ListItemIcon>
      <ListItemSecondaryAction>
        {sendQuestionStatus.pending ? (
          <Icon>
            <CircularProgress size={22} style={{ padding: '4px' }} />
          </Icon>
        ) : sendQuestionStatus.fulfilled ? (
          <SharedIcon.HistoryList.SendComplete />
        ) : (
          <IconButton
            edge="end"
            aria-label="delete"
            data-testid="sendQuestionIcon"
            onClick={props.handleQuestionIconClick}
          >
            {sendQuestionStatus.rejected && (
              <SharedIcon.RefreshBroken style={{ padding: '3px' }} />
            )}
            {sendQuestionStatus.idle && <SharedIcon.Question />}
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default ReceiptBar
