import React, { useContext } from 'react'
import moment from 'moment'

export type LoaderContextType = {
  setCounter: (arg: any) => void
  clearCounter: () => void
}
export const CounterContext = React.createContext<LoaderContextType>(
  {} as LoaderContextType
)
export const useCounterContext = () => useContext(CounterContext)

export const CounterProvider: React.FC = props => {
  const [{ endDate, isRunning, onEnd }, setCounter] = React.useState({
    endDate: undefined,
    onEnd: () => undefined,
    isRunning: false,
  })

  const getElapsedSeconds = React.useCallback(() => {
    let seconds = moment
      .duration(moment(endDate).diff(moment(), 'millisecond'))
      .asSeconds()
    if (seconds < 0) {
      seconds = 0
    }
    return seconds
  }, [endDate])

  const [seconds, setSeconds] = React.useState(getElapsedSeconds())
  const getElapsedTimeString = React.useCallback(
    (seconds: number) =>
      moment.duration(seconds, 'seconds').format('mm:ss', {
        trim: false,
      }),
    []
  )
  const [, setTimeString] = React.useState(
    getElapsedTimeString(getElapsedSeconds())
  )

  React.useEffect(() => {
    if (isRunning) {
      const timer = setInterval(() => {
        setSeconds(getElapsedSeconds())
      }, 1000)
      return () => {
        clearInterval(timer)
      }
    }
  }, [getElapsedSeconds, isRunning])

  React.useEffect(() => {
    setTimeString(getElapsedTimeString(seconds))
  }, [getElapsedTimeString, seconds])

  React.useEffect(() => {
    if (seconds > 0) {
      setCounter(state => ({ isRunning: true, ...state }))
    } else {
      stopCounter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds])
  const clearCounter = () =>
    setCounter({ endDate: undefined, isRunning: false, onEnd: () => undefined })

  const stopCounter = () => {
    onEnd()
    clearCounter()
  }

  return (
    <CounterContext.Provider value={{ setCounter, clearCounter }}>
      {props.children}
    </CounterContext.Provider>
  )
}

export default CounterContext
