import {
  PARK_CAR,
  PARK_CAR_PAY,
  TICKETS,
  PARK_CAR_REDIRECT,
  PARK_CAR_TRANSACTION_STATUS,
} from 'api/constants'
import {
  BuyMoreTimeData,
  BuyMoreTimePayload,
  GetTicketData,
  GetTicketPayload,
  GetTicketsData,
  ParkCarData,
  ParkCarPayload,
  ParkCarRedirectData,
  ParkCarRedirectPayload,
  ParkCarTransactionStatusData,
  ParkCarTransactionStatusPayload,
  PayForCarData,
  PayForCarPayload,
  Response,
} from 'typedef'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, { mockedAxios } from '.'
import './mocks/TicketsApiMock'

export const getTicket = async (args: GetTicketPayload) => {
  return axios
    .get<Response<GetTicketData>>(`${TICKETS}/${args.carId}`)
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      throw err
    })
}
export const getTickets = async () =>
  axios
    .get<Response<GetTicketsData>>(TICKETS)
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      throw err
    })

export const parkCar = async (params: ParkCarPayload) =>
  axios
    .post<Response<ParkCarData>>(PARK_CAR, params)
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      throw err
    })

export const buyMoreTime = async (params: BuyMoreTimePayload) =>
  axios
    .put<Response<BuyMoreTimeData>>(PARK_CAR, params)
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      throw err
    })

export const payForCar = async (params: PayForCarPayload) =>
  axios
    .post<Response<PayForCarData>>(PARK_CAR_PAY, params)
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      throw err
    })

export const parkCarRedirect = async (params: ParkCarRedirectPayload) =>
  axios
    .post<Response<ParkCarRedirectData>>(PARK_CAR_REDIRECT, params )
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      throw err
    })

export const parkCarTransactionStatus = async (
  params: ParkCarTransactionStatusPayload
) =>
  axios
    .get<Response<ParkCarTransactionStatusData>>(PARK_CAR_TRANSACTION_STATUS, {
      params,
    })
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      throw err
    })
