import { useChangeLayout } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { paths } from 'routing'
import { AppSpinner, ErrorPlaceholder, WhiteBoxContainer } from 'shared'
import { useCounterContext } from 'shared/CounterProvider/CounterProvider'
import { customerSlice, parkCarSlice, ticketsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { ExternalPricingType } from 'typedef'
import { Translations } from 'utils'
import { SelectedLotTitle } from './ParkCarComponents'
import MonthlyParkCarCard from './ParkCarComponents/MonthlyParkCarCard'
import ParkCarDefault from './ParkCarDefault'
import { useInitParkCar, useParkMode } from './ParkCarHooks'
import { ParkCarT2 } from './ParkCarT2'

export const ParkCar: React.FC = props => {
  const notExpiredTickets = useSelector(ticketsSlice.selectSortedParkedTickets)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { customerSignedInAsGuest, customerSignedOut } = useSelector(
    customerSlice.selectCustomerStatus
  )
  const { token } = useParams<{ token?: string }>()
  const { clearCounter } = useCounterContext()
  const title = t(Translations.PARK_CAR)
  const { push } = useHistory()
  const { lot } = useSelector(parkCarSlice.stateSelectors)
  const parkMode = useParkMode()

  useChangeLayout(
    {
      layout: {
        hasBottomBar: false,
      },
      bottomMenu: null,
      topbar: {
        title,
        onClickBack: () => push(paths.home.map),
      },
    },
    'app'
  )
  const { isError, isLoading, fetchData } = useInitParkCar()

  React.useEffect(() => {
    clearCounter()
    return () => {
      dispatch(parkCarSlice.actions.setTicket({ carId: null }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  window.onpageshow = function (event) {
    if (event.persisted) {
      window.location.reload()
    }
  }

  // no more than 1 active ticket for guest
  if (
    customerSignedInAsGuest &&
    notExpiredTickets.length &&
    parkMode === 'parkCar'
  )
    return <Redirect to={paths.parking.tickets} />

  if (customerSignedOut && !token) return <Redirect to={paths.home.map} />

  if (isLoading || isError)
    return (
      <WhiteBoxContainer>
        <SelectedLotTitle />
        <AppSpinner show={isLoading} />
        {isError && <ErrorPlaceholder onClick={fetchData} />}
      </WhiteBoxContainer>
    )

  if (lot?.monthlyParkerInfo) return <MonthlyParkCarCard lot={lot} />
  return !lot?.isExternalPricingEnabled ? (
    <ParkCarDefault />
  ) : (
    <>
      {lot?.externalPricingType === ExternalPricingType.T2 && (
        <ParkCarT2 initFetch={fetchData} />
      )}
    </>
  )
}

export default ParkCar
