import { PRICE, PRICE_EXTERNAL_PRICING } from 'api/constants'
import { ExternalPricingData, GetPriceData } from 'typedef/price'
import { carParkPrices } from '__fixtures__/prices'
import mock from '.'
import moment from 'moment'
import { generateExternalPricings } from './mocks.utils'
mock.onGet(PRICE).replyOnce(config => {
  return [
    200,
    {
      data: carParkPrices[0] as GetPriceData,
    },
  ]
})

mock.onGet(PRICE).replyOnce(config => {
  return [
    200,
    {
      data: carParkPrices[1] as GetPriceData,
    },
  ]
})

mock.onGet(PRICE).replyOnce(config => {
  return [
    200,
    {
      data: carParkPrices[2] as GetPriceData,
    },
  ]
})
const pricings = generateExternalPricings(23)
mock.onGet(PRICE_EXTERNAL_PRICING).reply(config => {
  return [
    200,
    {
      data: {
        currentDateUtc: moment().utc().toString(),
        pricingExpirationDateUtc: moment().add(5, 'minute').utc().toString(),
        pricings,
      } as ExternalPricingData,
    },
  ]
})
// mock.onGet(PRICE).networkErrorOnce()
