import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={295} height={168} viewBox="0 0 295 168" fill="none" {...props}>
      <g filter="url(#prefix__filter0_d)">
        <rect x={4} width={287} height={160} rx={15} fill="#EBEBEB" />
      </g>
      <path
        d="M171.293 0C171.293 91 87 103 87 160h189c8.284 0 15-6.716 15-15V15c0-8.284-6.7-15-14.984-15H171.293z"
        fill="#E1E1E1"
      />
      <rect
        x={24.5}
        y={21.5}
        width={42.154}
        height={32}
        rx={3.5}
        fill="url(#prefix__paint1_linear)"
        stroke="#B1831F"
      />
      <path
        d="M38.695 41.307c-.202.801-.31 1.654-.31 2.538 0 4.673 3.03 8.462 6.769 8.462 3.738 0 6.77-3.789 6.77-8.462 0-.884-.11-1.737-.31-2.538-.18-.705-.537-2.454-.537-3.808 0-1.354 1.128-3.102 1.692-3.808h14.385M51.923 42.154h15.23M38.385 33.691H24M38.385 42.154H24M51.71 29.038c-.752-3.65-3.402-6.347-6.556-6.347-3.739 0-6.77 3.789-6.77 8.462 0 .884.11 1.737.31 2.538.18.706.537 2.454.537 3.808 0 1.354-.358 3.103-.536 3.808"
        stroke="#B1831F"
      />
      <rect x={217} y={19} width={54} height={36} rx={2} fill="#fff" />
      <path
        d="M225.094 33H224v4.57c0 1.519 1.084 2.593 2.852 2.593 1.767 0 2.851-1.074 2.851-2.593V33h-1.089v4.477c0 1.006-.639 1.714-1.762 1.714s-1.758-.708-1.758-1.714V33zM230.875 40.046h1.055v-2.993c0-.796.478-1.323 1.22-1.323.733 0 1.094.424 1.094 1.196v3.12h1.06V36.72c0-1.177-.65-1.9-1.783-1.9-.791 0-1.337.362-1.616.948h-.024v-.86h-1.006v5.137zM237.428 37.111h-.01V33h-1.055v7.046h1.055V38.2l.439-.434 1.744 2.28h1.225l-2.212-2.925 2.129-2.212h-1.24l-2.075 2.202zM241.373 40.046h1.055v-2.993c0-.796.478-1.323 1.22-1.323.733 0 1.094.424 1.094 1.196v3.12h1.06V36.72c0-1.177-.65-1.9-1.782-1.9-.791 0-1.338.362-1.617.948h-.024v-.86h-1.006v5.137zM249.029 40.144c1.47 0 2.437-1.011 2.437-2.666 0-1.65-.972-2.662-2.437-2.662-1.465 0-2.436 1.011-2.436 2.661 0 1.656.967 2.667 2.436 2.667zm0-.87c-.825 0-1.362-.654-1.362-1.797 0-1.137.537-1.791 1.362-1.791.825 0 1.363.654 1.363 1.791 0 1.143-.533 1.797-1.363 1.797zM259.039 34.91h-1.045l-.937 4.042h-.025l-1.074-4.043h-1.016l-1.069 4.043h-.019l-.938-4.043h-1.055l1.411 5.137h1.089l1.07-3.848h.024l1.079 3.848h1.099l1.406-5.137zM259.684 40.046h1.054v-2.993c0-.796.479-1.323 1.221-1.323.732 0 1.094.424 1.094 1.196v3.12h1.059V36.72c0-1.177-.649-1.9-1.782-1.9-.791 0-1.338.362-1.616.948h-.025v-.86h-1.005v5.137z"
        fill="#646464"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={136}
          y1={80}
          x2={291}
          y2={80}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E1E1" />
          <stop offset={1} stopColor="#E1E1E1" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={24}
          y1={21}
          x2={67.154}
          y2={54}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C966" />
          <stop offset={0.516} stopColor="#FFE7BD" />
          <stop offset={1} stopColor="#E1A448" />
        </linearGradient>
        <filter
          id="prefix__filter0_d"
          x={0}
          y={0}
          width={295}
          height={168}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default SvgComponent
