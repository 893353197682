import { unwrapResult } from '@reduxjs/toolkit'
import { fetchStatusIs } from 'api/utils'
import { Form, Formik, FormikValues } from 'formik'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import paths from 'routing/paths'
import { AppButton, AppSpinner, FlexBox, WhiteBoxContainer } from 'shared'
import { showPopup } from 'store/globalPopup/actions'
import { customerSlice } from 'store/slices'
import {
  editCustomerDetails,
  selectEditCustomerDetailsFetchStatus,
} from 'store/slices/customerSlice'
import { useAppDispatch } from 'store/store'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Input } from '../../shared/Input'
import { AppState } from '../../store/store'
import { Translations, blurLastInput } from 'utils'
import NewPhoneNumberInput from 'shared/PhoneNumberAndCountryCodeInput'

const PhoneNumberContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
`
const PasswordInput = styled(Input)`
  font-size: 50px !important;
`
const GreyButton = styled(AppButton)`
  color: ${_ => _.theme.palette.grey['500']};
`
interface EditProfileProps {
  onClickChangePass: () => void
}

export const EditProfile: React.FC<EditProfileProps> = props => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const details = useSelector((state: AppState) => state.customer.details)

  const { pending } = fetchStatusIs(
    useSelector(selectEditCustomerDetailsFetchStatus)
  )

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t(Translations.FIELD_REQUIRED)),
    lastName: Yup.string().required(t(Translations.FIELD_REQUIRED)),
    email: Yup.string()
      .email(t(Translations.INVALID_EMAIL))
      .required(t(Translations.FIELD_REQUIRED)),
  })

  const onSubmit = async ({ firstName, lastName, email }: FormikValues) => {
    const error = () => {
      dispatch(
        showPopup({
          variant: 'warning',
          title: t(Translations.OOPS),
          description: `${t(Translations.YOUR_ACCOUNT_CANNOT_BE_SAVE)}. ${t(
            Translations.PLEASE_TRY_AGAIN_LATER
          )}.`,
          okButtonClick: () => undefined,
          handleAdditionalButtonClick: () =>
            onSubmit({ firstName, lastName, email }),
        })
      )
    }

    const actionResult = await dispatch(
      editCustomerDetails({
        firstName,
        lastName,
        email,
      })
    )

    if (editCustomerDetails.rejected.match(actionResult)) {
      error()
    }
    if (editCustomerDetails.fulfilled.match(actionResult)) {
      const res = unwrapResult(actionResult)
      if (!res) {
        error()
      } else {
        dispatch(
          showPopup({
            variant: 'success',
            title: t(Translations.SUCCESS),
            description: t(
              Translations.YOUR_ACCOUNT_HAS_BEEN_SUCCESSFULLY_SAVED
            ),
            okButtonClick: () => undefined,
          })
        )
      }
    }
  }

  const onDelete = () => {
    dispatch(
      showPopup({
        title: t(Translations.DELETE_MY_ACCOUNT),
        description: t(
          Translations.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_YOUR_ACCOUNT
        ),
        variant: 'delete',
        handleAdditionalButtonClick: () => history.push(paths.parker.delete),
      })
    )
  }
  React.useEffect(() => {
    if (!details) {
      dispatch(customerSlice.getCustomerDetails())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WhiteBoxContainer>
      <AppSpinner show={pending} />
      {details && (
        <Formik
          initialValues={{
            ...details,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount={false}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            errors,
            dirty,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Input
                id="firstName"
                name="firstName"
                type="text"
                inputMode="text"
                value={values.firstName || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.firstName}
                placeholder={t(Translations.FIRST_NAME)}
                autoComplete="given-name"
                onKeyDown={e => {
                  blurLastInput(e)
                }}
                data-testid="firstNameInput"
              />
              <Input
                id="lastName"
                name="lastName"
                type="text"
                inputMode="text"
                value={values.lastName || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.lastName}
                placeholder={t(Translations.LAST_NAME)}
                autoComplete="family-name"
                onKeyDown={e => {
                  blurLastInput(e)
                }}
                data-testid="lastNameInput"
              />
              <PhoneNumberContainer>
                <NewPhoneNumberInput
                  value={`${values.phoneNumberCountryCode}${values.phoneNumber}`}
                  countryCodeFieldName="phoneNumberCountryCode"
                  phoneNumberFieldName="phoneNumber"
                  disabled={true}
                  data-testid="phoneNumberInput"
                />
              </PhoneNumberContainer>
              <Input
                id="email"
                name="email"
                type="email"
                inputMode="email"
                value={values.email || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.email}
                placeholder={t(Translations.EMAIL)}
                autoComplete="email"
                onKeyDown={e => {
                  blurLastInput(e)
                }}
                data-testid="emailInput"
              />
              <PasswordInput
                id="password"
                name="password"
                type="password"
                defaultValue="******"
                readOnly
                autoComplete="off"
              />
              <FlexBox>
                <AppButton
                  textAlign="right"
                  color="primary"
                  onClick={props.onClickChangePass}
                >
                  {t(Translations.CHANGE_PASSWORD)}
                </AppButton>
              </FlexBox>
              <FlexBox hasTopMargin>
                <AppButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isValid || !dirty}
                  data-testid="saveDetails"
                >
                  {t(Translations.SAVE)}
                </AppButton>
                <GreyButton onClick={onDelete}>
                  {t(Translations.DELETE_MY_ACCOUNT)}
                </GreyButton>
              </FlexBox>
            </Form>
          )}
        </Formik>
      )}
    </WhiteBoxContainer>
  )
}

export default EditProfile
