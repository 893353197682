import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { paths } from 'routing'
import { FlexBox, GridWrapper } from 'shared'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'
import * as parkCar from 'store/slices/parkCarSlice'
import { useAppDispatch } from 'store/store'
import {
  GetLotDetailsParkData,
  TimeSelectOption,
  IntervalOptions,
  OptionType
} from 'typedef/lot'
import { GetPricePayload } from 'typedef/price'
import { ParkCarPayload } from 'typedef/ticket'
import { Translations, blurLastInput } from 'utils'
import * as C from '.'
import { parkCarSchema } from '../fields'
import usePayButton from '../ParkCarHooks/usePayButton'
import ConfirmPopup from './ConfirmPopup'
import LicenseField from './LicenseField'
import NeedHelpPopup from './NeedHelpPopup'
import SelectedLotTitle from './SelectedLotTitle'
import SpotField from './SpotField'
import TimeSelectOptionDialog from './TimeSelectOptionDialog'
import HybridRecaptcha from '../../../shared/ReCaptcha/HybridRecaptcha';
import {validateFeature } from '../../../shared/ReCaptcha/HybridRecaptcha';
export interface ParkCarCardProps {
  fetchPricing: (args: GetPricePayload) => void
  onPayConfirm: (
    args: Omit<ParkCarPayload, 'parkerMonthlyId' | 'parkerId'>
  ) => void
  lot: GetLotDetailsParkData
  initLicencePlate?: string
  initSpotNumber?: string
  disabled: boolean
  addTime?: boolean
  recaptchaToken: string | null
  setRecaptchaToken: React.Dispatch<React.SetStateAction<string>>
}

export const ParkCarCard: React.FC<ParkCarCardProps> = ({ lot, ...props }) => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const { state } = useLocation<{
    preValidationSettings: GetPricePayload & { time: number }
    providedPlate: string
    providedSpotNumber: string
  }>()
  const pricing = useSelector(parkCar.selectPricing)
  const [minutes, setMinutes] = React.useState(0)
  const [hours, setHours] = React.useState(0)
  const [days, setDays] = React.useState(0)
  const [isAllDay, setIsAllDay] = React.useState(false)
  const [licencePlate, setLicencePlate] = React.useState('')
  const [spotNumber, setSpotNumber] = React.useState('')
  const [isTosChecked, setIsTosChecked] = React.useState(false)
  const [showTimeSelectOptionDialog, setShowTimeSelectOptionDialog] =
    React.useState(false)
  const [showConfirmPopup, setShowConfirmPopup] = React.useState(false)
  const [showNeedHelpPopup, setShowNeedHelpPopup] = React.useState(false)
  const [selectedTimeOption, setSelectedTimeOption] =
    React.useState<TimeSelectOption | null>(null)
  const { getPriceStatus } = useSelector(parkCar.promiseStatusSelectors)
  const { setPopup, showPopup } = usePopup()
  const { t } = useTranslation()
  const { isPayButtonDisabled } = usePayButton({
    days,
    hours,
    isAllDay,
    licencePlate,
    minutes,
    selectedTimeOption,
    spotNumber,
    hasSpot: lot?.spot?.isEnabled,
    pricePromiseFullfilled: getPriceStatus.fulfilled,
  })
  const { enqueueSnackbar } = useSnackbar()
  const [intervalOptions, setIntervalOptions] = React.useState<IntervalOptions>(
    {
      showDaySelect: selectedTimeOption?.intervalOptions?.showDaySelect,
      showHourSelect: selectedTimeOption?.intervalOptions?.showHourSelect,
      showMinuteSelect: selectedTimeOption?.intervalOptions?.showMinuteSelect,
      dayInterval: selectedTimeOption?.intervalOptions?.dayInterval,
      maxDays: selectedTimeOption?.intervalOptions?.maxDays,
      hourInterval: selectedTimeOption?.intervalOptions?.hourInterval,
      maxHours: selectedTimeOption?.intervalOptions?.maxHours,
      minuteInterval: selectedTimeOption?.intervalOptions?.minuteInterval,
      maxMinutes: selectedTimeOption?.intervalOptions?.maxMinutes,
      maxTotalMinutes: selectedTimeOption?.intervalOptions?.maxTotalMinutes,
    }
  )
  const [recaptchaVerified, setRecaptchaVerified] = React.useState(false);  
  const handleTokenReceived = (token: string) => {
    props.setRecaptchaToken(token);
  };

  const handleRecaptchaSuccess = () => {
    setRecaptchaVerified(true);
  };

  const handleRecaptchaFailure = () => {
    enqueueSnackbar(t(Translations.RECAPTCHA_VERIFICATION_FAILED), { variant: 'error' });
    setRecaptchaVerified(false);
  };


  const onSelectedTimeChange = (arg: TimeSelectOption) => {
    setDays(0)
    setHours(0)
    setMinutes(0)
    setIsAllDay(false)
    setSelectedTimeOption(arg)
    if (arg.intervalOptions) {
      setInitialTimeValue(arg.intervalOptions)
    }
  }

  const setInitialTimeValue = (options: IntervalOptions) => {
    if (options?.showMinuteSelect) {
      setMinutes(options?.minuteInterval)
    } else if (options?.showHourSelect) {
      setHours(options?.hourInterval)
    } else if (options?.showDaySelect) {
      setDays(options?.dayInterval)
    }
  }

  const setPreviouslyChosenTimeValues = (
    option: GetPricePayload & {
      time: number
    }
  ) => {
    if (option.parkingTimeMinutes) {
      setMinutes(state.preValidationSettings.parkingTimeMinutes)
    }
    if (option.parkingTimeHours) {
      setHours(state.preValidationSettings.parkingTimeHours)
    }
    if (option.parkingTimeDays) {
      setDays(state.preValidationSettings.parkingTimeDays)
    }
  }

  const onPayConfirm = () => {
    setShowConfirmPopup(false)    
    props.onPayConfirm({
      plate: licencePlate,
      spot: spotNumber,
      parkTime: {
        optionId: selectedTimeOption.optionId,
        optionType: selectedTimeOption.optionType,
        parkingExpireDateTime: isAllDay  || selectedTimeOption.optionType == OptionType.MultiChoiceSubtype ? selectedTimeOption.endDateTime : null,
        parkingTimeDays: days,
        parkingTimeHours: hours,
        parkingTimeMinutes: minutes,
      },
    })
  }

  // auto select if only one option (mainly MIX type)
  // if doing validation, previous values will be set when validation is completed
  // if doing validation on multiprice location, previously choosen pricins will be selected
  React.useEffect(() => {
    if (lot.timeSelectOption?.length === 1) {
      setSelectedTimeOption(lot.timeSelectOption[0])
      setIntervalOptions(lot.timeSelectOption[0].intervalOptions)
      if (state?.preValidationSettings) {
        setPreviouslyChosenTimeValues(state.preValidationSettings)
        history.replaceState({}, '')
      } else setInitialTimeValue(lot.timeSelectOption[0].intervalOptions)
    } else if (state?.preValidationSettings?.optionId) {
      const preSelectedTimeOption = lot.timeSelectOption.find(
        e => e.optionId === state.preValidationSettings.optionId
      )
      if (preSelectedTimeOption) {
        setSelectedTimeOption(preSelectedTimeOption)
        if (preSelectedTimeOption.intervalOptions) {
          setPreviouslyChosenTimeValues(state.preValidationSettings)
        }
      }
      history.replaceState({}, '')
    }
    // eslint-disable-next-line
  }, [])

  //Sets plate and spot number after validation, if user typed it
  React.useEffect(() => {
    if (state?.providedPlate) {
      setLicencePlate(state.providedPlate)
    }
    if (state?.providedSpotNumber) {
      setSpotNumber(state.providedSpotNumber)
    }
    // eslint-disable-next-line
  }, [])

  // Fetch pricing  for types with interval options
  React.useEffect(() => {
    if (!selectedTimeOption || !selectedTimeOption.intervalOptions) return
    if (!minutes && !hours && !days && !isAllDay) {
      dispatch(parkCar.actions.resetPricing())
      return
    }    
    const pricingIntervalPayload = {
      parkingTimeMinutes: minutes,
      parkingTimeHours: hours,
      parkingTimeDays: days,
      parkingExpireDateTime: isAllDay || selectedTimeOption.optionType == OptionType.MultiChoiceSubtype ?  selectedTimeOption.endDateTime : null,
      lotId: lot.lotId,
      parkerId: lot.parkerId,
      optionId: selectedTimeOption.optionId,
      optionType: selectedTimeOption.optionType,
    }
    props.fetchPricing(pricingIntervalPayload)
    // eslint-disable-next-line
  }, [minutes, hours, days, isAllDay, selectedTimeOption])

  React.useEffect(() => {
    if (!selectedTimeOption || !selectedTimeOption.intervalOptions) return
    let i = {
      showDaySelect: selectedTimeOption.intervalOptions?.showDaySelect,
      showHourSelect: selectedTimeOption.intervalOptions?.showHourSelect,
      showMinuteSelect: selectedTimeOption.intervalOptions?.showMinuteSelect,
      dayInterval: selectedTimeOption.intervalOptions?.dayInterval,
      maxDays: selectedTimeOption.intervalOptions?.maxDays,
      hourInterval: selectedTimeOption.intervalOptions?.hourInterval,
      maxHours: selectedTimeOption.intervalOptions?.maxHours,
      minuteInterval: selectedTimeOption.intervalOptions?.minuteInterval,
      maxMinutes: selectedTimeOption.intervalOptions?.maxMinutes,
      maxTotalMinutes: selectedTimeOption?.intervalOptions?.maxTotalMinutes,
    }
    i = i

    const maxValueLastLimitationD = Math.floor(i.maxTotalMinutes / (24 * 60))
    const maxValueLastLimitationH =
      Math.floor((i.maxTotalMinutes % (24 * 60)) / 60) +
      (i.showDaySelect ? 0 : maxValueLastLimitationD * 24)
    const maxValueLastLimitationM = Math.floor(i.maxTotalMinutes % 60)

    if (i.showMinuteSelect) {
      if (!props.addTime) {
        if (
          hours == selectedTimeOption.intervalOptions.maxHours &&
          selectedTimeOption.intervalOptions.showHourSelect &&
          !selectedTimeOption.intervalOptions.showDaySelect
        ) {
          setMinutes(0)
        }
        if (minutes != 0) {
          if (
            selectedTimeOption.intervalOptions.maxHours != 0 &&
            !selectedTimeOption.intervalOptions.showDaySelect
          ) {
            if (i.maxHours == 0) {
              i.maxHours = 23
              i.hourInterval = 24
            }
          }
        }
      } else {
        if (i.maxHours >= maxValueLastLimitationH) {
          i.maxHours = maxValueLastLimitationH
        }

        if (hours == maxValueLastLimitationH) {
          if (
            i.maxHours == 0 &&
            selectedTimeOption.intervalOptions.showHourSelect
          ) {
            i.maxMinutes = Number(maxValueLastLimitationM)
            i.maxHours = 23
            i.hourInterval = 24
          }
          if (minutes > Number(maxValueLastLimitationM)) {
            setMinutes(
              Number(maxValueLastLimitationM) -
                (Number(maxValueLastLimitationM) % i.minuteInterval)
            )
          }
        }
      }
    }
    if (i.showDaySelect) {
      if (!props.addTime) {
        if (
          days == selectedTimeOption.intervalOptions.maxDays &&
          selectedTimeOption.intervalOptions.showHourSelect
        ) {
          setHours(0)
        }
        if (
          days == selectedTimeOption.intervalOptions.maxDays &&
          selectedTimeOption.intervalOptions.showMinuteSelect
        ) {
          i.minuteInterval = 24
          i.maxMinutes = 23
          setMinutes(0)
        }
        if (hours != 0 || minutes != 0) {
          if (selectedTimeOption.intervalOptions.maxDays != 0) {
            if (i.maxDays == 0) {
              i.maxDays = 23
              i.dayInterval = 24
            }
          }
        }
      } else {
        if (i.maxDays >= maxValueLastLimitationD) {
          i.maxDays = maxValueLastLimitationD
        }
        if (days == maxValueLastLimitationD) {
          if (i.maxDays == 0) {
            i.maxDays = 23
            i.dayInterval = 24
            i.maxHours = Number(maxValueLastLimitationH)
          }
          if (
            selectedTimeOption.intervalOptions.showHourSelect &&
            hours > Number(maxValueLastLimitationH)
          ) {
            setHours(
              Number(maxValueLastLimitationH) -
                (Number(maxValueLastLimitationH) % i.hourInterval)
            )
            if (
              selectedTimeOption.intervalOptions.showMinuteSelect &&
              minutes > Number(maxValueLastLimitationM)
            ) {
              setMinutes(
                Number(maxValueLastLimitationM) -
                  (Number(maxValueLastLimitationM) % i.minuteInterval)
              )
            }
          }
          if (
            selectedTimeOption.intervalOptions.showMinuteSelect &&
            !selectedTimeOption.intervalOptions.showHourSelect &&
            minutes > Number(maxValueLastLimitationM)
          ) {
            setMinutes(
              Number(maxValueLastLimitationM) -
                (Number(maxValueLastLimitationM) % i.minuteInterval)
            )
          }
          if (selectedTimeOption.intervalOptions.showHourSelect) {
            if (i.maxHours == 0) {
              i.hourInterval = 24
              i.maxHours = 23
            }
          }

          if (
            selectedTimeOption.intervalOptions.showMinuteSelect &&
            (i.maxHours == hours ||
              selectedTimeOption.intervalOptions.showHourSelect == false)
          ) {
            if (i.maxMinutes == 0) {
              i.minuteInterval = 24
              i.maxMinutes = 23
            }
          }
        }
      }
    }
    if (i.showHourSelect && !i.showDaySelect && !i.showMinuteSelect) {
      i.maxHours = Number(maxValueLastLimitationH)
    }
    setIntervalOptions(i as IntervalOptions)
    // eslint-disable-next-line
  }, [minutes, hours, days, selectedTimeOption])

  // Fetch pricing  for types without interval options
  React.useEffect(() => {
    if (!selectedTimeOption || selectedTimeOption.intervalOptions) return
    const pricingNonIntervalPayload = {
      lotId: lot.lotId,
      parkerId: lot.parkerId,
      optionId: selectedTimeOption.optionId,
      optionType: selectedTimeOption.optionType,
      parkingExpireDateTime: isAllDay || selectedTimeOption.optionType == OptionType.MultiChoiceSubtype ? selectedTimeOption.endDateTime : null,
    }

    props.fetchPricing(pricingNonIntervalPayload)
    // eslint-disable-next-line
  }, [selectedTimeOption, isAllDay])

  React.useEffect(() => {
    dispatch(parkCar.actions.resetPricing())
    if (!lot?.timeSelectOption?.length && !lot?.isExternalPricingEnabled) {
      if (!props.addTime) {
        setPopup({
          title: t(Translations.PARKING_UNAVAILABLE),
          disableBackdropClick: true,
          description: t(
            Translations.WE_ARE_SORRY_BUT_THE_CURRENT_PARKING_IS_NOT_AVAILABLE
          ),
          variant: 'error',
          buttons: [
            {
              onClick: () => push(paths.home.map),
            },
          ],
        })
      } else {
        setPopup({
          title: t(Translations.PARKING_UNAVAILABLE),
          disableBackdropClick: true,
          description: t(
            Translations.BUY_MORE_TIME_OPTION_IS_UNVAILABLE_BECAUSE_MAXIMUM_PARKING_TIME_HAS_BEEN_REACHED
          ),
          variant: 'error',
          buttons: [
            {
              onClick: () => push(paths.home.map),
            },
          ],
        })
      }
      showPopup(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPayClick = () => {    
    if (!recaptchaVerified) {
      enqueueSnackbar(t(Translations.PLEASE_COMPLETE_RECAPTCHA_VERIFICATION), { variant: 'warning' });
      return;
    }
    if (props.addTime) {            
      onPayConfirm()
    } else {
      if (lot.spot?.isEnabled) {
        setShowConfirmPopup(true)
      } else {
        onPayConfirm()
      }
    }
  }
  const onSelectPricingClick = () => setShowTimeSelectOptionDialog(true)

  React.useEffect(() => {
    if (props.initLicencePlate) {
      setLicencePlate(props.initLicencePlate)
    }
  }, [props.initLicencePlate])

  React.useEffect(() => {
    if (props.initSpotNumber) {
      setSpotNumber(props.initSpotNumber)
    }
  }, [props.initSpotNumber])
  return (
    <>
      <Formik
        initialValues={{
          spotNumber,
          licencePlate,
        }}
        validationSchema={parkCarSchema(t, lot)}
        onSubmit={onPayClick}
        validateOnBlur
        validateOnChange={false}
        enableReinitialize
      >
        {({ errors, handleChange, validateForm, submitForm, setTouched }) => {
          return (
            <form>
              <GridWrapper>
                <SelectedLotTitle />
                <FlexBox horizontal horizontalFullWith>
                  <LicenseField
                    onBlur={e => setLicencePlate(e.target.value)}
                    onChange={e => setLicencePlate(e.target.value)}
                    disabled={props.addTime}
                    value={licencePlate}
                    onKeyDown={e => {
                      !lot?.spot?.isEnabled && blurLastInput(e)
                    }}
                  />
                  {lot?.spot?.isEnabled && (
                    <SpotField
                      onBlur={e => {
                        handleChange(e)
                        setSpotNumber(e.target.value)
                      }}
                      onChange={e => setSpotNumber(e.target.value)}
                      disabled={props.addTime}
                      value={spotNumber}
                      maxSpotNumber={lot?.spot?.maxSpotNumber}
                      error={!!errors.spotNumber}
                      onKeyDown={e => {
                        blurLastInput(e)
                      }}
                    />
                  )}
                </FlexBox>

                {(!selectedTimeOption || (selectedTimeOption && !pricing)) && (
                  <>
                    <C.SelectPricingSection onClick={onSelectPricingClick} />
                  </>
                )}
                {selectedTimeOption?.intervalOptions && (
                  <C.SelectTimeSection
                    options={intervalOptions}
                    disabled={props.disabled || isAllDay}
                    minutes={minutes}
                    hours={hours}
                    days={days}
                    isAddTime={props.addTime}
                    onDaysChange={setDays}
                    onHoursChange={setHours}
                    onMinutesChange={setMinutes}
                  />
                )}
                {selectedTimeOption?.showAllDayUntil && (
                  <FlexBox horizontal horizontalFullWith>
                    <C.AllDayTick
                      endDateTime={selectedTimeOption.endDateTime}
                      allDayUntilMessage={selectedTimeOption.allDayUntilMessage}
                      isChecked={isAllDay}
                      setIsChecked={setIsAllDay}
                      disabled={props.disabled}
                    />
                  </FlexBox>
                )}
                {selectedTimeOption && pricing && (
                  <C.ParkingExpiresSection
                    {...pricing}
                    currency={lot.currency}
                    changePricingBtn={lot.timeSelectOption.length > 1}
                    changePricingOnClick={() =>
                      setShowTimeSelectOptionDialog(true)
                    }
                  />
                )}
                <FlexBox hasTopMargin>
                  <HybridRecaptcha
                    onValidationSuccess={handleRecaptchaSuccess}
                    onValidationFailure={handleRecaptchaFailure}
                    onTokenReceived={handleTokenReceived}
                    feature={validateFeature.Payment}
                  />
                </FlexBox>
                <C.PaySection
                  disabled={isPayButtonDisabled || props.disabled}
                  isTosChecked={isTosChecked}
                  setIsTosChecked={setIsTosChecked}
                  amount={pricing?.amount}
                  totalAmount={pricing?.totalAmount}
                  currency={lot.currency}
                  fee={pricing?.convenienceFee}
                  showNeedHelp={setShowNeedHelpPopup}
                  onNextClick={() => {
                    validateForm().then(errors => {
                      setTouched({
                        spotNumber: true,
                      })
                      const entries = Object.entries(errors)
                      if (entries.length === 0) {
                        submitForm()
                      } else {
                        entries.forEach(el => {
                          enqueueSnackbar(el[1])
                        })
                      }
                    })
                  }}
                  onValidateClick={() => {
                    dispatch(
                      parkCar.actions.setProvidedPlate({
                        providedPlate: licencePlate,
                      })
                    )
                    dispatch(
                      parkCar.actions.setProvidedSpotNumber({
                        providedSpotNumber: spotNumber,
                      })
                    )
                    push(`${paths.parking.upfrontValidate}/${lot.parkerId}`)
                  }}
                  isUpfrontCouponAvailable={
                    lot.isUpfrontCouponAvailable && !props.addTime
                  }
                >
                  <C.WantMonthly />
                </C.PaySection>
              </GridWrapper>
            </form>
          )
        }}
      </Formik>
      {lot.timeSelectOption && (
        <TimeSelectOptionDialog
          open={showTimeSelectOptionDialog}
          closeDialog={() => setShowTimeSelectOptionDialog(false)}
          timeSelectOptions={lot.timeSelectOption}
          onClick={onSelectedTimeChange}
          currency={lot.currency}
        />
      )}
      <ConfirmPopup
        closePopup={() => setShowConfirmPopup(false)}
        onConfirmClick={onPayConfirm}
        showConfirmPopup={showConfirmPopup}
        spotNumber={spotNumber}
      />
      <NeedHelpPopup
        closePopup={() => setShowNeedHelpPopup(false)}
        showNeedHelpPopup={showNeedHelpPopup}
        lotId={lot.lotId}
      />
    </>
  )
}

ParkCarCard.defaultProps = {
  initSpotNumber: '',
  initLicencePlate: '',
}
export default ParkCarCard
