import { useFormikContext } from 'formik'
import { SelectedLotTitle, TermsButton } from 'pages/park_car/ParkCarComponents'
import React from 'react'
import { AppButton, FlexBox, GridWrapper, WhiteBoxContainer } from 'shared'
import { PricingList, RemaingTime, SubmitButton, TotalAmount } from '.'
import { T2Fields } from '../fields'

export interface ExcerptListProps {
  onClickMore: () => void
  onClickSubmit: () => void
}

export const ExcerptList: React.FC<ExcerptListProps> = ({
  onClickMore,
  onClickSubmit,
}) => {
  const { values } = useFormikContext<T2Fields>()
  const { selectedPricing, pricings } = values

  return (
    <WhiteBoxContainer>
      <GridWrapper>
        <SelectedLotTitle />
        <RemaingTime />
        <PricingList limit={4}>
          {pricings.length > 4 && (
            <AppButton
              variant="text"
              textAlign="center"
              onClick={onClickMore}
              translation="SEE_MORE"
            />
          )}
        </PricingList>
        <FlexBox>
          <TotalAmount />
          <SubmitButton
            onClick={onClickSubmit}
            disabled={!selectedPricing}
            translation="SUBMIT"
          />
          <TermsButton />
        </FlexBox>
      </GridWrapper>
    </WhiteBoxContainer>
  )
}

export default ExcerptList
