import { Car } from 'typedef/car'

export const cars: Car[] = [
  {
    carId: '123123',
    plate: 'SQSQSQ',
    name: 'Car 1',
  },
  {
    carId: '123321',
    plate: 'CQSRT2',
    name: 'Car 2',
  },
]
