import { Action, combineReducers } from '@reduxjs/toolkit'
import { popupReducer } from './globalPopup/reducers'
import { loaderReducer } from './loader/reducers'
import { customerSlice } from './slices'
import adsReducer from './slices/adsSlice'
import carsReducer from './slices/carsSlice'
import creditCardsReducer from './slices/creditCardsSlice'
import customerReducer from './slices/customerSlice'
import layoutReducer from './slices/layoutSlice'
import mapReducer from './slices/mapSlice'
import parkCarReducer from './slices/parkCarSlice'
import receiptsReducer from './slices/receiptsSlice'
import simulatePriceReducer from './slices/simulatePriceSlice'
import termsReducer from './slices/termsSlice'
import ticketsReducer from './slices/ticketsSlice'
import validatorsReducer from './slices/validatorsSlice'
import contactInformationsReducer from './slices/contactInformationsSlice'
import termsOfServiceReducer from './slices/termsOfServiceSlice'

const combinedReducer = combineReducers({
  ads: adsReducer,
  cars: carsReducer,
  creditCards: creditCardsReducer,
  customer: customerReducer,
  layout: layoutReducer,
  loader: loaderReducer,
  map: mapReducer,
  parkCar: parkCarReducer,
  popup: popupReducer,
  receipts: receiptsReducer,
  terms: termsReducer,
  tickets: ticketsReducer,
  validators: validatorsReducer,
  simulatedPrices: simulatePriceReducer,
  contactInformations: contactInformationsReducer,
  termsOfService: termsOfServiceReducer,
})
const rootReducer = (state: RootState, action: Action) => {
  if (action.type === customerSlice.actions.destroySession().type) {
    const { map, layout, terms } = state
    state = { map, layout, terms } as RootState
  }
  return combinedReducer(state, action)
}

export type RootState = ReturnType<typeof combinedReducer>

export default rootReducer
