import React from 'react'
import { WhiteBoxContainer } from 'shared'
import { TicketState } from 'store/slices/ticketsSlice.types'
import { parseCustomStyles } from 'utils'
import TicketNormal from './TicketNormal'

export interface TicketProps {
  ticket: TicketState
  center?: boolean
  minHeight?: number
}

const Ticket: React.ForwardRefRenderFunction<null, TicketProps> = (
  { ticket, center, minHeight },
  ref
) => {
  const { cardBgUrl } = parseCustomStyles(ticket.customStyles)

  return (
    <WhiteBoxContainer
      minWidth={300}
      maxWidth={center ? undefined : 300}
      ref={ref}
      minHeight={minHeight}
      backgroundUrl={cardBgUrl}
    >
      <TicketNormal ticket={ticket} />
    </WhiteBoxContainer>
  )
}

export default React.forwardRef(Ticket)
