import {
  PARK_CAR,
  PARK_CAR_PAY,
  PARK_CAR_REDIRECT,
  PARK_CAR_TRANSACTION_STATUS,
} from 'api/constants'
import moment from 'moment'
import {
  CardType,
  CarStatus,
  ErrorCode,
  GetTicketData,
  GetTicketsData,
  ParkCarData,
  ParkCarRedirectData,
  ParkCarTransactionStatusData,
  ParkerType,
  PayForCarData,
  Ticket,
  TransactionStatus,
  Response,
} from 'typedef'
import { parkLot } from '__fixtures__'
import mock from '.'

export const grticket: Ticket = {
  carId: '123456',
  customStyles: [],
  carStatus: CarStatus.RequiresPayment,
  parkingBeginDateTime: '2020-09-29T10:00:45',
  parkingExpireDateTime: '2020-09-29T11:15:45',
  currentDateTime: '2020-09-29T11:00:45',
  externalServiceDeliversReceipts: false,
  settlement: {
    finalAmount: 150,
    creditCardInfo: {
      cardType: CardType.JCB,
      last4Digits: '1234',
      creditCardId: 'creditCardId',
    },
    currency: {
      currencySymbol: '$',
      currencyFormat: 0,
      cultureName: 'en_US',
    },
    parkingAmount: 100,
    convenienceFee: 50,
    convenienceFeeName: '',
    paymentOperationList: [
      {
        amount: 100,
        name: '',
        type: 1,
        dateAtLocal: moment().toString(),
        dateAtUTC: '2020-09-29T15:40:45',
      },
    ],
  },
  parkerType: 1,
  advertisementUrl: '',
  plate: 'DASDA',
  spot: '1232',
  lot: {
    customStyles: [],
    lotType: 1,
    lotId: '145471',
    lotName: 'LOT 51 - Demo',
    locationId: 113041,
    locationName: 'PCA - Demo',
    logoLink: 'https://test1apismsvalet.t2p.mobi/api/v1/File/GetFile/31745640',
    geolocation: {
      latitude: 33.1139571,
      longitude: -96.8024388,
    },
  },
  validateEnable: true,
  addTimeEnable: false,
}

mock.onGet('/Tickets').reply(config => {
  return [
    200,
    {
      data: {
        tickets: [
          {
            ...grticket,
            carStatus: CarStatus.RequiresPayment,
            parkingExpireDateTime: '',
          },
        ],
      } as GetTicketsData,
      errors: [
        {
          errorCode: ErrorCode.ObjectExist,
        },
      ],
    },
  ]
})

mock
  .onGet(`/Tickets/${grticket.carId}`)
  .replyOnce(config => {
    return [
      200,
      {
        data: {
          ...grticket,
          carStatus: CarStatus.RequiresPayment,
          parkingExpireDateTime: '',
        } as GetTicketData,
      },
    ]
  })
  .onGet(`/Tickets/${grticket.carId}`)
  .replyOnce(config => {
    return [
      200,
      {
        data: {
          ...grticket,
          carStatus: CarStatus.ParkingHasExpired,
        } as GetTicketData,
      },
    ]
  })
// mock.onGet('/Tickets').networkErrorOnce()

mock.onPost(PARK_CAR).reply(config => {
  return [
    400,
    {
      errors: [
        {
          errorCode: ErrorCode.CurrentPricingIsExpired,
          message: 'Current pricing is expired',
        },
      ],
    } as Response,
    // 200,
    // {
    //   data: {
    //     carId: 'test car id',
    //     carStatus: 1,
    //   } as ParkCarData,
    // },
  ]
})
mock.onPost(PARK_CAR_PAY).reply(config => {
  return [
    400,
    {
      data: {
        success: true,
      } as PayForCarData,
    },
  ]
})
mock.onGet(PARK_CAR_REDIRECT).reply(config => {
  return [
    200,
    {
      data: {
        token: 'redirect-token',
      } as ParkCarRedirectData,
    },
  ]
})
mock.onGet(PARK_CAR_TRANSACTION_STATUS).reply(config => {
  return [
    200,
    {
      data: {
        transactionStatus: TransactionStatus.Failed,
      } as ParkCarTransactionStatusData,
    },
  ]
})

mock
  .onGet(
    'https://portal-test.targetadnetwork.com/api/ads/location?uid=564850455&slot=21'
  )
  .reply(config => {
    return [
      200,
      `
      <style>
      .slideshow-container * {box-sizing:border-box}
      .slideshow-container {
              max-width: 1000px;
              position: relative;
              margin: auto;
      }
      .slideshow-container .slide {
              top: 0;
              position: absolute;
              width: 100%;
      }
      .slideshow-container .fade {
          -webkit-animation-name: fade;
          -webkit-animation-duration: 1s;
          -webkit-animation-delay: 1s;
          animation-name: fade;
          animation-duration: 2s;
          animation-delay: 2s;
      }
      @-webkit-keyframes fade {
              from {opacity: 1; left: 0px;} 
              to {opacity: 0; left: 100vw;}
      }
      @keyframes fade {
              from {opacity: 1; left: 0px;} 
              to {opacity: 0; left: 100vw;}
      }
</style>
<div id="tez-slideshow-container" class="slideshow-container"><div class="slide ">
              <a href="https://tez-adv-web-node-test2.azurewebsites.net//api/ads/click?uid=9446a3bd-694f-d76d-dd7d-1a9d6329297b" target="_blank"><img src="https://tezadvnodetest.blob.core.windows.net/tezadvnodetest/banners/197/3ec00c48-c606-ba72-9583-9bebeb370845.png" style="width:100%;object-fit:contain;;object-position:bottom center;"></a>
          </div></div>
<script>
      var sI = -1;
      var sls = document.getElementById("tez-slideshow-container").getElementsByClassName("slide");
      function next() {
              var i;
              let zIndex = sls.length;
              let zIndex2 = sI;
              for (i = 0; i < sls.length; i++) {
                      if (sI !== -1 && sI >= i)
                              sls[i].style.zIndex  = zIndex2--;
                      else {
                              sls[i].style.zIndex = zIndex--;
                      }
                      sls[i].classList.remove('fade');
              };
              sI++;
              if (sI >= sls.length) {
                      sI = 0
              }
              sls[sI].classList.add('fade');
              setTimeout(next, 4000);
      }
      if(sls.length > 1){
              next();
      }
</script>`,
      ,
    ]
  })
mock.onGet('https://test.pl').reply(config => {
  return [
    200,

    `
      <style>
      .slideshow-container * {box-sizing:border-box}
      .slideshow-container {
              max-width: 1000px;
              position: relative;
              margin: auto;
      }
      .slideshow-container .slide {
              top: 0;
              position: absolute;
              width: 100%;
      }
      .slideshow-container .fade {
          -webkit-animation-name: fade;
          -webkit-animation-duration: 1s;
          -webkit-animation-delay: 1s;
          animation-name: fade;
          animation-duration: 2s;
          animation-delay: 2s;
      }
      @-webkit-keyframes fade {
              from {opacity: 1; left: 0px;} 
              to {opacity: 0; left: 100vw;}
      }
      @keyframes fade {
              from {opacity: 1; left: 0px;} 
              to {opacity: 0; left: 100vw;}
      }
</style>
<div id="tez-slideshow-container" class="slideshow-container"><div class="slide ">
              <a href="https://tez-adv-web-node-test2.azurewebsites.net//api/ads/click?uid=9446a3bd-694f-d76d-dd7d-1a9d6329297b" target="_blank"><img src="https://redro.pl/gfx/fototapeta-panorama-gor-SL782.jpg" style="width:100%;object-fit:contain;;object-position:bottom center;"></a>
          </div></div>
<script>
      var sI = -1;
      var sls = document.getElementById("tez-slideshow-container").getElementsByClassName("slide");
      function next() {
              var i;
              let zIndex = sls.length;
              let zIndex2 = sI;
              for (i = 0; i < sls.length; i++) {
                      if (sI !== -1 && sI >= i)
                              sls[i].style.zIndex  = zIndex2--;
                      else {
                              sls[i].style.zIndex = zIndex--;
                      }
                      sls[i].classList.remove('fade');
              };
              sI++;
              if (sI >= sls.length) {
                      sI = 0
              }
              sls[sI].classList.add('fade');
              setTimeout(next, 4000);
      }
      if(sls.length > 1){
              next();
      }
</script>
      `,
    ,
  ]
})
