import { Partner } from 'typedef/validator'

export const partners: Partner[] = [
  {
    couponId: 12312,
    name: 'Auchan',
    partnerId: 1237,
    logoDir: 'https://test1apismsvalet.t2p.mobi/api/v1/File/GetFile/23523040',
    isAddFieldEnable: false,
    addFields: [],
    qrCodeEnable: true,
  },
  {
    couponId: 123123,
    name: 'Pull&Bear',
    partnerId: 1235,
    logoDir: 'https://test1apismsvalet.t2p.mobi/api/v1/File/GetFile/23523040',
    isAddFieldEnable: false,
    qrCodeEnable: false,
    addFields: [],
  },
  {
    couponId: 1234,
    name: 'Burger King',
    partnerId: 1234,
    logoDir: 'https://test1apismsvalet.t2p.mobi/api/v1/File/GetFile/156394860',
    isAddFieldEnable: false,
    qrCodeEnable: false,
    addFields: [],
  },
]
