import { SharedIcon } from 'shared'
import * as React from 'react'

export interface LanguageData {
  name: string
  abbreviation: string
  flag: JSX.Element
}

export const languageList: LanguageData[] = [
  {
    name: 'AMERICAN_ENGLISH',
    abbreviation: 'en-US',
    flag: <SharedIcon.FlagUSA />,
  },
  {
    name: 'BRITISH_ENGLISH',
    abbreviation: 'en-GB',
    flag: <SharedIcon.FlagUK />,
  },
  {
    name: 'GERMAN',
    abbreviation: 'de-DE',
    flag: <SharedIcon.FlagGermany />,
  },
  {
    name: 'ITALIAN',
    abbreviation: 'it-IT',
    flag: <SharedIcon.FlagItaly />,
  },
]
