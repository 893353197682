import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import paths from 'routing/paths'
import { AppButton, AppTypography, FlexBox, SharedIcon } from 'shared'
import { makeStyles } from '@mui/styles'
import { Translations } from 'utils'
import { CustomerDetails } from '../../../typedef/customer'
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber'
interface MyProfileDetailsProps {
  details: CustomerDetails | undefined
}

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 36,
  },
}))

export const MyProfileDetails: React.FC<MyProfileDetailsProps> = props => {
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()

  const newPhoneNumber = () => {
    const phoneUtil = PhoneNumberUtil.getInstance()
    if (props.details.phoneNumber) {
      const parsedPhoneNumber = phoneUtil.parse(
        `+${props.details.phoneNumberCountryCode}${props.details.phoneNumber}`
      )
      return `${phoneUtil.format(
        parsedPhoneNumber,
        PhoneNumberFormat.INTERNATIONAL
      )}`
    } else {
      return ''
    }
  }

  return (
    <>
      <AppTypography
        variant="h2"
        weight="bold"
        style={{ wordWrap: 'break-word' }}
        data-testid="nameText"
      >
        {props.details?.firstName} {props.details?.lastName}
      </AppTypography>
      <List dense={true}>
        <ListItem disableGutters>
          <ListItemIcon className={classes.root}>
            <SharedIcon.Outlined.Phone />
          </ListItemIcon>
          <ListItemText primary={newPhoneNumber()} data-testid="phoneText" />
        </ListItem>
        {props.details?.email && (
          <ListItem disableGutters>
            <ListItemIcon className={classes.root}>
              <SharedIcon.Outlined.Envelope />
            </ListItemIcon>
            <ListItemText
              primary={props.details?.email}
              data-testid="emailText"
            />
          </ListItem>
        )}
      </List>
      <FlexBox hasTopMargin>
        <AppButton
          color="primary"
          variant="outlined"
          onClick={() => history.push(paths.parker.edit)}
          data-testid="editCustomerDetails"
        >
          {t(Translations.EDIT)}
        </AppButton>
      </FlexBox>
    </>
  )
}

export default MyProfileDetails
