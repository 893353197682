import { ButtonProps, DialogTitle, Typography } from '@mui/material'
import { DialogProps } from '@mui/material'
import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppDialog } from 'shared'
import AppButton, { AppButtonProps } from 'shared/AppButton'
import FlexBox from 'shared/FlexBox'
import styled from 'styled-components'
import { Translations } from 'utils'
import { SharedIcon } from 'shared'
import { NARROW_INNER_WIDTH } from 'shared/layout/constants'
import { makeStyles } from '@mui/styles'

/**
 * NOTE: Popup with better customization for use inside components
 */
const Space = styled.div<{ gap?: number }>`
  width: 100%;
  padding-bottom: ${_ => _.theme.spacing(_.gap)};
`
Space.defaultProps = {
  gap: 1,
}
const Text = styled(Typography)`
  max-width: ${NARROW_INNER_WIDTH}px;
`

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    maxWidth: NARROW_INNER_WIDTH,
    margin: 'auto',
  },
}))

export type PopupButton = {
  text?: string
  preventClose?: boolean
} & AppButtonProps
export type PopupProps = {
  variant?: 'info' | 'success' | 'warning' | 'error' | 'delete'
  title?: string
  description?: string
  closePopup?: () => void
  customIcon?: JSX.Element
  buttons?: PopupButton[]
  noButtons?: boolean
  noIcon?: boolean
  children?: React.ReactNode
  disableBackdropClick?: boolean
} & DialogProps

const PopupIcon: React.FC<{ variant: PopupProps['variant'] }> = props => {
  const icon = {
    info: <SharedIcon.PopupPlaceholder.InfoIcon />,
    success: <SharedIcon.PopupPlaceholder.SuccessIcon />,
    warning: <SharedIcon.PopupPlaceholder.WarningIcon />,
    error: <SharedIcon.PopupPlaceholder.ErrorIcon />,
    delete: <SharedIcon.PopupPlaceholder.ErrorIcon />,
  }

  return <div>{icon[props.variant]}</div>
}

export const AppPopup: React.FC<PopupProps> = props => {
  const {
    variant,
    title,
    description,
    customIcon,
    closePopup,
    buttons,
    noButtons,
    noIcon,
    disableBackdropClick,
    ...dialogProps
  } = props

  const classes = useStyles()
  const { t } = useTranslation()
  const buttonColor: {
    info: ButtonProps['color']
    error: ButtonProps['color']
    success: ButtonProps['color']
    warning: ButtonProps['color']
    delete: ButtonProps['color']
  } = {
    info: 'primary',
    error: 'secondary',
    success: 'primary',
    warning: 'primary',
    delete: 'secondary',
  }
  const buttonText = {
    info: t(Translations.OK),
    error: t(Translations.OK),
    success: t(Translations.OK),
    warning: t(Translations.CLOSE),
    delete: t(Translations.CANCEL),
  }
  const buttonSecondText = {
    info: t(Translations.TRY_AGAIN),
    error: t(Translations.TRY_AGAIN),
    success: t(Translations.TRY_AGAIN),
    warning: t(Translations.TRY_AGAIN),
    delete: t(Translations.DELETE),
  }

  const renderButtons = () => {
    if (!buttons) return null

    const validButtons = buttons.filter(el => el)

    // empty array, defult button (OK)
    if (!validButtons.length) {
      return (
        <AppButton
          variant="contained"
          color={buttonColor[variant]}
          onClick={closePopup}
        >
          {buttonText[variant]}
        </AppButton>
      )
    }

    // custom button
    if (validButtons.length === 1) {
      return validButtons.map(({ preventClose, ...prop }, i) => (
        <AppButton
          key={nanoid()}
          color={buttonColor[variant]}
          variant={'contained'}
          {...prop}
          onClick={e => {
            !preventClose && closePopup()
            prop.onClick && prop.onClick(e)
          }}
        >
          {prop.text || buttonText[variant]}
        </AppButton>
      ))
    }

    // up to 2 default buttons
    if (validButtons.length > 1) {
      return validButtons.map(({ preventClose, ...prop }, i) => (
        <AppButton
          key={nanoid()}
          color={buttonColor[variant]}
          variant={!i ? 'contained' : 'outlined'}
          {...prop}
          onClick={e => {
            !preventClose && closePopup()
            prop.onClick && prop.onClick(e)
          }}
        >
          {prop.text || (!i ? buttonSecondText[variant] : buttonText[variant])}
        </AppButton>
      ))
    }
  }

  return (
    <AppDialog
      onClose={(e, reason) => {
        if (
          (disableBackdropClick && reason !== 'backdropClick') ||
          !disableBackdropClick
        ) {
          closePopup()
        }
      }}
      fullWidth={false}
      {...dialogProps}
    >
      {!noIcon &&
        (customIcon ? (
          <div>{customIcon}</div>
        ) : (
          <PopupIcon variant={variant} />
        ))}
      {title && (
        <DialogTitle data-testid="popupTitle" classes={{ root: classes.title }}>
          {title}
        </DialogTitle>
      )}
      {description && (
        <>
          <Text data-testid="popupDescription">{description}</Text>
          <Space gap={3} />
        </>
      )}
      {props.children}
      {!noButtons && <FlexBox>{renderButtons()}</FlexBox>}
    </AppDialog>
  )
}
AppPopup.defaultProps = {
  buttons: [],
  variant: 'info',
  // onClick: () => undefined,
  disableBackdropClick: false,
}

export default AppPopup
