import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { ticketsApiError } from 'api/apiErrors'
import * as ticketsApi from 'api/TicketsApi'
import { getErrorCode, getErrorMessage } from 'api/utils'
import moment from 'moment'
import { RootState } from 'store/rootReducer'
import { TicketsState, TicketState } from 'store/slices/ticketsSlice.types'
import {
  CarStatus,
  FetchStatus,
  GetTicketPayload,
  ParkCarRedirectData,
  ParkCarRedirectPayload,
  PayForCarData,
  PayForCarPayload,
  Ticket,
  ErrorData,
} from 'typedef'
import * as adsSlice from './adsSlice'
import { FETCH, initialPromiseStatus, TICKETS } from './constants'
import * as mapSlice from './mapSlice'
import { LotState } from './mapSlice.types'
import * as sliceUtil from './slice.utils'
import { SelectParkedCars } from './ticketsSlice.types'

/**
 * state
 */
const ticketsAdapter = createEntityAdapter<TicketState>({
  selectId: ticket => ticket.carId,
})

const initialState = ticketsAdapter.getInitialState<TicketsState>({
  selectedTicket: null,
  promisesStatus: {
    getTickets: initialPromiseStatus,
    getTicket: {},
    payForCar: {},
  },
})

/**
 * thunks
 */
export const getTickets = createAsyncThunk<
  { tickets: TicketState[] },
  null,
  { rejectValue: ErrorData }
>(
  `${TICKETS}${FETCH}/getTickets`,
  async (params, thunkApi) => {
    try {
      const resp = await ticketsApi.getTickets()
      const tickets: TicketState[] = resp.tickets
        .filter(el => el.lot?.geolocation)
        .map(el => ({ ...el, refCurrentTime: Date.now() }))

      ;(function () {
        if (!tickets.length) return
        new Set(
          tickets.map(el => el.advertisementUrl).filter(el => el)
        ).forEach(el => {
          thunkApi.dispatch(adsSlice.getAd(el))
        })
      })()
      const lots = tickets.map(el => el.lot)
      thunkApi.dispatch(mapSlice.actions.addLots({ lots }))

      return { tickets }
    } catch (error) {
      const errorMessage = getErrorMessage(
        getErrorCode(error),
        ticketsApiError.getTickets
      )
      return thunkApi.rejectWithValue(errorMessage)
    }
  },
  {
    condition: (arg, api) => {
      const { tickets } = api.getState() as RootState
      return !tickets.promisesStatus.getTickets.status.includes(
        FetchStatus.Pending
      )
    },
  }
)

export const getTicket = createAsyncThunk<
  TicketState,
  GetTicketPayload,
  { rejectValue: ErrorData }
>(
  `${TICKETS}${FETCH}/getTicket`,
  async (params, thunkApi) => {
    try {
      const resp = await ticketsApi.getTicket(params)

      thunkApi.dispatch(adsSlice.getAd(resp.advertisementUrl))
      thunkApi.dispatch(mapSlice.actions.addLots({ lots: [resp.lot] }))
      return { ...resp, refCurrentTime: Date.now() }
    } catch (error) {
      const errorMessage = getErrorMessage(
        getErrorCode(error),
        ticketsApiError.getTicket
      )
      return thunkApi.rejectWithValue(errorMessage)
    }
  },
  {
    condition: (arg, api) => {
      const { tickets } = api.getState() as RootState
      if (!tickets.promisesStatus.getTicket[arg.carId]) return true
      return !tickets.promisesStatus.getTicket[arg.carId].status.includes(
        FetchStatus.Pending
      )
    },
  }
)

export const payForCar = createAsyncThunk<
  PayForCarData,
  PayForCarPayload,
  { rejectValue: ErrorData }
>(`${TICKETS}${FETCH}/payForCar`, async (params, thunkApi) => {
  try {
    const resp = await ticketsApi.payForCar(params)

    return resp
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      ticketsApiError.payForCar
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const parkCarRedirect = createAsyncThunk<
  ParkCarRedirectData,
  ParkCarRedirectPayload,
  { rejectValue: ErrorData }
>(`${TICKETS}${FETCH}/parkCarRedirect`, async (params, thunkApi) => {
  try {
    const resp = await ticketsApi.parkCarRedirect(params)

    return resp
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      ticketsApiError.payForCar
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

/**
 * reducers
 */
const ticketsSlice = createSlice({
  name: TICKETS,
  initialState,
  reducers: {
    setTickets(state, { payload }: PayloadAction<{ tickets: Ticket[] }>) {
      ticketsAdapter.setAll(
        state,
        payload.tickets.map(el => ({ ...el, refCurrentTime: Date.now() }))
      )
    },
    setSelectedTicket(state, { payload }: PayloadAction<{ ticketId: string }>) {
      state.selectedTicket = ticketsAdapter
        .getSelectors()
        .selectById(state, payload.ticketId)
    },
    unsetSelectedTicket(state) {
      state.selectedTicket = null
    },
    addTicket(state, { payload }: PayloadAction<{ ticket: Ticket }>) {
      ticketsAdapter.upsertOne(state, {
        ...payload.ticket,
        refCurrentTime: Date.now(),
      })
    },
    setTicketStatus(
      state,
      { payload }: PayloadAction<{ ticketId: string; status: CarStatus }>
    ) {
      ticketsAdapter.updateOne(state, {
        id: payload.ticketId,
        changes: { carStatus: payload.status },
      })
    },
    removeTicket(
      state,
      { payload }: PayloadAction<{ ticketId: string; status: CarStatus }>
    ) {
      ticketsAdapter.removeOne(state, payload.ticketId)
    },
    resetTicketPromiseStatus(state, { payload }: PayloadAction<string>) {
      state.promisesStatus.getTicket[payload] = initialPromiseStatus
    },
  },
  extraReducers: builder => {
    builder

      /**
       * getTickets
       */
      .addCase(getTickets.pending, (state, action) => {
        state.promisesStatus.getTickets = sliceUtil.pendingPromise(
          state.promisesStatus.getTickets,
          action
        )
      })
      .addCase(getTickets.fulfilled, (state, action) => {
        // eslint-disable-next-line
        if (state.promisesStatus.getTickets.requestId != action.meta.requestId)
          return
        state.promisesStatus.getTickets = sliceUtil.fulfilledPromise()
        action.payload.tickets.forEach(
          el =>
            (state.promisesStatus.getTicket[el.carId] =
              sliceUtil.fulfilledPromise())
        )
        // ticketsAdapter.upsertMany(state, action.payload.tickets)
        ticketsAdapter.upsertMany(state, action.payload.tickets)
      })
      .addCase(getTickets.rejected, (state, action) => {
        // eslint-disable-next-line
        if (state.promisesStatus.getTickets.requestId != action.meta.requestId)
          return
        state.promisesStatus.getTickets = sliceUtil.rejectedPromise(
          action.payload
        )
      })

      /**
       * getTicket
       */
      .addCase(getTicket.pending, (state, action) => {
        const { carId } = action.meta.arg
        const currentStatus = Boolean(state.promisesStatus.getTicket[carId])
          ? state.promisesStatus.getTicket[carId]
          : initialPromiseStatus
        state.promisesStatus.getTicket[carId] =
          sliceUtil.pendingPromise(currentStatus)
      })
      .addCase(getTicket.fulfilled, (state, action) => {
        const { carId } = action.meta.arg
        ticketsAdapter.upsertOne(state, action.payload)
        state.promisesStatus.getTicket[carId] = sliceUtil.fulfilledPromise()
      })
      .addCase(getTicket.rejected, (state, action) => {
        const { carId } = action.meta.arg
        state.promisesStatus.getTicket[carId] = sliceUtil.rejectedPromise(
          action.payload
        )
      })

      /**
       * payForCar
       */
      .addCase(payForCar.pending, (state, action) => {
        const { carId } = action.meta.arg
        const currentStatus = Boolean(state.promisesStatus.payForCar[carId])
          ? state.promisesStatus.payForCar[carId]
          : initialPromiseStatus
        state.promisesStatus.payForCar[carId] =
          sliceUtil.pendingPromise(currentStatus)
      })
      .addCase(payForCar.fulfilled, (state, action) => {
        const { carId } = action.meta.arg
        state.entities[carId].carStatus = CarStatus.Parked
        state.promisesStatus.payForCar[carId] = sliceUtil.fulfilledPromise()
      })
      .addCase(payForCar.rejected, (state, action) => {
        const { carId } = action.meta.arg
        state.promisesStatus.payForCar[carId] = sliceUtil.rejectedPromise(
          action.payload
        )
      })
  },
})

export default ticketsSlice.reducer
export const { actions } = ticketsSlice

/**
 *  selectors
 */
export const stateSelectors = (state: RootState) => state.tickets

export const { selectAll, selectById, selectIds } =
  ticketsAdapter.getSelectors<RootState>(state => state.tickets)

export const selectParkedTickets = (state: RootState) => {
  const allParkedTicketsFilter = (el: TicketState) =>
    el.carStatus === CarStatus.Parked

  return selectAll(state).filter(allParkedTicketsFilter)
}

export const selectExpiredTickets = (state: RootState) =>
  selectAll(state).filter(el => el.carStatus === CarStatus.ParkingHasExpired)

export const selectSortedParkedTickets = (state: RootState) =>
  selectParkedTickets(state).sort((a, b) =>
    moment(a.parkingExpireDateTime).diff(moment(b.parkingExpireDateTime))
  )
export const selectSortedExpiredTickets = (state: RootState) =>
  selectExpiredTickets(state).sort((a, b) =>
    moment(b.parkingExpireDateTime).diff(moment(a.parkingExpireDateTime))
  )

export const selectParkAnotherCarLots = (state: RootState) => {
  const parkedlotsIds = selectParkedTickets(state).map(el => el.lot.lotId)
  const entities = mapSlice.selectEntities(state)
  const uniqLots: LotState[] = []
  new Set(parkedlotsIds).forEach(el => uniqLots.push(entities[el]))
  return uniqLots.filter(el => el)
}

export const selectParkingHistory = (state: RootState) => {
  const tickets = selectAll(state).sort((a, b) =>
    moment(b.parkingExpireDateTime).diff(moment(a.parkingExpireDateTime))
  )
  const lotEntities = mapSlice.selectEntities(state)
  const history = [] as (LotState & Pick<Ticket, 'carStatus'>)[]
  const uniqLotsIds = new Set(tickets.map(el => el.lot.lotId))
  uniqLotsIds.forEach(lotId => {
    const ticket = tickets.find(el => el.lot.lotId === lotId)
    if (ticket) {
      history.push({
        ...lotEntities[lotId],
        carStatus: ticket.carStatus,
      })
    }
  })
  return history
}

export const selectParkedCars: SelectParkedCars = state =>
  selectParkedTickets(state).map(el => ({
    carId: el.carId,
    carStatus: el.carStatus,
    plate: el.plate,
    lot: el.lot,
  }))

export const promiseStatusSelectors = (state: RootState, carId?: string) => {
  const promises = stateSelectors(state).promisesStatus
  return {
    getTicketsStatus: sliceUtil.fetchStatus(promises.getTickets),
    getTicketStatus: carId
      ? sliceUtil.fetchStatus(promises.getTicket[carId])
      : sliceUtil.fetchStatus(),
    payForCarStatus: carId
      ? sliceUtil.fetchStatus(promises.payForCar[carId])
      : sliceUtil.fetchStatus(),
  }
}

export const singleTicketsFetchSuccess = (state: RootState) => {
  let success = true
  Object.entries(state.tickets.promisesStatus.getTicket).find(([key, val]) => {
    const { fulfilled } = sliceUtil.fetchStatus(val.status)
    if (!fulfilled) {
      success = false
      return true
    }
    return false
  })
  return success
}

export const selectTicketByLotId = (state: RootState, lotId?: string) => {
  if (!lotId) return null
  return selectAll(state).find(el => el?.lot?.lotId === lotId)
}
