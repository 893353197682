import { Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, AppTypography, SharedIcon } from 'shared'
import styled from 'styled-components'
import { CarStatus, Ticket as ITicket } from 'typedef/ticket'
import { formatCurrency, Translations } from 'utils'

const InfoBox = styled.div<{ valid: boolean }>`
  background: ${_ =>
    _.valid ? _.theme.palette.grey[100] : _.theme.palette.secondary.light};
  padding: ${_ => _.theme.spacing(1)};
  margin-bottom: ${_ => _.theme.spacing(2.5)};
  border-radius: 8px;
`
const CarRow = styled.div`
  padding: ${_ => _.theme.spacing(1)} 0;
  display: flex;
  div:first-of-type {
    margin-right: ${_ => _.theme.spacing(3)};
  }
`
const ParkingExpireRow = styled.div<{ valid: boolean }>`
  padding: ${_ => _.theme.spacing(1)} 0;
  border: 1px solid ${_ => (_.valid ? _.theme.palette.divider : '#ffc6c6')};
  border-width: 1px 0 0 0;
  text-align: left;
  color: ${_ => (_.valid ? 'inherit' : _.theme.palette.secondary.main)};
`
const SeeDetailsRow = styled.div`
  padding-top: ${_ => _.theme.spacing(1)};
  border: 1px solid ${_ => _.theme.palette.divider};
  border-width: 1px 0 0 0;
`

const TimeCell = styled.div`
  p {
    display: inline-block;
    font-weight: bold;
  }
  p:first-of-type {
    font-size: 1.5rem;
    margin-right: ${_ => _.theme.spacing(1)};
  }
`
const IconText = styled.div`
  display: flex;
  align-items: center;
`

const formatTime = (d: string) => moment(d).format('LT')
const formatDate = (d: string) => moment(d).format('MMM DD')

export interface TicketDetailsProps {
  ticket: ITicket
  onClickDetails: () => void
}

const TicketDetails: React.FC<TicketDetailsProps> = ({
  ticket,
  onClickDetails,
}) => {
  const { t } = useTranslation()

  // const isTicketValid = isValid(ticket.parkingExpireDateTime)
  const isTicketValid = ticket.carStatus !== CarStatus.ParkingHasExpired

  return (
    <>
      <InfoBox valid={isTicketValid}>
        <CarRow>
          <IconText>
            <SharedIcon.Car />
            <Typography>{ticket.plate}</Typography>
          </IconText>
          <IconText>
            <SharedIcon.Parking />
            <Typography>{ticket.spot}</Typography>
          </IconText>
        </CarRow>
        <ParkingExpireRow valid={isTicketValid}>
          <Typography>
            {t(
              isTicketValid
                ? Translations.PARKING_EXPIRES_AT
                : Translations.PARKING_EXPIRED_AT
            )}
          </Typography>
          <TimeCell>
            <Typography>{formatTime(ticket.parkingExpireDateTime)}</Typography>
            <Typography>{formatDate(ticket.parkingExpireDateTime)}</Typography>
          </TimeCell>
        </ParkingExpireRow>
        {!ticket.isMonthlyParker && isTicketValid && (
          <SeeDetailsRow>
            <AppButton
              color="primary"
              variant="text"
              onClick={onClickDetails}
              textAlign="center"
            >
              {t(Translations.SEE_DETAILS)}
            </AppButton>
          </SeeDetailsRow>
        )}
      </InfoBox>
      {!ticket.isMonthlyParker && ticket.settlement && isTicketValid && (
        <AppTypography
          weight="bold"
          variant="subtitle1"
          align="center"
          variantMapping={{
            subtitle1: 'p',
          }}
        >
          {t(Translations.TOTAL)}
          {': '}
          {formatCurrency(
            ticket.settlement?.currency,
            ticket.settlement?.finalAmount
          )}
        </AppTypography>
      )}
    </>
  )
}

export default TicketDetails
