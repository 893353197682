import { CreditCard, CardType } from 'typedef/creditCards'

export const cards: CreditCard[] = [
  {
    creditCardId: '21379110-1234-1111-0000-123412341234',
    last4Digits: '2137',
    cardType: CardType.MasterCard,
  },
  {
    creditCardId: '21379110-1234-1111-0000-32112321',
    last4Digits: '3221',
    cardType: CardType.AmericanExpress,
  },
  {
    creditCardId: '32322222-1234-1111-0000-123412341234',
    last4Digits: '1267',
    cardType: CardType.DinersClub,
  },
  {
    creditCardId: '42322222-1234-1111-0000-123412341234',
    last4Digits: '3212',
    cardType: CardType.Invalid,
  },
  {
    creditCardId: '52322222-1234-1111-0000-123412341234',
    last4Digits: '1214',
    cardType: CardType.JCB,
  },
  {
    creditCardId: '52322222-1234-1111-0000-123412341234',
    last4Digits: '2231',
    cardType: CardType.Discover,
  },
  {
    creditCardId: '52322222-1234-1111-0000-123412341234',
    last4Digits: '3333',
    cardType: CardType.Visa,
  },
]
