import * as React from 'react'
import { SharedIcon } from 'shared'
import styled, { css } from 'styled-components'
import { mapSlice } from 'store/slices'
import { useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'

interface CarMarkerProps {
  lat: number
  lng: number
  $hover?: boolean
  lotId: string
}

const PinContainer = styled.div<{
  hovered: boolean
  isSelected: boolean
}>`
  position: absolute;
  transform-origin: center bottom;
  transform: translate(-50%, -61%) scale(0.75);
  z-index: 2;
  transition: all 0.2s;
  ${_ =>
    _.isSelected &&
    css`
      transform: translate(-50%, -68%);
    `}
  ${_ =>
    _.hovered &&
    !_.isSelected &&
    css`
      transform:translate(-50%, -100%); scale(0.85);
    `}
`

const CarMarker: React.FC<CarMarkerProps> = props => {
  const selectedLot = useSelector((state: RootState) =>
    mapSlice.selectById(state, state.map.selectedLot)
  )
  const { findCarMode } = useSelector(mapSlice.stateSelectors)
  if (!findCarMode) return null
  return (
    <PinContainer
      hovered={props.$hover}
      isSelected={selectedLot?.lotId === props.lotId}
    >
      <SharedIcon.PinCar />
    </PinContainer>
  )
}

export default CarMarker
