import { styled, Typography } from '@mui/material'
import styledC, { css } from 'styled-components'
import * as React from 'react'
import { SharedIcon } from 'shared'

const SubTitle = styled(Typography)(_ => ({
  marginBottom: _.theme.spacing(3),
}))
const Title = styled(Typography)(_ => ({
  textTransform: 'uppercase',
  marginBottom: _.theme.spacing(2),
}))
const Logo = styled('div')(_ => ({
  marginBottom: _.theme.spacing(4),
  textAlign: 'center',
}))

const StyledPageHeading = styledC.div<{
  normalizeIcon?: boolean
}>`text-align: center;`
const IconWrapper = styledC.div<{ normalizeIcon?: boolean }>`
  margin-bottom: ${_ => _.theme.spacing(3)};
  ${_ =>
    _.normalizeIcon &&
    css`
      svg {
        max-width: 80px;
        height: auto;
      }
    `}
`

interface PageHeadingProps {
  title?: string
  subtitle?: string
  color?: 'textPrimary' | 'textSecondary'
  hasLogo?: boolean
  normalizeIcon?: boolean
  externalLogoUrl?: string
}

const PageHeading: React.FC<PageHeadingProps> = ({
  subtitle,
  title,
  hasLogo,
  color,
  normalizeIcon,
  children,
  externalLogoUrl,
}) => {
  return (
    <StyledPageHeading>
      {hasLogo && (
        <Logo>
          <SharedIcon.Logo />
        </Logo>
      )}
      {!!externalLogoUrl && (
        <Logo>
          <img style={{ height: 100 }} src={externalLogoUrl} />
        </Logo>
      )}
      {children && (
        <IconWrapper normalizeIcon={normalizeIcon}>{children}</IconWrapper>
      )}
      {title && (
        <Title variant="h1" color={color} gutterBottom>
          {title}
        </Title>
      )}
      {subtitle && <SubTitle color={color}>{subtitle}</SubTitle>}
    </StyledPageHeading>
  )
}
PageHeading.defaultProps = {
  hasLogo: true,
  color: 'textPrimary',
  normalizeIcon: true,
}
export default PageHeading
