import mock from '.'
import { Response } from 'typedef/api'
import { AddCarPayload, GetCarsData, AddCarData } from 'typedef/car'
import { cars } from '__fixtures__/myCars'

// mock.onGet('/CustomerCar').networkError()
mock.onGet('/CustomerCar').reply(config => {
  return [
    200,
    {
      data: { cars },
    } as Response<GetCarsData>,
  ]
})

// mock.onPost('/CustomerCar').networkError()
mock.onPost('/CustomerCar').reply(config => {
  const request = JSON.parse(config.data) as AddCarPayload
  if (!request) return [400]

  return [200, { data: { carId: '0-' + cars.length } } as Response<AddCarData>]
})

mock.onDelete('/CustomerCar').networkError()
// mock.onDelete('/CustomerCar').reply(config => {
// return [200, {} as Response<boolean>]
// })
