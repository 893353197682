import { ThemeProvider } from '@mui/material/styles'
import ResetPassword from 'pages/auth/forgot_password/resetPassword/ResetPassword'
import ForgotPassword from 'pages/auth/forgot_password/sendMail/ForgotPassword'
import Login from 'pages/auth/login/Login'
import SignUp from 'pages/auth/signup/SignUp'
import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import paths from 'routing/paths'
import { whiteTheme } from 'themes'
interface SignedOutRoutesProps {}

const WhiteThemeRoutes = (props: SignedOutRoutesProps) => {
  return (
    <ThemeProvider theme={whiteTheme}>
      <Switch>
        <Route path={paths.auth.login} component={Login} />
        <Route
          path={`${paths.auth.resetPassword}/:token`}
          component={ResetPassword}
        />
        <Route path={paths.auth.signUp} component={SignUp} />
        <Route path={paths.auth.forgotPassword} component={ForgotPassword} />
        <Route path={paths.sms.map} component={ForgotPassword} />
        <Route path={paths.root.root} component={Login} />
        <Redirect to={paths.auth.login} />
      </Switch>
    </ThemeProvider>
  )
}
const SignedOutRoutes = (props: SignedOutRoutesProps) => {
  return (
    <Switch>
      <Route path={paths.root.root} component={WhiteThemeRoutes} />
      <Redirect to={paths.auth.login} />
    </Switch>
  )
}

export default SignedOutRoutes
