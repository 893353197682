import { useHistory } from 'react-router-dom'
import paths from 'routing/paths'
import { UserRole } from 'typedef'

export const usePushToApp = () => {
  const { push } = useHistory()

  const pushToApp = (role = UserRole.User) => {
    push(paths.home.map)
    return
  }

  return pushToApp
}

export default usePushToApp
