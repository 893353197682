import { GetPriceData } from 'typedef/price'
import moment from 'moment'

export const carParkPrices: GetPriceData[] = [
  {
    amount: 1111,
    totalAmount: 1111,
    convenienceFee: 50,
    parkingExpireDateTime: moment().add(25, 'm').toISOString(),
    validations: [],
  },
  {
    amount: 2233,
    totalAmount: 2233,
    convenienceFee: 20,
    parkingExpireDateTime: moment().add(40, 'm').toISOString(),
    convenienceFeeName: 'Test convenience fee name',
    validations: [],
  },
  {
    amount: 3233,
    totalAmount: 3233,
    convenienceFee: 213,
    parkingExpireDateTime: moment().add(55, 'm').toISOString(),
    convenienceFeeName: 'Test3 convenience fee name',
    validations: [],
  },
]
