import { LOTS, LOTS_DETAILS_PARK } from 'api/constants'
import { GetLotDetailsParkData, GetLotsData } from 'typedef/lot'
import { parkLot, getLots } from '__fixtures__/lots'
import mock from '.'

mock.onGet(LOTS).reply(config => {
  return [
    200,
    {
      data: getLots as GetLotsData,
    },
  ]
})
mock.onGet(LOTS_DETAILS_PARK).reply(config => {
  return [
    200,
    {
      data: { ...parkLot, carId: null } as GetLotDetailsParkData,
    },
  ]
})
// mock.onGet(LOTS_DETAILS_PARK).networkErrorOnce()
