import {
  GetLotDetailsMapData,
  GetLotDetailsParkData,
  GetLotsData,
  Lot,
} from 'typedef/lot'
export const lots: Lot[] = [
  {
    customStyles: [],
    lotType: 1,
    lotId: '131361',
    lotName: 'A_Końcowa',
    locationName: 'L_Zawady',
    locationId: 3123,
    geolocation: {
      latitude: 53.1666491,
      longitude: 23.1170682,
    },
  },
]

export const getLots: GetLotsData = {
  lots: [
    {
      customStyles: [],
      locationId: 3123,
      lotType: 1,
      lotId: '145471',
      lotName: 'LOT 51 - Demo',
      locationName: 'PCA - Demo',
      geolocation: {
        latitude: 33.1139571,
        longitude: -96.8024388,
      },
      pCode: 'PCA123',
    },
    {
      customStyles: [],
      locationId: 323,
      lotType: 1,
      lotId: '271011',
      lotName: 'Garage',
      locationName: "Moose's Garage",
      geolocation: {
        latitude: 33.0849878,
        longitude: -96.8033517,
      },
      pCode: 'M3RHVZ',
    },
  ],
  newTokens: {
    role: 1,
    tokens: {
      auth: 'new auth',
      expires: Date.now() + 120000,
      refresh: 'new refresh',
    },
  },
}
export const mapLot: GetLotDetailsMapData = {
  ...lots[0],
  logoLink: 'https://test1apismsvalet.t2p.mobi/api/v1/File/GetFile/156394860',
  address: {
    fullAddress: 'Końcowa 2{NL}Białystok {NL}15-697{NL}Poland',
    street: 'Końcowa 2',
    country: 'Poland',
    postalCode: '15-697',
    city: 'Białystok',
    state: '',
  },
  currentDateTimeLocal: 'date',
  pCode: 'true',
  geolocation: {
    latitude: 53.1666491,
    longitude: 23.1170682,
  },
  isLocationStopped: false,
  locationStoppedMessage: '',
  lotCapacity: {
    capacityFullSMSMessage: 'test SMS message',
    capacityFullWEBMessage: 'test WEB message',
    lotIsFull: false,
  },
  openingHours: [
    {
      dayOfWeek: 0,
      openingHoursInDay: [
        {
          startTime: '00:00:00',
          endTime: '23:59:59',
        },
      ],
    },
    {
      dayOfWeek: 1,
      openingHoursInDay: [
        {
          startTime: '00:00:00',
          endTime: '23:59:59',
        },
      ],
    },
    {
      dayOfWeek: 2,
      openingHoursInDay: [
        {
          startTime: '00:00:00',
          endTime: '23:59:59',
        },
      ],
    },
    {
      dayOfWeek: 3,
      openingHoursInDay: [
        {
          startTime: '00:00:00',
          endTime: '23:59:59',
        },
      ],
    },
    {
      dayOfWeek: 4,
      openingHoursInDay: [
        {
          startTime: '00:00:00',
          endTime: '23:59:59',
        },
      ],
    },
    {
      dayOfWeek: 5,
      openingHoursInDay: [
        {
          startTime: '00:00:00',
          endTime: '23:59:59',
        },
      ],
    },
    {
      dayOfWeek: 6,
      openingHoursInDay: [
        {
          startTime: '00:00:00',
          endTime: '23:59:59',
        },
      ],
    },
  ],
}

export const lotMIX: GetLotDetailsParkData = {
  parkerId: '40e748fd-400d-46ef-98b9-08d83fb8f424',
  lotType: 1,
  lotId: '131361',
  locationId: 12312,
  externalPricingType: 0,
  customStyles: [],
  isExternalPricingEnabled: false,
  lotName: 'A_Końcowa',
  locationName: 'L_Zawady',
  logoLink: 'https://test1apismsvalet.t2p.mobi/api/v1/File/GetFile/156394860',
  monthlyEnable: true,
  currentDateTimeLocal: '2020-08-13T23:59:00',
  isLocationStopped: false,
  isUpfrontCouponAvailable: false,
  locationStoppedMessage: '',
  lotCapacity: {
    capacityFullSMSMessage: 'test SMS message',
    capacityFullWEBMessage: 'test WEB message',
    lotIsFull: false,
  },
  currency: {
    cultureName: null,
    currencyFormat: 1,
    currencySymbol: '$',
  },
  spot: {
    isEnabled: true,
    minSpotNumber: 1,
    maxSpotNumber: 1000,
  },
  timeSelectOption: [
    {
      pricingType: 1,
      optionType: 0,
      showAllDayUntil: true,
      allDayUntilMessage: '',
      endDateTime: '2020-08-13T23:59:00',
      optionLabel: null,
      amount: null,
      convenienceFee: null,
      optionId: null,
      intervalOptions: {
        showDaySelect: false,
        showHourSelect: true,
        showMinuteSelect: true,
        maxDays: 14,
        maxHours: 48,
        maxMinutes: 59,
        dayInterval: 1,
        hourInterval: 1,
        minuteInterval: 3,
      },
    },
  ],
}
export const parkLot: GetLotDetailsParkData = {
  ...lots[0],
  newTokens: {
    tokens: {
      auth: 'auth token',
      refresh: 'refresh token',
      expires: Date.now() + 120000,
    },
    role: 2,
  },
  parkerId: '5479dcb8-7681-4352-29d9-08d83ef0fb95',
  carId: '123456',
  logoLink: 'https://test1apismsvalet.t2p.mobi/api/v1/File/GetFile/156394860',
  currentDateTimeLocal: '2020-08-13T23:59:00',
  monthlyEnable: true,
  externalPricingType: 0,
  isExternalPricingEnabled: false,
  isUpfrontCouponAvailable: false,
  isLocationStopped: false,
  locationStoppedMessage: '',
  lotCapacity: {
    capacityFullSMSMessage: 'test SMS message',
    capacityFullWEBMessage: 'test WEB message',
    lotIsFull: false,
  },
  currency: {
    cultureName: null,
    currencyFormat: 1,
    currencySymbol: '$',
  },
  spot: {
    isEnabled: true,
    minSpotNumber: 1,
    maxSpotNumber: 1000,
  },
  timeSelectOption: [
    //MULTI
    {
      pricingType: 3,
      optionType: 4,
      showAllDayUntil: false,
      allDayUntilMessage: null,
      endDateTime: null,
      optionLabel: 'Parking until 03/19/2020 / 12:00 AM',
      amount: 200,
      convenienceFee: 5,
      optionId: '915f9f00-4edd-ea11-8b03-000d3a12cd78',
      intervalOptions: null,
    },
    {
      pricingType: 3,
      optionType: 4,
      showAllDayUntil: false,
      allDayUntilMessage: null,
      endDateTime: null,
      optionLabel: 'Parking: # 3',
      amount: 3,
      convenienceFee: 5,
      optionId: '11c9bdfb-8edd-ea11-8b03-000d3a12cd78',
      intervalOptions: null,
    },
    {
      pricingType: 3,
      optionType: 3,
      showAllDayUntil: false,
      allDayUntilMessage: null,
      endDateTime: null,
      optionLabel: 'Select your parking time',
      amount: null,
      convenienceFee: null,
      optionId: 'c59022a8-2068-ea11-a94c-000d3a705ce6',
      intervalOptions: {
        showDaySelect: true,
        showHourSelect: true,
        showMinuteSelect: true,
        maxDays: 14,
        maxHours: 23,
        maxMinutes: 59,
        dayInterval: 1,
        hourInterval: 1,
        minuteInterval: 3,
      },
    },
    {
      pricingType: 3,
      optionType: 4,
      showAllDayUntil: false,
      allDayUntilMessage: null,
      endDateTime: '2020-08-14T00:00:00',
      optionLabel: 'until 08/14 12:00 AM',
      amount: 400,
      convenienceFee: 5,
      optionId: '14836f1b-4edd-ea11-8b03-000d3a12cd78',
      intervalOptions: null,
    },
  ],
}
