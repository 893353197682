import { Box, Divider, FormControlLabel, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, AppPopup, AppTypography, FlexBox } from 'shared'
import { Currency } from 'typedef/ticket'
import { Translations } from 'utils'
import { TermsButton } from './TermsButton'
import TotalText from './TotalText'
import { useSelector } from 'react-redux'
import { customerSlice } from 'store/slices'
import { LoginInfoSeparator } from 'pages/my_receipts/receiptsAndNotifications/styles'
import { TermsOfServiceCheckbox } from 'shared/TermOfServiceCheckbox'
import { useAppDispatch } from 'store/store'
import { termsOfServiceSlice } from 'store/slices'
import { getLanguageEnum } from 'utils/language'
import { UserLanguage } from 'typedef'
export interface PaySectionProps {
  disabled: boolean
  amount?: number
  totalAmount?: number
  fee?: number
  onlyButton?: boolean
  onNextClick: () => void
  isUpfrontCouponAvailable?: boolean
  onValidateClick?: () => void
  currency?: Currency
  showNeedHelp?: React.Dispatch<React.SetStateAction<boolean>>
  isTosChecked: boolean
  setIsTosChecked: React.Dispatch<React.SetStateAction<boolean>>
}

export const PaySection: React.FC<PaySectionProps> = props => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { termsOfService, termsOfServiceContent } = useSelector(
    termsOfServiceSlice.stateSelectors
  )
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { customerSignedInAsGuest } = useSelector(
    customerSlice.selectCustomerStatus
  )
  const language: UserLanguage = getLanguageEnum()

  useEffect(() => {
    dispatch(
      termsOfServiceSlice.showForCustomer({
        language: language,
      })
    )
  }, [dispatch, language])

  const nextButtonClick = async () => {
    if (!customerSignedInAsGuest && termsOfService.show) {
      const promise = await dispatch(
        termsOfServiceSlice.accept({
          termsAndConditionsId: termsOfService.termsAndConditionsID,
          clientDateTime: new Date(),
        })
      )
      if (termsOfServiceSlice.accept.fulfilled.match(promise)) {
        props.onNextClick()
      }
    } else {
      props.onNextClick()
    }
  }

  const openTermsOfServicePopup = async () => {
    const promise = await dispatch(
      termsOfServiceSlice.getTermsOfServiceContent({
        id: termsOfService.termsAndConditionsID,
        language: language,
      })
    )
    if (termsOfServiceSlice.getTermsOfServiceContent.fulfilled.match(promise)) {
      setIsOpen(true)
    }
  }

  const label = (
    <>
      <Grid
        container
        item
        direction="row"
        style={{ marginTop: '7px' }}
        justifyContent="center"
      >
        <LoginInfoSeparator>
          {t(Translations.READ_AND_AGREE)}
        </LoginInfoSeparator>
        <AppButton
          style={{ paddingTop: 0, paddingLeft: 4 }}
          minWidth="0"
          type="button"
          letterCase="none"
          hasHoverBg={false}
          color="primary"
          onClick={() => openTermsOfServicePopup()}
          data-testid="tos-button"
        >
          {t(Translations.TERMS_OF_SERVICE)}
        </AppButton>
      </Grid>
    </>
  )

  return (
    <>
      <FlexBox>
        {!props.onlyButton && (
          <TotalText
            disabled={props.disabled}
            currency={props.currency}
            amount={props.totalAmount}
          />
        )}
        {props.children}
        {termsOfService.show && (
          <Box display="flex">
            <FormControlLabel
              control={
                <TermsOfServiceCheckbox
                  value={props.isTosChecked}
                  onChange={() => {
                    props.setIsTosChecked(!props.isTosChecked)
                  }}
                />
              }
              style={{ marginRight: '0px' }}
              label={label}
            />
          </Box>
        )}
        <Box display="flex" justifyContent="center" gap="18px">
          {!!props.isUpfrontCouponAvailable && !!props.totalAmount && (
            <AppButton
              minWidth="130px"
              variant="outlined"
              color="primary"
              type="button"
              onClick={props.onValidateClick}
              data-testid="validateButton"
            >
              {t(Translations.VALIDATE)}
            </AppButton>
          )}

          <AppButton
            minWidth="130px"
            variant="contained"
            color="primary"
            type="button"
            disabled={
              props.disabled || (termsOfService.show && !props.isTosChecked)
            }
            onClick={nextButtonClick}
            data-testid="submitButton"
          >
            {props.totalAmount || customerSignedInAsGuest
              ? t(Translations.NEXT)
              : t(Translations.PARK)}
          </AppButton>
        </Box>
        <Box display="flex" justifyContent="center">
          <TermsButton />
          <Divider flexItem orientation="vertical" variant="middle" />
          <AppButton
            minWidth="110px"
            type="button"
            letterCase="none"
            hasHoverBg={false}
            color="primary"
            onClick={() => props.showNeedHelp(true)}
            data-testid="helpButton"
          >
            {t(Translations.NEED_HELP)}
          </AppButton>
        </Box>
      </FlexBox>

      <AppPopup
        open={isOpen}
        closePopup={() => {
          setIsOpen(false)
        }}
        variant="info"
        fullWidth={false}
        title={t(Translations.TERMS_OF_SERVICE)}
        addCloseButton={true}
        darkBackground={true}
      >
        <FlexBox>
          <AppTypography
            dangerouslySetInnerHTML={{ __html: termsOfServiceContent }}
          ></AppTypography>
          <AppButton
            color="secondary"
            variant="contained"
            onClick={() => setIsOpen(false)}
          >
            {t(Translations.OK)}
          </AppButton>
        </FlexBox>
      </AppPopup>
    </>
  )
}
PaySection.defaultProps = {
  fee: 0,
  amount: 0,
  onNextClick: () => undefined,
  onValidateClick: () => undefined,
}
export default PaySection
