import { List } from '@mui/material'
import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FullLayout, PageDialog, Topbar, WhiteBoxContainer } from 'shared'
import { TimeSelectOption } from 'typedef/lot'
import { Translations } from 'utils'
import SelectPriceItem from './SelectPriceItem'
import { Currency } from 'typedef'

export interface TimeSelectOptionDialogProps {
  timeSelectOptions: TimeSelectOption[]
  currency?: Currency
  open: boolean
  closeDialog: () => void
  onClick: (e: TimeSelectOption) => void
}

export const TimeSelectOptionDialog: React.FC<
  TimeSelectOptionDialogProps
> = props => {
  const { t } = useTranslation()
  const onClickItem = (arg: TimeSelectOption) => {
    props.onClick(arg)
    props.closeDialog()
  }

  return (
    <PageDialog
      open={props.open}
      onBackdropClick={props.closeDialog}
      fullScreen
    >
      <FullLayout hasTopBar hasFlatBackground innerScroll>
        <Topbar
          title={t(Translations.PRICING_LIST)}
          onClickBack={props.closeDialog}
        />
        <WhiteBoxContainer>
          <List dense={true}>
            {props.timeSelectOptions.map(el => (
              <SelectPriceItem
                amount={el.amount}
                label={el.optionLabel}
                onClick={() => onClickItem(el)}
                key={nanoid()}
                currency={props.currency}
              />
            ))}
          </List>
        </WhiteBoxContainer>
      </FullLayout>
    </PageDialog>
  )
}

export default TimeSelectOptionDialog
