import { Actions } from './actions'
import { SHOW_POPUP, CLOSE_POPUP, SHOW_TERMS, CLOSE_TERMS } from './constants'
import { Popup } from '../../typedef/popup'

export type PopupState = {
  popup: Popup
  show: boolean
  showTerms: boolean
}

const initialState: PopupState = {
  popup: {
    title: '',
    description: '',
    variant: 'info',
    handleAdditionalButtonClick: () => undefined,
  },
  show: false,
  showTerms: false,
}

export const popupReducer = (
  state = initialState,
  action: Actions
): PopupState => {
  switch (action.type) {
    case SHOW_POPUP:
      return {
        ...state,
        show: true,
        popup: action.popup,
      }
    case CLOSE_POPUP:
      return {
        ...state,
        show: false,
      }
    case SHOW_TERMS:
      return {
        ...state,
        showTerms: true,
      }
    case CLOSE_TERMS:
      return {
        ...state,
        showTerms: false,
      }
    default:
      return state
  }
}
