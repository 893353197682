import { useChangeLayout } from 'hooks'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { ConditionalRoute } from 'routing'
import paths from 'routing/paths'
import { AppButton, FlexBox, PaddingContainer } from 'shared'
import PageDialog from 'shared/PageDialog'
import { showTerms } from 'store/globalPopup/actions'
import { termsSlice } from 'store/slices'
import { actions } from 'store/slices/customerSlice'
import { useAppDispatch } from 'store/store'
import { UserRole } from 'typedef'
import { TermsType } from 'typedef/terms'
import Translations from '../../../utils/translations'
import CodeStep from './CodeStep'
import PersonalDataStep from './PersonalDataStep'
import RegistrationComplete from './RegistrationComplete'
import SetPasswordStep from './SetPasswordStep'
import { ThemeProvider } from '@mui/material/styles'
import { whiteTheme } from 'themes'
import { PersonalData } from './SignUp'

export const SignUpRedirect = () => {
  const { t } = useTranslation()
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [completeStep, setCompleteStep] = React.useState(0)
  const [personalData, setPersonalData] = React.useState<PersonalData>({
    firstName: '',
    lastName: '',
    code: '',
    phoneNumber: '',
    email: '',
  })
  const [confirmToken, setConfirmToken] = React.useState('')
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const { carId } = useParams<{ carId: string }>()
  useChangeLayout()

  React.useEffect(() => {
    if (completeStep === 1) {
      push(`${paths.parking.signUp}/${carId}/set-password`)
    }
    if (completeStep === 2) {
      push(`${paths.parking.signUp}/${carId}/code`)
    }
  }, [completeStep, push, carId])

  const submitPassword = (token: string) => {
    setConfirmToken(token)
    setCompleteStep(2)
  }
  const submitCode = () => {
    setShowSuccess(true)
  }

  return (
    <>
      <ThemeProvider theme={whiteTheme}>
        <PaddingContainer>
          <Switch>
            <Route exact path={`${paths.parking.signUp}/:carId`}>
              <>
                <PersonalDataStep
                  personalData={personalData}
                  setPersonalData={setPersonalData}
                  resetStep={() => setCompleteStep(0)}
                  handleSubmit={() => setCompleteStep(1)}
                  backToLogin={false}
                />
                <FlexBox hasTopMargin>
                  <AppButton
                    letterCase="none"
                    hasHoverBg={false}
                    onClick={() => {
                      dispatch(
                        termsSlice.getTerms({
                          type: TermsType.Login,
                        })
                      )
                      dispatch(showTerms())
                    }}
                  >
                    {t(Translations.TERMS_AND_CONDITIONS)}
                  </AppButton>
                </FlexBox>
              </>
            </Route>
            <ConditionalRoute
              path={`${paths.parking.signUp}/:carId/set-password`}
              redirectTo={`${paths.parking.signUp}/${carId}`}
              shouldRedirect={completeStep !== 1}
            >
              <SetPasswordStep
                handleSubmit={submitPassword}
                personalData={personalData}
                goBack={() => push(`${paths.parking.signUp}/${carId}`)}
              />
            </ConditionalRoute>
            <ConditionalRoute
              path={`${paths.parking.signUp}/:carId/code`}
              redirectTo={`${paths.parking.signUp}/${carId}`}
              shouldRedirect={completeStep !== 2}
            >
              <CodeStep confirmToken={confirmToken} onSubmit={submitCode} />
            </ConditionalRoute>
          </Switch>
        </PaddingContainer>
        <PageDialog open={showSuccess}>
          <RegistrationComplete
            onClick={() => {
              dispatch(actions.setRole(UserRole.User))
              push(`${paths.parking.login}/${carId}`)
            }}
          />
        </PageDialog>
      </ThemeProvider>
    </>
  )
}
