import { unwrapResult } from '@reduxjs/toolkit'
import { usePopup } from 'shared/popup_provider/AppPopupProvider'
import { contactInformationsSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { VerificationType } from 'typedef'
import { ReceiptsAndNotificationsFormValues } from './types'
import { hideLoader, showLoader } from 'store/loader'

export const useSendVerificationSMS = (
  setShowCodeStepPopup: React.Dispatch<React.SetStateAction<boolean>>,
  setConfirmToken: React.Dispatch<React.SetStateAction<string>>,
  carId: string,
  recaptchaToken: string
) => {
  const { setPopup, showPopup } = usePopup()
  const dispatch = useAppDispatch()

  const handleSubmitWithPhoneNumber = async (
    values: ReceiptsAndNotificationsFormValues
  ) => {
    dispatch(showLoader())
    const promise = await dispatch(
      contactInformationsSlice.sendVerificationSMS({
        phoneNumber: values.phoneNumber,
        countryCode: parseInt(values.code),
        tokenValuePart: carId,
        verificationType: VerificationType.SavingGuestsPhoneNumber,
        recaptchaToken: recaptchaToken
      })
    )
    dispatch(hideLoader())
    if (contactInformationsSlice.sendVerificationSMS.fulfilled.match(promise)) {
      const res = unwrapResult(promise)
      setShowCodeStepPopup(true), setConfirmToken(res.confirmToken)
    } else {
      setPopup({
        ...promise.payload,
      })
      showPopup(true)
    }
  }

  return handleSubmitWithPhoneNumber
}
