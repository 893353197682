import { useFormikContext } from 'formik'
import moment from 'moment'
import { T2Fields } from 'pages/park_car/ParkCarT2'
import React from 'react'
import { AppSelectListField, FlexBox } from 'shared'
export interface SelectCustomTimeProps {
  disabled?: boolean
}

export const SelectCustomTime: React.FC<SelectCustomTimeProps> = props => {
  const { values, handleChange, setFieldValue } = useFormikContext<T2Fields>()
  const { day, hour, minute, startDate } = values

  React.useEffect(() => {
    let val: string
    if (day || hour || minute) {
      val = moment(startDate)
        .add(day, 'days')
        .add(hour, 'hours')
        .add(minute, 'minute')
        .toString()
    } else {
      val = null
    }
    setFieldValue('parkForCustomTimeDate', val)
  }, [day, hour, minute, setFieldValue, startDate])

  return (
    <FlexBox horizontal horizontalGap={0.5} horizontalFullWith>
      <AppSelectListField
        value={day}
        name="day"
        onChange={handleChange}
        disabled={props.disabled}
        interval={1}
        max={7}
        sufix="D"
        $innerSufix
      />
      <AppSelectListField
        value={hour}
        name="hour"
        onChange={handleChange}
        disabled={props.disabled}
        interval={1}
        max={24}
        sufix="H"
        $innerSufix
      />
      <AppSelectListField
        value={minute}
        name="minute"
        onChange={handleChange}
        disabled={props.disabled}
        interval={5}
        max={55}
        sufix="M"
        $innerSufix
      />
    </FlexBox>
  )
}

export default SelectCustomTime
