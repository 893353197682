import React from 'react'
import { useTranslation } from 'react-i18next'
import Translations from 'utils/translations'
import AppAlert from '../AppAlert'
import { useCounter } from './useCounter'

export interface CounterProps {
  title: keyof typeof Translations
  onEnd?: () => void
  endTime: string
}

export const Counter: React.FC<CounterProps> = props => {
  const { t } = useTranslation()
  const timeString = useCounter(props.endTime, props.onEnd)

  return (
    <AppAlert gutterTop={0}>
      {`${t(Translations[props.title])}:  ${timeString}`}
    </AppAlert>
  )
}
Counter.defaultProps = {
  onEnd: () => undefined,
}
export default Counter
