import { Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, FlexBox } from 'shared'
import { FindCarPayload } from 'typedef/customer'
import * as Yup from 'yup'
import { Input } from '../../shared/Input'
import { licencePlateRegex } from '../../utils/regex'
import Translations from '../../utils/translations'

interface FindCarsProps {
  findCar: (args: FindCarPayload) => void
  disabled?: boolean
}

export const FindCarsForm: React.FunctionComponent<FindCarsProps> = props => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const FindCarSchema = Yup.object().shape({
    plate: Yup.string()
      .required(t(Translations.INVALID_LICENCE_PLATE))
      .matches(licencePlateRegex, t(Translations.INVALID_LICENCE_PLATE)),
    ccLast4Digits: Yup.number()
      .typeError(t(Translations.INVALID_CARD_NUMBER))
      .required(t(Translations.INVALID_CARD_NUMBER))
      .test('len', t(Translations.INVALID_CARD_NUMBER), val => {
        return val?.toString().length === 4
      }),
  })

  return (
    <Formik
      initialValues={{ plate: '', ccLast4Digits: '' }}
      onSubmit={values => {
        props.findCar({
          plate: values.plate.replace(/\s/g, ''),
          ccLast4Digits: values.ccLast4Digits,
        })
      }}
      validationSchema={FindCarSchema}
      validateOnMount={false}
      validateOnChange={false}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        validateForm,
        submitForm,
      }) => (
        <Form>
          {/* <InformationText text={t(Translations.FIND_CARS_INFO)} /> */}
          <Input
            name="plate"
            placeholder={t(Translations.LICENCE_PLATE)}
            value={values.plate}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={12}
            error={!!errors.plate}
          ></Input>
          <Input
            name="ccLast4Digits"
            placeholder={t(Translations.LAST_4_CREDIT_CARD_DIGITS)}
            value={values.ccLast4Digits}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.ccLast4Digits}
            maxLength={4}
          ></Input>
          <FlexBox hasTopMargin>
            <AppButton
              variant="contained"
              color="primary"
              onClick={() =>
                validateForm().then(errors => {
                  const entries = Object.entries(errors)
                  if (entries.length === 0) {
                    submitForm()
                  } else {
                    entries.forEach(el => {
                      enqueueSnackbar(el[1])
                    })
                  }
                })
              }
              disabled={props.disabled}
            >
              {t(Translations.FIND_CAR)}
            </AppButton>
          </FlexBox>
        </Form>
      )}
    </Formik>
  )
}

export default FindCarsForm
