import { ThemeProvider } from '@mui/material/styles'
import { unwrapResult } from '@reduxjs/toolkit'
import { batch, useSelector } from 'react-redux'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import paths from 'routing/paths'
import { customerSlice, layoutSlice, mapSlice } from 'store/slices'
import { useAppDispatch } from 'store/store'
import { login, refreshToken } from 'store/slices/customerSlice'
import { UserRole } from 'typedef'
import { useTokenValidation } from 'hooks'
import { AppSpinner, PaddingContainer, PageHeading } from 'shared'
import { whiteTheme } from 'themes'
import { useTranslation } from 'react-i18next'
import { Translations } from 'utils'

export interface PcodeSignInProps {}

export const PcodeSignIn: React.FC<PcodeSignInProps> = props => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const { myPosition } = useSelector(mapSlice.stateSelectors)
  const { setLoader } = layoutSlice.actions
  const { pcode } = useParams<{ pcode: string }>()

  const validToken = useTokenValidation()
  const trySignIn = async () => {
    //Check if token exist and if its valid
    if (validToken) {
      //Refresh token
      dispatch(setLoader(true))
      const promise = await dispatch(
        refreshToken({ token: validToken.refresh })
      )
      if (refreshToken.fulfilled.match(promise)) {
        //If user is already logged as guest, session needs to be destroyed and
        //he will be logged as a new guest
        const res = unwrapResult(promise)
        if (res.role !== UserRole.User) {
          await dispatch(customerSlice.actions.destroySession())
          await dispatch(login({ mode: UserRole.Guest }))
        }
      }
      dispatch(setLoader(false))
    } else {
      //If token is not valid or does not exist user will be logged
      //automatically as a guest
      await dispatch(login({ mode: UserRole.Guest }))
    }
  }

  const redirectFromQrCode = async () => {
    await trySignIn()
    await getLotDetailsFromPcode()
  }

  const getLotDetailsFromPcode = async () => {
    const promise = await dispatch(mapSlice.getLotsByPcode({ pcode }))
    if (mapSlice.getLotsByPcode.fulfilled.match(promise)) {
      const { lots } = unwrapResult(promise)
      if (lots.length === 1) {
        const lotId = lots[0].lotId
        const landingPageUrl = lots[0].landingPageUrl
        if (landingPageUrl) {
          window.location.href = landingPageUrl
        } else push(`${paths.parking.parkCar}/${lotId}`)
      } else if (lots.length > 1) {
        if (myPosition) {
          batch(() => {
            dispatch(customerSlice.actions.setCorporationGlobalPcode(pcode))
            dispatch(mapSlice.actions.focusMapOnMyLocation())
            dispatch(mapSlice.actions.setZoom(12))
          })
          push(paths.home.map)
        } else {
          batch(() => {
            dispatch(customerSlice.actions.setCorporationGlobalPcode(pcode))
            dispatch(mapSlice.getLotDetailsMap({ lotId: lots[0].lotId }))
            dispatch(
              mapSlice.actions.selectLot({
                lotId: lots[0].lotId,
                position: true,
              })
            )
            dispatch(mapSlice.actions.setZoom(1))
          })
          push(paths.home.map)
        }
      } else {
        push(paths.root.root)
      }
    } else {
      push(paths.root.root)
    }
  }

  React.useEffect(() => {
    redirectFromQrCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={whiteTheme}>
      <PaddingContainer>
        <PageHeading title={t(Translations.PARKING_MADE_EASY)} />
        <AppSpinner show relative height="150px" />
      </PaddingContainer>
    </ThemeProvider>
  )
}

export default PcodeSignIn
