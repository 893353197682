import React from 'react'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import { Placeholder, SharedIcon } from 'shared'
import Translations from 'utils/translations'

export interface PaymentSuccessfulProps {
  show: boolean
}

export const PaymentSuccessful: React.FC<PaymentSuccessfulProps> = props => {
  const { t } = useTranslation()
  return (
    <CSSTransition
      unmountOnExit
      in={props.show}
      timeout={500}
      classNames="item"
    >
      <Placeholder
        icon={<SharedIcon.SuccessTick />}
        title={t(Translations.PAYMENT_SUCCESSFUL)}
        minHeight={120}
      />
    </CSSTransition>
  )
}

export default PaymentSuccessful
