import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { termsApiError } from 'api/apiErrors'
import * as termsApi from 'api/TermsAndConditionsApi'
import { getErrorCode, getErrorMessage } from 'api/utils'
import i18n from 'i18n'
import { RootState } from 'store/rootReducer'
import { FETCH, initialPromiseStatus, TERMS } from 'store/slices/constants'
import * as sliceUtil from 'store/slices/slice.utils'
import { ErrorData, FetchStatus, GetTermsAndConditionsParams } from 'typedef'
import { Terms, TermsAndConditionState } from './termsSlice.types'

/**
 * state
 */
export const initialPromiseStatuses = {
  getTerms: initialPromiseStatus,
}
export const initialState: TermsAndConditionState = {
  terms: {},
  lang: null,
  logoLink: '',
  promisesStatus: initialPromiseStatuses,
}

/**
 * thunks
 */

export const getTerms = createAsyncThunk<
  Terms,
  GetTermsAndConditionsParams,
  { rejectValue: ErrorData }
>(
  `${TERMS}${FETCH}/getTerms`,
  async (arg, thunkApi) => {
    try {
      thunkApi.dispatch(termsSlice.actions.setLang({ lang: i18n.language }))
      const response = await termsApi.getTerms({
        ...arg,
        languageCode: i18n.language,
      })
      return {
        languageCode: i18n.language,
        lotId: arg.lotId,
        type: arg.type,
        text: response.text,
      }
    } catch (error) {
      const errorMessage = getErrorMessage(
        getErrorCode(error),
        termsApiError.getTerms
      )
      return thunkApi.rejectWithValue(errorMessage)
    }
  },
  {
    condition: (arg, api) => {
      const { terms } = api.getState() as RootState
      const { lotId, text, type } = terms.terms
      if (
        // eslint-disable-next-line
        lotId == arg.lotId &&
        // eslint-disable-next-line
        type == arg.type &&
        text
      ) {
        return false
      }
    },
  }
)

const termsSlice = createSlice({
  name: TERMS,
  initialState,
  reducers: {
    setTerms(state, { payload }: PayloadAction<Terms>) {
      state.terms = payload
    },
    setLang(state, { payload }: PayloadAction<{ lang: string }>) {
      state.lang = payload.lang
    },
    setLogoLink(state, { payload }: PayloadAction<string>) {
      state.logoLink = payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTerms.fulfilled, (state, action) => {
        termsSlice.caseReducers.setTerms(state, action)
        state.promisesStatus.getTerms = {
          ...initialPromiseStatus,
          status: [FetchStatus.Fulfilled],
        }
      })
      .addCase(getTerms.pending, ({ promisesStatus }) => {
        promisesStatus.getTerms.status = promisesStatus.getTerms.status.concat(
          FetchStatus.Pending
        )
      })
      .addCase(getTerms.rejected, (state, action) => {
        state.promisesStatus.getTerms = {
          ...action.payload,
          status: [FetchStatus.Rejected],
        }
      })
  },
})

export default termsSlice.reducer
export const { actions } = termsSlice

export const stateSelectors = (state: RootState) => state.terms
export const promiseStatusSelectors = (state: RootState) => {
  const promises = stateSelectors(state).promisesStatus
  return {
    getTermstatus: sliceUtil.fetchStatus(promises.getTerms),
  }
}
