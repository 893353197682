import T from 'utils/translations'
import { ErrorCode, ErrorResponse } from 'typedef'

// TODO:
export const getCars: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.CANNOT_FETCH_CAR_LIST,
    variant: 'warning',
  },
}
export const AddCar: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.YOUR_CAR_CANNOT_BE_ADDED,
    variant: 'warning',
  },
  [ErrorCode.ObjectExist]: {
    description: T.THE_LICENCE_PLATE_IS_ALREADY_IN_USE,
  },
}
export const deleteCar: ErrorResponse = {
  [ErrorCode.OtherError]: {
    title: T.OOPS,
    description: T.YOUR_CAR_CANNOT_BE_DELETED_AT_THE_MOMENT,
    variant: 'warning',
  },
}

export const CarsApiError = { getCars, AddCar, deleteCar }
export default CarsApiError
