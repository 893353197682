import {
  ChooseCard,
  ParkingDetails,
} from 'pages/park_car_credit_card/CreditCardComponents'
import React from 'react'
import { batch, useSelector } from 'react-redux'
import { AppSpinner, ErrorPlaceholder, WhiteBoxContainer } from 'shared'
import { RootState } from 'store/rootReducer'
import { creditCardsSlice, parkCarSlice, ticketsSlice } from 'store/slices'
import { AppState, useAppDispatch } from 'store/store'

export interface SelectCardGuestWrapperProps {
  paymentPath: string
  manageCardPath: string
  addCardPath: string
  ticketId: string
}

export const SelectCardGuestWrapper: React.FC<SelectCardGuestWrapperProps> = props => {
  const [initSlide, setInitSlide] = React.useState(0)
  const mounted = React.useRef(false)
  const dispatch = useAppDispatch()
  const ticket = useSelector((state: RootState) =>
    ticketsSlice.selectById(state, props.ticketId)
  )
  const { getTicketStatus } = useSelector((state: AppState) =>
    ticketsSlice.promiseStatusSelectors(state, props.ticketId)
  )

  const cards = useSelector(creditCardsSlice.selectAll)
  const selectedCard = useSelector(parkCarSlice.selectCardId)

  const fetchTicket = () =>
    dispatch(ticketsSlice.getTicket({ carId: props.ticketId }))

  const fetchData = () =>
    batch(() => {
      if (!getTicketStatus.fulfilled) fetchTicket()
    })

  React.useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  React.useLayoutEffect(() => {
    if (selectedCard && cards.length) {
      const selectedCardIndex = cards.findIndex(
        el => el.creditCardId === selectedCard?.creditCardId
      )
      if (selectedCardIndex !== -1) {
        setInitSlide(selectedCardIndex)
      }
    }

    // for autoselect and focus first card
    mounted.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const showElement = () => {
    const error = getTicketStatus.pendingRejected || getTicketStatus.rejected
    const spinner =
      getTicketStatus.pendingIdle || getTicketStatus.pendingRejected
    const chooseCard = mounted.current
    return { error, spinner, chooseCard }
  }

  return (
    <>
      <WhiteBoxContainer minHeight={150}>
        <AppSpinner show={showElement().spinner} />
        {showElement().error && <ErrorPlaceholder onClick={fetchData} />}
        <ParkingDetails />
      </WhiteBoxContainer>
      {showElement().chooseCard && (
        <ChooseCard
          paymentPath={props.paymentPath}
          manageCardPath={props.manageCardPath}
          addCardPath={props.addCardPath}
          ticket={ticket}
          cards={cards}
          initSlide={initSlide}
        />
      )}
    </>
  )
}

export default SelectCardGuestWrapper
