import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit'
import {
  AcceptToSParams,
  ErrorData,
  GetTermsOfServiceContentParams,
  ShowForCustomerParams,
  ShowForCustomerResult,
  TermsOfService,
} from 'typedef'
import { FETCH, TERMS_OF_SERVICE, initialPromiseStatus } from './constants'
import * as termsApi from 'api/TermsAndConditionsApi'
import { getErrorCode, getErrorMessage } from 'api/utils'
import { termsApiError } from 'api'
import { TermsOfServiceState } from './termsOfServiceSlice.types'
import { sliceUtil } from '.'
import { RootState } from 'store/rootReducer'

const adapter = createEntityAdapter<TermsOfService>({
  selectId: tos => tos.termsAndConditionsId,
})
export const initialPromiseStatuses: TermsOfServiceState['promisesStatus'] = {
  showForCustomer: initialPromiseStatus,
  accept: initialPromiseStatus,
  getTermsOfServiceContent: initialPromiseStatus,
}
const initialState = adapter.getInitialState<TermsOfServiceState>({
  promisesStatus: initialPromiseStatuses,
  termsOfService: {
    termsAndConditionsID: '',
    show: false,
  },
  termsOfServiceContent: '',
})

export const showForCustomer = createAsyncThunk<
  ShowForCustomerResult,
  ShowForCustomerParams,
  { rejectValue: ErrorData }
>(`${TERMS_OF_SERVICE}${FETCH}/showForCustomer`, async (arg, thunkApi) => {
  try {
    const response = await termsApi.showForCustomer(arg)
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      termsApiError.getTerms
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const accept = createAsyncThunk<
  boolean,
  AcceptToSParams,
  { rejectValue: ErrorData }
>(`${TERMS_OF_SERVICE}${FETCH}/accept`, async (arg, thunkApi) => {
  try {
    const response = await termsApi.accept(arg)
    return response
  } catch (error) {
    const errorMessage = getErrorMessage(
      getErrorCode(error),
      termsApiError.getTerms
    )
    return thunkApi.rejectWithValue(errorMessage)
  }
})

export const getTermsOfServiceContent = createAsyncThunk<
  string,
  GetTermsOfServiceContentParams,
  { rejectValue: ErrorData }
>(
  `${TERMS_OF_SERVICE}${FETCH}/getTermsOfServiceContent`,
  async (arg, thunkApi) => {
    try {
      const response = await termsApi.getTermsOfServiceContent(arg)
      return response
    } catch (error) {
      const errorMessage = getErrorMessage(
        getErrorCode(error),
        termsApiError.getTerms
      )
      return thunkApi.rejectWithValue(errorMessage)
    }
  }
)

const termsOfServiceSlice = createSlice({
  name: TERMS_OF_SERVICE,
  initialState,
  reducers: {
    showForCustomer(state, { payload }: PayloadAction<ShowForCustomerResult>) {
      state.termsOfService = payload
    },
    getTermsOfServiceContent(state, { payload }: PayloadAction<string>) {
      state.termsOfServiceContent = payload
    },
  },
  extraReducers: builder => {
    // Show for customer
    builder.addCase(showForCustomer.pending, ({ promisesStatus }) => {
      promisesStatus.showForCustomer = sliceUtil.pendingPromise(
        promisesStatus.showForCustomer
      )
    })
    builder.addCase(
      showForCustomer.fulfilled,
      (state, { payload }: PayloadAction<ShowForCustomerResult>) => {
        state.promisesStatus.showForCustomer = sliceUtil.fulfilledPromise()
        state.termsOfService = payload
      }
    )
    builder.addCase(showForCustomer.rejected, (state, action) => {
      state.promisesStatus.showForCustomer = sliceUtil.rejectedPromise(
        action.payload
      )
    })

    // Accept
    builder.addCase(accept.pending, ({ promisesStatus }) => {
      promisesStatus.accept = sliceUtil.pendingPromise(promisesStatus.accept)
    })
    builder.addCase(accept.fulfilled, state => {
      state.promisesStatus.accept = sliceUtil.fulfilledPromise()
    })
    builder.addCase(accept.rejected, (state, action) => {
      state.promisesStatus.accept = sliceUtil.rejectedPromise(action.payload)
    })

    // Get terms of service content
    builder.addCase(getTermsOfServiceContent.pending, ({ promisesStatus }) => {
      promisesStatus.getTermsOfServiceContent = sliceUtil.pendingPromise(
        promisesStatus.getTermsOfServiceContent
      )
    })
    builder.addCase(
      getTermsOfServiceContent.fulfilled,
      (state, { payload }: PayloadAction<string>) => {
        state.promisesStatus.getTermsOfServiceContent =
          sliceUtil.fulfilledPromise()
        state.termsOfServiceContent = payload
      }
    )
    builder.addCase(getTermsOfServiceContent.rejected, (state, action) => {
      state.promisesStatus.getTermsOfServiceContent = sliceUtil.rejectedPromise(
        action.payload
      )
    })
  },
})

export default termsOfServiceSlice.reducer
export const { actions } = termsOfServiceSlice
export const stateSelectors = (state: RootState) => state.termsOfService
