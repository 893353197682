import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.5 10c0 1.8-.897 4.208-2.235 6.579-1.239 2.194-2.816 4.288-4.284 5.744-1.621-1.447-3.197-3.538-4.392-5.737C7.306 14.226 6.5 11.812 6.5 10a6.5 6.5 0 0113 0z"
        stroke="#9D9D9D"
      />
      <circle cx={13} cy={10} r={2} fill="#9D9D9D" />
    </svg>
  )
}

export default SvgComponent
