import { useChangeLayout } from 'hooks'
import { CancelParkCarDialog } from 'pages/park_car_credit_card/CreditCardComponents'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { paths } from 'routing'
import {
  AppSnackBarProvider,
  AppSpinner,
  ErrorPlaceholder,
  WhiteBoxContainer,
} from 'shared'
import { RootState } from 'store/rootReducer'
import { creditCardsSlice, parkCarSlice, ticketsSlice } from 'store/slices'
import { AppState, useAppDispatch } from 'store/store'
import { CarStatus } from 'typedef/ticket'
import Translations from 'utils/translations'
import usePay from './ParkCarSummaryHooks/usePay'
import ParkCarSummaryView from './ParkCarSummaryView'
export interface PaymentSummaryProps {}

export const ParkCarSummary: React.FC<PaymentSummaryProps> = props => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  const { ticketId } = useParams<{ ticketId: string }>()

  const ticket = useSelector((state: RootState) =>
    ticketsSlice.selectById(state, ticketId)
  )
  const { getTicketStatus, payForCarStatus } = useSelector((state: AppState) =>
    ticketsSlice.promiseStatusSelectors(state, ticketId)
  )
  const { getCreditCardsStatus } = useSelector(
    creditCardsSlice.promiseStatusSelectors
  )
  const selectedCard = useSelector(parkCarSlice.selectCardId)
  const { pay: onSubmit, loading } = usePay()
  const fetchData = () => dispatch(ticketsSlice.getTicket({ carId: ticketId }))

  React.useEffect(
    () => () => {
      dispatch(
        creditCardsSlice.actions.removeCardZip(selectedCard?.creditCardId)
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  React.useEffect(() => {
    if (getTicketStatus.fulfilled && !ticket) {
      return push(paths.parking.tickets)
    }
  }, [dispatch, getTicketStatus.fulfilled, push, ticket])

  React.useEffect(() => {
    if (getTicketStatus.fulfilled && ticket) {
      if (!selectedCard && ticket.carStatus === CarStatus.RequiresPayment) {
        push(`${paths.parking.selectCard}/${ticketId}`)
      }
    }
  }, [getTicketStatus.fulfilled, push, selectedCard, ticket, ticketId])

  const showElement = () => {
    const spinner =
      getTicketStatus.pendingRejected ||
      getTicketStatus.pendingIdle ||
      loading ||
      getCreditCardsStatus.pending ||
      payForCarStatus.pending
    const error = getTicketStatus.pendingRejected || getTicketStatus.rejected
    const summary =
      Boolean(ticket) &&
      (getTicketStatus.fulfilled || getTicketStatus.pendingFulfilled) &&
      Boolean(selectedCard)

    return { spinner, error, summary }
  }
  return (
    <WhiteBoxContainer minHeight={150}>
      {<AppSpinner show={showElement().spinner} />}
      {showElement().error && <ErrorPlaceholder onClick={fetchData} />}
      {showElement().summary && (
        <ParkCarSummaryView
          pending={payForCarStatus.pending}
          paid={false}
          onSubmit={onSubmit}
          ticket={ticket}
          card={selectedCard}
          summaryProps={ticket}
        />
      )}
    </WhiteBoxContainer>
  )
}

export const ParkCarSummaryPage: React.FC = props => {
  const { goBack } = useHistory()
  const { ticketId } = useParams<{ ticketId: string }>()
  const { t } = useTranslation()
  const [showDialog, setShowDialog] = React.useState(false)
  const title = t(Translations.PARK_CAR)
  const ticket = useSelector((state: RootState) =>
    ticketsSlice.selectById(state, ticketId)
  )
  const { pending } = useSelector((state: RootState) =>
    ticketsSlice.promiseStatusSelectors(state, ticketId)
  ).payForCarStatus
  const selectedCard = useSelector(parkCarSlice.selectCardId)
  const { pricing } = useSelector(parkCarSlice.stateSelectors)
  const dispatch = useAppDispatch()
  const onClickRight = () => {
    setShowDialog(true)
  }
  const noTopbarButtons = () =>
    ticket?.carStatus === CarStatus.Parked || pending
  useChangeLayout(
    {
      topbar: {
        title,
        onClickBack: goBack,
        hasGoBack: noTopbarButtons() ? false : true,
        rightIcon: noTopbarButtons() ? null : 'close',
        onClickRight,
      },
      bottomMenu: false,
    },
    'app',
    [ticket?.carStatus, pending]
  )

  React.useEffect(
    () => () => {
      dispatch(parkCarSlice.actions.setZipCode(''))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch]
  )

  if (!ticketId) {
    return <Redirect to={paths.home.map} />
  }
  if (!selectedCard || !pricing) {
    return <Redirect to={paths.parking.tickets} />
  }

  return (
    <AppSnackBarProvider>
      <ParkCarSummary />
      <CancelParkCarDialog
        open={showDialog}
        closeDialog={() => setShowDialog(false)}
      />
    </AppSnackBarProvider>
  )
}

export default ParkCarSummaryPage
