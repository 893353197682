import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CardType } from 'typedef/creditCards'
import { Translations } from 'utils'
import * as C from './Cards'

const StyledCardWrapperFullDetails = styled.div<{
  digits: string
  unknown: boolean
}>`
  position: relative;
  transition: all 0.2s;
  width: 290px;
  margin: auto;
  text-align: center;
  :after {
    content: '${_ => _.digits}';
    position: absolute;
    bottom: 40%;
    right: 14px;
    color: ${_ => (_.unknown ? '#646464' : 'white')};
    font-weight: bold;
    letter-spacing: 0.3em;
    width: auto;
    font-family: 'SF Pro Display', 'Helvetica', 'Arial', sans-serif;
    text-align: right;
  }
`
const ExpDate = styled.span<{ unknown: boolean }>`
  position: absolute;
  bottom: 20%;
  right: 18px;
  color: ${_ => (_.unknown ? '#646464' : 'white')};
  font-weight: 500;
  font-size: 14px;
  width: auto;
  font-family: 'SF Pro Display', 'Helvetica', 'Arial', sans-serif;
  text-align: right;
`
export interface CardWrapperFullDetailsProps {
  digits: string
  month: string
  year: string
  type: CardType
}
export const CardWrapperFullDetails: React.FC<CardWrapperFullDetailsProps> = ({
  type,
  digits,
  month,
  year,
}) => {
  const {
    AmericanExpress,
    DinersClub,
    Discover,
    Invalid,
    JCB,
    MasterCard,
    Visa,
  } = CardType
  const { t } = useTranslation()
  return (
    <StyledCardWrapperFullDetails
      digits={digits}
      unknown={type === CardType.Invalid}
    >
      <ExpDate unknown={type === CardType.Invalid}>
        {`${month || t(Translations.MONTH_SHORT)}/${year || t(Translations.YEAR_SHORT)}`}
      </ExpDate>
      <>
        {type === MasterCard && <C.MasterCard />}
        {type === DinersClub && <C.DinersClub />}
        {type === AmericanExpress && <C.AmericanExpress />}

        {type === Discover && <C.Discover />}
        {type === JCB && <C.Jbc />}
        {type === Visa && <C.Visa />}
        {type === Invalid && <C.Unknown />}
      </>
    </StyledCardWrapperFullDetails>
  )
}

export default CardWrapperFullDetails
